
  import {computed, defineComponent, reactive, SetupContext, toRefs, watch} from "vue";
  import useVuelidate from "@vuelidate/core";
  import moment from "moment";

  export default defineComponent({
    name: "FormDatePicker",
    props: {
      birthdayPicker: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      errorMessage: {
        type: String,
        default: null
      },
      format: {
        type: String,
        default: "DD.MM.YYYY"
      },
      label: {
        type: String,
        default: "<label>"
      },
      maxWidth: {
        type: Number,
        default: null
      },
      min: {
        type: String,
        default: null
      },
      month: {
        type: Boolean,
        default: false,
      },
      textInput: {
        type: Boolean,
        default: false
      },
      value: {
        type: String,
        default: ""
      }
    },
    setup(props, context: SetupContext) {
      const initialState = {
        dialog: false,
        currentDateText: props.value
      };

      const state = reactive({...initialState});

      const rules = computed(() => ({
        currentDateText: {
          isValidFormat (value: string): boolean {
            if (value) {
              const customFormatValid = moment(value, props.format, true).isValid();
              const isoFormatValid = moment(value, "YYYY-MM-DD", true).isValid();
              return props.month || customFormatValid || isoFormatValid;
            } else {
              return true;
            }
          }
        }
      }));

      // @ts-ignore
      const v$ = useVuelidate(rules, state);

      const displayedErrorMessage = computed (() => {
        if (!props.month && v$.value.$dirty && !v$.value.currentDateText.isValidFormat) {
          return `Ungültiges Datum. Erlaubt sind ${props.format} oder YYYY-MM-DD`;
        } else {
          return props.errorMessage;
        }
      });

      const date = computed({
        get(): string {
          if (props.month) {
            return props.value ? moment(props.value).format(props.format) : "";
          } else {
            if (props.value && moment(props.value).isValid()) {
              return moment(props.value).format(props.format);
            } else {
              return state.currentDateText;
            }
          }
        },
        set(currentDateText: string): void {
          if (!props.month) {
            state.currentDateText = currentDateText;
          }
        },
      });

      const pickerDate = computed(() => {
        return props.birthdayPicker ? moment().subtract(18, "years").format("YYYY-MM") : undefined;
      });

      const isValidFormat = (date: string) => {
        if (date) {
          return moment(date, props.format, true).isValid() || moment(date, "YYYY-MM-DD", true).isValid();
        } else {
          return true;
        }
      };

      const onDateSelected = (newDate: string) => {
        context.emit("input", newDate);
        state.dialog = false;
      };

      const updateDate = (date: string) => {
        // Don't do stuff to the textfield if the picker dialog is open
        if (!state.dialog) {
          v$.value.$reset();
          v$.value.$touch();

          if (!v$.value.$invalid || props.month) {
            if (moment(date, props.format, true).isValid()) {
              context.emit("input", moment(date, props.format).format("YYYY-MM-DD"));
            } else if (moment(date, "YYYY-MM-DD", true).isValid()) {
              context.emit("input", date);
            } else {
              context.emit("input", date);
            }
          } else {
            context.emit("input", "");
          }
        }
      };

      const onTextfieldClicked = () => {
        // Only open the picker if we do not allow manual text input
        if (!props.textInput) {
          state.dialog = true;
        }
      };

      watch(() => state.dialog, (newValue, oldValue) => {
        if (props.birthdayPicker) {
          // @ts-ignore
          newValue && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
        }
      });

      watch(() => props.value, (newValue, oldValue) => {
        if (isValidFormat(newValue)) {
          v$.value.$reset();
          state.currentDateText = newValue;
        } else {
          state.currentDateText = "";
        }
      });

      return {
        ...toRefs(state),
        v$,
        date,
        displayedErrorMessage,
        pickerDate,
        onDateSelected,
        onTextfieldClicked,
        updateDate
      };
    }
  });
