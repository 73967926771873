
  import {computed, ComputedRef, defineComponent, onMounted, PropType, reactive, toRefs} from "vue";
  import {AccountingEmployee} from "@/service/AccountingService";

  export default defineComponent({
    name: "AccountingEmployeesList",
    props: {
      items: {
        type: Array as PropType<Array<AccountingEmployee>>,
        default: () => new Array<AccountingEmployee>()
      },
      loading: {
        type: Boolean,
        default: false
      },
    },
    setup(props) {

      const initialState = {
        sortingCriteria: [
          { value: "name", localizedName: "Name" },
          { value: "employeeId", localizedName: "Mitarbeiter-ID" },
          { value: "work", localizedName: "Arbeitszeit" },
          { value: "travel", localizedName: "Reisezeit" }
        ],
        activeSort: {
          criterion: { value: "name", localizedName: "Name" },
          direction: "ASC"
        },
        visibleEmployeesCount: 25,
        sheet: false
      };

      const state = reactive({...initialState});

      const sortedEmployees: ComputedRef<Array<AccountingEmployee>> = computed(()=> {
        const filteredEmployees = props.items.slice(0) as Array<AccountingEmployee>;
        if (state.activeSort && state.activeSort.criterion) {
          filteredEmployees.sort((a, b) => {
            if (state.activeSort.criterion.value === "name") {
              return state.activeSort.direction === "ASC" ? a.employee.lastName.localeCompare(b.employee.lastName) : b.employee.lastName.localeCompare(a.employee.lastName);
            } else if (state.activeSort.criterion.value === "employeeId") {
              return state.activeSort.direction === "ASC" ? a.employee.employeeId - b.employee.employeeId : b.employee.employeeId - a.employee.employeeId;
            } else if (state.activeSort.criterion.value === "work") {
              return state.activeSort.direction === "ASC" ? a.minutes.minutesWork - b.minutes.minutesWork : b.minutes.minutesWork - a.minutes.minutesWork;
            } else if (state.activeSort.criterion.value === "travel") {
              return state.activeSort.direction === "ASC" ? a.minutes.minutesTravel - b.minutes.minutesTravel : b.minutes.minutesTravel - a.minutes.minutesTravel;
            } else {
              return a.employee.employeeId - b.employee.employeeId;
            }
          });
        }

        return filteredEmployees.slice(0, state.visibleEmployeesCount);
      });

      const changeSort = (selectedCriterion: any) => {
        state.sheet = false;

        let direction = "ASC";
        if (state.activeSort.criterion === selectedCriterion) {
          direction = (state.activeSort.direction === "ASC" ? "DESC" : "ASC");
        }

        state.activeSort = {
          criterion: selectedCriterion,
          direction: direction
        };
      };

      const onScroll = (event: any) => {
        const maxHeight = document.body.scrollHeight;
        const scrollPosition = event.target.documentElement.scrollTop;
        const windowHeight = window.innerHeight;

        const invisibleElements = props.items.length - state.visibleEmployeesCount;
        if (invisibleElements > 0 && (scrollPosition + windowHeight) >= maxHeight) {
          const increment = (invisibleElements > 25) ? 25 : invisibleElements;
          state.visibleEmployeesCount += increment;
        }
      };

      onMounted(() => {
        state.activeSort = {
          // @ts-ignore
          criterion: state.sortingCriteria.find(criterion => criterion.value === "name"),
          direction: "ASC"
        };
      });

      return {
        ...toRefs(state),
        sortedEmployees,
        changeSort,
        onScroll
      };
    }
  });
