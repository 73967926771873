
  import {defineComponent, computed, PropType, Ref} from "vue";
  import {CompanyLocation} from "@/model/CompanyLocation";

  export default defineComponent({
    name: "InventoriesCompanyLocationsGeneralForm",
    props: {
      companyLocation: {
        required: true,
        type: Object as PropType<CompanyLocation>
      },
      errors: {
        required: false,
        type: Map as PropType<Map<string, string | Ref<string>>>,
        default: () => new Map<string, string>()
      }
    },
    setup(props, context) {

      const companyLocationInputModel = computed({
        get(): CompanyLocation {
          return props.companyLocation;
        },
        set(newValue: CompanyLocation) {
          context.emit("companyLocationChanged", newValue);
        },
      });

      return {
        companyLocationInputModel
      };
    }
  });
