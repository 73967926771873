import Worklog from "@/model/Worklog";

export default class TimeclockEmployeeEntry {
  public id: number;
  public employeeId: number;

  public firstName: string;

  public lastName: string;

  public operatorNumber?: number;

  public openWorklog?: Worklog;

  public clockingStatus?: string;

  public statusMessage?: string;

  public validationError?: string;

  public worklogType?: string | null;

  public clockingAction?: string | null;

  public selected = false;

  public get fullName () {
    return this.firstName + " " + this.lastName;
  }
}
