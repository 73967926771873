
  import {defineComponent, PropType} from "vue";
  import {CompanyLocation} from "@/model/CompanyLocation";
  import InventoriesCompanyLocationsListTile from "./InventoriesCompanyLocationsListTile.vue";

  export default defineComponent({
    name: "InventoriesCompanyLocationsList",
    components: {
      InventoriesCompanyLocationsListTile
    },
    props: {
      companyLocations: {
        type: Array as PropType<CompanyLocation[]>,
        default: () => new Array<CompanyLocation>()
      }
    },
    setup (props, context) {
      const openCompanyLocationDialog = (companyLocation: CompanyLocation) => {
        context.emit("openCompanyLocationDialog", companyLocation);
      };

      return {
        openCompanyLocationDialog
      };
    }
  });
