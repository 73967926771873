export default class EmployeeName {
  public id: number;

  public firstName: string;

  public lastName: string;

  public employeeId: number;

  public get fullName (): string {
    return this.firstName + " " + this.lastName;
  }
}
