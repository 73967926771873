<template>
  <VDialog
    v-model="dialog"
    max-width="500px"
  >
    <VCard>
      <VCardTitle flat>
        <span class="header-title">
          Neue Rolle hinzufügen
        </span>
        <VSpacer />
        <VBtn
          class="close-button"
          icon
          @click.stop="dialog=false"
        >
          <VIcon class="close-button-icon">
            mdi-close
          </VIcon>
        </VBtn>
      </VCardTitle>

      <VCardText>
        <b>
          {{ getUserName() }}
        </b> hat die folgenden Rollen
        <ul>
          <li
            v-for="assignedRole in assignedRoles"
            :key="assignedRole.id"
          >
            {{ assignedRole.name }}
          </li>
        </ul>
        <VSelect
          v-model="selectedRole"
          class="mt-4"
          :items="availableRoles"
          label="Neue Rolle"
          item-text="name"
          item-value="id"
          no-data-text="Keine weiteren Rollen verfügbar"
        />

        <VAlert
          :value="showWarning"
          type="warning"
        >
          Es wurde keine Rolle ausgewählt.
        </VAlert>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <VBtn
          text
          @click.stop="dialog=false"
        >
          Abbrechen
        </VBtn>
        <VBtn
          color="primary"
          text
          @click.native="assignRole()"
        >
          Hinzufügen
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
<script>

  import RightsService from "@/service/RightsService";
  import { MessageTypes } from "@/components/message-types";

  export default {
    data: () => ({
      dialog: false,
      selectedRole: null,
      result: null,
      showWarning: false,
      user: null,
      assignedRoles: [],
      availableRoles: []
    }),
    watch: {
      selectedRole (role) {
        if (role != null) {
          this.showWarning = false;
        }
      }
    },
    methods: {
      getUserName: function () {
        return (this.user != null) ? this.user.name : "";
      },
      open: function (user, assignedRoles, availableRoles) {
        this.selectedRole = null;
        this.user = user;
        this.assignedRoles = assignedRoles;
        this.availableRoles = availableRoles;

        this.showWarning = false;
        this.dialog = true;
        const vue = this;

        return new Promise(function (resolve, reject) {
          vue.result = resolve;
        });
      },
      assignRole: function () {
        if (this.selectedRole != null) {
          RightsService.assignRoleToUser(this.user.id, this.selectedRole)
            .then(response => {
              this.result(this.selectedRole);
              this.dialog = false;
              this.$eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, this.$vuetify.lang.t("$vuetify.roleAdded"));
            });
        } else {
          this.showWarning = true;
        }
      }
    }
  };
</script>
