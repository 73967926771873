<template>
  <VDataTable
    :headers="getVisibleHeaders"
    :items="filteredItems"
    :loading="loading"
    no-data-text="Keine Daten verfügbar"
    :hide-default-footer="false"
    :footer-props="footerProps"
    :hide-default-header="false"
    :show-select="false"
    item-key="id"
  >
    <template #header.settings>
      <VMenu
        bottom
        left
      >
        <template #activator="{ on }">
          <VBtn
            text
            icon
            v-on="on"
          >
            <VIcon>
              mdi-dots-vertical
            </VIcon>
          </VBtn>
        </template>
        <VList>
          <VListItem @click="filterRowVisible = !filterRowVisible">
            <VListItemIcon>
              <VIcon>
                mdi-filter-variant
              </VIcon>
            </VListItemIcon>
            <VListItemTitle>
              Filter {{ filterRowVisible ? "ausblenden" : "anzeigen" }}
            </VListItemTitle>
          </VListItem>
          <VListItem @click="openColumnDialog">
            <VListItemIcon>
              <VIcon>
                mdi-table-settings
              </VIcon>
            </VListItemIcon>
            <VListItemTitle>
              Spaltenanzeige ändern
            </VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>

      <VDialog
        ref="columnDialog"
        v-model="columnDialog"
        max-width="290"
        :return-value.sync="headers"
      >
        <VCard>
          <VCardTitle>
            Spaltenanzeige ändern
          </VCardTitle>

          <VCardText>
            Wähle die Spalten aus, die angezeigt werden sollen.
          </VCardText>

          <VList>
            <VListItem
              v-for="column in columns"
              :key="column.value"
              @click="column.visible = !column.visible"
            >
              <template v-if="column.selectable">
                <VListItemAction>
                  <VCheckbox
                    v-model="column.visible"
                    @click.self.capture.stop=""
                  />
                </VListItemAction>

                <VListItemContent>
                  <VListItemTitle>
                    {{ column.text }}
                  </VListItemTitle>
                </VListItemContent>
              </template>
            </VListItem>
          </VList>

          <VCardActions>
            <VSpacer />
            <VBtn
              color="primary"
              text
              @click="$refs.columnDialog.save(columns)"
            >
              Fertig
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #body="{ items, headers }">
      <tbody>
        <tr
          v-if="filterRowVisible"
          class="filter-row"
        >
          <td
            v-for="header in headers"
            :key="header.text"
          >
            <VRow>
              <template v-if="header.text !== ''">
                <VCol
                  v-if="header.filter !== false"
                  class="pr-3 pb-3 pt-0"
                  cols="12"
                >
                  <VSelect
                    v-if="header.enumTypes"
                    v-model="filters[header.value]"
                    item-value="code"
                    item-text="localizedName"
                    single-line
                    hide-details
                    :items="header.enumTypes"
                    clearable
                    :placeholder="header.text"
                  />
                  <VTextField
                    v-else
                    v-model="filters[header.value]"
                    single-line
                    hide-details
                    :placeholder="header.text"
                    class="text-center"
                  />
                </VCol>
              </template>
              <template v-else>
                <VCol style="min-width: 40px" />
              </template>
            </VRow>
          </td>
        </tr>
        <tr
          v-for="item in items"
          :key="item.id"
          @click.stop="openCustomer(item)"
        >
          <template v-for="header in headers">
            <td
              v-if="getColumnVisibilityMap[header.value] && !header.onlyHeader"
              :key="header.text"
              :class="`cell no-padding text-${header.align}`"
              :width="header.width"
            >
              {{ item[header.value] }}
            </td>
          </template>
          <td class="cell no-padding text-center px-2 sheet">
            <VBtn
              text
              icon
              class="ma-0"
              @click="openCustomer(item)"
            >
              <VIcon>
                mdi-pencil
              </VIcon>
            </VBtn>
          </td>
        </tr>
      </tbody>
    </template>
  </VDataTable>
</template>

<script>
  import FilterUtils from "@/FilterUtils";

  export default {
    name: "CustomersTable",
    props: {
      items: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data: (self) => ({
      headers: [
        {
          text: "ID",
          align: "left",
          value: "id",
          visible: true,
          selectable: true,
          width: 100
        },
        {
          text: "Name",
          align: "left",
          value: "name",
          visible: true,
          selectable: true
        },
        {
          text: "Unternehmen",
          align: "left",
          value: "company",
          visible: true,
          selectable: true
        },
        {
          text: "Adresse",
          align: "left",
          value: "addressAsString",
          visible: true,
          selectable: true
        },
        {
          text: "Kundenkostenstelle",
          align: "left",
          value: "costCenterFormatted",
          visible: true,
          selectable: true,
          width: 200
        },
        {
          text: "Warenwert",
          align: "left",
          value: "valueFormatted",
          visible: true,
          selectable: true,
          width: 150
        },
        {
          text: "",
          align: "right",
          value: "settings",
          sortable: false,
          width: 40,
          visible: true,
          selectable: false,
          onlyHeader: true
        }
      ],
      filters: {
        customerId: "",
        name: "",
        company: "",
        city: "",
        addressAsString: "",
        valueFormatted: ""
      },
      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, -1]
      },
      filterRowVisible: true,
      columnDialog: false,
      columnDialog2: false,
      columns: []
    }),
    computed: {
      getColumnVisibilityMap () {
        return Object.assign(...this.getAvailableHeaders.map(({ value, visible }) => ({ [value]: visible })));
      },
      getVisibleHeaders () {
        return this.getAvailableHeaders.filter(header => header.visible);
      },
      getAvailableHeaders () {
        return this.headers
          .filter(header => header.authorization === undefined || this.isAuthorized(header.authorization));
      },
      filteredItems () {
        let filteredItems = this.items.slice(0) ;

        for (const filterProp in this.filters) {
          if (typeof this.filters[filterProp] === "boolean" || (this.filters[filterProp] && this.filters[filterProp].trim() !== "")) {
            if(filterProp === "costCenterFormatted") {
              filteredItems = filteredItems.filter(
                item =>{
                  let costCenterFormatted = item.costCenterFormatted ? item.costCenterFormatted : "";
                  return FilterUtils.compare(costCenterFormatted, this.filters[filterProp], true);
                });
            } else {
              filteredItems = filteredItems.filter(
                item => FilterUtils.compare(item[filterProp], this.filters[filterProp]));
            }
          }
        }

        return filteredItems;
      }
    },
    methods: {
      openColumnDialog () {
        this.columns = JSON.parse(JSON.stringify(this.getAvailableHeaders));
        this.columnDialog = true;
      },
      openCustomer (item) {
        this.$router.push({ path: `/customers/${item.id}/info` });
      }
    }
  };
</script>
<style lang="scss" scoped>
.v-data-table tbody {
  tr {
    &.filter-row {
      &:hover {
        background: white !important;
      }

      td {
        border-bottom: none !important;
      }
    }
  }

  tr:not(.filter-row) {
    .v-btn--icon:hover {
      color: var(--v-primary-base) !important;
    }

    td {
      border-bottom: none !important;
    }

    .v-icon {
      visibility: hidden;
    }

    &:hover {
      cursor: pointer;

      .v-icon {
        visibility: visible;
      }
    }
  }
}
</style>
