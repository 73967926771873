import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify-center":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"10"}},[_c(VList,{attrs:{"two-line":""}},[_vm._l((_vm.sortedGroups),function(group,groupIndex){return [_c(VSubheader,{key:`subheader-${groupIndex}`},[_vm._v(" "+_vm._s(_vm.getOrderTypeName(group.orderType))+" ")]),_vm._l((group.orders),function(item){return [_c('OrderListTile',{key:item.id,attrs:{"order":item,"icon":"mdi-clock","to":"timeclockOrder"}})]}),_c(VDivider,{key:`divider-${groupIndex}`})]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }