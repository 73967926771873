import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAction } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"pa-0":"","mx-0":"","fluid":""}},[_c('TimeclockOrderTabSelectableList',{attrs:{"items":_vm.pauseable,"errors":_vm.errors,"title":"Pausierbar"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.selectAll(_vm.pauseable)}}},[_c(VIcon,{attrs:{"color":_vm.allItemsSelected ? 'primary' : ''}},[_vm._v(" mdi-pause-circle-outline ")])],1)],1)]},proxy:true},{key:"default",fn:function(slotProps){return [_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.onClick(slotProps.item)}}},[_c(VIcon,{attrs:{"color":_vm.itemColor(slotProps.item)}},[_vm._v(" mdi-pause-circle-outline ")])],1)],1)]}}])}),_c(VDivider,{class:{ 'mb-5': _vm.selectedItems.length > 0}}),(_vm.selectedItems.length > 0)?_c('FabBlockButton',{on:{"click":function($event){return _vm.pause()}}},[_vm._v(" Pausieren ")]):_vm._e(),_c('TimeclockOrderEmployeeList',{attrs:{"items":_vm.paused,"title":"Pausiert"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }