import "@/class-component-hooks";

import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "@/router";
import store, {Actions} from "@/store/store";
import axios, {AxiosError} from "axios";
import moment from "moment";
import "@fontsource/roboto";
import eventBus from "@/eventbus";
import "@/filter";
import {MessageTypes} from "@/components/message-types";
import Authentication from "@/model/Authentication";
import {AuthMutations} from "@/store/modules/auth";
import LoggingService from "@/service/LoggingService";
import {loading, loadingFinished} from "@/MessageUtils";
import {isAuthorized, t} from "@/mixin/mixins";

Vue.config.productionTip = true;

Object.defineProperty(Vue.prototype, "$axios", { value: axios });
Object.defineProperty(Vue.prototype, "$moment", { value: moment });
Object.defineProperty(Vue.prototype, "$eventBus", { value: eventBus });
Object.defineProperty(Vue.prototype, "t", { value: t });

// All requests are to be routed to the /api endpoint
const baseUrl = `${process.env.VUE_APP_API ? process.env.VUE_APP_API : ""}/api`;
axios.defaults.baseURL = baseUrl;

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  eventBus.$emit(MessageTypes.LOAD);
  loading();

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response: any) {
  loadingFinished();

  // Do something with response data
  return response;
}, function (error: any) {
  loadingFinished();

  if(error instanceof AxiosError) {
    const axiosError = error as AxiosError;
    if(axiosError.code === "ECONNABORTED") {
      eventBus.$emit(MessageTypes.ERROR, "Request timed out");
    }
  }

  if (error.response.status === 401) {
    eventBus.$emit(MessageTypes.CLOSE_ALL_DIALOGS);
    eventBus.$emit(MessageTypes.ERROR, "Session abgelaufen");
    store.commit("auth/" + AuthMutations.SET_AUTHENTICATION, new Authentication());
    setTimeout(function () {
      router.push("/login");
    }, 500);
  } else if (error.response.status === 403) {
    eventBus.$emit(MessageTypes.ERROR, "Zugriff verweigert!");
    if (!error.response.data) {
      setTimeout(function () {
        store.commit("auth/" + AuthMutations.SET_AUTHENTICATION, new Authentication());
        router.push("/login");
      }, 2000);
    }
  } else if (error.response.status === 404) {
    // This should be handled by the corresponding view
    return Promise.reject(error);
  } else if (error.response.status === 409) {
    // Dirty hack where we overwrite the default behavior for one use case as it is currently unknown where the current
    // behavior might be desired
    if(error && error.config && error.config.url && error.config.url.indexOf("/operators") != -1) {
      return Promise.reject(error);
    }
    // @ts-ignore
    eventBus.$emit(MessageTypes.ERROR, error.response.data.title);
  } else if (error.response.status === 410) {
    // This should be handled by the corresponding view
    return Promise.reject(error);
  } else if (error.response.status !== 422) {
    const routerLog = {
      name: router.currentRoute.name,
      path: router.currentRoute.path,
      params: router.currentRoute.params,
      query: router.currentRoute.query
    };
    if (error.response.config.url !== "/logger") {
      // @ts-ignore
      LoggingService.error(error.response.status, routerLog, error.response.data);
    }
    eventBus.$emit(MessageTypes.ERROR, "Ein technischer Fehler ist aufgereten.");
  }

  // Do something with response error
  return Promise.reject(error);
});


Vue.mixin({
  methods: {
    isAuthorized(authority: string): boolean {
      return isAuthorized(authority);
    }
  }
});

const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

eventBus.$on(MessageTypes.AUTHENTICATED, () => {
  store.dispatch(Actions.GET_EARLIEST_VALID_FROM);
});

export default vue;
