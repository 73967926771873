export function groupBy<TYPE, KEY, VALUE>(items: Array<TYPE>, keyFunction: ((value: TYPE) => KEY),
                                          valueFunction: ((currentValue: VALUE | null | undefined, newValue: TYPE) => VALUE)): Map<KEY, VALUE> {
    const map = new Map<KEY, VALUE>();
    items.forEach((item) => {
        const key = keyFunction.call(null, item);

        if (!map.has(key)) {
            const value = valueFunction.call(null, null, item);
            map.set(key, value);
        } else {
            const currentValue = map.get(key);
            map.set(key, valueFunction.call(null, currentValue, item));
        }
    });
    return map;
}

export function zeroIfNull(value: number | undefined) {
    return value ?? 0;
}

export function access(value: any, path: string) {
    if (path) {
        if (path.indexOf(".") != -1) {
            let search = value;
            path.split(".").forEach((property: string) => (search = search[property]));

            return search;
        } else {
            return value[path];
        }
    } else {
        return value;
    }
}

export default {
    groupBy<T>(items: Array<T>, keyGetter: Function): Map<any, Array<any>> {
        const map = new Map();
        items.forEach((item) => {
            const key = keyGetter(item);
            if (!map.has(key)) {
                map.set(key, [item]);
            } else {
                const collection = map.get(key);
                collection.push(item);
            }
        });
        return map;
    },
    remove(items: Array<any>, toRemove: any) {
        const indexToRemove = items.indexOf(toRemove);
        items.splice(indexToRemove, 1);
    },
    removeIfExists(items: Array<any>, toRemove: any) {
        if (items.indexOf(toRemove) !== -1) {
            this.remove(items, toRemove);
        }
    },
    replaceIfExists(items: Array<any>, source: any, target: any) {
        if (items.indexOf(source) !== -1) {
            this.remove(items, source);
            items.push(target);
        }
    },
    toCamelCase(value: string) {
        return value.replace(/([-_]\w)/g, g => g[1].toUpperCase());
    }
};
