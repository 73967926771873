import axios, {AxiosResponse} from "axios";
import {PagedResult} from "@/service/PagedResult";
import Order from "@/model/Order";
import {OrderWorklogs} from "@/model/OrderWorklogs";
import {OrderType} from "@/enums";

const PATH = "/v2/orders";

class OrderServiceV2 {

  getOrder(orderId: number): Promise<AxiosResponse<Order>> {
    return axios.get(`${PATH}/${orderId}`);
  }

  updateOrder(orderId: number, data: unknown) {
    return axios.patch(`${PATH}/${orderId}`, data, {headers: {"Content-Type": "application/merge-patch+json"}});
  }

  deleteOrder(orderId: number) {
    return axios.delete(`${PATH}/${orderId}`);
  }

  findOrdersInTimeframe(from: string, to: string, include: string | null = null, orderType: OrderType | null = null): Promise<AxiosResponse<PagedResult>> {
    return axios.get(`${PATH}`, {
      params: {
        from: from,
        to: to,
        include: include,
        orderType: orderType,
        size: 1000
      }
    });
  }

  exportOrdersInTimeframe(from: string, to: string, orderType: OrderType | undefined = undefined): string {
    const baseUrl = `/api${PATH}/export?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`;
    const orderTypeParam = orderType ? `&orderType=${encodeURIComponent(orderType)}` : "";
    return baseUrl + orderTypeParam;
  }
   

  // Worklogs
  getOrderWorklogs(orderId: number, yearMonth: string | null): Promise<AxiosResponse<OrderWorklogs>> {
    if (yearMonth) {
      return axios.get(`${PATH}/${orderId}/worklogs`, {
        params: {
          yearMonth: yearMonth
        }
      });
    } else {
      return axios.get(`${PATH}/${orderId}/worklogs`);
    }
  }

  getOrderEmployeeAssignments(orderId: number): Promise<AxiosResponse<PagedResult>> {
    return axios.get(`${PATH}/${orderId}/assignments`);
  }
}

export default new OrderServiceV2();
