import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.dates.length > 0)?_c(VList,{attrs:{"two-line":""}},_vm._l((_vm.dates),function(date,index){return _c(VListGroup,{key:index,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItem,[_c(VListItemContent,{attrs:{"dense":""}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm._f("formatDate")(date,"dddd, Do MMMM YYYY"))+" ")])],1)],1)]},proxy:true}],null,true)},[_vm._l((_vm.groups.get(date)),function(worklog){return _c(VListItem,{key:worklog.id,on:{"click":function($event){return _vm.editWorklog(worklog)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(worklog.order.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.t("start"))+": "+_vm._s(worklog.start)+" "+_vm._s(_vm.t("end"))+": "+_vm._s(worklog.end)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.t("enum.worklogType." + worklog.worklogType))+" ")])],1),_c(VListItemAction,[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icon(worklog.worklogType))+" ")])],1)],1)}),_c(VListItem,[_c('AccountingEmployeeWorklogsSummaryChips',{attrs:{"worklogs":_vm.groups.get(date)}})],1)],2)}),1):_c('span',[_c(VFlex,{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.t("noDataText"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }