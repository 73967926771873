import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[(_vm.editable)?_c(VRow,[_c(VSpacer),_c(VSwitch,{staticClass:"mr-5",attrs:{"hide-details":"","label":_vm.t('accounting.perDiems.showPotentialPerDiems')},model:{value:(_vm.includePotentialPerDiems),callback:function ($$v) {_vm.includePotentialPerDiems=$$v},expression:"includePotentialPerDiems"}})],1):_vm._e(),_c(VRow,[_c(VCol,[_c('AccountingEmployeePerDiemsList',{attrs:{"editable":_vm.editable,"employee":_vm.employee,"per-diems":_vm.extendedPerDiems,"worklogs":_vm.worklogs}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }