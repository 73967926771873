export const GTE = "$vuetify.validation.greaterThanOrEqualTo";
export const MIN_VALUE = "$vuetify.validation.minValue";
export const NUMERIC = "$vuetify.validation.numeric";
export const REQUIRED = "$vuetify.validation.required";
export const INVALID = "$vuetify.validation.invalid";
export const VALID_DATE = "$vuetify.validation.validDate";
export const HINT = "hint";
export const YES = "yes";
export const NO = "no";
export const REGISTRATION = "registration";
export const VALUE = "value";
export const ADDING_ENTRY = "addingEntry";
export const ENTRY_ADDED = "entryAdded";
export const UPDATING_ENTRY = "updatingEntry";
export const ENTRY_UPDATED = "entryUpdated";
export const DELETING_ENTRY = "deletingEntry";
export const ENTRY_DELETED = "entryDeleted";
