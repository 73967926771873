import Vue from "vue";
import Vuex, {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";

import {
  RegistrationData,
  RegistrationDataAddressAndContact,
  RegistrationDataBanking,
  RegistrationDataCitizenship,
  RegistrationDataEducation,
  RegistrationDataEmployment,
  RegistrationDataMisc,
  RegistrationDataPersonal,
  RegistrationDataSocialSecurity,
  RegistrationDataTax
} from "@/model/Registration";
import RegistrationService from "@/service/RegistrationService";
import {RegistrationType} from "@/enums";

Vue.use(Vuex);

export class RegistrationState {
  registration: RegistrationData = new RegistrationData();
  registrationType: RegistrationType;
  workPermitShown: boolean;
}

export const RegistrationMutations = Object.freeze({
  SET_REGISTRATION: "setRegistration",
  SET_REGISTRATION_TYPE: "setRegistrationType",
  SET_WORK_PERMIT_SHOWN: "setWorkPermitShown"
});

const mutations: MutationTree<RegistrationState> = {
  [RegistrationMutations.SET_REGISTRATION] (state, registration: RegistrationData) {
    state.registration = registration;
  },
  [RegistrationMutations.SET_REGISTRATION_TYPE] (state, registrationType: RegistrationType) {
    state.registrationType = registrationType;
  },
  [RegistrationMutations.SET_WORK_PERMIT_SHOWN] (state, workPermitShown: boolean) {
    state.workPermitShown = workPermitShown;
  }
};

export const RegistrationActions = Object.freeze({
  GET_REGISTRATION: "getRegistration",
  UPDATE_REGISTRATION: "updateRegistration",
  UPDATE_WORK_PERMIT_SHOWN: "updateWorkPermitShown"
});

const actions: ActionTree<RegistrationState, RootState> = {
  async [RegistrationActions.GET_REGISTRATION] ({ commit }, registrationUuid: string) {
    return await RegistrationService.getRegistration(registrationUuid)
      .then(response => {
        commit(RegistrationMutations.SET_REGISTRATION_TYPE, response.data.registrationType);

        const registration = new RegistrationData();
        // @ts-ignore
        const registrationData = response.data.registrationData;
        registration.personal = Object.assign(new RegistrationDataPersonal(), registrationData.personal);

        if (registrationData.addressAndContact) {
          registration.addressAndContact = Object.assign(new RegistrationDataAddressAndContact(), registrationData.addressAndContact);
        }
        if (registrationData.banking) {
          registration.banking = Object.assign(new RegistrationDataBanking(), registrationData.banking);
        }
        if (registrationData.citizenship) {
          commit(RegistrationMutations.SET_WORK_PERMIT_SHOWN, false);
          registration.citizenship = Object.assign(new RegistrationDataCitizenship(), registrationData.citizenship);
        }
        if (registrationData.education) {
          registration.education = Object.assign(new RegistrationDataEducation(), registrationData.education);
        }
        if (registrationData.employment) {
          registration.employment = Object.assign(new RegistrationDataEmployment(), registrationData.employment);
        }
        if (registrationData.misc) {
          registration.misc = Object.assign(new RegistrationDataMisc(), registrationData.misc);
        }
        if (registrationData.tax) {
          registration.tax = Object.assign(new RegistrationDataTax(), registrationData.tax);
        }
        if (registrationData.socialSecurity) {
          registration.socialSecurity = Object.assign(new RegistrationDataSocialSecurity(), registrationData.socialSecurity);
        }

        commit(RegistrationMutations.SET_REGISTRATION, registration);

        return registration;
      });
  },
  async [RegistrationActions.UPDATE_REGISTRATION] ({ commit, state }, params) {
    return await RegistrationService.updateRegistration(params.registrationUuid, params.registration)
      .then(() => {
        const updatedRegistration = Object.assign(state.registration, params.registration);
        commit(RegistrationMutations.SET_REGISTRATION, updatedRegistration);

        return updatedRegistration;
      });
  },
  [RegistrationActions.UPDATE_WORK_PERMIT_SHOWN] ({ commit }, workPermitShown: boolean) {
    commit(RegistrationMutations.SET_WORK_PERMIT_SHOWN, workPermitShown);
  }
};

export const RegistrationGetters = Object.freeze({
  GET_REGISTRATION: "getRegistration",
  GET_REGISTRATION_TYPE: "getRegistrationType",
  GET_WORK_PERMIT_SHOWN: "getWorkPermitShown"
});

const getters: GetterTree<RegistrationState, RootState> = {
  [RegistrationGetters.GET_REGISTRATION] (state) {
    return state.registration;
  },
  [RegistrationGetters.GET_REGISTRATION_TYPE] (state) {
    return state.registrationType;
  },
  [RegistrationGetters.GET_WORK_PERMIT_SHOWN] (state) {
    return state.workPermitShown;
  }
};

export const registration: Module<RegistrationState, RootState> = {
  namespaced: false,
  state: new RegistrationState(),
  getters,
  actions,
  mutations
};
