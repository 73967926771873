
  import OrderAssignmentsList from "@/views/Orders/Assignments/OrderAssignmentsList.vue";
  import ConfirmDialog from "@/components/ConfirmDialog.vue";
  import ConfirmDialogImpl from "@/components/ConfirmDialog.vue";
  import EmployeeLookup from "@/components/EmployeeLookup.vue";
  import {MessageTypes} from "@/components/message-types";
  import OrderService from "../../../service/OrderService";
  import OrderServiceV2 from "@/service/OrderServiceV2";
  import PageUtils from "@/components/PageUtils";
  import {defineComponent, onMounted, reactive, ref, toRefs} from "vue";
  import {OrderEmployeeAssignment, OrderEmployeeAssignmentType} from "@/model/OrderEmployeeAssignment";
  import {useRoute} from "vue-router/composables";
  import eventBus from "@/eventbus";
  import {isAuthorized, t} from "@/mixin/mixins";
  import {orderId} from "@/RouterUtils";
  import Order from "@/model/Order";

  export default defineComponent({
    name: "OrdersAssignments",
    components: {
      OrderAssignmentsList: OrderAssignmentsList,
      ConfirmDialog: ConfirmDialog,
      EmployeeLookup: EmployeeLookup
    },
    setup(props, context) {

      const confirmDialog = ref<InstanceType<typeof ConfirmDialogImpl>>();
      const route = useRoute();

      const initialState = {
        dialog: true,
        order: new Order(),
        itemsAdmins: new Array<OrderEmployeeAssignment>(),
        itemsEmployees: new Array<OrderEmployeeAssignment>()
      };

      const state = reactive({...initialState});

      const load = () => {
        loadOrder();
        loadAssignments();
      };

      const loadOrder = () => {
        OrderServiceV2.getOrder(orderId.value)
          .then(response => {
            state.order = response.data;
          })
          .catch(() => { });
      };

      const loadAssignments = () => {
        OrderServiceV2.getOrderEmployeeAssignments(orderId.value)
          .then(response => {
            let assignments = PageUtils.extractCollection<OrderEmployeeAssignment>(response.data, "assignments");
            state.itemsAdmins = assignments.filter(assignment => assignment.assignmentType == OrderEmployeeAssignmentType.SUP);
            state.itemsEmployees = assignments.filter(assignment => assignment.assignmentType == OrderEmployeeAssignmentType.EMP);
          });
      };

      const employeeSelected = (items: any, selectedItem: any, type: OrderEmployeeAssignmentType) => {
        OrderService.assignEmployee(orderId.value, selectedItem.employeeId, type)
          .then(response => {
            if (response) {
              loadAssignments();
            }
            state.dialog = false;
          });
      };

      const deleteAssignment = (itemToDelete: OrderEmployeeAssignment) => {
        confirmDialog.value?.open("Löschen bestätigen", "Soll der Eintrag wirklich gelöscht werden?")
          .then(confirmResult => {
            if (confirmResult) {
              OrderService.unassignEmployee(orderId.value, itemToDelete.employee.employeeId)
                .then(() => {
                  loadAssignments();
                  eventBus.$emit(MessageTypes.SUCCESS, "Zuweisung entfernt!");
                });
            }
          });
      };

      const assignTeamLeader = (teamLeaderId: number) => {
        OrderServiceV2.updateOrder(orderId.value, { teamLeaderId: teamLeaderId })
          .then(response => {
            state.order = response.data;
            state.dialog = false;
          })
          .catch(() => {
            eventBus.$emit(MessageTypes.ERROR, t("errors.generalError"));
          });
      };

      onMounted(() => {
        load();
      });

      return {
        ...toRefs(state),
        confirmDialog,
        orderId,
        assignTeamLeader,
        employeeSelected,
        deleteAssignment,
        isAuthorized
      };
    }
  });
