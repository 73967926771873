import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/src/util/colors";
import de from "./de";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  lang: {
    locales: { de },
    current: "de"
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: "#B1CA00",
        secondary: "#B1CA00",
        accent: "#B1CA00",
        error: "#FF1744",
        info: "#B1CA00",
        ok: "#B1CA00",
        success: "#4CAF50",
        warning: "#FFC107",
        white: "#FFFFFF",
        work: colors.green.lighten2,
        travel: colors.blue.lighten2,
        passenger: colors.orange.lighten2
      },
      dark: {
        primary: "#B1CA00",
        secondary: "#B1CA00",
        accent: "#B1CA00",
        error: "#FF1744",
        info: "#B1CA00",
        ok: "#B1CA00",
        success: "#4CAF50",
        warning: "#FFC107",
        white: "#FFFFFF",
        work: colors.green.lighten2,
        travel: colors.blue.lighten2,
        passenger: colors.orange.lighten2
      }
    }
  }
});

export default vuetify;
