
  import Vue from "vue";
  import TheBottomSheet from "@/components/TheBottomSheet.vue";
  import DateRangeUtils, { DateRange, DateRangeUpdateVO } from "@/components/DateRangeUtils";
  import DateRangeSelection from "@/components/DateRangeSelection.vue";

  export default Vue.extend({
    name: "DateRangeBottomSheet",
    components: {
      DateRangeSelection,
      TheBottomSheet
    },
    data: () => ({
      selectedDateRange: (null as unknown as DateRange | null),
      sheet: false,
      currentDateSelection: (null as unknown as DateRangeUpdateVO | null)
    }),
    computed: {
      bottomSheetTitle (): string {
        return this.t("date.timerange");
      },
      dateText (): string {
        if (!this.currentDateSelection) {
          return DateRangeUtils.dateText(this.t, DateRange.LAST_TWO_WEEKS, null, null);
        } else {
          const dateRange = this.currentDateSelection.dateRange;
          const from = this.currentDateSelection.from;
          const to = this.currentDateSelection.to;
          return DateRangeUtils.dateText(this.t, dateRange, from, to);
        }
      }
    },
    mounted () {
      this.selectedDateRange = DateRange.LAST_TWO_WEEKS;
    },
    methods: {
      updateDates (updatedDates: DateRangeUpdateVO) {
        this.currentDateSelection = updatedDates;
        this.sheet = false;
        this.$emit("date-change", updatedDates);
      }
    }
  });
