import Vue from "vue";
import moment from "moment";
import Utils from "@/Utils";

export function toCurrency(value: number) {
  if (value === null) {
    return "-";
  } else {
    const formatter = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }
}

export function formatDate(value: string | undefined | null, format: string) : string | null {
  moment.locale("de");
  return value ? moment(value).format(format) : null;
}

Vue.filter("normalizeTimeString", function (value: string) {
  if (value) {
    moment.locale("de");
    return moment(value, "HH:mm:ss").format("HH:mm");
  } else {
    return value;
  }
});

Vue.filter("formatDate", function (value: string, format: string) {
  return formatDate(value, format);
});

Vue.filter("toCurrency", function (value: number) {
  return toCurrency(value);
});

Vue.filter("toTimeFormat", function (minutes: number) {
  const h = Math.floor(minutes / 60);
  const m = minutes % 60;
  const hour = h < 10 ? "0" + h : h;
  const minute = m < 10 ? "0" + m : m;
  return hour + ":" + minute;
});

Vue.filter("map", function (items: Array<any>, key: string) {
  if (items) {
    return items.map(item => item[key]);
  } else {
    return [];
  }
});

Vue.filter("toLocalizedName", function (enumCode: string, enumTypes: any) {
  const found = enumTypes.find((type: any) => type.code === enumCode);
  return found ? found.localizedName : "";
});

Vue.filter("sum", function (numbers: Array<number>) {
  return numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
});

Vue.filter("avg", function (numbers: Array<number>) {
  const elementsWithValues = numbers.filter(elem => elem != null);

  const sum = elementsWithValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (elementsWithValues.length > 0) ? sum / elementsWithValues.length : null;
});

Vue.filter("toCamelCase", function (value: string) {
  return Utils.toCamelCase(value);
});
