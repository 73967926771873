import { render, staticRenderFns } from "./OrdersListTile.vue?vue&type=template&id=2c5b2c45&scoped=true&"
import script from "./OrdersListTile.vue?vue&type=script&lang=ts&"
export * from "./OrdersListTile.vue?vue&type=script&lang=ts&"
import style0 from "./OrdersListTile.vue?vue&type=style&index=0&id=2c5b2c45&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c5b2c45",
  null
  
)

export default component.exports