import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c('RightsBreadcrumbs'),_c(VList,{staticClass:"py-0 thumbsup-list"},_vm._l((_vm.items),function(role){return _c('div',{key:role.roleId},[_c(VListItem,{staticClass:"thumbsup-list-item",on:{"click":function($event){return _vm.openRole(role)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(role.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(role.description)+" ")])],1),_c(VListItemAction,{staticClass:"text-right",staticStyle:{"min-width":"160px"},attrs:{"align-center":"","justify-center":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c('RouterLink',{attrs:{"to":_vm.getRoleUsersLink(role)},nativeOn:{"click":function($event){return (event => _vm.onOpenRoleUsers(event)).apply(null, arguments)}}},[_vm._v(" "+_vm._s(role.memberCount)+" Mitarbeiter ")])],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VIcon,{staticClass:"text-right"},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }