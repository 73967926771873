import axios, {AxiosResponse} from "axios";
import {PagedResult} from "@/service/PagedResult";

const PATH = "/v2/orders";

export interface OrderOperatorCreateRequest {
  employeeId: number;
  operatorNumber?: number;
  numberOfPositions?: number;
}

export interface OrderOperatorUpdateRequest {
  operatorNumber?: number;
  numberOfPositions?: number;
}

class OrderOperatorService {

  getOrderOperators (orderId: number): Promise<AxiosResponse<PagedResult>> {
    return axios.get(`${PATH}/${orderId}/operators`);
  }

  createOrderOperator (orderId: number, data: OrderOperatorCreateRequest) {
    return axios.post(`${PATH}/${orderId}/operators`, data, { headers: { "Content-Type": "application/json" } });
  }

  updateOrderOperator (orderId: number, orderOperatorId: number, data: OrderOperatorUpdateRequest) {
    return axios.put(`${PATH}/${orderId}/operators/${orderOperatorId}`, data, { headers: { "Content-Type": "application/json" } });
  }

  deleteOrderOperator (orderId: number, orderOperatorId: number) {
    return axios.delete(`${PATH}/${orderId}/operators/${orderOperatorId}`);
  }

  getOrderWorklogs (orderId: number): Promise<AxiosResponse<PagedResult>> {
    return axios.get(`${PATH}/${orderId}/operators/worklogs`);
  }
}

export default new OrderOperatorService();
