
  import EmployeeCreateDialogFormCard from "./EmployeeCreateDialogFormCard.vue";
  import EmployeeEmploymentForm from "@/views/Employee/forms/EmployeeEmploymentForm.vue";
  import EmployeePersonalForm from "../forms/EmployeePersonalForm.vue";
  import EmployeeWageForm from "../forms/EmployeeWageForm.vue";
  import ModalDialog from "@/components/ModalDialog.vue";

  import Employee from "@/model/Employee";
  import {computed, defineComponent, reactive, ref, toRefs} from "vue";
  import EmployeeService from "@/service/EmployeeService";
  import Wage from "@/model/Wage";
  import Employment from "@/model/Employment";
  import {MessageTypes} from "@/components/message-types";
  import {AxiosError} from "axios";
  import ValidationError from "@/model/ValidationError";
  import {t} from "@/mixin/mixins";
  import eventBus from "@/eventbus";

  export default defineComponent({
    name: "EmployeeCreateDialog",
    components: {
      EmployeeCreateDialogFormCard,
      EmployeeEmploymentForm,
      EmployeePersonalForm,
      EmployeeWageForm,
      ModalDialog
    },
    setup(props, context) {
      const errors = ref(new Map<string, string>());

      const state = reactive({
        dialog: false,
        employee: new Employee(),
        registrationUuid: null as unknown as string,
        result: (confirmed: boolean | PromiseLike<boolean>) => {
        },
        employeeErrors: computed(() => errors.value.forEach((value, key) => {
          if (!key.includes(".")) {
            errors.value.set(key, value);
          }
        })),
        employmentErrors: computed(() => errors.value.forEach((value, key) => {
          if (key.startsWith("employment.")) {
            // Only pass the prop name after wage.
            errors.value.set(key.split("employment.")[1], value);
          }
        })),
        wageErrors: computed(() => errors.value.forEach((value, key) => {
          if (key.startsWith("wage.")) {
            // Only pass the prop name after wage.
            errors.value.set(key.split("wage.")[1], value);
          }
        }))
      });

      const close = () => {
        state.dialog = false;
        state.result(false);
      };

      const open = (registrationUuid: string) => {
        state.dialog = true;
        state.registrationUuid = registrationUuid;

        EmployeeService.getDefaultData(registrationUuid)
          .then(response => {
            const employee = Object.assign(new Employee(), response.data);
            employee.wages = employee.wages
              .map(wage => Object.assign(new Wage(), wage));
            employee.employments = employee.employments
              .map(employment => Object.assign(new Employment(), employment));
            Object.assign(state.employee, employee);
          });

        return new Promise<boolean>((resolve) => {
          state.result = resolve;
        });
      };

      const save = () => {
        errors.value.clear();

        EmployeeService.createEmployee(state.employee, state.registrationUuid)
          .then(response => {
            eventBus.$emit(MessageTypes.SUCCESS, t("employee.created"), 1000);

            state.dialog = false;
            state.result(true);
          })
          .catch((error: AxiosError) => {
            if (error && error.response && error.response.status === 422) {
              eventBus.$emit(MessageTypes.WARNING, t("checkInput"));

              // @ts-ignore
              error.response.data.forEach((error: ValidationError) => {
                errors.value.set(error.path, error.message);
              });
            } else {
              eventBus.$emit(MessageTypes.ERROR, t("employee.createError"));
            }
          });
      };

      return {...toRefs(state), close, open, save};
    }
  });
