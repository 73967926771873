
  import Vue from "vue";
  import Component from "vue-class-component";

  import EmployeeDetailsCardEmployments from "./EmployeeDetailsCardEmployments.vue";
  import EmployeeDetailsCardOperator from "./EmployeeDetailsCardOperator.vue";
  import EmployeeDetailsCardPersonal from "./EmployeeDetailsCardPersonal.vue";
  import EmployeeDetailsCardWages from "./EmployeeDetailsCardWages.vue";
  import EmployeeDetailsCardCompetencies from "./EmployeeDetailsCardCompetencies.vue";
  import FabButton from "@/components/FabButton.vue";
  import {Watch} from "vue-property-decorator";
  import EmployeeService from "@/service/EmployeeService";
  import {HeaderModule, HeaderMutations} from "@/store/modules/header";
  import {Mutation} from "vuex-class";
  import Employee from "@/model/Employee";
  import {MessageTypes} from "@/components/message-types";
  import {AxiosError} from "axios";
  import {CompanyLocation} from "@/model/CompanyLocation";

  @Component({
    components: {
      EmployeeDetailsCardEmployments,
      EmployeeDetailsCardOperator,
      EmployeeDetailsCardPersonal,
      EmployeeDetailsCardWages,
      EmployeeDetailsCardCompetencies,
      FabButton
    }
  })
  export default class EmployeeDetails extends Vue {
    private employee: Employee = new Employee();
    private companyLocations: Array<CompanyLocation> | null = null;
    private speedDial = false;

    private snackbarTimeout = -1;

    @Mutation(HeaderMutations.SET_TITLE, {namespace: HeaderModule})
    private mutationTitle: any;

    get fullName() {
      if (this.employee.firstName && this.employee.lastName) {
        return this.employee.firstName + " " + this.employee.lastName;
      } else {
        return "";
      }
    }

    get employeeId(): number | null {
      const id = this.$route.params.employeeId;
      const isNum = /^\d+$/.test(id);
      if (isNum) {
        return Number(id);
      } else {
        return null;
      }
    }

    get isEditable(): boolean {
      return !this.isInactive && this.isAuthorized("Employee_EditOther_Edit");
    }

    get isInactive(): boolean {
      return this.employee && this.employee.active !== undefined && !this.employee.active;
    }

    get hasEmployments(): boolean {
      return this.employee.employments?.length > 0;
    }

    get hasWages(): boolean {
      return this.employee.wages?.length > 0;
    }

    @Watch("fullName")
    onFullNameChanged(value: string) {
      this.mutationTitle(value);
    }

    mounted() {
      this.load();
    }

    load() {
      if (this.employeeId) {
        EmployeeService.getEmployeeById(this.employeeId)
          .then(response => {
            this.employee = Object.assign(new Employee(), response.data);
          });
      }
    }

    update(updatedEmployee: Employee) {
      this.employee = Object.assign(new Employee(), updatedEmployee);
    }

    recreateWelcomePdf() {
      if (this.employeeId) {
        EmployeeService.createWelcomePdf(this.employeeId)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              // @ts-ignore
              link.setAttribute("download", response.headers.filename);
              document.body.appendChild(link);
              link.click();
            }
          });
      }
    }

    deactivate() {
      const employeeId = this.employeeId;
      if (employeeId) {
        const title = this.$vuetify.lang.t("$vuetify.employee.deactivationDialog.title");

        const firstName = this.employee.firstName ?? "";
        const lastName = this.employee.lastName ?? "";
        const text = this.$vuetify.lang.t("$vuetify.employee.deactivationDialog.text", firstName, lastName);

        this.$root.$confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult && this.employeeId) {
              EmployeeService.deactivateEmployee(employeeId)
                .then(response => {
                  if (response.status === 200) {
                    this.employee.active = false;
                    this.$eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, "Mitarbeiter deaktiviert");
                    this.update(response.data);
                  }
                })
                .catch((error: AxiosError) => {
                  if (error && error.response && error.response.status === 422) {
                    this.$eventBus.$emit(MessageTypes.SNACKBAR_WARNING, "Mitarbeiter konnte nicht deaktiviert werden");
                  }
                });
            }
          });
      }
    }

    reactivate() {
      const employeeId = this.employeeId;
      if (employeeId && !this.employee.active) {
        const title = this.$vuetify.lang.t("$vuetify.employee.reactivationDialog.title");

        const firstName = this.employee.firstName ?? "";
        const lastName = this.employee.lastName ?? "";
        const text = this.$vuetify.lang.t("$vuetify.employee.reactivationDialog.text", firstName, lastName);

        this.$root.$confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult && this.employeeId) {
              EmployeeService.reactivateEmployee(this.employeeId)
                .then(response => {
                  this.employee.active = true;
                  this.$eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, "Mitarbeiter wurde reaktiviert.");
                  this.update(response.data);
                })
                .catch((error: AxiosError) => {
                  if (error && error.response && error.response.status === 422) {
                    this.$eventBus.$emit(MessageTypes.SNACKBAR_WARNING, "Mitarbeiter konnte nicht reaktiviert werden");
                  }
                });
            }
          });
      }
    }
  }
