
  import {computed, defineComponent, PropType, reactive, toRefs} from "vue";
  import AccountingEmployeePerDiemsList from "./AccountingEmployeePerDiemsList.vue";
  import EmployeeName from "@/model/EmployeeName";
  import PerDiem from "@/model/PerDiem";
  import Worklog from "@/model/Worklog";
  import {groupBy} from "@/Utils";

  export default defineComponent({
    name: "AccountingEmployeePerDiems",
    components: {
      AccountingEmployeePerDiemsList
    },
    props: {
      editable: {
        type: Boolean,
        default: () => false
      },
      employee: {
        type: Object as PropType<EmployeeName>,
        default: () => new EmployeeName()
      },
      perDiems: {
        type: Array as PropType<Array<PerDiem>>,
        default: () => new Array<PerDiem>()
      },
      worklogs: {
        type: Array as PropType<Array<Worklog>>,
        default: () => new Array<Worklog>()
      }
    },
    setup(props) {
      const initialState = {
        includePotentialPerDiems: false,
      };

      const state = reactive({...initialState});

      // We build dummy PerDiem objects her to enable the user to create new PerDiems from those
      const extendedPerDiems = computed(() => {
        if(state.includePotentialPerDiems && props.editable) {
          let perDiems = [];

          let worklogsByDate = groupBy(props.worklogs, (item: Worklog) => item.date, item => item);

          for (const date of worklogsByDate.keys()) {
            let existingPerDiem = props.perDiems.find(perDiem => perDiem.date == date);
            if(existingPerDiem) {
              perDiems.push(existingPerDiem);
            } else {
              perDiems.push({
                date: date
              } as PerDiem);
            }
          }

          return perDiems;
        } else {
          return props.perDiems;
        }
      });

      return {
        ...toRefs(state),
        extendedPerDiems
      };
    }
  });
