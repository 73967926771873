import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VOverlay,{attrs:{"value":_vm.loading,"z-index":"999"}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VRow,{attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm.t("statistic.reports.reportGenerationInProgress"))+" ")])],1),_c('ModalDialog',{attrs:{"value":_vm.value},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.t("statistic.reports.inventoryPerformancesReport.title"))+" ")]},proxy:true},{key:"content",fn:function(){return [_c(VForm,[_c('FormDatePickerV2',{attrs:{"label":_vm.t('from'),"format":"DD.MM.YYYY"},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}}),_c('FormDatePickerV2',{attrs:{"format":"DD.MM.YYYY","label":_vm.t('to')},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)]},proxy:true},{key:"actions",fn:function(){return [_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.generateReport}},[_vm._v(" "+_vm._s(_vm.t("statistic.reports.generateReportButton"))+" ")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }