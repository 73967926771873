
  import {mapGetters} from "vuex";
  import HourOverviewClockingHistory from "./HourOverviewClockingHistory.vue";
  import {HeaderGetters} from "@/store/modules/header";
  import Utils from "@/Utils";
  import EmployeeService from "@/service/EmployeeService";
  import Vue from "vue";
  import {AxiosError, AxiosResponse} from "axios";
  import {isAuthorized} from "@/mixin/mixins";
  import moment from "moment";
  import PageUtils from "@/components/PageUtils";
  import {PagedResult} from "@/service/PagedResult";

  export default Vue.extend({
    name: "HourOverview",
    components: {
      HourOverviewClockingHistory
    },
    data: () => ({
      blur: false,
      itemsPerPage: -1,
      totalHours: "",
      items: new Array<any>(),
      showPassengerColumn: false
    }),
    computed: {
      ...mapGetters({
        month: "header/" + HeaderGetters.GET_MONTH
      }),
      dateColumnFormat (): string {
        return this.$vuetify.breakpoint.xs ? "DD.MM (dd)" : "DD.MM (dddd)";
      },
      groupedItems (): Array<any> {
        const dateItems = new Array<any>();

        const groupedByDate = Utils.groupBy(this.items, (item: any) => item.date);
        groupedByDate.forEach((value, key) => {
          dateItems.push({
            date: key,
            items: value,
            start: moment(value[0].start, "HH:mm:ss").format("HH:mm"),
            end: moment(value[value.length - 1].end, "HH:mm:ss").format("HH:mm"),
            timeSpent: this.calculateTimeSpent(value)
          });
        });

        return dateItems;
      },
      employeeId (): number {
        return Number(this.$route.params.employeeId);
      }
    },
    watch: {
      month (newMonth) {
        this.loadMonth(newMonth);
      },
      $route () {
        this.loadMonth(this.month);
      }
    },
    mounted () {
      this.showPassengerColumn = isAuthorized("Accounting_Employee_ViewPassengerTimes");
      this.loadMonth(this.month);
    },
    created () {
      this.blur = false;
    },
    methods: {
      openDetails (item: any) {
        this.blur = true;
        // @ts-ignore
        this.$refs.hourOverviewClockingHistory.open(item);
      },
      detailsClosed: function () {
        this.blur = false;
      },
      loadMonth (date: string) {
        EmployeeService.getEmployeeWorklogsV2(this.employeeId, date)
          .then((response: AxiosResponse<PagedResult>) => {
            this.items = PageUtils.extractCollection(response.data, "worklogs");
          }).catch((error: AxiosError<any>) => {
            this.$eventBus.$emit("error", error);
          });
      },
      toStartEnd: function (item: any) {
        return item.start + " - " + item.end;
      },
      calculateTimeSpent (worklogs: Array<any>) {
        const timeSpent = {
          work: 0,
          pause: 0,
          travel: 0,
          passenger: 0
        };
        worklogs.forEach((worklog: any) => {
          const minutes = moment(worklog.end, "HH:mm:ss").diff(moment(worklog.start, "HH:mm:ss"), "minutes");
          switch (worklog.worklogType) {
          case "WORK":
            timeSpent.work = timeSpent.work + minutes;
            break;
          case "PAUSE":
            timeSpent.pause = timeSpent.pause + minutes;
            break;
          case "TRAVEL":
            timeSpent.travel = timeSpent.travel + minutes;
            break;
          case "PASSENGER":
            timeSpent.passenger = timeSpent.passenger + minutes;
            break;
          }
        });

        return timeSpent;
      }
    }
  });
