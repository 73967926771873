import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,{class:{ 'login-view-background': !_vm.isLoggedIn, 'main-view-background': _vm.isLoggedIn }},[(_vm.isFormPath)?[_c(VMain,[_c('RouterView')],1)]:(!_vm.isLoggedIn)?_c('Login'):[_c('TheHeader',{attrs:{"show-clock-icon":_vm.showClockIcon,"show-info-icon":_vm.showInfoIcon,"title":_vm.headerTitle},on:{"menuClicked":function($event){return _vm.menuClicked()}}}),_c('TheMenu',{model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}}),_c(VMain,[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c(VProgressLinear,{staticClass:"my-0",attrs:{"color":"primary","indeterminate":""}})],1):_vm._e(),_c('keep-alive',{attrs:{"include":_vm.keepAliveRoutes}},[_c('RouterView')],1)],1),_c('TheFooter')],_c(VSnackbar,{ref:"snackbar",attrs:{"color":_vm.snackbarColor,"timeout":3000,"bottom":"","multi-line":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c(VBtn,{class:{ 'snackbar-error-button': _vm.isSnackbarError,
                'snackbar-warning-button': _vm.isSnackbarWarning },attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" Schließen ")])],1),_c('LoadingDialog'),_c('InfoDialog'),_c('ConfirmDialog',{ref:"confirmDialog"}),_c('ConfirmDialogComposition',{ref:"confirmDialogComposition"}),_c('UserProfile',{model:{value:(_vm.userProfileDialog),callback:function ($$v) {_vm.userProfileDialog=$$v},expression:"userProfileDialog"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }