import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VCol,[_c(VRow,{attrs:{"justify":"start"}},[_c(VCol,{attrs:{"cols":"3"}},[_c(VCard,{staticClass:"d-flex flex-column",attrs:{"hover":"","height":"100%"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.t("statistic.reports.workingHoursReport.title"))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.t("statistic.reports.workingHoursReport.description"))+" ")]),_c(VSpacer),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.showWorkingHoursDialog()}}},[_vm._v(" "+_vm._s(_vm.t("statistic.reports.generateReportButton"))+" ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VCard,{staticClass:"d-flex flex-column",attrs:{"hover":"","height":"100%"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.t("statistic.reports.inventoryPerformancesReport.title"))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.t("statistic.reports.inventoryPerformancesReport.description"))+" ")]),_c(VSpacer),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.showInventoryPerformancesDialog()}}},[_vm._v(" "+_vm._s(_vm.t("statistic.reports.generateReportButton"))+" ")])],1)],1)],1)],1)],1),_c('StatisticReportsInventoryPerformancesDialog',{model:{value:(_vm.inventoryPerformancesDialog),callback:function ($$v) {_vm.inventoryPerformancesDialog=$$v},expression:"inventoryPerformancesDialog"}}),_c('StatisticReportsWorkingHoursDialog',{model:{value:(_vm.workingHoursDialog),callback:function ($$v) {_vm.workingHoursDialog=$$v},expression:"workingHoursDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }