
  import Vue from "vue";
  import Component from "vue-class-component";

  import {TimeclockActions, TimeclockGetters, TimeclockModule} from "@/store/modules/timeclock";
  import OrderGroup from "@/model/OrderGroup";
  import {Action} from "vuex-class";
  import {Watch} from "vue-property-decorator";
  import OrderListTile from "@/views/Orders/OrdersListTile.vue";
  import {HeaderGetters, HeaderModule} from "@/store/modules/header";
  import {OrderTypes} from "@/enums";
  import store from "@/store/store";
  import {NavigationGuardNext, Route} from "vue-router/types/router";

  @Component({
    components: {
      OrderListTile
    }
  })
  export default class Timeclock extends Vue {
    get date() : string {
      return store.getters[HeaderModule+"/"+HeaderGetters.GET_DATE];
    }

    get sortedGroups() : Array<OrderGroup> {
      return store.getters[TimeclockModule+"/"+TimeclockGetters.GET_SORTED_GROUPS];
    }

    @Action(TimeclockActions.LIST_ORDERS, { namespace: TimeclockModule }) actionListOrders: any;

    @Watch("date")
    onDateChanged () {
      this.listOrders();
    }

    // When coming from outside the timeclock context directly go into the project if clocked in
    beforeRouteEnter (to: Route, from: Route, next: NavigationGuardNext) {
      if (from.path.startsWith("/timeclock")) {
        next();
      } else {
        // @ts-ignore
        const authentication = store.state.auth.authentication;
        if (authentication && authentication.clockedInOrder) {
          next({
            name: "timeclockOrder",
            params: { orderId: authentication.clockedInOrder }
          });
        } else {
          next();
        }
      }
    }

    mounted () {
      this.listOrders();
    }

    listOrders () {
      this.actionListOrders(this.date);
    }

    getOrderTypeName (code: string) {
      const orderType = OrderTypes.find(item => item.code === code);
      return orderType ? orderType.localizedName : "";
    }
  }
