
  import Vue from "vue";

  import Component from "vue-class-component";
  import {Getter} from "vuex-class";
  import {HeaderGetters, HeaderModule} from "@/store/modules/header";
  import {Watch} from "vue-property-decorator";
  import StatisticEmployees from "./StatisticEmployees.vue";
  import StatisticEmployeesV2 from "./StatisticEmployeesV2.vue";
  import StatisticService from "@/service/StatisticService";
  import StatisticOrderSummariesTable from "@/views/Statistic/StatisticOrderSummariesTable.vue";
  import {OrderTypes} from "@/enums";
  import Utils from "@/Utils";
  import moment, {Moment} from "moment";
  import {calculateProductivity} from "@/views/Statistic/StatisticUtils";
  import StatisticReports from "@/views/Statistic/StatisticReports.vue";
  import StatisticPerformance from "@/views/Statistic/StatisticPerformance.vue";
  import PageUtils from "@/components/PageUtils";

  @Component({
    components: {
      StatisticEmployees,
      StatisticEmployeesV2,
      StatisticOrderSummariesTable,
      StatisticReports,
      StatisticPerformance,
    },
  })
  export default class Statistic extends Vue {
    items: Array<any> = [];
    itemsPreviousYear: Array<any> = [];

    private active = 0;
    activeType = this.typeAll;

    pagination: any = {
      sortBy: "name",
      rowsPerPage: 10,
    };

    @Getter(HeaderGetters.GET_MONTH, {namespace: HeaderModule})
    private month: string;

    @Watch("month")
    onDateChanged() {
      if (this.active === 0) {
        this.getOrderSummaries();
      }
    }

    get from(): Moment {
      return this.$moment(this.month).subtract(2, "months");
    }

    get to(): Moment {
      return this.$moment(this.month);
    }

    get isOverview(): boolean {
      return this.activeType.code === "ALL";
    }

    mounted() {
      this.getOrderSummaries();
    }

    get activeItems() {
      if (this.activeType.code === "ALL") {
        const itemsByOrderType = Utils.groupBy(
          this.items,
          (item: any) => item.order.orderType
        );

        return OrderTypes.map((orderType) => {
          // @ts-ignore
          const items: Array<any> = itemsByOrderType.get(orderType.code);

          return {
            order: {name: orderType.localizedName},
            minutes: this.sum(this.map(items, "minutes")),
            costs: this.sum(this.map(items, "costs")),
            costsWithPayrollTaxes: this.sum(this.map(items, "costsWithPayrollTaxes")),
            costsTravel: this.sum(this.map(items, "costsTravel")),
            costsPassenger: this.sum(this.map(items, "costsPassenger")),
            perDiemTotal: this.sum(this.map(items, "perDiemTotal")),
            earnings: this.sum(this.map(items, "earnings")),
            expenses: this.sum(this.map(items, "expenses")),
            profit: this.sum(this.map(items, "profit")),
            // @ts-ignore
            averageWage: this.$options.filters.avg(this.map(items, "averageWage")),
            // @ts-ignore
            productivity: calculateProductivity(items),
          };
        }).sort(function (a, b) {
          return a.order.name.localeCompare(b.order.name);
        });
      } else {
        return this.items.filter((item) => item.order.orderType === this.activeType.code);
      }
    }

    get activeItemsPreviousYear() {
      if (this.activeType.code === "ALL") {
        return this.itemsPreviousYear;
      } else {
        return this.itemsPreviousYear.filter(
          (item) => item.order.orderType === this.activeType.code
        );
      }
    }

    sum(items: Array<any>) {
      // @ts-ignore
      return this.$options.filters.sum(items);
    }

    map(items: Array<any>, key: string) {
      // @ts-ignore
      return this.$options.filters.map(items, key);
    }

    get typeAll() {
      return {code: "ALL", name: "Gesamt"};
    }

    get availableTypes() {
      const availableTypes = [this.typeAll];

      const orderTypes = OrderTypes.map((orderType) => ({
        code: orderType.code,
        name: orderType.localizedName,
      })).sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });

      return availableTypes.concat(orderTypes);
    }

    getOrderSummaries() {
      StatisticService.getOrderSummaries(this.month).then((response) => {
        this.items = PageUtils.extractCollection(response.data, "orderSummaries");
      });

      let sameMonthLastYear = moment(this.month, "YYYY-MM").subtract(12, "months");
      StatisticService.getOrderSummaries(sameMonthLastYear.format("YYYY-MM")).then(
        (response) => {
          this.itemsPreviousYear = PageUtils.extractCollection(
            response.data,
            "orderSummaries"
          );
        }
      );
    }

    refresh() {
      StatisticService.refreshOrders(this.month, null).then((response) => {
        this.items = response.data;
      });
    }

    itemClicked(entry: any) {
      if (entry.order.id) {
        this.$router.push({
          name: "StatisticOrder",
          params: {orderId: entry.order.id.toString()},
        });
      } else {
        const matchingType = this.availableTypes.find(
          (availableType) => availableType.name === entry.order.name
        );
        if (matchingType) {
          this.activeType = matchingType;
        }
      }
    }

    itemRefreshed(entry: any) {
      if (entry.order.id) {
        StatisticService.refreshOrder(this.month, entry.order.id).then((response) => {
          this.items = response.data;
        });
      } else {
        const matchingType = this.availableTypes.find(
          (availableType) => availableType.name === entry.order.name
        );
        if (matchingType) {
          StatisticService.refreshOrders(this.month, matchingType.code).then((response) => {
            this.items = response.data;
          });
        }
      }
    }
  }
