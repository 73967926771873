import axios, {AxiosResponse} from "axios";
import {PagedResult} from "@/service/PagedResult";

const PATH = "/order-history";

class OrderHistoryService {
    getOrderHistory(orderId: number): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH}/${orderId}`);
    }
}

export default new OrderHistoryService();
