
  import {RegistrationDataPersonal} from "@/model/Registration";
  import {SelectModel} from "@/model/SelectModel";
  import moment from "moment";
  import {REGISTRATION} from "@/plugins/i18n";
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    set,
    SetupContext,
    toRefs,
    watch
  } from "vue";
  import {useDialog} from "@/views/Registration/RegistrationComposable";
  import {helpers, maxValue, minValue, numeric, required} from "@vuelidate/validators";
  import useVuelidate, {ErrorObject} from "@vuelidate/core";
  import {ValidationResult} from "@/model/ValidationResult";
  import {t, tWithParms} from "@/mixin/mixins";

  const validDate = (value: string) => {
    if (moment(value, "YYYY-MM-DD", true).isValid()) {
      return moment().diff(moment(value, "YYYY-MM-DD", true), "years") < 99;
    } else {
      return false;
    }
  };

  export default defineComponent({
    name: "RegistrationPersonalForm",
    components: {
      RegistrationCard
    },
    props: {
      step: {
        type: Number,
        default: null
      }
    },
    setup(props, context: SetupContext) {

      const {
        state: generalState,
        registration,
        mobile,
        next
      } = useDialog(context);

      const initialState = {
        model: {
          personal: new RegistrationDataPersonal()
        },
        dayPlaceholder: "",
        yearPlaceholder: "",
        day: "",
        month: "",
        year: ""
      };

      const state = reactive({...initialState});

      const rules = computed(() => ({
        day: {
          numeric,
          required,
          maxValue: maxValue(31)
        },
        month: {
          numeric,
          required,
          maxValue: maxValue(12)
        },
        year: {
          numeric,
          required,
          minValue: minValue(1940)
        },
        model: {
          personal: {
            birthdate: {
              required: helpers.withMessage(tWithParms("validation.required", t("birthdate")), required),
              validDate: helpers.withMessage(t("validation.validDate"), validDate)
            },
            firstName: {
              required: helpers.withMessage(tWithParms("validation.required", t("firstName")), required),
            },
            lastName: {
              required: helpers.withMessage(tWithParms("validation.required", t("lastName")), required),
            },
            maritalStatus: {
              required: helpers.withMessage(tWithParms("validation.required", t("registration.maritalStatus.general")), required),
            },
            sex: {
              required: helpers.withMessage(tWithParms("validation.required", t("sex.general")), required),
            }
          }
        }
      }));

      let v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (generalState.serverErrors.length > 0) {
          generalState.serverErrors.forEach((error: ValidationResult) => {
            errors.set(error.path, error.message);
          });

          return errors;
        } else {
          const model = v$.value;
          if (model.$dirty) {
            model.$errors.forEach((error: ErrorObject) => {
              if(!errors.has(error.$propertyPath)) {
                // @ts-ignore
                errors.set(error.$propertyPath, error.$message);
              }
            });
          }
        }

        return errors;
      });

      const monthItems = computed(() => {
        return [
          new SelectModel("1", t("month.january")),
          new SelectModel("2", t("month.february")),
          new SelectModel("3", t("month.march")),
          new SelectModel("4", t("month.april")),
          new SelectModel("5", t("month.may")),
          new SelectModel("6", t("month.june")),
          new SelectModel("7", t("month.july")),
          new SelectModel("8", t("month.august")),
          new SelectModel("9", t("month.september")),
          new SelectModel("10", t("month.october")),
          new SelectModel("11", t("month.november")),
          new SelectModel("12", t("month.december"))
        ];
      });

      const maritalStatus = computed(() => {
        return [
          new SelectModel("single", t(REGISTRATION, "maritalStatus.single")),
          new SelectModel("married", t(REGISTRATION, "maritalStatus.married")),
          new SelectModel("widowed", t(REGISTRATION, "maritalStatus.widowed")),
          new SelectModel("divorced", t(REGISTRATION, "maritalStatus.divorced")),
          new SelectModel("living_apart", t(REGISTRATION, "maritalStatus.livingApart")),
          new SelectModel("civil_union", t(REGISTRATION, "maritalStatus.civilUnion"))
        ];
      });

      const sexItems = computed(() => {
        return [
          new SelectModel("male", t("sex.male")),
          new SelectModel("female", t("sex.female")),
          new SelectModel("diverse", t("sex.diverse")),
          new SelectModel("undefined", t("sex.undefined"))
        ];
      });

      const personal = computed(() => {
        return state.model?.personal ? state.model.personal : new RegistrationDataPersonal();
      });



      watch(() => registration.personal, (newValue, oldValue) => {
        if (newValue) {
          state.model.personal = Object.assign(state.model.personal, newValue);
        }
      });

      watch(() => state.day, (newValue, oldValue) => {
        if (state.year && state.month && moment(state.year + "-" + state.month + "-" + newValue, "YYYY-M-D").isValid()) {
          const birthdate = moment(state.year + "-" + state.month + "-" + newValue, "YYYY-M-D").format("YYYY-MM-DD");
          set(personal.value, "birthdate", birthdate);
        }
      });

      watch(() => state.month, (newValue, oldValue) => {
        if (moment(state.year + "-" + newValue + "-" + state.day, "YYYY-M-D").isValid()) {
          const birthdate = moment(state.year + "-" + newValue + "-" + state.day, "YYYY-M-D").format("YYYY-MM-DD");
          set(personal.value, "birthdate", birthdate);
        }
      });

      watch(() => state.year, (newValue, oldValue) => {
        if (moment(newValue + "-" + state.month + "-" + state.day, "YYYY-M-D").isValid()) {
          const birthdate = moment(newValue + "-" + state.month + "-" + state.day, "YYYY-M-D").format("YYYY-MM-DD");
          set(personal.value, "birthdate", birthdate);
        }
      });

      onMounted(() => {
        if(registration.value.personal) {
          state.model.personal = registration.value.personal;
        }

        if (registration
          && registration.value.personal
          && registration.value.personal.birthdate
          && moment(registration.value.personal.birthdate, "YYYY-MM-DD").isValid()) {
          const personal = registration.value.personal;
          state.day = moment(personal.birthdate, "YYYY-MM-DD").format("DD");
          state.month = moment(personal.birthdate, "YYYY-MM-DD").format("M");
          state.year = moment(personal.birthdate, "YYYY-MM-DD").format("YYYY");
        }

        if (!registration.personal) {
          generalState.dirty = true;
        }
      });

      const onNext = () => {
        next(v$, state.model);
      };

      return {
        ...toRefs(state),
        errors,
        monthItems,
        maritalStatus,
        sexItems,
        mobile,
        onNext
      };
    }
  });
