export default class Authentication {
  public employeeId: number;
  public username: string;
  public firstName: string;
  public lastName: string;
  public lastLogin: string;
  public authenticated = false;
  public enabled = false;
  public authorities: Array<string> = new Array<string>();
  public clockedInOrder: number;
}
