
  import WorklogDetailsDialog from "@/views/Accounting/WorklogDetailsDialog/WorklogDetailsDialog.vue";
  import AccountingEmployeesList from "./AccountingEmployeesList.vue";
  import AccountingEmployeesTable from "./AccountingEmployeesTable.vue";
  import SwitchView from "@/components/SwitchView.vue";
  import FabButton from "@/components/FabButton.vue";
  import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, toRefs} from "vue";
  import {date, employeeId} from "@/store/StoreUtils";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import CreateWorklogParameters from "@/views/Accounting/WorklogDetailsDialog/CreateWorklogParameters";
  import {isAuthorized} from "@/mixin/mixins";
  import router from "@/router";
  import MonthPicker from "@/components/MonthPicker.vue";
  import moment from "moment";
  import AccountingService, {AccountingEmployee} from "@/service/AccountingService";
  import {mobile} from "@/CompositionUtils";
  import PageUtils from "@/components/PageUtils";
  import {useRoute} from "vue-router/composables";

  export default defineComponent({
    name: "AccountingEmployees",
    components: {
      MonthPicker,
      AccountingEmployeesList,
      AccountingEmployeesTable,
      FabButton,
      SwitchView,
      WorklogDetailsDialog
    },
    setup () {
      const initialState = {
        yearMonth: moment().format("YYYY-MM"),
        items: new Array<AccountingEmployee>(),
        loading: false,
        maxYearMonth: moment().format("YYYY-MM"),
        search: ""
      };

      const state = reactive({...initialState});

      const route = useRoute();

      const create = () => {
        eventBus.$emit(MessageTypes.CREATE_WORKLOG, new CreateWorklogParameters());
      };

      const load = () => {
        state.loading = true;

        AccountingService.findEmployeesInYearMonth(state.yearMonth)
          .then(response => {
            state.items = PageUtils.extractCollection<AccountingEmployee>(response.data, "employees");
          })
          .finally(() => state.loading = false);
      };

      const search = (search: string) => {
        state.search = search;
      };

      const visibleItems = computed(() => {
        if(state.search) {
          const isNum = /^\d+$/.test(state.search);
          if (isNum) {
            return state.items.filter((item: AccountingEmployee) => {
              return item.employee.employeeId === Number(state.search);
            });
          } else if (state.search.length > 0) {
            return state.items.filter((item: AccountingEmployee) => {
              const fullname = item.employee.firstName + " " + item.employee.lastName;
              return fullname.toUpperCase().includes(state.search.toUpperCase());
            });
          }
        } else {
          return state.items;
        }
      });

      const dateChanged = (date: string) => {
        router.push({
          path: "/accounting/employees",
          query: {yearMonth: date}
        });

        load();
      };

      onMounted(() => {
        if(route.query.yearMonth && moment(route.query.yearMonth as string, "YYYY-MM").isValid()) {
          state.yearMonth = route.query.yearMonth as string;
        }

        eventBus.$on(MessageTypes.WORKLOG_CREATED, () => {
          load();
        });
        eventBus.$on(MessageTypes.WORKLOG_UPDATED, () => {
          load();
        });
        if (isAuthorized("Accounting_Overview_ViewOther")) {
          load();
        } else {
          router.push({ path: `/accounting/employees/${employeeId.value}/worklogs` });
        }
      });

      onBeforeUnmount(() => {
        eventBus.$off(MessageTypes.WORKLOG_CREATED);
        eventBus.$off(MessageTypes.WORKLOG_UPDATED);
      });

      return {
        ...toRefs(state),
        create,
        search,
        visibleItems,
        date,
        dateChanged,
        mobile
      };
    }
  });
