import {computed, ComputedRef, Ref} from "vue";
import Worklog from "@/model/Worklog";
import Utils from "@/Utils";

export function init(worklogs: Ref<Array<Worklog>>) {
    const groups: ComputedRef<Map<string, Array<Worklog>>> = computed(() => {
        return Utils.groupBy(worklogs.value, (item: Worklog) => item.date);
    });

    const dates: ComputedRef<Array<string>> = computed(() => {
        return Array.from(groups.value.keys());
    });

    return {
        groups,
        dates
    };
}
