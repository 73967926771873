
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import {computed, defineComponent, reactive, Ref, SetupContext, toRefs} from "vue";
  import {helpers, required} from "@vuelidate/validators";
  import {useDialog} from "@/views/Registration/RegistrationComposable";
  import {HINT, VALUE} from "@/plugins/i18n";
  import useVuelidate, {ErrorObject} from "@vuelidate/core";
  import {MessageTypes} from "@/components/message-types";
  import {ValidationResult} from "@/model/ValidationResult";
  import {t, tWithParms} from "@/mixin/mixins";
  import eventBus from "@/eventbus";

  export default defineComponent({
    name: "RegistrationShortTermEmploymentQuestionnaire",
    components: {
      RegistrationCard
    },
    props: {
      step: {
        type: Number,
        default: null
      }
    },
    setup(props, context: SetupContext) {

      const initialState = {
        onParentalLeave: null,
        receivesStateBenefits: null,
        unemployed: null,
        inBetweenTrainings: null
      };

      const {
        state: generalState,
        falseValue,
        trueValue,
        next
      } = useDialog(context);

      const state = reactive({...initialState});

      const rules = computed(() => ({
        onParentalLeave: {
          required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required),
        },
        receivesStateBenefits: {
          required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required),
        },
        unemployed: {
          required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required),
        },
        inBetweenTrainings: {
          required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required),
        }
      }));

      // @ts-ignore
      let v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (generalState.serverErrors.length > 0) {
          generalState.serverErrors.forEach((error: ValidationResult) => {
            errors.set(error.path, error.message);
          });

          return errors;
        } else {
          const model = v$.value;
          if (model.$dirty) {
            model.$errors.forEach((error: ErrorObject) => {
              // @ts-ignore
              errors.set(error.$propertyPath, error.$message);
            });
          }
        }

        return errors;
      });

      const validateAndNext = () => {
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$error) {
          if (state.unemployed || state.receivesStateBenefits || state.onParentalLeave || state.inBetweenTrainings) {
            eventBus.$emit(MessageTypes.INFO,  t(HINT),  t("registration.shortTermEmploymentQuestionnaire.shortTermEmploymentUnavailable"));
          } else {
            next(v$);
          }
        }
      };

      return {
        ...toRefs(state),
        errors,
        falseValue,
        trueValue,
        validateAndNext
      };
    }
  });
