import axios from "axios";

const PATH = "/logger";

export default {
  info (message: any) {
    axios.post(`${PATH}`, {
      level: "INFO",
      msg: message
    });
  },
  error (httpStatus: string, route: any, message: any) {
    axios.post(`${PATH}`, {
      level: "ERROR",
      httpStatus: httpStatus,
      route: route,
      message: message
    }).catch(() => {
      console.error("Error sending error log to server");
    });
  }
};
