
  import RightsBreadcrumbs from "./RightsBreadcrumbs.vue";
  import RightsService from "@/service/RightsService";
  import PageUtils from "@/components/PageUtils";
  import Utils from "@/Utils";
  import {defineComponent, inject, onMounted, reactive, toRefs} from "vue";
  import {roleId} from "@/RouterUtils";
  import store from "@/store/store";
  import {Role} from "@/model/Role";
  import {Authority} from "@/model/Authority";
  import {t, tWithParms} from "@/mixin/mixins";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";

  export default defineComponent({
    name: "RoleDetails",
    components: {
      RightsBreadcrumbs
    },
    setup(_props, context) {
      let appRoot = inject("app-root");
      // @ts-ignore
      const confirmDialog = appRoot.confirmDialogComposition as InstanceType<typeof ConfirmDialogComposition>;

      const initialState = {
        allAuthorities: new Array<Authority>(),
        assignedAuthorities: new Array<string>(),
        groups: new Map<string, Array<Authority>>(),
        loading: false,
        model: new Array<string>(),
        assigned: false,
        role: null as unknown as Role
      };
      const state = reactive({...initialState});

      const loadAllAuthorities = () => {
        return RightsService.getOperations()
          .then(response => {
            state.allAuthorities = PageUtils.extractCollection<Authority>(response.data, "authorities")
              .filter(authority => authority.name.indexOf("_") !== -1);

            state.groups = Utils.groupBy<Authority>(state.allAuthorities, (authority: Authority) => authority.name.substring(0, authority.name.indexOf("_")));
          });
      };

      const loadRole = () => {
        return RightsService.getRole(roleId.value)
          .then(response => {
            state.role = response.data;
            store.commit("header/setTitle", response.data.name);

            state.assignedAuthorities = response.data.authorities ?? new Array<string>();
            state.assignedAuthorities.forEach(authority => state.model.push(authority));
          });
      };

      const load = () => {
        state.loading = true;

        Promise.all([loadAllAuthorities(), loadRole()])
          .finally(() => {
            state.loading = false;
          });
      };

      const findAuthorityTitle = (authority: string): string => {
        let found = state.allAuthorities.find(auth => auth.name === authority);
        return found?.title ?? "";
      };

      const isAssigned = (authority: string) => {
        return state.assignedAuthorities.includes(authority);
      };

      const onChange = (authority: string) => {
        // This is fired after the value has been assigned, so if it is now pert of the model, then this value was just
        // set
        if (state.model.includes(authority)) {
          assign(authority);
        } else {
          unassign(authority);
        }
      };

      const assign = (authority: string) => {
        const title = t("authorization.assignAuthority.title");
        const authorityName = findAuthorityTitle(authority);
        const text = tWithParms("authorization.assignAuthority.text", authorityName);
        confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              RightsService.assignAuthority(roleId.value, authority)
                .then(() => {
                  eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("authorization.assignSuccess"));
                });
            } else {
              // Action aborted, reset to previous state
              Utils.remove(state.model, authority);
            }
          });
      };

      const unassign = (authority: string) => {
        const title = t("authorization.unassignAuthority.title");
        const authorityName = findAuthorityTitle(authority);
        const text = tWithParms("authorization.unassignAuthority.text", authorityName);
        confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              RightsService.unassignAuthority(roleId.value, authority)
                .then(() => {
                  eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("authorization.assignSuccess"));
                });
            } else {
              // Action aborted, reset to previous state
              state.model.push(authority);
            }
          });
      };

      onMounted(() => {
        load();
      });

      return {
        ...toRefs(state),
        isAssigned,
        onChange,
        assign,
        unassign
      };
    }
  });
