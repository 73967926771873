
  import {computed, defineComponent, PropType, reactive, toRefs, watch} from "vue";
  import ModalDialog from "@/components/ModalDialog.vue";
  import WageDialog from "./WageDialog.vue";
  import {MessageTypes} from "@/components/message-types";
  import Wage from "@/model/Wage";
  import WageUtils from "@/views/Employee/details/WageUtils";
  import {employeeId} from "@/RouterUtils";
  import eventBus from "@/eventbus";
  import {t} from "@/mixin/mixins";
  import EmployeeService from "@/service/EmployeeService";
  import Employee from "@/model/Employee";
  import FormDatePicker from "@/components/FormDatePicker.vue";

  export default defineComponent({
    name: "EmployeeDetailsCardWagesDialog",
    components: {
      FormDatePicker,
      ModalDialog,
      WageDialog
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      inventoryBonusEligible: {
        type: Boolean,
        default: false
      },
      inventoryBonusEligibleSince: {
        type: String,
        default: ""
      },
      wages: {
        type: Array as PropType<Array<Wage>>,
        default: () => []
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context) {
      const initialState = {
        editDialog: false,
        editWageItem: new Wage(),
        inventoryBonusEligibility: props.inventoryBonusEligible,
        inventoryBonusEligibilitySince: props.inventoryBonusEligibleSince
      };

      const state = reactive({...initialState});

      const currentWage = computed(() => {
        return WageUtils.getCurrentWage(props.wages);
      });

      const nextWage = computed(() => {
        return WageUtils.getNextWage(props.wages);
      });

      const futureWages = computed(() => {
        return WageUtils.getFutureWages(props.wages);
      });

      const close = () => {
        context.emit("input", false);
      };

      const createWage = () => {
        editWage(new Wage());
      };

      const editWage = (wage: Wage) => {
        close();
        state.editDialog = true;
        state.editWageItem = Object.assign(new Wage(), wage);
      };

      const closeEditDialog = () => {
        context.emit("input", true);
        state.editDialog = false;
      };

      const onEditDialogCreate = () => {
        closeEditDialog();
        eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("entryAdded"));
        context.emit("update");
      };

      const onEditDialogUpdate = () => {
        closeEditDialog();
        eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("entryUpdated"));
        context.emit("update");
      };

      const onEditDialogDelete = () => {
        context.emit("update");
        eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("entryDeleted"));
      };

      watch(() => state.inventoryBonusEligibility, (newValue, oldValue) => {
        EmployeeService.updateEmployeePatch(employeeId.value, {
          inventoryBonusEligible: newValue
        } as Employee)
          .then(response => {
            context.emit("update");
            eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("entryUpdated"));
          });
      });

      watch(() => state.inventoryBonusEligibilitySince, (newValue, oldValue) => {
        EmployeeService.updateEmployeePatch(employeeId.value, {
          isInventoryBonusEligibleSince: newValue
        } as Employee)
          .then(response => {
            context.emit("update");
            eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("entryUpdated"));
          });
      });

      return {
        ...toRefs(state),
        employeeId,
        currentWage,
        nextWage,
        futureWages,
        createWage,
        editWage,
        closeEditDialog,
        onEditDialogCreate,
        onEditDialogDelete,
        onEditDialogUpdate
      };
    }
  });
