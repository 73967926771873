
  import Vue from "vue";
  import Order from "@/model/Order";
  import Employee from "@/model/Employee";

  export default Vue.extend({
    name: "TheHeaderSearchLookupResultList",
    props: {
      items: {
        type: Array,
        default: () => new Array<any>()
      },
      title: {
        type: String,
        default: ""
      }
    },
    data: () => ({
    }),
    methods: {
      itemClicked (item: any) {
        this.$emit("itemClicked", item);
      },
      isEmployee (item: any) {
        return item instanceof Employee;
      },
      isOrder (item: any) {
        return item instanceof Order;
      },
      getAvatarColorForOrderType (orderType: string): string {
        let color = "";

        switch (orderType) {
        case "INV":
          color = "orange lighten-2";
          break;
        case "MOD":
          color = "blue lighten-2";
          break;
        case "DRY":
          color = "grey lighten-1";
          break;
        case "MIS":
          color = "lime  lighten-1";
          break;
        }

        return color;
      }
    }
  });
