
  import {computed, defineComponent, onMounted, reactive, Ref, toRefs} from "vue";
  import InventoriesCompanyLocationsFormCard from "./InventoriesCompanyLocationsFormCard.vue";
  import InventoriesCompanyLocationsGeneralForm from "../forms/InventoriesCompanyLocationsGeneralForm.vue";
  import InventoriesCompanyLocationsAddressForm from "../forms/InventoriesCompanyLocationsAddressForm.vue";
  import ModalDialog from "@/components/ModalDialog.vue";
  import {MessageTypes} from "@/components/message-types";
  import {CompanyLocation} from "@/model/CompanyLocation";
  import InventoriesService from "@/service/InventoriesService";
  import {AxiosError} from "axios";
  import ValidationError from "@/model/ValidationError";
  import eventBus from "@/eventbus";
  import {helpers, required} from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import {t} from "@/mixin/mixins";

  export default defineComponent({
    name: "InventoriesCompanyLocationsDialog",
    components: {
      InventoriesCompanyLocationsFormCard,
      InventoriesCompanyLocationsGeneralForm,
      InventoriesCompanyLocationsAddressForm,
      ModalDialog
    },
    setup() {

      const state = reactive({
        dialog: false,
        edit: false,
        companyLocation: {} as CompanyLocation,
        oldCompanyLocationName: "",
        serverErrors: new Map<string, string>()
      });

      onMounted(() => {
        eventBus.$on(MessageTypes.OPEN_COMPANY_LOCATION_DIALOG, (editedCompanyLocation: CompanyLocation) => {
          state.serverErrors = new Map<string, string>();

          if (editedCompanyLocation) {
            state.edit = true;
            state.companyLocation = {...editedCompanyLocation};
            if (state.companyLocation.name) state.oldCompanyLocationName = state.companyLocation.name;
          } else {
            state.edit = false;
            state.companyLocation = {} as CompanyLocation;
            state.companyLocation.id = -1;
            state.oldCompanyLocationName = "";
          }
          state.dialog = true;
        });
      });

      const onCompanyLocationChanged = (newCompanyLocation: CompanyLocation) => {
        state.companyLocation = newCompanyLocation;
      };

      const save = () => {
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          if (!state.companyLocation.name || !state.companyLocation.name.trim()) state.companyLocation.name = null;

          if (state.companyLocation.id == -1) {
            InventoriesService.createCompanyLocation(state.companyLocation)
              .then(response => {
                const newCompanyLocation = response.data;
                eventBus.$emit(MessageTypes.COMPANY_LOCATION_CREATED, newCompanyLocation);
                closeDialog();
                eventBus.$emit(MessageTypes.SUCCESS, t("inventories.companyLocations.message.success.create"), 1000);
              })
              .catch((error: AxiosError) => {
                if (error && error.response && error.response.status === 422) {
                  eventBus.$emit(MessageTypes.WARNING, t("checkInput"));
                  const errorMap = new Map<string, string>();
                  // @ts-ignore
                  error.response.data.forEach((error: ValidationError) => {
                    errorMap.set(error.path, error.message);
                  });
                  state.serverErrors = errorMap;
                } else {
                  eventBus.$emit(MessageTypes.ERROR, t("inventories.companyLocations.message.error.create"));
                }
              });
          } else {
            InventoriesService.updateCompanyLocation(state.companyLocation)
              .then(response => {
                const newCompanyLocation = response.data;
                eventBus.$emit(MessageTypes.COMPANY_LOCATION_CREATED, newCompanyLocation);
                closeDialog();
              })
              .catch((error: AxiosError) => {
                if (error && error.response && error.response.status === 422) {
                  eventBus.$emit(MessageTypes.WARNING, t("checkInput"));
                  const errorMap = new Map<string, string>();
                  // @ts-ignore
                  error.response.data.forEach((error: ValidationError) => {
                    errorMap.set(error.path, error.message);
                  });
                  state.serverErrors = errorMap;
                } else {
                  eventBus.$emit(MessageTypes.ERROR, t("inventories.companyLocations.message.error.edit"));
                }
              });
          }
        }
      };

      const closeDialog = () => {
        state.dialog = false;
        state.companyLocation = {} as CompanyLocation;
        state.oldCompanyLocationName = "";
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
      };

      const rules = computed(() => ({
        companyLocation: {
          name: {
            required: helpers.withMessage(t("inventories.companyLocations.validation.name"), required),
          }
        }
      }));

      // @ts-ignore
      const v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (state.serverErrors.size > 0) {
          return state.serverErrors;
        } else {
          const model = v$.value.companyLocation;
          if (model.$dirty) {
            if(model.name.$error) {
              errors.set("name", model.name.$errors[0].$message);
            }
          }
        }
        return errors;
      });

      return {
        ...toRefs(state),
        onCompanyLocationChanged,
        save,
        closeDialog,
        errors
      };
    }
  });
