import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VList,{attrs:{"two-line":"","subheader":""}},[_c(VSubheader,{staticClass:"subtitle-1 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.title.toUpperCase())+" ")]),(_vm.items.length === 0)?_c('span',[_vm._v(" Keine Ergebnisse gefunden ")]):_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.id,on:{"click":function($event){return _vm.itemClicked(item)}}},[_c(VListItemAvatar,[(_vm.isOrder(item))?_c(VAvatar,{attrs:{"color":_vm.getAvatarColorForOrderType(item.orderType)}},[_c('span',{staticClass:"white--text headline"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.enum.orderType." + item.orderType).substring(0,1))+" ")])]):(_vm.isEmployee(item))?_c(VAvatar,[_c(VIcon,{attrs:{"size":36}},[_vm._v(" mdi-account-circle ")])],1):_vm._e()],1),_c(VListItemContent,[_c(VListItemTitle,[(_vm.isOrder(item))?[_vm._v(" "+_vm._s(item.name)+" ")]:_vm._e(),(_vm.isEmployee(item))?[_vm._v(" "+_vm._s(item.fullName)+" ")]:_vm._e()],2),_c(VListItemSubtitle,[(_vm.isOrder(item))?[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.enum.orderType." + item.orderType) + " - " + item.city)+" ")]:_vm._e(),(_vm.isEmployee(item))?[_vm._v(" "+_vm._s("ID: "+item.employeeId)+" "),_c('span',{class:item.active ? 'primary--text' : 'error--text'},[_vm._v(_vm._s(item.active ? "Aktiv" : "Inaktiv"))])]:_vm._e()],2)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }