import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm.order.name)+" ")]),_c(VRow,[(_vm.isAuthorized('Timeclock_Clocking'))?_c(VTabs,{attrs:{"color":"primary","centered":"","fixed-tabs":"","permanent":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.name},[_c(VIcon,[_vm._v(" "+_vm._s(tab.icon)+" ")])],1)}),_c(VTabItem,[_c('TimeclockOrderTabClockIn',{attrs:{"items":_vm.items,"errors":_vm.errors,"operators":_vm.operators},on:{"clockIn":_vm.doClock}})],1),_c(VTabItem,[_c('TimeclockOrderTabPause',{attrs:{"items":_vm.items,"errors":_vm.errors},on:{"pause":_vm.doClock}})],1),_c(VTabItem,[_c('TimeclockOrderTabClockedOut',{attrs:{"items":_vm.items,"errors":_vm.errors},on:{"clockOut":_vm.doClock}})],1)],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }