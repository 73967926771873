import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDataTable,{staticClass:"elevation-0 pa-0",attrs:{"headers":_vm.headers,"items":_vm.companyLocations,"item-key":"id","hide-default-footer":"","sort-by":['name'],"sort-desc":[false]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],key:item.id,on:{"click":function($event){return _vm.openCompanyLocationDialog(item)}}},[_c('td',[_vm._v(" "+_vm._s(item.name ? item.name : "–")+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.street ? item.street : "–")+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.zip ? item.zip : "–")+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.city ? item.city : "–")+" ")]),_c('td',{staticClass:"cell no-padding text-center px-2 sheet",on:{"click":function($event){$event.stopPropagation();return _vm.openCompanyLocationDialog(item)}}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"ma-0",attrs:{"icon":"","text":""}},on),[_c(VIcon,[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.t("inventories.companyLocations.tooltip.edit")))])])],1),_c('td',{staticClass:"cell no-padding text-center px-2 sheet",on:{"click":function($event){$event.stopPropagation();return _vm.deleteCompanyLocation(item)}}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"ma-0",attrs:{"icon":"","text":""}},on),[_c(VIcon,[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.t("inventories.companyLocations.tooltip.delete")))])])],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }