
  import Vue from "vue";
  import Component from "vue-class-component";
  import { Watch } from "vue-property-decorator";
  import { Getter, Mutation } from "vuex-class";
  import { HeaderGetters, HeaderModule, HeaderMutations } from "@/store/modules/header";
  import moment from "moment";

  @Component
  export default class TheHeaderTimePicker extends Vue {
    private time = "";
    private selectedTime = "";
    private modal = false;

    @Mutation(HeaderMutations.SET_TIMEPICKER_TIME, { namespace: HeaderModule })
    private mutationSetTimePickerTime: any;

    @Getter(HeaderGetters.GET_TIME, { namespace: HeaderModule })
    private headerTime: string;

    mounted () {
      if (!this.time) {
        this.time = moment().format("HH:mm");
        this.mutationSetTimePickerTime(this.time);
      }
    }

    @Watch("selectedTime")
    onTimeSelected (newTime: string) {
      this.mutationSetTimePickerTime(newTime);
    }
  }
