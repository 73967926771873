export default {
  compare (value1: any, value2: any, forceStringComparison= false): boolean {
    if (typeof value1 === "boolean" && typeof value2 === "boolean") {
      return value1 === value2;
    } else if (value1 && value2) {
      const number1 = Number(value1);
      const number2 = Number(value2);

      if (!forceStringComparison && (number1 || number2)) {
        return Number(value1).toString().startsWith(Number(value2).toString());
      } else {
        return value1 && value1.toLowerCase().includes(value2.toLowerCase());
      }
    } else {
      return false;
    }
  },
  compareArray (value1: Array<any>, value2: any, forceStringComparison= false): boolean {
    let comparedArray = false;
    value1.forEach(v => {
      const compared = this.compare(v, value2);
      if (compared) comparedArray = true;
    });
    return comparedArray;
  },
  isNum (toCheck: any) {
    return /^\d+$/.test(toCheck);
  }
};
