import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items.length > 0)?_c(VList,{attrs:{"dense":""}},[_c(VSubheader,[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._l((_vm.items),function(item){return [_c(VListItem,{key:item.id,attrs:{"disabled":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s((item.fullName ) ? item.fullName : item.firstName + " " + item.lastName)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s((item.statusMessage) ? item.statusMessage : _vm.getStatusMessage(item))+" ")])],1)],1)]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }