import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{class:{'px-0': _vm.$vuetify.breakpoint.xsOnly}},[_c(VChip,{staticClass:"mr-1 mb-1",attrs:{"outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icon("WORK"))+" ")]),_vm._v(" "+_vm._s(_vm._f("toTimeFormat")(_vm.sumMinutes(_vm.worklogs, "WORK")))+" h ")],1),_c(VChip,{staticClass:"mr-1 mb-1",attrs:{"outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icon("PAUSE"))+" ")]),_vm._v(" "+_vm._s(_vm._f("toTimeFormat")(_vm.sumMinutes(_vm.worklogs, "PAUSE")))+" h ")],1),_c(VChip,{staticClass:"mr-1 mb-1",attrs:{"outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icon("TRAVEL"))+" ")]),_vm._v(" "+_vm._s(_vm._f("toTimeFormat")(_vm.sumMinutes(_vm.worklogs, "TRAVEL")))+" h ")],1),(_vm.isAuthorized('Accounting_Employee_ViewPassengerTimes'))?_c(VChip,{attrs:{"outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icon("PASSENGER"))+" ")]),_vm._v(" "+_vm._s(_vm._f("toTimeFormat")(_vm.sumMinutes(_vm.worklogs, "PASSENGER")))+" h ")],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }