
  import {MarketArea} from "@/model/MarketArea/MarketArea";
  import {defineComponent, inject, PropType, ref, watch} from "vue";
  import {MarketAreaCategory} from "@/model/MarketArea/MarketAreaCategory";
  import {MessageTypes} from "@/components/message-types";
  import eventBus from "@/eventbus";
  import {MarketAreaWithCategoriesModel} from "./InventoriesMarketAreas.vue";
  import {t} from "@/mixin/mixins";
  import InventoriesService from "@/service/InventoriesService";

  export default defineComponent({
    name: "InventoriesMarketAreasTable",
    props: {
      marketAreasWithCategories: {
        type: Array as PropType<Array<MarketAreaWithCategoriesModel>>,
        default: () => new Array<MarketAreaWithCategoriesModel>()
      }
    },
    setup(props) {

      let expanded = ref(props.marketAreasWithCategories);

      const headers = [
        {
          text: t("inventories.marketAreas.table.header.name"),
          align: "left",
          value: "marketArea.name"
        },
        {
          text: t("inventories.marketAreas.table.header.countingSpeedDeviation"),
          align: "left",
          value: "countingSpeedDeviation",
          sortable: false
        },
        {
          align: "right",
          value: "add",
          sortable: false,
          width: 40
        },
        {
          align: "right",
          value: "edit",
          sortable: false,
          width: 40
        },
        {
          align: "right",
          value: "delete",
          sortable: false,
          width: 40
        },
      ];

      const openMarketAreaDialog = (marketArea: MarketArea) => {
        eventBus.$emit(MessageTypes.OPEN_MARKET_AREA_DIALOG, marketArea);
      };
      const openMarketAreaCategoryDialog = (marketAreaCategory: MarketAreaCategory | undefined, marketArea: MarketArea) => {
        eventBus.$emit(MessageTypes.OPEN_MARKET_AREA_CATEGORY_DIALOG, marketAreaCategory, marketArea);
      };

      let appRoot = inject("app-root");
      // @ts-ignore
      const confirmDialog = appRoot.confirmDialogComposition as InstanceType<typeof ConfirmDialogComposition>;
      const deleteMarketArea = (marketArea: MarketArea) => {
        const title = `${t("inventories.marketAreas.dialog.title.delete")}\n${marketArea.name}`;
        const text = t("inventories.marketAreas.dialog.text.delete");
        confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              InventoriesService.deleteMarketArea(marketArea.id)
                .then(() => {
                  eventBus.$emit(MessageTypes.MARKET_AREA_DELETED, marketArea.id);
                })
                .catch(() => {
                });
            }
          });
      };
      const deleteMarketAreaCategory = (marketAreaCategory: MarketAreaCategory) => {
        const title = `${t("inventories.marketAreaCategories.dialog.title.delete")}\n${marketAreaCategory.name}`;
        const text = t("inventories.marketAreaCategories.dialog.text.delete");
        confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              InventoriesService.deleteMarketAreaCategory(marketAreaCategory.id)
                .then(() => {
                  eventBus.$emit(MessageTypes.MARKET_AREA_CATEGORY_DELETED, marketAreaCategory.id);
                })
                .catch(() => {
                });
            }
          });
      };

      watch(() => props.marketAreasWithCategories, (newValue) => {
        newValue.forEach((val: MarketAreaWithCategoriesModel) => {
          expanded.value.push(val);
        });
      });

      return {
        expanded,
        headers,
        openMarketAreaDialog,
        openMarketAreaCategoryDialog,
        deleteMarketArea,
        deleteMarketAreaCategory
      };
    }
  });
