import {CompanyLocation} from "@/model/CompanyLocation";

export default {
    toAddressString(location: CompanyLocation) {
        let str = "";
        if (location.street) {
            str += location.street;
            if (location.zip) {
                str += ", " + location.zip;
                if (location.city) {
                    str += " " + location.city;
                }
            } else {
                if (location.city) {
                    str += ", " + location.city;
                }
            }
        } else {
            if (location.zip) {
                str += location.zip;
                if (location.city) {
                    str += " " + location.city;
                }
            } else {
                if (location.city) {
                    str += location.city;
                }
            }
        }
        return str;
    }
};
