import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"content":"","content-class":"centered-dialog","max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VContainer,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[(_vm.loading)?_c(VProgressCircular,{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}}):_vm._e(),(_vm.success)?_c(VIcon,{attrs:{"color":"primary","size":"100px"}},[_vm._v(" mdi-check-circle-outline ")]):(_vm.warning)?_c(VIcon,{attrs:{"color":"orange","size":"100px"}},[_vm._v(" mdi-alert ")]):(_vm.error)?_c(VIcon,{attrs:{"color":"error","size":"100px"}},[_vm._v(" mdi-cancel ")]):_vm._e()],1),_c(VRow,{attrs:{"align":"center","justify":"center"}},[(_vm.message)?_c('h1',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }