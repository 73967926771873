
  import {RegistrationDataBanking} from "@/model/Registration";
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import {friendlyFormatIBAN, isValidBIC, isValidIBAN} from "ibantools";
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    SetupContext,
    toRefs,
    watch
  } from "vue";
  import {useDialog} from "@/views/Registration/RegistrationComposable";
  import {helpers, required} from "@vuelidate/validators";
  import useVuelidate, {ErrorObject} from "@vuelidate/core";
  import {ValidationResult} from "@/model/ValidationResult";
  import {t, tWithParms} from "@/mixin/mixins";

  const validBic = (value: string) => {
    return !!value && isValidBIC(value);
  };

  const validIban = (value: string) => {
    return !!value && isValidIBAN(value.replace(/\s/g, ""));
  };

  export default defineComponent({
    name: "RegistrationBankingForm",
    components: {
      RegistrationCard
    },
    props: {
      step: {
        type: Number,
        default: null
      }
    },
    setup(props, context: SetupContext) {

      const {
        state: generalState,
        registration,
        mobile,
        next
      } = useDialog(context);

      const initialState = {
        model: {
          banking: new RegistrationDataBanking()
        }
      };

      const state = reactive({...initialState});

      const rules = computed(() => ({
        model: {
          banking: {
            bic: {
              required: helpers.withMessage(tWithParms("validation.required", t("banking.bic")), required),
              validBic: helpers.withMessage(tWithParms("validation.invalid", t("banking.bic")), validBic)

            },
            iban: {
              required: helpers.withMessage(tWithParms("validation.required", t("banking.iban")), required),
              validIban: helpers.withMessage(tWithParms("validation.invalid", t("banking.iban")), validIban)

            }
          }
        }
      }
      ));

      // @ts-ignore
      let v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (generalState.serverErrors.length > 0) {
          generalState.serverErrors.forEach((error: ValidationResult) => {
            errors.set(error.path, error.message);
          });

          return errors;
        } else {
          const model = v$.value;
          if (model.$dirty) {
            model.$errors.forEach((error: ErrorObject) => {
              if(!errors.has(error.$propertyPath)) {
                // @ts-ignore
                errors.set(error.$propertyPath, error.$message);
              }
            });
          }
        }

        return errors;
      });

      watch(() => registration.banking, (newValue, oldValue) => {
        if (newValue) {
          state.model.banking = Object.assign(state.model.banking, newValue);
        }
      });

      onMounted(() => {
        if(registration.value.banking) {
          state.model.banking = registration.value.banking;

          // formattedIban will be null if the input is undefined
          let formattedIban = friendlyFormatIBAN(state.model.banking.iban);
          if (formattedIban) {
            let visibleStart = formattedIban.substring(0, 4);
            let visibleEnd = formattedIban.substring(formattedIban.length - 2, formattedIban.length);
            formattedIban = visibleStart + "***************" + visibleEnd;
            state.model.banking.iban = formattedIban;
          }
        } else {
          generalState.dirty = true;
        }
      });

      const onInput = () => {
        if (state.model.banking.bic && state.model.banking.bic.length > 0) {
          state.model.banking.bic = state.model.banking.bic.toUpperCase().replace(/\s/g,"");
        }
        if (state.model.banking.iban && state.model.banking.iban.length > 0) {
          state.model.banking.iban = state.model.banking.iban.toUpperCase();
        }
      };

      const onNext = () => {
        next(v$, state.model);
      };

      const clearBic = () => {
        if (!generalState.dirty) {
          state.model.banking.bic = "";
        }
      };

      const clearIban = () => {
        if (!generalState.dirty) {
          state.model.banking.iban = "";
        }
      };

      return {
        ...toRefs(state),
        errors,
        clearBic,
        clearIban,
        mobile,
        onInput,
        onNext
      };
    }
  });
