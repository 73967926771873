
  import {computed, defineComponent, reactive, SetupContext, toRefs, watch} from "vue";
  import moment from "moment";

  export default defineComponent({
    name: "FormDatePickerV2",
    props: {
      birthday: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      errorMessage: {
        type: String,
        default: null
      },
      format: {
        type: String,
        default: "DD.MM.YYYY"
      },
      label: {
        type: String,
        default: "<label>"
      },
      min: {
        type: String,
        default: null
      },
      max: {
        type: String,
        default: null
      },
      month: {
        type: Boolean,
        default: false,
      },
      outlined: {
        type: Boolean,
        default: false,
      },
      value: {
        type: String,
        default: ""
      }
    },
    setup(props: any, context: SetupContext) {
      const initialState = {
        activePicker: null as unknown as string,
        date: props.value,
        menuModel: false
      };

      const state = reactive({...initialState});

      watch(() => state.date, (newValue, oldValue) => {
        context.emit("input", newValue);
      });

      watch(() => state.menuModel, (newValue, oldValue) => {
        if (props.birthday) {
          newValue && setTimeout(() => (state.activePicker = "YEAR"));
        }
      });

      watch(() => props.value, (newValue, oldValue) => {
        state.date = newValue;
      });

      const formattedDate = computed(() => {
        return state.date ? moment(state.date).format(props.format) : null;
      });

      return {
        ...toRefs(state),
        formattedDate
      };
    }
  });
