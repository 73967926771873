
  import moment from "moment";
  import Worklog from "@/model/Worklog";
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    set,
    SetupContext,
    toRefs,
    watch
  } from "vue";
  import {helpers, required} from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import WorklogService from "@/service/WorklogService";
  import {MessageTypes} from "@/components/message-types";
  import {WorklogTypes} from "@/enums";
  import CreateWorklogParameters
    from "@/views/Accounting/WorklogDetailsDialog/CreateWorklogParameters";
  import AccountingService from "@/service/AccountingService";
  import Order from "@/model/Order";
  import WorklogDetailsDialogDateField
    from "@/views/Accounting/WorklogDetailsDialog/WorklogDetailsDialogDateField.vue";
  import WorklogDetailsDialogEmployeeField
    from "@/views/Accounting/WorklogDetailsDialog/WorklogDetailsDialogEmployeeField.vue";
  import WorklogDetailsDialogOrderField
    from "@/views/Accounting/WorklogDetailsDialog/WorklogDetailsDialogOrderField.vue";
  import WorklogDetailsDialogTimeField
    from "@/views/Accounting/WorklogDetailsDialog/WorklogDetailsDialogTimeField.vue";
  import EmployeeService from "@/service/EmployeeService";
  import Employee from "@/model/Employee";
  import {AxiosError} from "axios";
  import {isAuthorized, t} from "@/mixin/mixins";
  import eventBus from "@/eventbus";
  import store from "@/store/store";
  import OrderServiceV2 from "@/service/OrderServiceV2";

  export default defineComponent({
    name: "WageDialog",
    components: {
      WorklogDetailsDialogDateField,
      WorklogDetailsDialogEmployeeField,
      WorklogDetailsDialogOrderField,
      WorklogDetailsDialogTimeField
    },
    setup (props, context: SetupContext) {
      const initialState = {
        availableOrders: new Array<Order>(),
        createMode: false,
        dateDisabled: false,
        dialog: false,
        employeeDisabled: false,
        model: new Worklog() as Worklog,
        minDateOrder: "",
        maxDateOrder: "",
        orderDisabled: false,
        serverErrors: new Map<string, string>()
      };

      const state = reactive({ ...initialState });

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (state.serverErrors.size > 0) {
          return state.serverErrors;
        } else {
          if (v$.value.state.$dirty) {
            const model = v$.value.state.model;
            if (model.employee.$error) {
              errors.set("employee", model.employee.$errors[0].$message);
            }
            if (model.order.$error) {
              errors.set("order", model.order.$errors[0].$message);
            }
            if (model.date.$error) {
              errors.set("date", model.date.$errors[0].$message);
            }
            if (model.start.$error) {
              errors.set("start", model.start.$errors[0].$message);
            }
            if (model.end.$error) {
              errors.set("end", model.end.$errors[0].$message);
            }
            if (model.worklogType.$error) {
              errors.set("worklogType", model.worklogType.$errors[0].$message);
            }
          }
          if (v$.value.total.$dirty) {
            if (v$.value.total.$error) {
              errors.set("start", "Startzeit muss vor Endzeit liegen!");
              errors.set("end", "Startzeit muss vor Endzeit liegen!");
            }
          }
        }

        return errors;
      });

      const clearForm = () => {
        state.model = new Worklog();
        v$.value.$reset();
      };

      const close = () => {
        state.serverErrors = new Map<string, string>();
        clearForm();
        state.dialog = false;
        context.emit("closed");
      };

      const deleteWorklog = () => {
        WorklogService.delete(state.model)
          .then((deleted: boolean) => {
            if (deleted) {
              context.emit("deleted");
              eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("entryDeleted"));
              eventBus.$emit(MessageTypes.WORKLOG_DELETED, state.model);
              close();
            }
          });
      };

      const openDetails = (worklog: Worklog) => {
        state.dialog = true;
        state.createMode = false;
        clearForm();

        WorklogService.findById(worklog.id)
          .then(response => {
            state.model = Worklog.fromJson(response.data);
          });
      };

      const openCreate = (createWorklogParameters: CreateWorklogParameters) => {
        state.minDateOrder = earliestValidFrom.value;
        state.maxDateOrder = moment().format("YYYY-MM-DD");

        state.dialog = true;
        state.createMode = true;
        clearForm();

        let order = null;
        if (createWorklogParameters.order) {
          order = createWorklogParameters.order;
        } else if (createWorklogParameters.orderId) {
          OrderServiceV2.getOrder(createWorklogParameters.orderId)
            .then(response => {
              order = response.data;
            });
        }

        if (order) {
          state.orderDisabled = true;
          set(state.model, "order", order);

          if (moment(order.validFrom).isAfter(state.minDateOrder)) {
            state.minDateOrder = order.validFrom;
          }

          if (order.validTo) {
            if (moment(order.validTo).isBefore(state.maxDateOrder)) {
              state.maxDateOrder = order.validTo;
            }
          }
        }

        let employee = null;
        if (createWorklogParameters.employee) {
          employee = Object.assign(new Employee(), createWorklogParameters.employee);
        } else if (createWorklogParameters.employeeId) {
          EmployeeService.getEmployeeById(createWorklogParameters.employeeId)
            .then(response => {
              employee = Object.assign(new Employee(), response.data);
            });
        }

        if (employee) {
          state.model.employee = employee;
          state.employeeDisabled = true;
        }

        if (createWorklogParameters.date) {
          if (moment(createWorklogParameters.date).isBefore(state.minDateOrder)
            || moment(createWorklogParameters.date).isAfter(state.maxDateOrder)) {
            set(state.model, "date", "");
          } else {
            set(state.model, "date", createWorklogParameters.date);
          }
        }

        if (moment(state.minDateOrder).isSame(state.maxDateOrder)) {
          state.dateDisabled = true;
          set(state.model, "date", state.minDateOrder);
        }

        if (!state.model.date) {
          set(state.model, "date", state.maxDateOrder);
        }
      };

      const save = () => {
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          WorklogService.createOrUpdate(state.model)
            .then(response => {
              state.serverErrors = response.data;
              if (response.status === 200) {
                eventBus.$emit(MessageTypes.SUCCESS, "Änderungen gespeichert.");
                if (state.model.id) {
                  eventBus.$emit(MessageTypes.WORKLOG_UPDATED, state.model);
                } else {
                  eventBus.$emit(MessageTypes.WORKLOG_CREATED, state.model);
                }
                close();
              }
            })
            .catch((error: AxiosError) => {
              if (error && error.response && error.response.status === 422) {
                const errorMap = new Map<string, string>();
                // @ts-ignore
                error.response.data.errors.forEach((error: ValidationError) => {
                  errorMap.set(error.path, error.message);
                });

                // Needed to trigger reactivity
                state.serverErrors = errorMap;
              }
            });
        }
      };

      const earliestValidFrom = computed(() => {
        return store.state.general.earliestValidFrom;
      });

      const minDate = computed(() => {
        if (state.minDateOrder) {
          return state.minDateOrder;
        } else {
          return earliestValidFrom.value;
        }
      });

      const maxDate = computed(() => {
        if (state.maxDateOrder) {
          return state.maxDateOrder;
        } else {
          return moment().endOf("month").format("YYYY-MM-DD");
        }
      });

      const isAuthorizedEdit = computed(() => {
        return isAuthorized("Accounting_Overview_EditSelf")
          || isAuthorized("Accounting_Overview_EditOther")
          || isAuthorized("Order_Worklogs_SelfEdit")
          || isAuthorized("Order_Worklogs_OtherEdit");
      });

      const isEditable = computed(() => {
        const isDateBefore = moment(state.model.date).isBefore(moment(earliestValidFrom.value));
        return isAuthorizedEdit.value && !isDateBefore;
      });

      const total = computed(() => {
        if (state.model.start != null && state.model.end != null) {
          const minutes = moment(state.model.end, "HH:mm:ss").diff(moment(state.model.start, "HH:mm:ss"), "minutes");
          if (minutes < 0) {
            return "Ungültig";
          } else {
            return moment.utc().startOf("day").add(minutes, "minutes").format("HH:mm");
          }
        } else {
          return "00:00h";
        }
      });

      const worklogTypes = computed(() => {
        return WorklogTypes;
      });

      const rules = computed(() => ({
        state: {
          model: {
            date: {
              required: helpers.withMessage("Datum ist erforderlich.", required)
            },
            employee: {
              required: helpers.withMessage("Mitarbeiter ist erforderlich.", required)
            },
            order: {
              required: helpers.withMessage("Auftrag ist erforderlich.", required)
            },
            start: {
              required: helpers.withMessage("Start ist erforderlich.", required)
            },
            end: {
              required: helpers.withMessage("Ende ist erforderlich.", required)
            },
            worklogType: {
              required: helpers.withMessage("Arbeitsart ist erforderlich.", required)
            }
          },
        },
        total: {
          startBeforeEnd (): boolean {
            // @ts-ignore
            if (state.model && state.model.start && state.model.end) {
              // @ts-ignore
              const minutes = moment(state.model.end, "HH:mm:ss").diff(moment(state.model.start, "HH:mm:ss"), "minutes");
              return minutes >= 0;
            } else {
              return true;
            }
          }
        }
      }));

      // @ts-ignore
      const v$ = useVuelidate(rules, { state, total });

      onMounted(() => {
        eventBus.$on(MessageTypes.UPDATE_WORKLOG, (worklog: Worklog) => {
          if (!state.dialog) {
            openDetails(worklog);
          }
        });
        eventBus.$on(MessageTypes.CREATE_WORKLOG, (createWorklogParameters: CreateWorklogParameters) => {
          if (!state.dialog) {
            openCreate(createWorklogParameters);
          }
        });
      });

      watch(() => state.model.date, (newValue, oldValue) => {
        if (!state.orderDisabled && newValue && newValue !== oldValue && moment(newValue as string, "YYYY-MM-DD", true).isValid()) {
          AccountingService.findAvailableOrders(state.model.date)
            .then(response => {
              state.availableOrders = response.data;
              if (state.model.order) {
                const orderId = state.model.order.id;
                const index = state.availableOrders.findIndex(availableOrder => availableOrder.id === orderId);
                if (index === -1) {
                  // Order is no longer available so drop it here
                  set(state.model, "order", null);
                }
              }
              if (state.availableOrders.length === 1 && !state.model.order) {
                set(state.model, "order", state.availableOrders[0]);
              }
            });
        }
      }, { deep: true });

      return {
        ...toRefs(state),
        v$,
        errors,
        isEditable,
        minDate,
        maxDate,
        total,
        worklogTypes,
        close,
        deleteWorklog,
        save
      };
    }
  });
