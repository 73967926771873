
  import {computed, defineComponent, reactive, toRefs, watch} from "vue";
  import Employee from "@/model/Employee";
  import Employment from "@/model/Employment";
  import ModalDialog from "@/components/ModalDialog.vue";
  import EmploymentDialog from "./EmploymentDialog.vue";
  import EmploymentUtils from "./EmploymentUtils";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import {t} from "@/mixin/mixins";
  import EmployeeService from "@/service/EmployeeService";
  import {employeeId} from "@/RouterUtils";

  export default defineComponent({
    name: "EmployeeDetailsCardEmploymentsDialog",
    components: {
      ModalDialog,
      EmploymentDialog
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      value: {
        type: Boolean,
        default: false
      },
      temporaryProp: {
        type: Boolean,
        default: false
      },
      employments: {
        type: Array as () => Array<Employment>,
        default: () => new Array<Employment>()
      }
    },
    setup(props, context) {
      const initialState = {
        editDialog: false,
        editEmploymentItem: null as Employment | null,
        temporary: props.temporaryProp
      };

      const state = reactive({...initialState});

      const currentEmployment = computed(() => {
        return EmploymentUtils.getCurrentEmployment(props.employments);
      });
      const nextEmployment = computed(() => {
        return EmploymentUtils.getNextEmployment(props.employments);
      });
      const futureEmployments = computed(() => {
        return EmploymentUtils.getFutureEmployments(props.employments);
      });

      const close = () => {
        context.emit("input", false);
      };

      const createEmployment = () => {
        editEmployment(new Employment());
      };

      const editEmployment = (employment: Employment) => {
        close();
        state.editDialog = true;
        state.editEmploymentItem = Object.assign(new Employment(), employment);
      };

      const closeEditDialog = () => {
        context.emit("input", true);
        state.editDialog = false;
      };

      const onEditDialogCreate = () => {
        closeEditDialog();
        eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("entryAdded"));
        context.emit("update");
      };

      const onEditDialogUpdate = () => {
        closeEditDialog();
        eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("entryUpdated"));
        context.emit("update");
      };

      const onEditDialogDelete = () => {
        context.emit("update");
        eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("entryDeleted"));
      };

      watch(() => state.temporary, (newValue, oldValue) => {
        EmployeeService.updateEmployeePatch(employeeId.value, {
          temporary: newValue
        } as Employee)
          .then(response => {
            context.emit("update");
            eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("entryUpdated"));
          });
      });

      return {
        ...toRefs(state),
        employeeId,
        currentEmployment,
        nextEmployment,
        futureEmployments,
        createEmployment,
        editEmployment,
        closeEditDialog,
        onEditDialogCreate,
        onEditDialogDelete,
        onEditDialogUpdate
      };
    }
  });

