
  import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, toRefs, watch} from "vue";
  import Customer from "@/model/Customer";
  import CustomerService from "@/service/CustomerService";
  import CustomerType from "@/model/CustomerType";
  import {MessageTypes} from "@/components/message-types";
  import {customerId} from "@/RouterUtils";
  import eventBus from "@/eventbus";
  import store from "@/store/store";

  export default defineComponent({
    name: "CustomerDetails",
    setup(props, context) {
      // Init a customer object as active to not show the inactive banner before the actual customer has been loaded
      let customer = new Customer();
      customer.active = true;
      const initialState = {
        activeTab: 0,
        customer: customer,
        customerTypes: new Array<CustomerType>(),
        alertDismissed: false
      };

      const state = reactive({...initialState});

      const load = () => {
        Promise.all([CustomerService.getCustomerById(customerId.value), CustomerService.getCustomerTypes()]).then((values) => {
          state.customer = values[0];
          state.customerTypes = values[1];
        });
      };

      const inactiveCustomer = computed(() => {
        return !state.customer?.active;
      });

      watch(() => state.customer.name, (newValue, oldValue) => {
        store.commit("header/setTitle", newValue);
      });

      onMounted(() => {
        load();

        eventBus.$on(MessageTypes.CUSTOMER_EDIT_SUCCESS, () => {
          load();
        });
        eventBus.$on(MessageTypes.CUSTOMER_DELETED, () => {
          load();
        });
      });

      onBeforeUnmount(() => {
        eventBus.$off(MessageTypes.CUSTOMER_EDIT_SUCCESS);
        eventBus.$off(MessageTypes.CUSTOMER_DELETED);
      });

      return {
        ...toRefs(state),
        customerId,
        inactiveCustomer
      };
    }
  });
