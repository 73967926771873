
  import {defineComponent, nextTick, PropType, reactive, Ref, toRefs, watch} from "vue";
  import {CompanyLocation} from "@/model/CompanyLocation";

  export default defineComponent({
    name: "InventoriesCompanyLocationsAddressForm",
    props: {
      companyLocation: {
        required: true,
        type: Object as PropType<CompanyLocation>
      },
      errors: {
        required: false,
        type: Map as PropType<Map<string, string | Ref<string>>>,
        default: () => new Map<string, string>()
      }
    },
    setup(props, context) {
      const initialState = {
        companyLocationModel: props.companyLocation,
      };
      const state = reactive({...initialState});

      watch(() => props.companyLocation, (newValue) => {
        state.companyLocationModel = newValue;
      });

      const onZipInput = (zip: string) => {
        const zipInt = parseInt(zip.replace (/[^0-9]/g, "" ));
        nextTick(() => {
          state.companyLocationModel.zip = isNaN(zipInt) ? null : zipInt;
          context.emit("companyLocationChanged", state.companyLocationModel);
        });
      };

      return {
        ...toRefs(state),
        onZipInput
      };
    }
  });
