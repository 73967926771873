import {computed, reactive, ref} from "vue";
import {AsyncState} from "@/enums";
import OrderOperatorService from "@/service/OrderOperatorService";
import {OrderOperator} from "@/views/Orders/Operators/OrderOperators.vue";

export function init() {

    const initialState = {
        asyncState: null as unknown as AsyncState,
    };

    const state = reactive({...initialState});
    const $externalResults = ref({});

    const isLoading = computed(() => {
        return state.asyncState == AsyncState.LOADING;
    });

    const isError = computed(() => {
        return state.asyncState == AsyncState.ERROR;
    });

    const isSuccess = computed(() => {
        return state.asyncState == AsyncState.SUCCESS;
    });

    const createOrUpdate = (orderId: number, item: OrderOperator): Promise<OrderOperator> => {
        state.asyncState = AsyncState.LOADING;
        if (item.id) {
            return OrderOperatorService.updateOrderOperator(orderId, item.id, {
                operatorNumber: item.operatorNumber,
                numberOfPositions: item.numberOfPositions
            })
            .then(response => {
                state.asyncState = AsyncState.SUCCESS;

                return Promise.resolve(response.data);
            })
            .catch(error => {
                state.asyncState = AsyncState.ERROR;

                return Promise.reject(error);
            });
        } else {
            return OrderOperatorService.createOrderOperator(orderId, {
                employeeId: item.employee.id,
                operatorNumber: item.operatorNumber,
                numberOfPositions: item.numberOfPositions
            })
            .then(response => {
                state.asyncState = AsyncState.SUCCESS;

                // @ts-ignore
                const id = response.headers.location.split("/").pop();

                return Promise.resolve({
                    id: Number(id),
                    employee: item.employee,
                    operatorNumber: item.operatorNumber,
                    numberOfPositions: item.numberOfPositions
                } as OrderOperator);
            })
            .catch(error => {
                state.asyncState = AsyncState.ERROR;

                return Promise.reject(error);
            });
        }
    };

    return {
        state,
        $externalResults,
        createOrUpdate,
        isLoading,
        isError,
        isSuccess
    };
}
