import {AxiosResponse} from "axios";
import Vue from "vue";
import Vuex, {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import Authentication from "@/model/Authentication";
import eventBus from "@/eventbus";
import {MessageTypes} from "@/components/message-types";
import AuthService from "@/service/AuthService";

Vue.use(Vuex);

export class AuthState {
    authentication: Authentication = new Authentication();
}

export const AuthMutations = Object.freeze({
    SET_AUTHENTICATION: "setAuthentication"
});

const mutations: MutationTree<AuthState> = {
    [AuthMutations.SET_AUTHENTICATION](state, authentication: Authentication) {
        state.authentication = authentication;
    }
};

export const AuthActions = Object.freeze({
    AUTHENTICATE: "authenticate",
    LOGIN: "login",
    LOGOUT: "logout"
});

const actions: ActionTree<AuthState, RootState> = {
    async [AuthActions.AUTHENTICATE]({commit}) {
        return await AuthService.authenticate()
          .then((response: AxiosResponse<Authentication>) => {
              const authentication = response.data;
              commit(AuthMutations.SET_AUTHENTICATION, authentication);

              if (authentication.authenticated) {
                  eventBus.$emit(MessageTypes.AUTHENTICATED);
              }

              return response.data;
          })
          .catch(function () {
              commit(AuthMutations.SET_AUTHENTICATION, new Authentication());
          });
    },
    async [AuthActions.LOGIN]({commit}, credentials) {
        return await AuthService.login(credentials.username, credentials.password)
          .then((response: AxiosResponse<Authentication>) => {
              commit(AuthMutations.SET_AUTHENTICATION, response.data);
              return true;
          })
          .catch(function () {
              commit(AuthMutations.SET_AUTHENTICATION, new Authentication());
              return false;
          });
    },
    async [AuthActions.LOGOUT]({commit}) {
        AuthService.logout()
          .then(() => {
              commit(AuthMutations.SET_AUTHENTICATION, new Authentication());
          });
    }
};

export const AuthGetters = Object.freeze({
    IS_LOGGED_IN: "isLoggedIn",
    IS_AUTHORIZED: "isAuthorized",
    IS_AUTHORIZED_FOR_GROUP: "isAuthorizedForGroup",
    GET_AUTHENTICATION: "getAuthentication",
    GET_EMPLOYEE_ID: "getEmployeeId",
    GET_FULL_NAME: "getFullName"
});

const getters: GetterTree<AuthState, RootState> = {
    [AuthGetters.GET_AUTHENTICATION](state) {
        return state.authentication;
    },
    [AuthGetters.IS_LOGGED_IN](state) {
        return state.authentication.authenticated;
    },
    [AuthGetters.IS_AUTHORIZED]: (state) => (toCheck: string) => {
        if (toCheck === undefined || toCheck === null) {
            return true;
        } else {
            return state.authentication.authorities.filter((value: string) => toCheck === value).length > 0;
        }
    },
    [AuthGetters.IS_AUTHORIZED_FOR_GROUP]: (state) => (group: string) => {
        if (group === undefined || group === null) {
            return true;
        } else {
            return state.authentication.authorities.filter((authority: string) => group === authority || group === authority.substring(0, authority.indexOf("_"))).length > 0;
        }
    },
    [AuthGetters.GET_EMPLOYEE_ID](state) {
        return state.authentication.employeeId;
    },
    [AuthGetters.GET_FULL_NAME](state) {
        return state.authentication.firstName + " " + state.authentication.lastName;
    }
};

export const AuthModule = "auth";

export const auth: Module<AuthState, RootState> = {
    namespaced: true,
    state: new AuthState(),
    getters,
    actions,
    mutations
};
