import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"rounded-lg",attrs:{"outlined":_vm.$vuetify.breakpoint.smAndUp,"flat":_vm.$vuetify.breakpoint.xsOnly}},[(_vm.loading)?_c(VProgressLinear,{staticClass:"my-0",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c(VCardTitle,[_c(VContainer,{staticClass:"py-0"},[_c(VRow,{staticClass:"mb-3"},[_c('img',{attrs:{"src":require("@/assets/logo.png")}})]),_c(VRow,[_vm._t("title")],2)],1)],1),_c(VCardText,[_vm._t("content")],2),_c(VCardActions,[(_vm.previousButton)?_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('previous')}}},[_vm._t("button-previous-text",function(){return [_vm._v(" Zurück ")]})],2):_vm._e(),_c(VSpacer),(_vm.nextButton)?_c(VBtn,{staticClass:"mr-1",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.$emit('next')}}},[_vm._t("button-next-text",function(){return [_vm._v(" Weiter ")]})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }