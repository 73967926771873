
  import TimeControllingClockingHistory
    from "@/views/TimeControlling/TimeControllingClockingHistory.vue";
  import Vue from "vue";
  import {OrderWorklogsDate, OrderWorklogsDateEmployee} from "@/model/OrderWorklogs";
  import Worklog from "@/model/Worklog";
  import {MessageTypes} from "@/components/message-types";
  import {isAuthorized} from "@/mixin/mixins";
  import store from "@/store/store";
  import {WorklogType, WorklogTypes} from "@/enums";
  import moment from "moment";

  export default Vue.extend({
    name: "OrderWorklogsTable",
    components: {
      TimeControllingClockingHistory: TimeControllingClockingHistory
    },
    props: {
      items: {
        type: Array,
        default: () => new Array<OrderWorklogsDate>()
      }
    },
    data: () => ({
      expanded: [],
      panels: new Array<number>(),
      headers: [
        {
          text: "",
          align: "right",
          value: "id",
          sortable: false,
          width: 40
        },
        {
          text: "Nachname",
          align: "left",
          value: "employee.lastName"
        },
        {
          text: "Vorname",
          align: "left",
          value: "employee.firstName"
        },
        {
          text: "Arbeit",
          align: "right",
          value: "minutesWork"
        },
        {
          text: "Reise",
          align: "right",
          value: "minutesTravel"
        },
        {
          text: "Pause",
          align: "right",
          value: "minutesPause"
        },
        {
          text: "Mitfahrer",
          align: "right",
          value: "minutesPassenger"
        },
        {
          text: "Von - Bis",
          align: "right",
          value: "start"
        },
        {
          text: "",
          align: "right",
          value: "id",
          sortable: false,
          width: 40
        }
      ],
      footerProps: {
        "items-per-page-options": [-1]
      },
      detailsHeaders: [
        {
          text: "Arbeitsart",
          align: "left",
          value: "worklogType.localizedName"
        },
        {
          text: "Startzeit",
          align: "right",
          value: "start"
        },
        {
          text: "Endzeit",
          align: "right",
          value: "end"
        },
        {
          text: "Zeit",
          align: "right",
          value: "id"
        },
        {
          text: "Gestempelt von",
          align: "right",
          value: "id"
        },
        {
          text: "",
          align: "right",
          value: "id",
          sortable: false,
          width: 80
        }
      ],
      pagination: {
        sortBy: "employee.employeeId",
        descending: false,
        rowsPerPage: -1
      }
    }),
    computed: {
      isDate (): boolean {
        return this.$moment(this.date as string, "YYYY-MM-DD", true).isValid();
      },
      date (): string {
        return this.$route.query.date as string;
      },
      orderId (): number {
        return Number(this.$route.params.orderId);
      }
    },
    watch: {
      items (item) {
        this.refreshPanelState();
      },
      yearMonth: function (yearMonth) {
        this.$router.push({ query: Object.assign({}, this.$route.query, { date: yearMonth }) });
      }
    },
    mounted () {
      this.refreshPanelState();
    },
    methods: {
      WorklogTypes() {
        return WorklogTypes;
      },
      customSort(items: any[], sortBy: string[], sortDesc: boolean[]) {
        if(sortBy && sortBy.length == 1) {
          const sorted = items.slice(0);
          let desc = sortDesc[0];
          let sortCriterion = sortBy[0];
          sorted.sort((a, b) => {
            // @ts-ignore
            if (sortCriterion === "employee.firstName" || sortCriterion === "employee.lastName") {
              if(sortCriterion === "employee.firstName") {
                // @ts-ignore
                return desc ? b.employee.firstName.localeCompare(a.employee.firstName) : a.employee.firstName.localeCompare(b.employee.firstName);
              } else {
                // @ts-ignore
                return desc ? b.employee.lastName.localeCompare(a.employee.lastName) : a.employee.lastName.localeCompare(b.employee.lastName);
              }
            } else {
              if (sortCriterion === "minutesWork") {
                return desc ? this.getMinutes(b, "WORK") - this.getMinutes(a, "WORK") : this.getMinutes(a, "WORK") - this.getMinutes(b, "WORK");
              } else if (sortCriterion === "minutesTravel") {
                return desc ? this.getMinutes(b, "TRAVEL") - this.getMinutes(a, "TRAVEL") : this.getMinutes(a, "TRAVEL") - this.getMinutes(b, "TRAVEL");
              }else if (sortCriterion === "minutesPause") {
                return desc ? this.getMinutes(b, "PAUSE") - this.getMinutes(a, "PAUSE") : this.getMinutes(a, "PAUSE") - this.getMinutes(b, "PAUSE");
              } else if (sortCriterion === "minutesPassenger") {
                return desc ? this.getMinutes(b, "PASSENGER") - this.getMinutes(a, "PASSENGER") : this.getMinutes(a, "PASSENGER") - this.getMinutes(b, "PASSENGER");
              } else {
                return desc ? moment(b.start, "HH:mm").diff(moment(a.start,  "HH:mm")) : moment(a.start, "HH:mm").diff(moment(b.start,  "HH:mm"));
              }
            }
          });

          return sorted;
        } else {
          return items;
        }
      },
      refreshPanelState () {
        // If there is only one panel immediately open it
        if (this.items.length === 1) {
          this.panels = new Array<number>();
          this.panels.push(0);
          // If a date is provided open that panel
        } else if (this.isDate) {
          this.panels = new Array<number>();
          const itemIndex = this.items.findIndex((item: any) => item.date === this.date);
          this.panels.push(itemIndex);
        }
      },
      formatClockedInBy (worklog: Worklog) {
        if (worklog.updateUser == null && worklog.updateTime == null) {
          return worklog.insertUser + " am " + this.$moment(worklog.insertTime).format(
            "DD.MM.YYYY HH:mm");
        } else {
          return worklog.updateUser + " am " + this.$moment(worklog.updateTime).format(
            "DD.MM.YYYY HH:mm");
        }
      },
      edit (item: Worklog) {
        this.$eventBus.$emit(MessageTypes.UPDATE_WORKLOG, item);
      },
      deleteWorklog (item: Worklog) {
        this.$eventBus.$emit(MessageTypes.DELETE_WORKLOG, item);
      },
      openClockingHistory (employee: any, date: any) {
        const orderId = this.$route.params.orderId;
        // @ts-ignore
        this.$refs.clockingHistory.open(employee, date, orderId);
      },
      isAuthorizedForEdit (item: OrderWorklogsDateEmployee): boolean {
        // @ts-ignore
        if (item.employee.employeeId === store.state.auth.authentication.employeeId) {
          return isAuthorized("Order_Worklogs_SelfEdit");
        } else {
          return isAuthorized("Order_Worklogs_OtherEdit");
        }
      },
      hasAutomaticPause (item: OrderWorklogsDateEmployee): boolean {
        let hasAutomaticPause = false;

        if (item.worklogs) {
          hasAutomaticPause = item.worklogs
            .some(worklog => worklog.comment && worklog.comment === "Automatische Pause");
        }

        return hasAutomaticPause;
      },
      getMinutes(item: OrderWorklogsDateEmployee, worklogType: WorklogType | string): number {
        let minutes = item.minutes
          .find(entry => entry.worklogType === worklogType);
        return minutes ? minutes.minutes : 0;
      }
    }
  });
