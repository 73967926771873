import {ValidationResult} from "@/model/ValidationResult";
import router from "@/router";
import Vue from "vue";
import {RegistrationData} from "@/model/Registration";
import {mapActions, mapGetters} from "vuex";
import {RegistrationActions, RegistrationGetters} from "@/store/modules/registration";
import {RegistrationType} from "@/enums";

export default Vue.extend({
    props: {
        step: {
            type: Number,
            default: null
        }
    },
    data: () => ({
        model: {} as RegistrationData,
        dirty: false,
        mounted: false,
        serverErrors: new Array<ValidationResult>()
    }),
    computed: {
        ...mapGetters({
            registration: RegistrationGetters.GET_REGISTRATION,
            registrationType: RegistrationGetters.GET_REGISTRATION_TYPE,
            isWorkPermitShown: RegistrationGetters.GET_WORK_PERMIT_SHOWN
        }),
        registrationUuid(): string {
            return router.currentRoute.params.registrationUuid;
        },
        socialSecurity(): boolean {
            return this.registrationType === RegistrationType.MODIFICATION_HELPER || this.registrationType === RegistrationType.SOCIAL_SECURITY || this.registrationType === RegistrationType.STOCK_REPLENISHMENT_SOCIAL_SECURITY;
        },
        minorEmployment(): boolean {
            return this.registrationType === RegistrationType.MINOR_EMPLOYMENT || this.registrationType === RegistrationType.WORKING_STUDENT || this.registrationType === RegistrationType.STOCK_REPLENISHMENT_MINOR_EMPLOYMENT;
        },
        shortTerm(): boolean {
            return this.registrationType === RegistrationType.SHORT_TERM;
        },
        falseValue(): boolean {
            return false;
        },
        trueValue(): boolean {
            return true;
        }
    },
    watch: {
        model: {
            handler() {
                if (this.mounted) {
                    this.dirty = true;
                }
            },
            deep: true
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.mounted = true;
        });
    },
    methods: {
        ...mapActions({
            updateWorkPermitShown: RegistrationActions.UPDATE_WORK_PERMIT_SHOWN
        }),
    }
});
