
  import {defineComponent, PropType, ref} from "vue";
  import OrderInfoCard from "@/views/Orders/Info/OrderInfoCard.vue";
  import Customer from "@/model/Customer";
  import CustomerLookup from "@/components/CustomerLookup.vue";
  import FormListEntry from "@/components/FormListEntry.vue";
  import {t} from "@/mixin/mixins";
  import ConfirmDialog from "@/components/ConfirmDialog.vue";
  import ConfirmDialogImpl from "@/components/ConfirmDialog.vue";

  export default defineComponent({
    name: "OrderInfoCustomer",
    components: {
      CustomerLookup,
      FormListEntry,
      OrderInfoCard,
      ConfirmDialog
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object as PropType<Customer>,
        required: false,
        default: () => null
      }
    },
    emits: ["input"],
    setup(props, context) {

      const confirmDialog = ref<InstanceType<typeof ConfirmDialogImpl>>();
      const customerLookup = ref<InstanceType<typeof CustomerLookup>>();

      const addCustomer = (): void => {
        customerLookup.value?.open()
          .then(result => {
            if (result) {
              context.emit("input", result);
            }
          });
      };

      const removeCustomer = () => {
        const title = t("order.orderInfo.customer.removeCustomerConfirmationDialog.title");
        const text = t("order.orderInfo.customer.removeCustomerConfirmationDialog.text");
        confirmDialog.value?.open(title, text)
          .then((confirmResult) => {
            if (confirmResult) {
              context.emit("input", null);
            }
          });
      };

      return {
        confirmDialog,
        customerLookup,
        addCustomer,
        removeCustomer
      };
    }
  });
