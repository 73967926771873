
  import {ErrorObject, useVuelidate} from "@vuelidate/core";
  import {computed, defineComponent, reactive, Ref, toRefs, watch} from "vue";
  import {VALUE} from "@/plugins/i18n";
  import {helpers, required} from "@vuelidate/validators";
  import {t, tWithParms} from "@/mixin/mixins";
  import ModalDialog from "@/components/ModalDialog.vue";
  import moment from "moment";
  import FormDatePickerV2 from "@/components/FormDatePickerV2.vue";
  import {OrderType} from "@/enums";
  import ReportService from "@/service/ReportService";
  import {handleDownload} from "@/CompositionUtils";

  export default defineComponent({
    name: "StatisticReportsWorkingHoursDialog",
    components: {
      ModalDialog,
      FormDatePickerV2
    },
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    setup (props, context) {

      const initialState = {
        loading: false,
        yearMonth: moment().format("YYYY-MM"),
        orderType: null as unknown as OrderType | null
      };

      const state = reactive({...Object.assign({}, initialState)});

      const availableOrderTypes = computed(() => {
        return [
          {
            code: OrderType.BEV,
            localizedName: t("enum.orderType." + OrderType.BEV)
          },
          {
            code: OrderType.DRY,
            localizedName: t("enum.orderType." + OrderType.DRY)
          },
          {
            code: OrderType.INV,
            localizedName: t("enum.orderType." + OrderType.INV)
          },
          {
            code: OrderType.MFR,
            localizedName: t("enum.orderType." + OrderType.MFR)
          },
          {
            code: OrderType.MIS,
            localizedName:  t("enum.orderType." + OrderType.MIS)
          },
          {
            code: OrderType.MOD,
            localizedName:  t("enum.orderType." + OrderType.MOD)
          }
        ];
      });

      const rules = {
        yearMonth: { required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required) },
        orderType: { required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required) },
      };

      const v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        const model = v$.value;
        if (model.$dirty) {
          model.$errors.forEach((error: ErrorObject) => {
            if(!errors.has(error.$propertyPath)) {
              errors.set(error.$propertyPath, error.$message);
            }
          });
        }

        return errors;
      });

      watch(() => props.value, (newValue, oldValue) => {
        // dialog is reopened
        if(!oldValue && newValue) {
          v$.value.$reset();
          state.orderType = null;
        }
      });

      const close = () => {
        context.emit("input", false);
      };

      const generateReport = () => {
        v$.value.$reset();
        v$.value.$touch();

        if(!v$.value.$invalid) {
          state.loading = true;

          ReportService.generateWorkingHoursReport({
            month: state.yearMonth,
            // @ts-ignore OrderType is set here, otherwise validation would fail
            orderType: state.orderType
          })
            .then(response => {
              handleDownload(response);
              close();
            })
            .finally(() => state.loading = false);
        }
      };

      return {
        ...toRefs(state),
        availableOrderTypes,
        close,
        errors,
        generateReport
      };
    }
  });
