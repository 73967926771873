import axios, {AxiosResponse} from "axios";
import eventBus from "@/eventbus";
import {MessageTypes} from "@/components/message-types";
import Order, {InventoryChecklistEntry, OrderSendMail} from "@/model/Order";
import {OrderPerDiemMonth} from "@/service/OrderPerDiemMonth";

const PATH = "/orders";

class OrderService {
  getOrder (orderId: number): Promise<AxiosResponse<Order>> {
    return axios.get(`${PATH}/${orderId}`);
  }

  assignEmployee (orderId: number, employeeId: number, assignmentType: string) {
    return axios
      .post(`${PATH}/${orderId}/assignEmployee`, {
        assignmentType: assignmentType,
        id: employeeId
      });
  }

  unassignEmployee (orderId: number, employeeId: number) {
    return axios
      .post(`${PATH}/${orderId}/unassignEmployee/${employeeId}`)
      .then(response => {
        eventBus.$emit(MessageTypes.SUCCESS, "Mitarbeiterzuweisung entfernt");

        return response;
      });
  }

  assignCustomer (orderId: number, customerId: number) {
    return axios
      .post(`${PATH}/${orderId}/assignCustomer`, {
        id: customerId
      });
  }

  unassignCustomer (orderId: number, customerId: number) {
    return axios
      .post(`${PATH}/${orderId}/unassignCustomer/${customerId}`)
      .then(response => {
        return response;
      });
  }

  createOrder (data: OrderSendMail) {
    return axios.post(`${PATH}`, data);
  }

  updateOrder (orderId: number, data: OrderSendMail) {
    return axios.put(`${PATH}/${orderId}`, data);
  }

  findOrdersByTerm (term: string): Promise<AxiosResponse<Array<Order>>> {
    return axios.get(`${PATH}`, {
      params: {
        term: term
      }
    });
  }

  findPerDiemsByMonth (orderId: string | number, yearMonth: string): Promise<AxiosResponse<OrderPerDiemMonth>> {
    return axios.get(`${PATH}/${orderId}/perdiems`, {
      params: {
        yearMonth: yearMonth
      }
    });
  }

  findRecentPerDiems (orderId: number): Promise<AxiosResponse<OrderPerDiemMonth>> {
    return axios.get(`${PATH}/${orderId}/perdiems/recent`);
  }

  findOrderEmployees (orderId: string | number, term: string, assignmentType: string) {
    return axios.get(`${PATH}/${orderId}/findEmployee`, {
      params: {
        term: term,
        assignmentType: assignmentType
      }
    });
  }

  updateChecklist (orderId: string | number, checklistItems: Array<InventoryChecklistEntry>): Promise<AxiosResponse<Array<InventoryChecklistEntry>>> {
    return axios.post(`${PATH}/${orderId}/checklist`, checklistItems);
  }

  assignTeamLeader (orderId: number, teamLeaderId: number) {
    return axios.post(`${PATH}/${orderId}/assignTeamLeader/${teamLeaderId}`);
  }

}

export default new OrderService();
