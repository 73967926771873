import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VListItem,{on:{"click":function($event){return _vm.openCompanyLocationDialog(_vm.companyLocation)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.companyLocation.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.toAddressString(_vm.companyLocation))+" ")])],1),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"ma-0",attrs:{"icon":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.openCompanyLocationDialog(_vm.companyLocation)}}},on),[_c(VIcon,[_vm._v(" mdi-pencil ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.t("inventories.companyLocations.tooltip.edit")))])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"ma-0",attrs:{"icon":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCompanyLocation(_vm.companyLocation)}}},on),[_c(VIcon,[_vm._v(" mdi-delete ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.t("inventories.companyLocations.tooltip.delete")))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }