import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pl-4"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"lg":"10"}},[_c(VExpansionPanels,{attrs:{"flat":"","hover":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c(VCardTitle,{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.t('customer.orders.upcoming.title'))+" ")])],1),_c(VExpansionPanelContent,[_c('CustomerOrdersUpcoming',{attrs:{"items":_vm.upcoming}})],1)],1),(_vm.customer.active)?_c(VRow,{staticClass:"pl-10 pr-10 pt-5 pb-5",attrs:{"justify":"end"}},[_c(VBtn,{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.planOrder()}}},[_vm._v(" "+_vm._s(_vm.t('customer.orders.upcoming.plan'))+" ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.newOrder()}}},[_vm._v(" "+_vm._s(_vm.t('customer.orders.upcoming.create'))+" ")])],1):_vm._e(),_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c(VCardTitle,{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.t('customer.orders.past.title'))+" ")])],1),_c(VExpansionPanelContent,[_c('CustomerOrdersPast',{attrs:{"items":_vm.past},on:{"avgPosChanged":_vm.pastOrdersAvgPosChanged}})],1)],1)],1)],1)],1),_c('PlanOrderDialog',{attrs:{"is-visible":_vm.planOrderDialog,"positions":_vm.pastOrdersAvgPos},on:{"close":_vm.closePlanOrder,"save":_vm.savePlanOrder}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }