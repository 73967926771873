import axios, {AxiosResponse} from "axios";
import {CustomerTravelTime} from "@/model/CustomerTravelTime";

const PATH = "/customer-travel-times";

export default {
    createTravelTime (payload: CustomerTravelTime): Promise<AxiosResponse<CustomerTravelTime>> {
        return axios.post(PATH, payload, { headers: {"Content-Type": "application/json"} }
        );
    },
    updateTravelTime (payload: CustomerTravelTime) {
        return axios.put(`${PATH}/${payload.id}`, payload);
    }
};
