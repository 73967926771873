
  import {EmployeeStatus, EmploymentTypes, WageTypes} from "@/enums";
  import {computed, defineComponent, onMounted, reactive, toRefs, watch} from "vue";
  import Employee from "@/model/Employee";
  import {isAuthorized} from "@/mixin/mixins";
  import router from "@/router";
  import EmployeeService, {EmployeeFilter} from "@/service/EmployeeService";
  import PageUtils from "@/components/PageUtils";
  import {DataOptions} from "vuetify";
  import FilterUtils from "@/FilterUtils";

  export default defineComponent({
    name: "EmployeesTable",
    setup(props, context) {
      const initialState = {
        filterRowVisible: true,
        filters: {
          employeeId: null,
          firstName: null,
          lastName: null,
          wageType: null,
          wage: null,
          employmentType: null,
          active: true,
          operatorNumber: null
        },
        headers: [
          {
            text: "ID",
            align: "right",
            value: "employeeId",
            visible: true,
            selectable: true
          },
          {
            text: "Vorname",
            align: "left",
            value: "firstName",
            visible: true,
            selectable: true
          },
          {
            text: "Nachname",
            align: "left",
            value: "lastName",
            visible: true,
            selectable: true
          },
          {
            text: "Entlohnungsart",
            align: "left",
            value: "wageType",
            authorization: "Accounting_Overview_ViewWageAndCosts",
            enumTypes: WageTypes,
            sortable: false,
            visible: true,
            selectable: true
          },
          {
            text: "Stundenlohn / Gehalt",
            align: "right",
            value: "wage",
            authorization: "Accounting_Overview_ViewWageAndCosts",
            visible: true,
            selectable: true
          },
          {
            text: "Beschäftigungsart",
            align: "left",
            value: "employmentType",
            enumTypes: EmploymentTypes,
            sortable: false,
            visible: true,
            selectable: true
          },
          {
            text: "Status",
            align: "left",
            value: "active",
            authorization: "Employee_ViewOther_InactiveEmployees",
            sortable: false,
            visible: true,
            selectable: true,
            enumTypes: [{code: true, name: "Active", localizedName: "Aktiv"}, {
              code: false,
              name: "Inactive",
              localizedName: "Inaktiv"
            }]
          },
          {
            text: "Vertragsenddatum",
            align: "right",
            value: "contractExpiryDate",
            visible: false,
            selectable: true,
            filter: false
          },
          {
            text: "Bedienernummer",
            align: "right",
            value: "operatorNumber",
            visible: false,
            selectable: true,
            filter: true
          },
          {
            text: "",
            align: "right",
            value: "settings",
            sortable: false,
            width: 40,
            visible: true,
            selectable: false
          }
        ],
        options: {
          itemsPerPage: 25
        } as DataOptions,
        loading: false,
        columnDialog: false,
        columns: [],
        items: [] as Array<Employee>,
        serverItemsLength: -1,
        typeTimeout: 300
      };

      const state = reactive({...initialState});

      const footerProps = {
        "items-per-page-options": [25, 50, 100, 250, -1]
      };

      const availableHeaders = computed(() => {
        return state.headers.filter(header => header.authorization === undefined || isAuthorized(header.authorization));
      });

      const visibleHeaders = computed(() => {
        return availableHeaders.value.filter(header => header.visible);
      });

      const columnVisibilityMap = computed(() => {
        // @ts-ignore
        return Object.assign(...availableHeaders.value.map(({value, visible}) => ({[value]: visible})));
      });

      const openEmployee = (item: Employee) => {
        router.push({path: `/employees/${item.employeeId}`});
      };

      const openColumnDialog = () => {
        state.columns = JSON.parse(JSON.stringify(availableHeaders.value));
        state.columnDialog = true;
      };

      const saveColumnDialog = (newColumns: any) => {
        state.headers = newColumns;
        state.columnDialog = false;
      };

      watch(() => state.options, (newValue, oldValue) => {
        load();
      }, {deep: true});

      watch(() => state.filters, (newValue, oldValue) => {
        clearTimeout(state.typeTimeout);

        state.typeTimeout = setTimeout(function () {
          load();
        }, 300);
      }, {deep: true});

      const load = () => {
        if (state.loading) {
          return;
        }

        state.loading = true;

        let sort = null;
        if (state.options.sortBy && state.options.sortBy.length === 1) {
          sort = state.options.sortDesc[0] ? state.options.sortBy[0] + ",desc" : state.options.sortBy[0];
        }

        let employeeStatus = null;
        if (state.filters.active != null) {
          employeeStatus = state.filters.active ? EmployeeStatus.ACTIVE : EmployeeStatus.INACTIVE;
        }

        let filter: EmployeeFilter = {
          employeeId: FilterUtils.isNum(state.filters.employeeId) ? Number(state.filters.employeeId) : null,
          firstName: state.filters.firstName == "" ? null : state.filters.firstName,
          lastName: state.filters.lastName == "" ? null : state.filters.lastName,
          employmentType: state.filters.employmentType,
          wage: state.filters.wage == "" ? null : state.filters.wage,
          wageType: state.filters.wageType,
          status: employeeStatus,
          operatorNumber: state.filters.operatorNumber
        };

        EmployeeService.findEmployees(filter, state.options.itemsPerPage, state.options.page - 1, sort)
          .then(response => {
            state.items = PageUtils.extractCollection<Employee>(response.data, "employees");
            state.serverItemsLength = response.data.page.totalElements;
          })
          .finally(() => state.loading = false);
      };

      onMounted(() => {
        load();
      });

      return {
        ...toRefs(state),
        footerProps,
        availableHeaders,
        visibleHeaders,
        columnVisibilityMap,
        openColumnDialog,
        saveColumnDialog,
        openEmployee
      };
    }
  });
