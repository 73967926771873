
  import Vue from "vue";
  import Component from "vue-class-component";
  import FormDatePicker from "@/components/FormDatePicker.vue";
  import {Prop} from "vue-property-decorator";
  import Customer from "@/model/Customer";
  import CustomerDetailsCard from "@/views/Customers/details/CustomerDetailsCard.vue";
  import CustomerDetailsCardListEntry from "@/views/Customers/details/CustomerDetailsCardListEntry.vue";
  import CustomersContactForm, {FORM_ID} from "@/views/Customers/forms/CustomersContactForm.vue";
  import {getCustomerFieldIcon} from "@/views/Customers/CustomerUtils";

  class ListEntry {
    public label: string;
    public text: string;
    public icon?: string;
    public isMail?: boolean;
    public isPhone?: boolean;
  }

  @Component({
    components: {
      CustomersContactForm,
      CustomerDetailsCardListEntry,
      CustomerDetailsCard,
      FormDatePicker
    }
  })
  export default class CustomerDetailsCardContact extends Vue {
    @Prop({default: () => new Customer()})
    public customer: Customer;

    @Prop({default: false})
    public editable: boolean;

    public formId = FORM_ID;

    listEntries(customer: Customer): Array<ListEntry> {
      const listEntries: Array<ListEntry> = [];
      listEntries.push({
        label: "Kunde / Name",
        text: customer.name,
        icon: getCustomerFieldIcon("name")
      });
      listEntries.push({
        label: "Adresse",
        text: customer.addressAsString,
        icon: getCustomerFieldIcon("address")
      });
      listEntries.push({label: "E-Mail", text: customer.email, icon: getCustomerFieldIcon("email"), isMail: true});
      listEntries.push({
        label: "Telefon",
        text: customer.phone,
        icon: getCustomerFieldIcon("phone"),
        isPhone: true
      });
      listEntries.push({label: "Fax", text: customer.fax, icon: getCustomerFieldIcon("fax")});
      return listEntries;
    }

    toMailTo(email: string) {
      return "mailto:" + email;
    }

    toTel(phone: string) {
      return "tel:" + phone;
    }
  }
