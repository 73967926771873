
  import ModalDialog from "@/components/ModalDialog.vue";
  import {defineComponent, reactive, toRefs, watch} from "vue";
  import Customer from "@/model/Customer";
  import CustomerService from "@/service/CustomerService";
  import {MessageTypes} from "@/components/message-types";
  import {t} from "@/mixin/mixins";
  import eventBus from "@/eventbus";

  export default defineComponent({
    name: "CustomerLookup",
    components: {
      ModalDialog
    },
    setup(props, context) {

      const initialState = {
        dialog: false,
        model: null as unknown as Customer,
        isLoading: false,
        items: new Array<Customer>(),
        search: "",
        result: (selectedCustomer: Customer | PromiseLike<Customer>) => {
        }
      };

      const state = reactive({...initialState});

      const resetState = () => {
        Object.assign(state, initialState);
      };

      const close = () => {
        resetState();
        state.dialog = false;
      };

      const open = () => {
        resetState();
        state.dialog = true;

        return new Promise<Customer>((resolve) => {
          state.result = resolve;
        });
      };

      const save = () => {
        state.dialog = false;
        state.result(state.model);
        resetState();
      };

      const customerSelected = (customer: Customer) => {
        state.model = customer;
      };

      let timeout = 200;
      watch(() => state.search, (newValue, oldValue) => {
        clearTimeout(timeout);

        if (newValue != null && newValue.length > 2) {
          state.isLoading = true;
          timeout = setTimeout(function () {
            CustomerService.findCustomer(newValue)
              .then(response => {
                state.items = response;
                state.isLoading = false;
              })
              .catch((error) => {
                eventBus.$emit(MessageTypes.ERROR, t("errore.generalError"));
              });
          }, timeout);
        }
      });

      return {...toRefs(state), close, customerSelected, open, save};
    }
  });
