
  import {computed, defineComponent, PropType, reactive, toRefs} from "vue";
  import {integer, minValue} from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import {OrderOperator} from "@/views/Orders/Operators/OrderOperators.vue";
  import {orderId} from "@/RouterUtils";
  import {AsyncState} from "@/enums";
  import {init} from "@/views/Orders/Operators/OrderOperatorsComposable";

  export default defineComponent({
    name: "OrderOperatorsListNumberOfPositions",
    props: {
      editable: {
        type: Boolean,
        default: () => false
      },
      item: {
        type: Object as PropType<OrderOperator>,
        default: () => null,
      }
    },
    setup(props, context) {
      const initialState = {
        numberOfPositions: props.item.numberOfPositions
      };

      const {
        state: generalState,
        createOrUpdate,
        isLoading,
        isError,
        isSuccess,
        $externalResults,
      } = init();

      const state = reactive({...initialState});

      const rules = computed(() => ({
        numberOfPositions: {
          integer,
          minValue: minValue(1)
        }
      }));

      const v$ = useVuelidate(rules, state, { $externalResults });

      const update = () => {
        if(props.editable && props.item.numberOfPositions != state.numberOfPositions) {
          v$.value.$clearExternalResults();
          v$.value.$validate();

          if(!invalid.value) {
            createOrUpdate(orderId.value,{
              id: props.item.id,
              employee: props.item.employee,
              operatorNumber: props.item.operatorNumber,
              numberOfPositions: state.numberOfPositions
            })
              .then(updated => {
                context.emit("updated", updated);
              })
              .catch(error => {
                $externalResults.value = {
                  numberOfPositions: error.response.data.title
                };
              });
          } else {
            generalState.asyncState = AsyncState.ERROR;
          }
        }
      };

      const invalid = computed(() => {
        return v$.value.$dirty && v$.value.$error;
      });

      const errorMessage = computed(() => {
        return invalid.value ? v$.value.$errors[0].$message : null;
      });

      return {
        ...toRefs(state),
        isLoading,
        isError,
        isSuccess,
        errorMessage,
        update
      };
    }
  });
