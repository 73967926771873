
  import {CompanyLocation} from "@/model/CompanyLocation";
  import {defineComponent, inject, PropType} from "vue";
  import {t} from "@/mixin/mixins";
  import {MarketArea} from "@/model/MarketArea/MarketArea";
  import InventoriesService from "@/service/InventoriesService";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";

  export default defineComponent({
    name: "InventoriesCompanyLocationsFormCard",
    props: {
      companyLocations: {
        type: Array as PropType<CompanyLocation[]>,
        default: () => new Array<CompanyLocation>()
      }
    },
    setup (props, context) {
      const headers = [
        {
          text: t("inventories.companyLocations.table.header.name"),
          align: "left",
          value: "name"
        },
        {
          text: t("inventories.companyLocations.table.header.street"),
          align: "left",
          value: "street"
        },
        {
          text: t("inventories.companyLocations.table.header.zip"),
          align: "left",
          value: "zip"
        },
        {
          text: t("inventories.companyLocations.table.header.city"),
          align: "left",
          value: "city"
        },
        {
          align: "right",
          value: "edit",
          sortable: false,
          width: 40
        },
        {
          align: "right",
          value: "delete",
          sortable: false,
          width: 40
        },
      ];

      const openCompanyLocationDialog = (companyLocation: CompanyLocation) => {
        context.emit("openCompanyLocationDialog", companyLocation);
      };

      let appRoot = inject("app-root");
      // @ts-ignore
      const confirmDialog = appRoot.confirmDialogComposition as InstanceType<typeof ConfirmDialogComposition>;
      const deleteCompanyLocation = (companyLocation: CompanyLocation) => {
        const title = `${t("inventories.companyLocations.dialog.title.delete")}\n${companyLocation.name}`;
        const text = t("inventories.companyLocations.dialog.text.delete");
        confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              InventoriesService.deleteCompanyLocation(companyLocation.id)
                .then(() => {
                  eventBus.$emit(MessageTypes.COMPANY_LOCATION_DELETED, companyLocation.id);
                })
                .catch(() => {
                });
            }
          });
      };

      return {
        headers,
        openCompanyLocationDialog,
        deleteCompanyLocation
      };
    }
  });
