import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.checklistItems.length > 0)?_c(VList,[_vm._l((_vm.checklistItems),function(item){return [_c(VListItem,{key:item.code,staticClass:"px-0"},[_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(_vm.t("inventoryChecklist.codes", item.code))+" ")])],1),_c(VListItemAction,[_c(VSwitch,{attrs:{"disabled":item.done},on:{"click":function($event){return _vm.confirmItem(item)}},model:{value:(item.done),callback:function ($$v) {_vm.$set(item, "done", $$v)},expression:"item.done"}})],1),_c(VListItemAction,[(item.done)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({},on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.doneText(item))+" ")])]):_vm._e()],1)],1)]})],2):_c(VRow,{staticClass:"justify-center"},[_c(VProgressCircular,{staticClass:"my-0",attrs:{"indeterminate":"","color":"primary"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }