
  import {defineComponent, PropType, ref} from "vue";
  import OrderInfoCard from "@/views/Orders/Info/OrderInfoCard.vue";
  import FormListEntry from "@/components/FormListEntry.vue";
  import OrderInfoCostCenterDialog from "@/views/Orders/Info/OrderInfoCostCenterDialog.vue";
  import Order from "@/model/Order";

  export default defineComponent({
    name: "OrderInfoCostCenter",
    components: {
      FormListEntry,
      OrderInfoCard,
      OrderInfoCostCenterDialog
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object as PropType<Order>,
        required: false,
        default: () => null
      }
    },
    emits: ["updated"],
    setup(props, context) {

      const orderInfoCostCenterDialog = ref<InstanceType<typeof OrderInfoCostCenterDialog>>();

      const editCostCenter = (): void => {
        orderInfoCostCenterDialog.value?.open(props.value.id, props.value.costCenter)
          .then(result => {
            if (result) {
              context.emit("updated");
            }
          });
      };

      return {editCostCenter, orderInfoCostCenterDialog};
    }
  });
