
  import ModalDialog from "@/components/ModalDialog.vue";
  import {defineComponent, reactive, ref, toRefs} from "vue";
  import OrderServiceV2 from "@/service/OrderServiceV2";
  import {MessageTypes} from "@/components/message-types";
  import {t} from "@/mixin/mixins";
  import eventBus from "@/eventbus";

  export default defineComponent({
    name: "OrderInfoNotesDialog",
    components: {
      ModalDialog
    },
    setup(props, context) {

      const initialState = {
        dialog: false,
        model: null as unknown as string,
        orderId: null as unknown as number,
        result: (updated: boolean | PromiseLike<boolean>) => {
        }
      };

      const state = reactive({...initialState});

      const resetState = () => {
        Object.assign(state, initialState);
      };

      const title = ref<string>("");

      const close = () => {
        state.dialog = false;
        state.result(false);
        resetState();
      };

      const open = (orderId: number, notes: string | undefined | null) => {
        resetState();
        state.dialog = true;
        state.model = notes ? notes : "";
        state.orderId = orderId;

        title.value = notes ? t("order.orderInfo.notes.edit") : t("order.orderInfo.notes.add");

        return new Promise<boolean>((resolve, reject) => {
          state.result = resolve;
        });
      };

      const save = () => {
        let request = {
          "notes": state.model ? state.model : null
        };

        OrderServiceV2.updateOrder(state.orderId, request)
          .then(response => {
            state.dialog = false;
            state.result(true);
            resetState();
          })
          .catch(error => {
            eventBus.$emit(MessageTypes.ERROR, t("errors.generalError"));
          });
      };

      return {...toRefs(state), title, close, open, save};
    }
  });
