
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import ListEntry from "@/views/Registration/RegistrationSummaryCardListEntry.vue";
  import Utils from "@/Utils";
  import {mapGetters} from "vuex";
  import {RegistrationGetters} from "@/store/modules/registration";
  import RegistrationMixin from "@/views/Registration/RegistrationMixins";

  export default RegistrationMixin.extend({
    name: "RegistrationSummaryCard",
    components: {
      RegistrationCard,
      ListEntry
    },
    computed: {
      ...mapGetters({
        registration: RegistrationGetters.GET_REGISTRATION,
        registrationType: RegistrationGetters.GET_REGISTRATION_TYPE
      })
    },
    methods: {
      toCamelCase (value: string) {
        return Utils.toCamelCase(value);
      }
    }
  });
