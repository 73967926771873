import vuetify from "@/plugins/vuetify";
import {ref} from "vue";
import ConfirmDialogImpl from "@/components/ConfirmDialog.vue";
import store from "@/store/store";

export function confirmDialog() {
    return ref<InstanceType<typeof ConfirmDialogImpl>>();
}

export function isAuthorized(toCheck: string): boolean {
    return store.getters["auth/isAuthorized"](toCheck);
}

export function isAuthorizedForGroup(group: string): boolean {
    return store.getters["auth/isAuthorizedForGroup"](group);
}

export function mobile(): boolean {
    return vuetify.framework.breakpoint.smAndDown;
}

export function t(...keys: string[]): string {
    return vuetify.framework.lang.t("$vuetify." + keys.join("."));
}

export function tWithParms(key: string, ...params: Array<string | number>): string {
    return vuetify.framework.lang.t("$vuetify." + key, ...params);
}
