import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,[_c(VCol,[(_vm.isAuthorized('Order_Overview'))?_c(VTabs,{attrs:{"centered":"","color":"primary"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c(VTab,{attrs:{"ripple":"","to":"info"}},[_vm._v(" "+_vm._s(_vm.t("order.tabs.info"))+" ")]),(!_vm.isOrderCreateMode && _vm.isAuthorized('Order_Worklogs'))?_c(VTab,{attrs:{"to":"worklogs"}},[_vm._v(" "+_vm._s(_vm.t("order.tabs.worklogs"))+" ")]):_vm._e(),(!_vm.isOrderCreateMode && _vm.isAuthorized('Order_OrderAssignment_View'))?_c(VTab,{attrs:{"to":"assignments"}},[_vm._v(" "+_vm._s(_vm.t("order.tabs.assignments"))+" ")]):_vm._e(),(!_vm.isOrderCreateMode && _vm.isAuthorized('PerDiem_View_Order'))?_c(VTab,{attrs:{"to":"perdiems"}},[_vm._v(" "+_vm._s(_vm.t("order.tabs.perDiems"))+" ")]):_vm._e(),(_vm.isShowOperatorsTab)?_c(VTab,{attrs:{"to":"operators"}},[_vm._v(" "+_vm._s(_vm.t("order.tabs.operator"))+" ")]):_vm._e(),(!_vm.isOrderCreateMode)?_c(VTab,{attrs:{"to":"editHistory"}},[_vm._v(" "+_vm._s(_vm.t("order.tabs.editHistory"))+" ")]):_vm._e(),_c(VTabsItems,[_c('keep-alive',[_c('router-view',{attrs:{"order":_vm.order}})],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }