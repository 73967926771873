
  import {defineComponent} from "vue";

  export default defineComponent({
    name: "OrderInfoCard",
    props: {
      title: {
        type: String,
        default: ""
      }
    }
  });
