const CUSTOMER_ICON_MAPPING: Record<string, string> = {
    name: "mdi-domain",
    address: "mdi-home",
    company: "mdi-home",
    email: "mdi-email",
    phone: "mdi-phone",
    mobile: "mdi-cellphone",
    fax: "mdi-fax",
    customerTypeId: "mdi-account-switch-outline",
    customerId: "mdi-identifier",
    storeManager: "mdi-account-tie",
    storage: "mdi-package-variant-closed",
    deliveryDays: "mdi-calendar-today",
    doorcode: "mdi-lock-open",
    og: "mdi-food-apple",
    flowers: "mdi-flower-tulip",
    inventoryValue: "mdi-currency-eur",
    valueFormatted: "mdi-currency-eur",
    drinks: "mdi-bottle-wine",
    targetActualComparison: "mdi-bookmark-check",
    labels: "mdi-label-multiple",
    storeAssistant: "mdi-account-supervisor",
    additionalInformation: "mdi-comment-alert-outline",
    notes: "mdi-note-outline",
    positions: "mdi-barcode-scan"
};

export const getCustomerFieldIcon = (field: string) => {
    return CUSTOMER_ICON_MAPPING[field];
};
