import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c('RightsBreadcrumbs'),_c(VList,{staticClass:"py-0",attrs:{"two-line":""}},_vm._l((_vm.links),function(link){return _c('div',{key:link.title},[_c(VListItem,{on:{"click":function($event){return _vm.open(link.to)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(link.title)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(link.subtitle)+" ")])],1),_c(VListItemAction,[_c(VIcon,[_vm._v(" mdi-chevron-right ")])],1)],1),_c(VDivider)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }