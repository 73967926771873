
  import { defineComponent, PropType, } from "vue";
  import Order from "@/model/Order";
  import {OrderEmployeeAssignment} from "@/model/OrderEmployeeAssignment";

  export default defineComponent({
    name: "OrderDetailsTabAssignmentList",
    props: {
      title: {
        type: String,
        default: ""
      },
      order: {
        type: Object as PropType<Order>,
        required: true
      },
      items: {
        type: Array as PropType<Array<OrderEmployeeAssignment>>,
        default: () => []
      },
      showDelete: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context) {
      const assignTeamLeader = (item: OrderEmployeeAssignment) => {
        if (props.order.teamLeader?.id != item.employee.employeeId) {
          context.emit("assignTeamLeader", item.employee.employeeId);
        } else {
          context.emit("assignTeamLeader", -1);
        }
      };

      const getTeamLeaderColor = (item: OrderEmployeeAssignment) => {
        const employeeId = item.employee.employeeId;
        const teamLeaderId = props.order.teamLeader?.id;
        if (teamLeaderId) {
          return teamLeaderId == employeeId ? "primary" : "disabled";
        } else {
          return "disabled";
        }
      };

      const getTeamLeaderClass = (item: OrderEmployeeAssignment) => {
        const employeeId = item.employee.employeeId;
        const teamLeaderId = props.order.teamLeader?.id;
        if (teamLeaderId != null && teamLeaderId != employeeId) {
          return "btn-not-teamleader";
        }
      };

      const deleteAssignment = (item : OrderEmployeeAssignment) => {
        context.emit("deleteAssignment", item);
      };

      return {
        assignTeamLeader,
        getTeamLeaderColor,
        getTeamLeaderClass,
        deleteAssignment
      };
    }
  });
