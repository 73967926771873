
  import Vue from "vue";
  import Component from "vue-class-component";
  import {
    ClockingParameters,
    TimeclockActions,
    TimeclockGetters,
    TimeclockModule
  } from "@/store/modules/timeclock";
  import TimeclockEmployeeEntry from "@/model/TimeclockEmployeeEntry";
  import { Action, Getter } from "vuex-class";
  import TimeclockClockingRequest from "@/model/TimeclockClockingRequest";
  import { HeaderGetters, HeaderModule } from "@/store/modules/header";
  import FabBlockButton from "@/components/FabBlockButton.vue";
  import TimeclockOrderTabSelectableList
    from "@/views/Timeclock/TimeclockOrderSelectableEmployeeList.vue";
  import TimeclockOrderEmployeeList from "@/views/Timeclock/TimeclockOrderEmployeeList.vue";
  import { Prop, Watch } from "vue-property-decorator";
  import WorklogUtils from "@/WorklogUtils";
  import Utils from "@/Utils";

  @Component({
    components: {
      FabBlockButton,
      TimeclockOrderTabSelectableList,
      TimeclockOrderEmployeeList
    }
  })
  export default class TimeclockOrderTabPause extends Vue {
    @Getter(HeaderGetters.GET_DATE, { namespace: HeaderModule }) date: string;

    @Getter(HeaderGetters.GET_TIME, { namespace: HeaderModule }) time: string;

    @Prop({ default: () => [] })
    public items: Array<any>;

    @Prop({ default: () => [] })
    public errors: Array<any>;

    private selectedItems = [];

    @Watch("items")
    onItemsChanged () {
      this.selectedItems.length = 0;
    }

    get orderId (): number {
      return Number(this.$route.params.orderId);
    }

    get pauseable () {
      return this.items
        .filter(item => {
          const openWorklog = WorklogUtils.getOpenWorklog(this.filterOrderRelevantWorklogs(item.worklogs));
          // @ts-ignore
          return openWorklog && (openWorklog.worklogType === "WORK" || openWorklog.worklogType === "TRAVEL");
        })
        .sort((item1, item2) => {
          const openWorklog1 = WorklogUtils.getOpenWorklog(this.filterOrderRelevantWorklogs(item1.worklogs));
          const openWorklog2 = WorklogUtils.getOpenWorklog(this.filterOrderRelevantWorklogs(item2.worklogs));
          // @ts-ignore
          return this.$moment(openWorklog1.start, "HH:mm").diff(this.$moment(openWorklog2.start, "HH:mm"), "minutes");
        });
    }

    get paused () {
      return this.items
        .filter(item => {
          const openWorklog = WorklogUtils.getOpenWorklog(this.filterOrderRelevantWorklogs(item.worklogs));
          // @ts-ignore
          return openWorklog && (openWorklog.worklogType === "PAUSE");
        });
    }

    get allItemsSelected () {
      return this.pauseable.length === this.selectedItems.length;
    }

    filterOrderRelevantWorklogs (worklogs: any) {
      return worklogs ? worklogs.filter((worklog: any) => worklog.order.id === this.orderId) : [];
    }

    onClick (item: TimeclockEmployeeEntry) {
      const found = this.findItemInSelection(item);
      if (found) {
        this.deselectItem(item);
      } else {
        this.selectItem(item);
      }
    }

    findItemInSelection (item: TimeclockEmployeeEntry) {
      return this.selectedItems.find(selectedItem => {
        // @ts-ignore
        return selectedItem.employeeId === item.id;
      });
    }

    selectItem (item: TimeclockEmployeeEntry) {
      const found = this.findItemInSelection(item);
      if (!found) {
        // @ts-ignore
        this.selectedItems.push({ employeeId: item.id });
      } else {
        console.warn("Item already added");
      }
    }

    deselectItem (item: TimeclockEmployeeEntry) {
      const found = this.findItemInSelection(item);
      if (found) {
        Utils.remove(this.selectedItems, found);
      } else {
        console.warn("Item was not selected in the first place");
      }
    }

    selectAll () {
      if (this.allItemsSelected) {
        this.selectedItems = [];
      } else {
        // @ts-ignore
        this.selectedItems = Array.from(this.pauseable);
      }
    }

    itemColor (item: TimeclockEmployeeEntry) {
      return this.findItemInSelection(item) ? "primary" : "";
    }

    pause () {
      const employees: Array<TimeclockClockingRequest> = [];
      this.selectedItems.forEach((item: TimeclockEmployeeEntry) => {
        const clockingRequest = new TimeclockClockingRequest();
        // @ts-ignore
        clockingRequest.employeeId = item.employeeId;
        clockingRequest.worklogType = null;
        clockingRequest.clockingAction = "PAUSE";
        employees.push(clockingRequest);
      });

      const params = new ClockingParameters();
      params.orderId = Number(this.$route.params.orderId);
      params.date = this.date;
      params.time = this.time;
      params.employees = employees;
      params.messageProgress = "Pausiere...";
      params.messageSuccess = "Pausieren erfolgreich!";
      params.messageValidation = "Pausieren nicht erfolgreich!";

      this.$emit("pause", params);
    }
  }
