
  import Vue from "vue";
  import {MessageTypes} from "@/components/message-types";

  export default Vue.extend({
    name: "ModalDialog",
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      crud: {
        type: Boolean,
        default: false
      },
      deletable: {
        type: Boolean,
        default: false
      },
      disableFullscreen: {
        type: Boolean,
        default: false
      },
      value: {
        type: Boolean,
        default: false
      },
      maxWidth: {
        type: String,
        default: "500px"
      }
    },
    data: () => ({}),
    computed: {
      fullscreen (): boolean {
        return !this.disableFullscreen && this.$vuetify.breakpoint.smAndDown;
      }
    },
    created () {
      this.$eventBus.$on(MessageTypes.CLOSE_ALL_DIALOGS, () => {
        this.close();
      });
    },
    methods: {
      close () {
        this.$emit("input", false);
        this.$emit("close");
      },
      save () {
        this.$emit("save");
      },
      deleteItem () {
        const title = "Eintrag löschen";
        const text = "Soll der Eintrag wirklich gelöscht werden?";

        this.$root.$confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              this.$emit("delete");
            }
          });
      }
    }
  });
