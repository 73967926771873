
  import Employee from "@/model/Employee";
  import {AxiosError} from "axios";
  import ValidationError from "@/model/ValidationError";
  import {MessageTypes} from "@/components/message-types";
  import ModalDialog from "@/components/ModalDialog.vue";
  import {computed, defineComponent, reactive, SetupContext, toRefs} from "vue";
  import {helpers, required} from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import EmployeeService from "@/service/EmployeeService";
  import {t} from "@/mixin/mixins";
  import eventBus from "@/eventbus";

  export default defineComponent({
    name: "EmployeeDetailsCard",
    components: {
      ModalDialog
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      employee: {
        type: Employee,
        default: () => new Employee(),
      },
      icon: {
        type: String,
        default: null
      }
    },
    setup(props, context: SetupContext) {
      const initialState = {
        dialog: false,
        editItem: new Employee() as Employee,
        serverErrors: new Map<string, string>()
      };

      const state = reactive({...initialState});

      const rules = computed(() => ({
        editItem: {
          employeeId: {
            required: helpers.withMessage("Mitarbeiter-ID ist erforderlich.", required)
          },
          firstName: {
            required: helpers.withMessage("Vorname ist erforderlich.", required)
          },
          lastName: {
            required: helpers.withMessage("Nachname ist erforderlich.", required)
          },
          birthdate: {
            required: helpers.withMessage("Geburtsdatum ist erforderlich.", required)
          },
          email: {
            required: helpers.withMessage("E-Mail ist erforderlich.", required)
          },
          active: {
            required
          }
        }
      }));

      // @ts-ignore
      const v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string>();
        if (state.serverErrors.size > 0) {
          return state.serverErrors;
        } else {
          const model = v$.value.editItem;
          if (model.$dirty) {
            if (model.employeeId?.$error) {
              errors.set("employee.employeeId", model.employeeId.$errors[0].$message as string);
            }
            if (model.firstName?.$error) {
              errors.set("employee.firstName", model.firstName.$errors[0].$message as string);
            }
            if (model.lastName?.$error) {
              errors.set("employee.lastName", model.lastName.$errors[0].$message as string);
            }
            if (model.birthdate?.$error) {
              errors.set("employee.birthdate", model.birthdate.$errors[0].$message as string);
            }
            if (model.email?.$error) {
              errors.set("employee.email", model.email.$errors[0].$message as string);
            }
            if (model.contractExpiryDate?.$error) {
              errors.set("employee.contractExpiryDate", model.contractExpiryDate.$errors[0].$message);
            }
          }
        }

        return errors;
      });

      const save = () => {
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
        v$.value.$touch();

        if (props.employee.id && !v$.value.$invalid) {
          EmployeeService.updateEmployee(props.employee.id, state.editItem)
            .then(response => {
              eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("saveSuccessful"));
              state.dialog = false;
              EmployeeService.getEmployeeById(props.employee.id)
                .then(response => {
                  context.emit("update", response.data);
                });
            })
            .catch((error: AxiosError) => {
              if (error && error.response && error.response.status === 422) {
                const errorMap = new Map<string, string>();
                // @ts-ignore
                error.response.data.forEach((error: ValidationError) => {
                  errorMap.set(error.path, error.message);
                });

                // Needed to trigger reactivity
                state.serverErrors = errorMap;
              }
            });
        }
      };

      const edit = () => {
        state.serverErrors = new Map<string, string>();
        state.editItem = Object.assign({}, props.employee);
        // Ignore those properties when updating
        // @ts-ignore
        delete state.editItem.wages;
        // @ts-ignore
        delete state.editItem.employments;

        state.dialog = true;
      };

      const cancel = () => {
        state.dialog = false;
      };

      const openIcon = () => {
        if (props.icon) {
          context.emit("iconClicked");
        }
      };

      return {
        ...toRefs(state),
        v$,
        errors,
        cancel,
        edit,
        openIcon,
        save
      };
    }
  });
