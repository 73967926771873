import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VForm,[_c('CurrencyField',{attrs:{"label":"Gehalt","error-message":_vm.errors.get('wage'),"disabled":_vm.disabled},on:{"focus":_vm.clearWage},model:{value:(_vm.wage.wage),callback:function ($$v) {_vm.$set(_vm.wage, "wage", $$v)},expression:"wage.wage"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VTextField,_vm._g({attrs:{"label":"Gültig ab","append-icon":"mdi-calendar","disabled":!_vm.isValidFromEditable,"readonly":"","value":_vm._f("formatDate")(_vm.wage.validFrom,'MM.YYYY'),"error-messages":_vm.errors.get('validFrom')}},on))]}}]),model:{value:(_vm.wageDateMenu),callback:function ($$v) {_vm.wageDateMenu=$$v},expression:"wageDateMenu"}},[_c(VDatePicker,{attrs:{"type":"month","locale":"de-DE","min":_vm.earliestValidFrom},on:{"input":function($event){_vm.wageDateMenu = false}},model:{value:(_vm.wage.validFrom),callback:function ($$v) {_vm.$set(_vm.wage, "validFrom", $$v)},expression:"wage.validFrom"}})],1),_c(VSelect,{attrs:{"label":"Entlohnung","placeholder":"Lohn / Gehalt","disabled":_vm.disabled,"items":_vm.wageTypes,"item-value":"code","item-text":"localizedName","error-messages":_vm.errors.get('wageType')},model:{value:(_vm.wage.wageType),callback:function ($$v) {_vm.$set(_vm.wage, "wageType", $$v)},expression:"wage.wageType"}}),(_vm.isSalary)?_c(VTextField,{attrs:{"placeholder":"Sollstunden","label":"Sollstunden / Monat","error-messages":_vm.errors.get('targetHours'),"disabled":_vm.disabled},model:{value:(_vm.wage.targetHours),callback:function ($$v) {_vm.$set(_vm.wage, "targetHours", $$v)},expression:"wage.targetHours"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }