export function calculateProductivity(items: any): number {
  if(!items) {
    return 0;
  }

  // We only consider those items relevant for the calculation where earningd have already been entered
  // @ts-ignore
  const itemsWithEarnings = items.filter(item => item.earnings > 0);

  const minutes = itemsWithEarnings
  // @ts-ignore
  .map(item => item.minutes)
  // @ts-ignore
  .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const earnings = itemsWithEarnings
  // @ts-ignore
  .map(item => item.earnings)
  // @ts-ignore
  .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return minutes > 0 ? earnings / (minutes / 60) : 100;
}
