
  import {defineComponent, onBeforeUnmount, onMounted, ref} from "vue";

  import ModalDialog from "@/components/ModalDialog.vue";

  import {MessageTypes} from "@/components/message-types";
  import Order, {InventoryChecklistEntry} from "@/model/Order";
  import eventBus from "@/eventbus";
  import OrderChecklistDetailsDialogList from "@/views/Orders/OrderChecklistDetailsDialogList.vue";
  import OrderService from "@/service/OrderService";
  import {AxiosResponse} from "axios";
  import OrderServiceV2 from "@/service/OrderServiceV2";
  import {t} from "@/mixin/mixins";

  export default defineComponent({
    name: "OrderChecklistDetailsDialog",
    components: {
      OrderChecklistDetailsDialogList,
      ModalDialog
    },
    setup (props, context) {
      const dialog = ref<boolean>(false);
      const order = ref<Order>(new Order());
      const beforeInventoryChecklist = ref<Array<InventoryChecklistEntry>>(new Array<InventoryChecklistEntry>());
      const duringInventoryChecklist = ref<Array<InventoryChecklistEntry>>(new Array<InventoryChecklistEntry>());
      const afterInventoryChecklist = ref<Array<InventoryChecklistEntry>>(new Array<InventoryChecklistEntry>());

      const populateChecklists = (checklist: Array<InventoryChecklistEntry>) => {
        beforeInventoryChecklist.value = checklist.filter(entry => entry.code.startsWith("1"));
        duringInventoryChecklist.value = checklist.filter(entry => entry.code.startsWith("2"));
        afterInventoryChecklist.value = checklist.filter(entry => entry.code.startsWith("3"));
      };

      const updateChecklist = (item: InventoryChecklistEntry) => {
        OrderService.updateChecklist(order.value.id, [item])
          .then((response: AxiosResponse<Array<InventoryChecklistEntry>>) => {
            populateChecklists(response.data);
            eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("inventoryChecklist.statusUpdateSuccess"));
          })
          .catch(function () {
            eventBus.$emit(MessageTypes.ERROR, t("inventoryChecklist.statusUpdateError"));
          });
      };

      const closeDialog = () => {
        dialog.value = false;
        order.value = new Order();
      };

      onMounted(() => {
        eventBus.$on(MessageTypes.OPEN_ORDER_CHECKLIST_DETAILS, (orderId: number) => {
          dialog.value = true;

          OrderServiceV2.getOrder(orderId)
            .then((response: AxiosResponse<Order>) => {
              order.value = response.data;
              if (response.data.checklist) {
                populateChecklists(response.data.checklist);
              }
            });
        });
      });

      onBeforeUnmount(() => {
        eventBus.$off(MessageTypes.OPEN_ORDER_CHECKLIST_DETAILS, () => {
          closeDialog();
        });
      });

      return {
        dialog,
        order,
        beforeInventoryChecklist,
        duringInventoryChecklist,
        afterInventoryChecklist,
        updateChecklist,
        closeDialog
      };
    }
  });
