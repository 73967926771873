
  import {defineComponent} from "vue";

  export default defineComponent({
    name: "CustomerDetailsCardListEntry",
    props: {
      icon: {
        type: Boolean,
        default: false
      }
    }
  });
