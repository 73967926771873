
  import Vue from "vue";
  import Component from "vue-class-component";
  import {Action, Getter} from "vuex-class";
  import {AuthActions, AuthGetters, AuthModule} from "@/store/modules/auth";
  import {MessageTypes} from "@/components/message-types";
  import {isAuthorized} from "@/mixin/mixins";
  import {useRouter} from "@/RouterUtils";
  import store from "@/store/store";

  @Component
  export default class Timeclock extends Vue {
    private username = "";
    private password = "";
    private errorText = "";
    private showPassword = false;

    @Action(AuthActions.LOGIN, {namespace: AuthModule})
    private actionLogin: any;

    @Getter(AuthGetters.IS_LOGGED_IN, {namespace: AuthModule})
    private isLoggedIn: boolean;

    enterListener(event: any) {
      if (event.key === "Enter") {
        this.login();
      }
    }

    mounted() {
      window.addEventListener("keydown", this.enterListener);
    }

    beforeDestroy() {
      window.removeEventListener("keydown", this.enterListener);
    }

    get isShowError() {
      return !(!this.errorText);
    }

    onChange() {
      // @ts-ignore
      this.$refs.username.blur();
      // @ts-ignore
      this.$refs.password.blur();
    }

    login() {
      this.errorText = "";

      if (this.username && this.password) {
        this.$eventBus.$emit(MessageTypes.LOADING, "Einloggen..");
        this.actionLogin({
          username: this.username,
          password: this.password
        })
          .then((loginSuccessful: boolean) => {
            if (loginSuccessful) {
              this.$eventBus.$emit(MessageTypes.SUCCESS, "Login erfolgreich.");
              this.$eventBus.$emit(MessageTypes.AUTHENTICATED);
              if (isAuthorized("Timeclock")) {
                useRouter().push("/");
              } else {
                // @ts-ignore
                useRouter().push({path: `/houroverview/${store.state.auth.authentication.employeeId}`});
              }
            } else {
              this.$eventBus.$emit(MessageTypes.ERROR, "Login fehlgeschlagen.");
              this.errorText = "Login fehlgeschlagen.";
            }
          });
      } else {
        this.errorText = "Bitte Benutzername oder Passwort eingeben.";
      }
    }
  }
