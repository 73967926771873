import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.disabled)?_c(VTextField,{attrs:{"value":_vm._f("normalizeTimeString")(_vm.value),"label":_vm.label,"append-icon":"mdi-clock-outline","readonly":"","disabled":"","error-messages":_vm.errorMessages}}):_c(VDialog,{ref:"dialog",attrs:{"return-value":_vm.value,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.value=$event},"update:return-value":function($event){_vm.value=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VTextField,_vm._g({attrs:{"value":_vm._f("normalizeTimeString")(_vm.value),"label":_vm.label,"append-icon":"mdi-clock-outline","readonly":"","error-messages":_vm.errorMessages},on:{"click":function($event){return _setup.setHourView()}}},on))]}}]),model:{value:(_setup.state.dialog),callback:function ($$v) {_vm.$set(_setup.state, "dialog", $$v)},expression:"state.dialog"}},[_c(VTimePicker,{ref:"timepicker",attrs:{"value":_vm.value,"format":"24hr"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_setup.state.dialog = false}}},[_vm._v(" Abbrechen ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.value)}}},[_vm._v(" Speichern ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }