import EmployeeName from "@/model/EmployeeName";
import OrderName from "@/model/OrderName";

export default class PerDiem {
  public id?: number | null;

  public employee: EmployeeName;

  public order: OrderName;

  public date: string;

  public misc: number;

  public catering: number;

  public calculatedCatering: number;

  public breakfast: boolean;
}
