import {WorklogType} from "@/enums";
import Worklog from "@/model/Worklog";
import moment from "moment";

export function toTimeFormat(minutes: number | null) {
    if (minutes == null) {
        return "-";
    } else {
        const h = Math.floor(minutes / 60);
        const m = minutes % 60;
        const hour = h < 10 ? "0" + h : h;
        const minute = m < 10 ? "0" + m : m;
        return hour + ":" + minute + "h";
    }
}

export function minutes(worklog: Worklog): number {
    return moment(worklog.end, "HH:mm").diff(moment(worklog.start, "HH:mm"), "minutes");
}

export function sumMinutes(worklogs: Array<Worklog>, worklogType: WorklogType | string): number {
    if(!worklogs) {
        return -1;
    }

    return worklogs
    .filter(worklog => worklog.worklogType === worklogType)
    .map(worklog => minutes(worklog))
    .reduce((prev, cur) => prev + cur, 0);
}

export function icon(worklogType: WorklogType | string): string {
    switch (worklogType) {
        case WorklogType.WORK:
            return "mdi-wrench";
        case WorklogType.PAUSE:
            return "mdi-pause";
        case WorklogType.TRAVEL:
            return "mdi-car";
        case WorklogType.PASSENGER:
            return "mdi-van-passenger";
    }

    return "";
}

export default {
    getIcon(worklogType: WorklogType): string {
        switch (worklogType) {
            case WorklogType.WORK:
                return "mdi-wrench";
            case WorklogType.PAUSE:
                return "mdi-pause";
            case WorklogType.TRAVEL:
                return "mdi-car";
            case WorklogType.PASSENGER:
                return "mdi-van-passenger";
        }

        return "";
    },
    getOpenWorklog(worklogs: Array<any>): any | null {
        if (worklogs) {
            return worklogs.find(worklog => {
                return worklog.open === true;
            });
        } else {
            return null;
        }
    },
    hasAutomaticPause(worklogs: Array<Worklog>) {
        return worklogs.some((worklog: Worklog) => worklog.comment && worklog.comment === "Automatische Pause");
    },
    getMinutes(worklog: Worklog): number {
        return moment(worklog.end, "HH:mm").diff(moment(worklog.start, "HH:mm"), "minutes");
    }
};
