import { render, staticRenderFns } from "./StatisticEmployees.vue?vue&type=template&id=0e1f80c0&"
import script from "./StatisticEmployees.vue?vue&type=script&setup=true&lang=ts&"
export * from "./StatisticEmployees.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports