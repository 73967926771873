
  import EmployeeDetailsCardListEntry from "./EmployeeDetailsCardListEntry.vue";

  import EmployeeDetailsCard from "./EmployeeDetailsCard.vue";
  import Employee from "@/model/Employee";
  import {defineComponent, PropType} from "vue";

  export default defineComponent({
    name: "EmployeeDetailsCardOperator",
    components: {
      EmployeeDetailsCard,
      EmployeeDetailsCardListEntry
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      employee: {
        type: Object as PropType<Employee>,
        default: () => new Employee()
      }
    },
    setup(props, context) {

      return {};
    }
  });
