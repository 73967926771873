
  import {computed, defineComponent, PropType, watch} from "vue";
  import OrderInfoCard from "@/views/Orders/Info/OrderInfoCard.vue";
  import Order from "@/model/Order";

  export default defineComponent({
    name: "OrderInfoData",
    components: {
      OrderInfoCard
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      errors: {
        type: Map as PropType<Map<string, string>>,
        default: () => new Map<string, string>()
      },
      value: {
        type: Object as PropType<Order>,
        required: true
      }
    },
    emits: ["input"],
    setup(props, context) {
      const model = computed({
        get: () => props.value,
        set: (value) => {
          context.emit("input", value);
        }
      });

      watch(() => model, (newValue, oldValue) => {
        context.emit("input", newValue.value);
      }, { deep: true});

      const fruitAndVegetables = computed(() => {
        return model.value.customerCategory && model.value.customerCategory.fruitAndVegetables !== null
          ? model.value.customerCategory.fruitAndVegetables
          : false;
      });

      const updateFruitAndVegetables = (newValue: any) => {
        model.value.customerCategory.fruitAndVegetables = newValue;
      };

      const flowers = computed(() => {
        return model.value.customerCategory && model.value.customerCategory.flowers !== null
          ? model.value.customerCategory.flowers
          : false;
      });

      const updateFlowers = (newValue: any) => {
        model.value.customerCategory.flowers = newValue;
      };

      const storage = computed(() => {
        return model.value.customerCategory && model.value.customerCategory.storage !== null
          ? model.value.customerCategory.storage
          : false;
      });

      const updateStorage = (newValue: any) => {
        model.value.customerCategory.storage = newValue;
      };

      const targetActualComparison = computed(() => {
        return model.value.customerCategory && model.value.customerCategory.targetActualComparison !== null
          ? model.value.customerCategory.targetActualComparison
          : false;
      });

      const updateTargetActualComparison = (newValue: any) => {
        model.value.customerCategory.targetActualComparison = newValue;
      };

      const labels = computed(() => {
        return model.value.customerCategory && model.value.customerCategory.labels !== null
          ? model.value.customerCategory.labels
          : false;
      });

      const updateLabels = (newValue: any) => {
        model.value.customerCategory.labels = newValue;
      };

      return {model, fruitAndVegetables, flowers, storage, targetActualComparison, labels, updateFruitAndVegetables, updateFlowers, updateStorage, updateTargetActualComparison, updateLabels};
    }
  });
  