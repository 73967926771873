import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VInput } from 'vuetify/lib/components/VInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"fluid":""}},[_c('RightsBreadcrumbs'),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"auto","lg":"6"}},[_c(VSubheader,{staticClass:"pl-0"},[_c('span',[_vm._v(" "+_vm._s(_vm.t("description"))+" ")])])],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"auto","lg":"6"}},[_vm._v(" "+_vm._s(_vm.role?.description)+" ")])],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"auto","lg":"6"}},[_c(VSubheader,{staticClass:"pl-0"},[_c('span',[_vm._v(" "+_vm._s(_vm.t("rights"))+" ")])])],1)],1),_vm._l((_vm.groups.entries()),function([key, authorities],index){return _c(VRow,{key:index,attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"auto","lg":"6"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.t("authorization.authorityGroups." + key + ".title")))]),_c(VCardText,_vm._l((authorities),function(authority){return _c(VRow,{key:authority.name},[_c(VCol,{attrs:{"cols":"11"}},[_c(VInput,{attrs:{"messages":"key"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(authority.title)+" ")]},proxy:true},{key:"message",fn:function(){return [_vm._v(" "+_vm._s(authority.description)+" ")]},proxy:true}],null,true)})],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VSwitch,{staticClass:"align-self-baseline mt-0",attrs:{"value":authority.name,"false-value":false,"hide-details":""},on:{"change":function($event){return _vm.onChange(authority.name)}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)}),1)],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }