
  import {computed, defineComponent, onMounted, PropType, reactive, toRefs} from "vue";
  import Employee from "@/model/Employee";
  import PageUtils from "@/components/PageUtils";
  import {t} from "@/mixin/mixins";
  import WorklogUtils, {toTimeFormat} from "@/WorklogUtils";
  import OrderOperatorsListOperatorNumber from "@/views/Orders/Operators/OrderOperatorsListOperatorNumber.vue";
  import OrderOperatorsListNumberOfPositions from "@/views/Orders/Operators/OrderOperatorsListNumberOfPositions.vue";
  import {orderId} from "@/RouterUtils";
  import OrderOperatorService from "@/service/OrderOperatorService";
  import {groupBy, zeroIfNull} from "@/Utils";
  import Worklog from "@/model/Worklog";
  import Order from "@/model/Order";
  import {yearMonth} from "@/DateUtils";
  import {earliestValidFrom} from "@/store/StoreUtils";

  export interface OrderOperator {
    id?: number;
    employee: Employee;
    operatorNumber?: number;
    numberOfPositions?: number;
  }

  export default defineComponent({
    name: "OrderOperators",
    components: {
      OrderOperatorsListNumberOfPositions,
      OrderOperatorsListOperatorNumber
    },
    props: {
      order: {
        type: Object as PropType<Order>,
        default: () => null,
      }
    },
    setup(props) {
      const initialState = {
        orderOperators: [] as OrderOperator[],
        worklogs: [] as Worklog[]
      };

      const state = reactive({...initialState});

      const footerProps = {
        "items-per-page-options": [-1]
      };

      const headers = [
        {
          text: t("order.operators.operatorNumber"),
          align: "left",
          value: "operatorNumber",
          width: 180
        },
        {
          text: t("name"),
          align: "left",
          value: "name"
        },
        {
          text: t("workingTime"),
          align: "left",
          value: "workingTime"
        },
        {
          text: t("order.operators.numberOfPositions"),
          align: "left",
          value: "numberOfPositions",
          width: 150
        },
        {
          text: t("order.operators.positionsPerHour"),
          align: "right",
          value: "positionsPerHour"
        }
      ];

      const tableOptions = {
        itemsPerPage: -1,
        sortBy: ["operatorNumber"],
        sortDesc: [true]
      };

      const isEditable = computed(() => {
        let order = props.order;
        return order && order.validTo && yearMonth(order.validTo).isSameOrAfter(yearMonth(earliestValidFrom.value));
      });

      const loadItems = () => {
        OrderOperatorService.getOrderOperators(orderId.value)
          .then(response => {
            let orderOperatorEntries = PageUtils.extractCollection(response.data, "operators");
            state.orderOperators = orderOperatorEntries as OrderOperator[];
          });

        OrderOperatorService.getOrderWorklogs(orderId.value)
          .then(response => {
            state.worklogs = PageUtils.extractCollection(response.data, "worklogs");
          });
      };

      const fullName = (item: OrderOperator) => {
        return item.employee.firstName + " " + item.employee.lastName;
      };

      // Go over all worklogs to calculate the working minutes per employee
      const employeeMap = computed(() => {
        let keyFunction = (worklog: Worklog) => worklog.employee!.id;
        let valueFunction = (currentValue: number | null | undefined, newValue: Worklog) => {
          let minutes = WorklogUtils.getMinutes(newValue);
          return currentValue ? currentValue + minutes : minutes;
        };

        return groupBy<Worklog, number, number>(state.worklogs, keyFunction, valueFunction);
      });

      const items = computed(() => {
        let items = new Array<OrderOperator>();
        employeeMap.value.forEach((value, key) => {
          let operator = state.orderOperators.find(operator => operator.employee.id == key);

          let index = state.worklogs.findIndex(worklog => worklog.employee!.id == key);
          let employee = state.worklogs[index].employee;

          // If we have no entry for that employee yet we create a new one
          let item = {
            id: operator ? operator.id : null,
            employee: employee,
            operatorNumber: operator ? operator.operatorNumber : null,
            numberOfPositions: operator ? operator.numberOfPositions : null,
          } as OrderOperator;
          items.push(item);
        });

        // Sort by operator number first, then first name in ascending order
        items.sort((a, b) => {
          let diff = zeroIfNull(a.operatorNumber) - zeroIfNull(b.operatorNumber);
          if (diff == 0) {
            // @ts-ignore
            return a.employee.firstName.localeCompare(b.employee.firstName);
          } else {
            return diff;
          }
        });

        return items;
      });

      const workingTimeSummary = computed(() => {
        return Array.from(employeeMap.value.values())
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      });

      const positionCountSummary = computed(() => {
        return state.orderOperators
          .map(orderOperator => orderOperator.numberOfPositions || 0)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      });

      const positionsPerHourSummary = computed(() => {
        let totalMinutes = workingTimeSummary.value;
        if (totalMinutes && totalMinutes > 0) {
          return positionCountSummary.value ? positionCountSummary.value / (totalMinutes / 60) : 0;
        } else {
          return 0;
        }
      });

      const positionsPerHour = (item: OrderOperator) => {
        let minutes = employeeMap.value.get(item.employee.id);
        if (minutes && minutes > 0) {
          return item.numberOfPositions ? item.numberOfPositions / (minutes / 60) : 0;
        } else {
          return 0;
        }
      };

      const updateItem = (current: OrderOperator, updated: OrderOperator) => {
        // Even though the IDE complains we make no use of current we still need to make the assignment here
        // as Object.assign does not make an in-place update
        current = Object.assign(current, updated);
      };

      onMounted(() => {
        loadItems();
      });

      return {
        ...toRefs(state),
        footerProps,
        headers,
        tableOptions,
        isEditable,
        fullName,
        positionsPerHour,
        toTimeFormat,
        updateItem,
        employeeMap,
        items,
        workingTimeSummary,
        positionCountSummary,
        positionsPerHourSummary
      };
    }
  });
