import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"content":"","content-class":"centered-dialog","max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(_vm.text))])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},nativeOn:{"click":function($event){_vm.dialog = false}}},[_vm._v(" OK ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }