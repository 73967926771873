import axios, {AxiosResponse} from "axios";
import EmployeeName from "@/model/EmployeeName";
import {PagedResult} from "@/service/PagedResult";
import {EmploymentType, WageType} from "@/enums";

const PATH = "/accounting";

export interface Minutes {
    minutesWork: number;
    minutesPause: number;
    minutesTravel: number;
    minutesPassenger: number;
}

export interface Wage {
    wage: number;
    wageType: WageType;
}

export interface AccountingEmployee {
    yearMonth: string;
    employee: EmployeeName;
    minutes: Minutes;
    employmentType: EmploymentType,
    wage: Wage
}

export default {
    findAvailableOrders(date: string) {
        return axios
          .get(`${PATH}/worklog/availableOrders`, {
              params: {
                  date: date
              }
          });
    },
    findEmployeesInYearMonth(yearMonth: string): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH}/employees`, {
            params: {
                yearMonth: yearMonth
            }
        });
    },
    findEmployees(term: string): Promise<AxiosResponse<Array<EmployeeName>>> {
        return axios
          .get(`${PATH}/worklog/employees`, {
              params: {
                  term: term
              }
          });
    }
};
