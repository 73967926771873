import { WageType } from "@/enums";

export default class Wage {
  public id: number;

  public wage: number;

  public wageType: WageType;

  public validFrom: string;

  public validTo: string;

  public targetHours: number | null;
}
