
  import Order from "@/model/Order";
  import {defineComponent, onMounted, PropType, ref} from "vue";
  import {orderId} from "@/RouterUtils";
  import {DataTableHeader} from "vuetify";
  import {CashFlowInterface} from "@/model/CashFlow";
  import StatisticService from "@/service/StatisticService";
  import {HeaderMutations} from "@/store/modules/header";
  import eventBus from "@/eventbus";
  import {DELETING_ENTRY, ENTRY_DELETED} from "@/plugins/i18n";
  import {MessageTypes} from "@/components/message-types";
  import store from "@/store/store";
  import StatisticOrderCashFlowDetailsDialog from "./StatisticOrderCashFlowDetailsDialog.vue";
  import Utils from "@/Utils";
  import {formatDate, toCurrency} from "@/filter";
  import {t} from "@/mixin/mixins";
  import ConfirmDialogImpl from "@/components/ConfirmDialog.vue";
  import PageUtils from "@/components/PageUtils";
  import OrderServiceV2 from "@/service/OrderServiceV2";

  export default defineComponent({
    name: "StatisticOrder",
    components: {
      ConfirmDialog: ConfirmDialogImpl,
      StatisticOrderCashFlowDetailsDialog
    },
    props: {
      orders: {
        type: Array as PropType<Array<Order>>,
        default: () => new Array<Order>()
      }
    },
    setup(props, context) {
      const confirmDialog = ref<InstanceType<typeof ConfirmDialogImpl>>();

      const loadingMonths = ref<boolean>(false);
      const loadingCashFlows = ref<boolean>(false);
      const isOrderLoaded = ref<boolean>(false);

      const headersMonths: Array<DataTableHeader> = [
        {
          text: "Monat",
          align: "start",
          value: "yearMonth"
        },
        {
          text: "Stunden",
          align: "end",
          value: "minutes"
        },
        {
          text: "Kosten",
          align: "end",
          value: "costs"
        },
        {
          text: "Kosten (inkl. LNK)",
          align: "end",
          value: "costsWithPayrollTaxes"
        },
        {
          text: "Kosten (Fahrt)",
          align: "end",
          value: "costsTravel"
        },
        {
          text: "Kosten (Mitfahrer)",
          align: "end",
          value: "costsPassenger"
        },
        {
          text: "Zuschüsse",
          align: "end",
          value: "perDiem"
        },
        {
          text: "Einnahmen",
          align: "end",
          value: "earnings"
        },
        {
          text: "Ausgaben",
          align: "end",
          value: "expenses"
        },
        {
          text: "Gewinn",
          align: "end",
          value: "profit"
        },
        {
          text: "Stundenlohn (avg)",
          align: "end",
          value: "averageWage"
        }
      ];

      const headersCashFlow: Array<DataTableHeader> = [
        {
          text: "Datum",
          align: "start",
          value: "date"
        },
        {
          text: "Betrag",
          align: "end",
          value: "amount"
        },
        {
          text: "Typ",
          align: "start",
          value: "cashFlowType"
        },
        {
          text: "Beschreibung",
          align: "start",
          value: "description"
        },
        {
          text: "",
          align: "end",
          value: "id",
          sortable: false,
          width: 80
        }
      ];

      const order = ref<Order>(new Order());
      const cashFlows = ref<Array<CashFlowInterface>>(new Array<CashFlowInterface>());
      const months = ref<Array<any>>(new Array<any>());

      const cashFlowDialog = ref<InstanceType<typeof StatisticOrderCashFlowDetailsDialog>>();
      const createCashFlow = (): void => {
        cashFlowDialog.value?.createCashFlow(order.value, (cashFlow: CashFlowInterface) => {
          cashFlows.value.push(cashFlow);
        });
      };

      const editCashFlow = (source: CashFlowInterface) => {
        cashFlowDialog.value?.editCashFlow(order.value, source, (saved: CashFlowInterface) => {
          Utils.replaceIfExists(cashFlows.value, source, saved);
        });
      };

      const deleteCashFlow = (cashFlow: CashFlowInterface) => {
        const title = "Eintrag löschen";
        const date = formatDate(cashFlow.date, "MMMM YYYY");
        const amount = toCurrency(cashFlow.amount);

        const text = `Soll der Eintrag <br><br><b>Datum</b>: ${date}<br><b>Betrag</b>: ${amount}<br><br> wirklich gelöscht werden?`;

        confirmDialog.value?.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              eventBus.$emit(MessageTypes.LOADING, t(DELETING_ENTRY));
              StatisticService.deleteCashFlow(order.value!.id, cashFlow.id!)
                .then(response => {
                  months.value = response.data.months;
                  cashFlows.value = response.data.cashFlows;
                  eventBus.$emit(MessageTypes.SUCCESS, t(ENTRY_DELETED));
                });
            }
          });
      };

      onMounted(() => {
        OrderServiceV2.getOrder(orderId.value)
          .then(response => {
            order.value = response.data;
            isOrderLoaded.value = true;
            store.commit("header/" + HeaderMutations.SET_TITLE, order.value.name);
          });

        loadingMonths.value = true;
        StatisticService.getOrderMonthlySummaries(orderId.value)
          .then(response => {
            months.value = PageUtils.extractCollection(response.data, "orderSummaries");
          })
          .finally(() => {
            loadingMonths.value = false;
          });

        loadingCashFlows.value = true;
        StatisticService.getCashFlows(orderId.value)
          .then(response => {
            cashFlows.value = PageUtils.extractCollection(response.data, "cashFlows");
          })
          .finally(() => {
            loadingCashFlows.value = false;
          });
      });

      return {
        cashFlows,
        cashFlowDialog,
        createCashFlow,
        deleteCashFlow,
        editCashFlow,
        confirmDialog,
        headersCashFlow,
        headersMonths,
        loadingMonths,
        loadingCashFlows,
        isOrderLoaded,
        months
      };
    }
  });
