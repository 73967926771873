
  import {
    RegistrationDataEducation,
    RegistrationDataSocialSecurity,
    RegistrationDataSocialSecurityChild,
    RegistrationDataTax
  } from "@/model/Registration";
  import {alphaNum, helpers, maxLength, minLength, required, requiredIf} from "@vuelidate/validators";
  import {REGISTRATION, VALUE} from "@/plugins/i18n";
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import {SelectModel} from "@/model/SelectModel";
  import RegistrationFileUpload from "@/views/Registration/Forms/RegistrationFileUpload.vue";
  import RegistrationService from "@/service/RegistrationService";
  import {computed, defineComponent, onMounted, reactive, ref, Ref, SetupContext, toRefs, watch} from "vue";
  import {useDialog} from "@/views/Registration/RegistrationComposable";
  import useVuelidate, {ErrorObject} from "@vuelidate/core";
  import {ValidationResult} from "@/model/ValidationResult";
  import {t, tWithParms} from "@/mixin/mixins";
  import Utils from "@/Utils";
  import RegistrationTaxAndSocialSecurityChildrenDialog
    from "@/views/Registration/Forms/RegistrationTaxAndSocialSecurityChildrenDialog.vue";

  export default defineComponent({
    name: "RegistrationTaxAndSocialSecurityForm",
    components: {
      RegistrationTaxAndSocialSecurityChildrenDialog,
      RegistrationCard,
      RegistrationFileUpload
    },
    props: {
      step: {
        type: Number,
        default: null
      }
    },
    setup (props, context: SetupContext) {

      const {
        state: generalState,
        registration,
        registrationUuid,
        minorEmployment,
        shortTerm,
        falseValue,
        trueValue,
        mobile,
        next
      } = useDialog(context);

      const initialState = {
        model: {
          education: new RegistrationDataEducation(),
          socialSecurity: new RegistrationDataSocialSecurity(),
          tax: new RegistrationDataTax()
        },
        uploadedFiles: new Array<string>()
      };

      const state = reactive({ ...initialState });

      const rules = computed(() => ({
        model: {
          education: {
            schoolEducation: {
              required: helpers.withMessage(tWithParms("validation.required", t("registration.education.education.general")), required)
            },
            professionalDegree: {
              required: helpers.withMessage(tWithParms("validation.required", t("registration.education.degree.general")), required)
            }
          },
          socialSecurity: {
            pensionInsuranceNumber: {
              alphaNum: helpers.withMessage(tWithParms("validation.invalid", t("registration.socialSecurity.pensionInsuranceNumber")), alphaNum),
              minLength: helpers.withMessage(tWithParms("validation.invalid", t("registration.socialSecurity.pensionInsuranceNumber")), minLength(12)),
            },
            healthInsurance: {
              required: helpers.withMessage(tWithParms("validation.required", t("registration.socialSecurity.healthInsurance")), required)
            },
            pensionInsuranceVoluntaryPayment: {
              required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), requiredIf(function () {
                return minorEmployment.value;
              }))
            }
          },
          tax: {
            taxIdentNumber: {
              required: helpers.withMessage(tWithParms("validation.required", t("registration.tax.taxIdentNumber")), required),
              alphaNum: helpers.withMessage(tWithParms("validation.invalid", t("registration.tax.taxIdentNumber")), alphaNum)
            },
            taxClass: {
              required: helpers.withMessage(tWithParms("validation.required", t("registration.tax.taxClass.general")), required),
            }
          }
        },
        uploadedFiles: {
          required: helpers.withMessage(tWithParms("validation.required", t("registration.socialSecurity.healthInsuranceUploadRequired")), requiredIf(function () {
            return shortTerm.value;
          })),
          minLength: minLength(1)
        },
      }
      ));

      // @ts-ignore
      let v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (generalState.serverErrors.length > 0) {
          generalState.serverErrors.forEach((error: ValidationResult) => {
            errors.set(error.path.replace("registrationData.", "model."), error.message);
          });

          return errors;
        } else {
          const model = v$.value;
          if (model.$dirty) {
            model.$errors.forEach((error: ErrorObject) => {
              if (!errors.has(error.$propertyPath)) {
                // @ts-ignore
                errors.set(error.$propertyPath, error.$message);
              }
            });
          }
        }

        return errors;
      });

      const degree = computed(() => {
        return [
          new SelectModel("unknown", t("registration.education.degree.unknown")),
          new SelectModel("without", t("registration.education.degree.without")),
          new SelectModel("vocational_training", t("registration.education.degree.vocationalTraining")),
          new SelectModel("master_craftsman", t("registration.education.degree.masterCraftsman")),
          new SelectModel("bachelor", t("registration.education.degree.bachelor")),
          new SelectModel("master", t("registration.education.degree.master")),
          new SelectModel("doctorate", t("registration.education.degree.doctorate"))
        ];
      });

      const education = computed(() => {
        return [
          new SelectModel("unknown", t(REGISTRATION, "education.education.unknown")),
          new SelectModel("without", t(REGISTRATION, "education.education.without")),
          new SelectModel("lower_secondary", t(REGISTRATION, "education.education.lowerSecondary")),
          new SelectModel("intermediate_secondary", t(REGISTRATION, "education.education.intermediateSecondary")),
          new SelectModel("upper_secondary", t(REGISTRATION, "education.education.upperSecondary"))
        ];
      });

      const taxClass = computed(() => {
        return [
          new SelectModel("one", t("registration.tax.taxClass.one")),
          new SelectModel("two", t("registration.tax.taxClass.two")),
          new SelectModel("three", t("registration.tax.taxClass.three")),
          new SelectModel("four", t("registration.tax.taxClass.four")),
          new SelectModel("five", t("registration.tax.taxClass.five")),
          new SelectModel("six", t("registration.tax.taxClass.six"))
        ];
      });

      watch(() => registration.tax, (newValue, oldValue) => {
        if (newValue) {
          state.model.tax = Object.assign(state.model.tax, newValue);
        }
      });


      watch(() => registration.socialSecurity, (newValue, oldValue) => {
        if (newValue) {
          state.model.socialSecurity = Object.assign(state.model.socialSecurity, newValue);
        }
      });

      watch(() => registration.education, (newValue, oldValue) => {
        if (newValue) {
          state.model.education = Object.assign(state.model.education, newValue);
        }
      });

      const onBlur = () => {
        if (state.model.socialSecurity.pensionInsuranceNumber && state.model.socialSecurity.pensionInsuranceNumber.length > 0) {
          state.model.socialSecurity.pensionInsuranceNumber = state.model.socialSecurity.pensionInsuranceNumber.toUpperCase().replace(/\s/g,"");
        }
        if (state.model.tax.taxIdentNumber && state.model.tax.taxIdentNumber.length > 0) {
          state.model.tax.taxIdentNumber = state.model.tax.taxIdentNumber.toUpperCase().replace(/\s/g,"");
        }
      };


      onMounted(() => {
        if (registration.value.education) {
          state.model.education = registration.value.education;
        }

        if (registration.value.tax) {
          state.model.tax = registration.value.tax;
        }

        if (registration.value.socialSecurity) {
          state.model.socialSecurity = registration.value.socialSecurity;
        }

        RegistrationService.getFiles(registrationUuid.value, "health_insurance")
          .then(response => {
            state.uploadedFiles = response.data.files;
          });

        generalState.dirty = true;
      });

      const addChildDialog = ref<InstanceType<typeof RegistrationTaxAndSocialSecurityChildrenDialog>>();

      const addChild = () => {
        addChildDialog.value?.addChild()
          .then(result => {
            if (result) {
              state.model.socialSecurity.children.push(result);
            }
          });
      };

      const removeChild = (toRemove: RegistrationDataSocialSecurityChild) => {
        Utils.removeIfExists(state.model.socialSecurity.children, toRemove);
      };

      const onFileUploaded = (file: File) => {
        state.uploadedFiles.push(file.name);
      };

      const onNext = () => {
        next(v$, state.model);
      };

      return {
        ...toRefs(state),
        addChildDialog,
        errors,
        degree,
        education,
        taxClass,
        minorEmployment,
        shortTerm,
        falseValue,
        trueValue,
        mobile,
        addChild,
        removeChild,
        onFileUploaded,
        onBlur,
        onNext
      };
    }
  });
