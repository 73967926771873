
  import {defineComponent, inject, PropType} from "vue";
  import {CompanyLocation} from "@/model/CompanyLocation";
  import {t} from "@/mixin/mixins";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import InventoriesService from "@/service/InventoriesService";
  import LocationUtils from "@/views/Inventories/InventoriesCompanyLocations/LocationUtils";

  export default defineComponent({
    name: "InventoriesCompanyLocationsListTile",
    props: {
      companyLocation: {
        type: Object as PropType<CompanyLocation>,
        required: true,
        default: () => {}
      }
    },
    setup (props, context) {
      const openCompanyLocationDialog = (companyLocation: CompanyLocation) => {
        context.emit("openCompanyLocationDialog", companyLocation);
      };

      let appRoot = inject("app-root");
      // @ts-ignore
      const confirmDialog = appRoot.confirmDialogComposition as InstanceType<typeof ConfirmDialogComposition>;
      const deleteCompanyLocation = (companyLocation: CompanyLocation) => {
        const title = `${t("inventories.companyLocations.dialog.title.delete")}\n${companyLocation.name}`;
        const text = t("inventories.companyLocations.dialog.text.delete");
        confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              InventoriesService.deleteCompanyLocation(companyLocation.id)
                .then(() => {
                  eventBus.$emit(MessageTypes.COMPANY_LOCATION_DELETED, companyLocation.id);
                })
                .catch(() => {
                });
            }
          });
      };

      return {
        toAddressString: LocationUtils.toAddressString,
        openCompanyLocationDialog,
        deleteCompanyLocation
      };
    }
  });
