
  import {computed, defineComponent, PropType, toRefs} from "vue";
  import PerDiemUpdateDialog from "@/views/PerDiem/PerDiemDetailsDialog.vue";
  import PerDiemDetailsDialogParameters from "@/views/PerDiem/PerDiemDetailsDialogParameters";
  import {MessageTypes} from "@/components/message-types";
  import EmployeeName from "@/model/EmployeeName";
  import eventBus from "@/eventbus";
  import PerDiem from "@/model/PerDiem";
  import Worklog from "@/model/Worklog";
  import {init} from "@/views/Accounting/Employee/AccountingEmployeeComposable";
  import Order from "@/model/Order";
  import {sumMinutes} from "@/WorklogUtils";
  import OrderName from "@/model/OrderName";

  export default defineComponent({
    name: "PerDiemEmployeeEntryList",
    components: {
      PerDiemUpdateDialog
    },
    props: {
      editable: {
        type: Boolean,
        default: () => false
      },
      employee: {
        type: Object as PropType<EmployeeName>,
        default: () => new EmployeeName()
      },
      perDiems: {
        type: Array as PropType<Array<PerDiem>>,
        default: () => []
      },
      worklogs: {
        type: Array as PropType<Array<Worklog>>,
        default: () => new Array<Worklog>()
      }
    },
    setup(props) {
      const { worklogs } = toRefs(props);
      const { groups } = init(worklogs);


      const ordersByDate = computed(() => {
        let ordersByDateMap = new Map<string, Order[]>();

        for (let [date, worklogs] of groups.value) {
          let orders = worklogs.map(worklog => worklog.order!);

          let uniqueOrders = orders.filter((order, index) =>  {
            let existingOrderIndex = orders.findIndex(item => item.id == order.id);
            return existingOrderIndex == index || existingOrderIndex == -1;
          });

          ordersByDateMap.set(date, uniqueOrders);
        }

        return ordersByDateMap;
      });

      const editPerDiem = (item: PerDiem) => {
        const perDiemDialogParameters = new PerDiemDetailsDialogParameters();
        perDiemDialogParameters.date = item.date;
        perDiemDialogParameters.employees.push(props.employee);
        // If it is no longer editable restrict the available orders to the already assigned one
        perDiemDialogParameters.orders = props.editable ? ordersByDate.value.get(item.date) as OrderName[] : [item.order];
        perDiemDialogParameters.perDiem = item;

        eventBus.$emit(MessageTypes.OPEN_PERDIEM_DETAILS, perDiemDialogParameters);
      };

      const toHtmlFormattedOrdersString = (item: PerDiem) => {
        let ordersOnDate = new Array<Order>();
        if(ordersByDate.value.has(item.date)) {
          ordersOnDate =  ordersByDate.value.get(item.date) ?? [];
        }

        return ordersOnDate.map(order => {
          return item.order && item.order.id == order.id ? "<b>"+order.name+"</b>" : order.name;
        }).join(", ");
      };

      return {
        editPerDiem,
        groups,
        ordersByDate,
        toHtmlFormattedOrdersString,
        sumMinutes
      };
    }
  });
