import axios, {AxiosResponse} from "axios";
import {OrderType} from "@/enums";

const PATH = "/v1/reports";

export interface InventoryPerformancesReportRequest {
    from: string;
    to: string;
}

export interface WorkingHoursReportRequest {
    month: string;
    orderType: OrderType;
}

export default {
    generateInventoryPerformancesReport(request: InventoryPerformancesReportRequest): Promise<AxiosResponse<BlobPart>> {
        return axios.post(`${PATH}/inventory-performances`, request, {
            responseType: "blob",
            timeout: 30000 // 30s timeout
        });
    },
    generateWorkingHoursReport(request: WorkingHoursReportRequest): Promise<AxiosResponse<BlobPart>> {
        return axios.post(`${PATH}/working-hours`, request, {
            responseType: "blob",
            timeout: 30000 // 30s timeout
        });
    }
};
