
  import Vue from "vue";
  import {Prop} from "vue-property-decorator";
  import Component from "vue-class-component";
  import Customer from "@/model/Customer";
  import CurrencyField from "@/components/CurrencyField.vue";
  import {getCustomerFieldIcon} from "@/views/Customers/CustomerUtils";
  import {MessageTypes} from "@/components/message-types";

  export const FORM_ID = "CustomersGeneralInformationForm";

  @Component({
    components: {
      CurrencyField
    },
    methods: {
      getCustomerFieldIcon
    }
  })
  export default class CustomersGeneralInformationForm extends Vue {
  
    @Prop({default: () => new Customer()})
    public customer: Customer;

    @Prop({default: () => new Map<string, string>()})
    public errors: Map<string, string>;

    rules = {
      positiveInteger: (value: number) => Number.isInteger(Number(value)) && Number(value) > 0 || "The value must be a positive integer"
    };

    mounted() {
      this.$eventBus.$on(MessageTypes.CUSTOMER_CREATE_VALIDATE, () => {
        const form: any = this.$refs.form;

        let isValid = true;
        if (form) {
          isValid = form.validate();
        }
        if (isValid) {
          this.$eventBus.$emit(MessageTypes.CUSTOMER_CREATE_VALID, FORM_ID);
        }
      });
    }

    beforeDestroy() {
      this.$eventBus.$off(MessageTypes.CUSTOMER_CREATE_VALIDATE);
    }

    clearValue() {
      this.$set(this.customer, "inventoryValue", null);
    }
  }
