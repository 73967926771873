
  import WorklogUtils from "@/WorklogUtils";
  import {defineComponent, PropType} from "vue";
  import Worklog from "@/model/Worklog";
  import {orderId} from "@/RouterUtils";
  import Order from "@/model/Order";
  import {OrderType} from "@/enums";
  import {OrderOperator} from "@/views/Orders/Operators/OrderOperators.vue";

  interface TimeclockEmployee {
    id: number;
    employeeId: number;
    firstName: string;
    lastName: string;
    operatorNumber?: number;
    worklogs?: Array<Worklog>
  }

  export default defineComponent({
    name: "TimeclockOrderSelectableEmployeeList",
    props: {
      errors: {
        type: Array as PropType<Array<any>>,
        default: () => new Array<any>()
      },
      items: {
        type: Array as PropType<Array<TimeclockEmployee>>,
        default: () => new Array<TimeclockEmployee>()
      },
      operators: {
        type: Array as PropType<Array<OrderOperator>>,
        default: () => new Array<OrderOperator>()
      },
      order: {
        type: Object as PropType<Order>,
        required: true,
        default: () => new Order()
      },
      title: {
        type: String,
        default: ""
      }
    },
    setup(props, context) {

      const errorMessage = (item: TimeclockEmployee) => {
        const validationErrors = props.errors
          .filter(error => error.path === "employees." + item.id)
          .map(error => error.message);
        return validationErrors.length > 0 ? validationErrors[0] : "";
      };

      const name = (item: TimeclockEmployee) => {
        if(item && item.firstName && item.lastName) {
          let fullName = item.firstName + " " + item.lastName;

          let existingOperatorAssignment = props.operators.find(operator => operator.employee.id == item.id);
          let operatorNumber = existingOperatorAssignment && existingOperatorAssignment.operatorNumber;
          // If it is not assigned just display the default
          operatorNumber = operatorNumber || item.operatorNumber;

          let showOperatorNumber = operatorNumber && props.order.orderType == OrderType.INV;
          return showOperatorNumber ? fullName + " (#"+operatorNumber+")" : fullName;
        } else {
          return "";
        }
      };

      const statusMessage = (item: TimeclockEmployee) => {
        // @ts-ignore
        if(item.statusMessage) {
          // @ts-ignore
          return item.statusMessage;
        }

        // @ts-ignore
        const worklogs = item.worklogs ? item.worklogs.filter((worklog) => worklog.order.id === orderId.value) : [];
        const openWorklog = WorklogUtils.getOpenWorklog(worklogs);

        let statusMessage = "";
        if (openWorklog) {
          switch (openWorklog.worklogType) {
          case "WORK":
            statusMessage += "Arbeit";
            break;
          case "PAUSE":
            statusMessage += "Pause";
            break;
          case "TRAVEL":
            statusMessage += "Reise";
            break;
          case "PASSENGER":
            statusMessage += "Mitfahrer";
            break;
          }

          statusMessage += " seit " + openWorklog.start;
        } else if (worklogs && worklogs.length > 0) {
          const lastWorklog = worklogs[worklogs.length - 1];
          statusMessage = "Ausgestempelt seit " + lastWorklog.end;
        }

        return statusMessage;
      };

      return {
        errorMessage,
        name,
        statusMessage
      };
    }
  });
