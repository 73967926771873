
  import {defineComponent, inject, PropType} from "vue";
  import {formatDate} from "@/filter";
  import {InventoryChecklistEntry} from "@/model/Order";
  import {t, tWithParms} from "@/mixin/mixins";

  export default defineComponent({
    name: "OrderChecklistDetailsDialogList",
    props: {
      checklistItems: {
        type: Array as PropType<Array<InventoryChecklistEntry>>,
        required: true
      }
    },
    emits: ["confirmed"],
    setup (props, context) {
      let appRoot = inject("app-root");
      // @ts-ignore
      const confirmDialog = appRoot.confirmDialogComposition as InstanceType<typeof ConfirmDialogComposition>;

      const confirmItem = (item: InventoryChecklistEntry) => {
        const itemText = t("inventoryChecklist.codes", item.code);
        const text = "Wollen Sie die Aufgabe <br><b> " + itemText + "</b><br> abschließen?";
        confirmDialog.open("Aufgabe abschließen", text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              context.emit("confirmed", item);
            } else {
              item.done = false;
            }
          });
      };

      const doneText = (item: InventoryChecklistEntry) => {
        const updateTime = formatDate(item.updateTime, "DD.MM.YYYY HH:mm");
        const updateUser = item.updateUser ?? "";
        return tWithParms("inventoryChecklist.doneText", updateTime ?? "", updateUser);
      };

      return {
        confirmItem,
        doneText
      };
    }
  });
