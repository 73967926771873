
  import {computed, defineComponent, reactive, toRefs} from "vue";
  import {getCustomerFieldIcon} from "@/views/Customers/CustomerUtils";
  import {isAuthorized, isAuthorizedForGroup} from "@/mixin/mixins";
  import {authentication, employeeId} from "@/store/StoreUtils";
  import eventBus from "@/eventbus";
  import vuetify from "@/plugins/vuetify";

  class MenuItem {
    public icon: string;
    public to: string;
    public title: string;
    public webOnly: boolean;
    public authority?: string;

    constructor(icon: string, to: string, title: string, webOnly: boolean, authority?: string) {
      this.icon = icon;
      this.to = to;
      this.title = title;
      this.webOnly = webOnly;
      this.authority = authority;
    }
  }

  export default defineComponent({
    name: "TheMenu",
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const initialState = {
        item: 0
      };

      const state = reactive({...initialState});

      const avatarShortName = computed(() => {
        return authentication.value.firstName.substring(0, 1) + " " + authentication.value.lastName.substring(0, 1);
      });

      const fullName = computed(() => {
        return authentication.value.firstName + " " + authentication.value.lastName;
      });

      const employeeLink = computed(() => {
        if (isAuthorized("Superadmin") || isAuthorized("Employee_Create_Create")) {
          return "/employees";
        } else {
          return "/employees/" + employeeId.value;
        }
      });

      const defaultMenuItems = computed(() => {
        const items = new Array<MenuItem>();
        items.push(new MenuItem("mdi-clock", "/timeclock", "Stempeluhr", false, "Timeclock"));
        items.push(new MenuItem("mdi-format-list-bulleted", "/houroverview/" + employeeId.value, "Stundenübersicht", false));
        items.push(new MenuItem(getCustomerFieldIcon("name"), "/customers", "Kunden", false, "Customer"));
        items.push(new MenuItem("mdi-account", employeeLink.value, "Mitarbeiter", false, "Employee"));
        items.push(new MenuItem("mdi-wallet", "/accounting/employees", "Abrechnung", false, "Accounting"));
        items.push(new MenuItem("mdi-format-list-checks", "/inventories", "Inventuren", false, "Inventory"));
        items.push(new MenuItem("mdi-store", "/v2/orders", "Aufträge", false, "Order"));
        items.push(new MenuItem("mdi-timetable", "/timecontrolling", "Zeitkontrolle", true, "Superadmin"));
        items.push(new MenuItem("mdi-bank", "/monthendclosing", "Monatsabschluss", true, "MonthEndClosing"));
        items.push(new MenuItem("mdi-finance", "/statistic", "Statistik", true, "Statistic"));
        items.push(new MenuItem("mdi-account-plus", "/registrations", "Registrierungen", false, "Registration"));
        items.push(new MenuItem("mdi-account-supervisor", "/rights", "Rechte", false, "Authorization"));
        items.push(new MenuItem("mdi-archive", "/archive/" + employeeId.value, "Dokumente", false));

        return items;
      });

      const activeMenuItems = computed(() => {
        let items = defaultMenuItems.value;

        if (!vuetify.framework.breakpoint.lgAndUp) {
          items = items.filter(item => !item.webOnly);
        }

        return items.filter(item => item.authority == null || isAuthorizedForGroup(item.authority));
      });

      const openUserProfile = () => {
        eventBus.$emit("openUserProfile");
      };

      const isShowMenu = computed(() => {
        if (vuetify.framework.breakpoint.mdAndDown) {
          return props.value;
        } else {
          // Always show the menu in higher resolutions
          return true;
        }
      });

      const isShowMiniVariant = computed(() => {
        // Show the minivariant if the menu was hidden on large screens
        if (vuetify.framework.breakpoint.lgAndUp) {
          return !props.value;
        } else {
          return false;
        }
      });

      return {
        ...toRefs(state),
        avatarShortName,
        fullName,
        isShowMenu,
        isShowMiniVariant,
        activeMenuItems,
        openUserProfile
      };
    }
  });
