
  import {
    defineComponent, nextTick,
    onMounted,
    PropType,
    reactive,
    toRefs,
    watch
  } from "vue";
  import Customer from "@/model/Customer";
  import {CompanyLocation} from "@/model/CompanyLocation";
  import InventoriesService from "@/service/InventoriesService";
  import PageUtils from "@/components/PageUtils";
  import {CustomerTravelTime} from "@/model/CustomerTravelTime";
  import CustomerService from "@/service/CustomerService";
  import CustomerTravelTimeService from "@/service/CustomerTravelTimeService";
  import ModalDialog from "@/components/ModalDialog.vue";
  import CustomerDetailsCardListEntry from "@/views/Customers/details/CustomerDetailsCardListEntry.vue";

  class ListEntry {
    public label: string;
    public text: string;
  }

  class TravelTimeModel {
    public companyLocation: CompanyLocation;
    public customerTravelTimeId: number | null;
    public hours: number;
    public minutes: number;
  }

  export default defineComponent({
    name: "CustomerDetailsCardTravelTimes",
    components: {
      CustomerDetailsCardListEntry,
      ModalDialog
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      customer: {
        type: Object as PropType<Customer>,
        default: () => new Customer()
      }
    },
    setup(props, context) {
      const initialState = {
        dialog: false,
        companyLocations: new Array<CompanyLocation>(),
        customerTravelTimes: new Array<CustomerTravelTime>(),
        travelTimeModels: new Array<TravelTimeModel>(),
        listEntries: [] as Array<ListEntry>
      };

      const state = reactive({...initialState});

      onMounted(() => {
        loadCompanyLocations();
      });

      watch(() => props.customer, () => {
        loadCompanyLocations();
      });

      const loadCompanyLocations = () => {
        if (props.customer.id) {
          InventoriesService.findCompanyLocations()
            .then(response => {
              state.companyLocations = PageUtils.extractCollection(response.data, "companyLocations");
            });
        }
      };

      watch(() => state.companyLocations, () => {
        loadCustomerTravelTimes();
      });

      const loadCustomerTravelTimes = () => {
        CustomerService.getTravelTimes(props.customer.id)
          .then(response => {
            state.customerTravelTimes =  PageUtils.extractCollection(response.data, "travelTimes");
          });
      };

      watch(() => state.customerTravelTimes, () => {
        updateTravelTimeModels();
      },{ deep: true });

      const updateTravelTimeModels = () => {
        state.travelTimeModels = new Array<TravelTimeModel>();
        state.companyLocations.forEach(location => {
          let customerTravelTime = state.customerTravelTimes?.find(time => time.companyLocationId == location.id);
          state.travelTimeModels.push({
            companyLocation: location,
            customerTravelTimeId: customerTravelTime?.id ? customerTravelTime.id : null,
            hours: customerTravelTime?.travelTime ? Math.floor(customerTravelTime.travelTime / 60) : 0,
            minutes: customerTravelTime?.travelTime ? customerTravelTime.travelTime % 60 : 0,
          });
        });
        state.travelTimeModels.sort((a, b) => {
          if (a.companyLocation?.name && b.companyLocation?.name) {
            return a.companyLocation.name.localeCompare(b.companyLocation.name);
          } else {
            return 0;
          }
        });
      };

      watch(() => state.travelTimeModels, () => {
        updateListEntries(state.companyLocations);
      });

      const updateListEntries = (companyLocations: CompanyLocation[]) => {
        state.listEntries = companyLocations.map(companyLocation => {
          let text = "–";
          const model = state.travelTimeModels.find(m => m.companyLocation.id == companyLocation.id);
          if (model) {
            if (model.hours > 0 || model.minutes > 0) {
              const h = model.hours.toString().length == 1 ? `0${model.hours}` : model.hours;
              const m = model.minutes.toString().length == 1 ? `0${model.minutes}` : model.minutes;
              text = `${h}:${m}`;
            }
          }
          return {
            label: companyLocation.name ? companyLocation.name : "–",
            text
          };
        });
      };

      const click = () => {
        if (state.listEntries.length > 0) {
          updateTravelTimeModels();
          state.dialog = true;
        } else {
          state.dialog = false;
        }
      };

      const inputHours = (hours: string, companyLocationId: number) => {
        let hoursInt = parseInt(hours.replace (/[^0-9]/g, "" ));
        if (hoursInt > 99) hoursInt = 99;
        nextTick(() => {
          const travelTimeModel = state.travelTimeModels.find(m => m.companyLocation.id == companyLocationId);
          if (travelTimeModel) {
            travelTimeModel.hours = isNaN(hoursInt) ? 0 : hoursInt;
          }
        });
      };
      const inputMinutes = (minutes: string, companyLocationId: number) => {
        let minutesInt = parseInt(minutes.replace (/[^0-9]/g, "" ));
        if (minutesInt > 59) minutesInt = 59;
        nextTick(() => {
          const travelTimeModel = state.travelTimeModels.find(m => m.companyLocation.id == companyLocationId);
          if (travelTimeModel) {
            travelTimeModel.minutes = isNaN(minutesInt) ? 0 : minutesInt;
          }
        });
      };

      const save = () => {
        let promises = new Array<Promise<void>>();

        state.travelTimeModels.forEach(travelTimeModel => {
          const travelTime = travelTimeModel.hours * 60 + travelTimeModel.minutes;
          const customerTravelTime: CustomerTravelTime = {
            id: travelTimeModel.customerTravelTimeId,
            customerId: props.customer.id,
            travelTime: travelTime,
            companyLocationId: travelTimeModel.companyLocation.id
          };
          if (customerTravelTime.id == null) {
            const create = CustomerTravelTimeService.createTravelTime(customerTravelTime)
              .then((response) => {
                const created = response.data;
                state.customerTravelTimes.push(created);
              })
              .catch(() => { });
            promises.push(create);
          } else {
            const update = CustomerTravelTimeService.updateTravelTime(customerTravelTime)
              .then((response) => {
                const updated = response.data;
                state.customerTravelTimes = state.customerTravelTimes.map(travelTime => {
                  if (travelTime.companyLocationId == updated.companyLocationId) {
                    travelTime = updated;
                  }
                  return travelTime;
                });
              })
              .catch(() => { });
            promises.push(update);
          }
        });

        Promise.all(promises).then(() => {
          state.dialog = false;
        });
      };

      return {
        ...toRefs(state),
        click,
        inputHours,
        inputMinutes,
        save
      };
    }
  });
