import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VTabs,{attrs:{"slot":"extension","color":"primary","centered":""},slot:"extension",model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[(_vm.isAuthorized('Superadmin'))?_c(VTab,[_vm._v(" Stempelübersicht ")]):_vm._e(),(_vm.isAuthorized('Superadmin'))?_c(VTab,[_vm._v(" Teamleiter ")]):_vm._e(),(_vm.isAuthorized('Superadmin'))?_c(VTabItem,[_c('TimeControllingClockedInOverviewTable')],1):_vm._e(),(_vm.isAuthorized('Superadmin'))?_c(VTabItem,[_c('TimeControllingWithoutTeamleadTable')],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }