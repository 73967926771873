
  import Vue from "vue";
  import CurrencyField from "@/components/CurrencyField.vue";
  import { Prop, Watch } from "vue-property-decorator";
  import Component from "vue-class-component";
  import EmployeeCreateDialogFormCard from "./EmployeeCreateDialogStepperContent.vue";
  import { WageTypes } from "@/enums";
  import Wage from "@/model/Wage";
  import moment from "moment";

  @Component({
    components: {
      CurrencyField
    }
  })
  export default class EmployeeWageForm extends Vue {
    @Prop({ default: false })
    public disabled: boolean;

    @Prop({ default: () => new Map<string, string>() })
    public errors: Map<string, string>;

    @Prop({ default: () => new Wage() })
    public wage: Wage;

    private wageDateMenu = false;

    get isValidFromEditable (): boolean {
      if (this.wage && !this.disabled) {
        if (this.wage.validFrom && this.wage.id) {
          return !this.$moment(this.wage.validFrom).isSameOrBefore(this.$moment(this.$store.state.general.earliestValidFrom));
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    get isSalary (): boolean {
      return this.wage && this.wage.wageType && this.wage.wageType === "SAL";
    }

    get earliestValidFrom (): boolean {
      return this.$store.state.general.earliestValidFrom;
    }

    get wageTypes () {
      return WageTypes;
    }

    clearWage () {
      this.$set(this.wage, "wage", null);
    }
  }
