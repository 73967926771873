
  import Vue from "vue";
  import moment from "moment";
  import {mobile} from "@/mixin/mixins";

  export default Vue.extend({
    name: "RegistrationCitizenshipFormDatePicker",
    props: {
      errorMessage: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: "<label>"
      },
      value: {
        type: String,
        default: ""
      }
    },
    data: () => ({
      dialog: false,
      date: ""
    }),
    computed: {
      minDate (): string {
        return moment().format("YYYY-MM-DD");
      },
      mobile(): boolean {
        return mobile();
      }
    },
    watch: {
      value (val) {
        this.date = val;
      }
    },
    mounted () {
      this.date = this.value;
    },
    methods: {
      onDateSelected (newDate: string) {
        this.date = newDate;
        this.dialog = false;
        this.$emit("input", newDate);
      }
    }
  });
