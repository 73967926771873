<template>
  <VContainer fluid>
    <RightsBreadcrumbs />

    <VList class="py-0 thumbsup-list">
      <div
        v-for="role in items"
        :key="role.roleId"
      >
        <VListItem
          class="thumbsup-list-item"
          @click="openRole(role)"
        >
          <VListItemContent>
            <VListItemTitle>
              {{ role.name }}
            </VListItemTitle>
            <VListItemSubtitle>
              {{ role.description }}
            </VListItemSubtitle>
          </VListItemContent>
          <VListItemAction
            class="text-right"
            align-center
            justify-center
            style="min-width: 160px;"
          >
            <VRow>
              <VCol cols="10">
                <RouterLink
                  :to="getRoleUsersLink(role)"
                  @click.native="event => onOpenRoleUsers(event)"
                >
                  {{ role.memberCount }} Mitarbeiter
                </RouterLink>
              </VCol>
              <VCol cols="2">
                <VIcon class="text-right">
                  mdi-chevron-right
                </VIcon>
              </VCol>
            </VRow>
          </VListItemAction>
        </VListItem>
      </div>
    </VList>
  </VContainer>
</template>

<script>
  import RightsBreadcrumbs from "./RightsBreadcrumbs";
  import RightsService from "@/service/RightsService";

  export default {
    components: {
      RightsBreadcrumbs: RightsBreadcrumbs
    },
    data: () => ({
      items: []
    }),
    watch: {
      items (val) {
        this.$store.commit("header/setTitle", "Rollen (" + val.length + ")");
      }
    },
    created () {
      this.load();
    },
    methods: {
      load () {
        RightsService.getRoles()
          .then(response => {
            this.items = response.data;
          });
      },
      openRole: function (role) {
        this.$router.push({ name: "rightsRoleDetails", params: { roleId: role.id } });
      },
      getRoleUsersLink: function (role) {
        return { name: "rightsRoleUsers", params: { roleId: role.id } };
      },
      onOpenRoleUsers: function (event) {
        event.stopPropagation();
      }
    }
  };
</script>
<style>
</style>
