
  import {HeaderMutations} from "@/store/modules/header";
  import Order from "@/model/Order";
  import OrderServiceV2 from "@/service/OrderServiceV2";
  import {computed, defineComponent, onMounted, reactive, toRefs} from "vue";
  import {OrderType} from "@/enums";
  import store from "@/store/store";
  import {isOrderCreateMode, orderId} from "@/RouterUtils";
  import {isAuthorized} from "@/mixin/mixins";

  export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Order",
    setup(props, context) {
      const initialState = {
        active: 0,
        order: new Order()
      };

      const state = reactive({...Object.assign({}, initialState)});

      const isInventory = computed(() => {
        return state.order && state.order.orderType === OrderType.INV;
      });

      const updateTitle = (newTitle: string) => {
        store.commit("header/" + HeaderMutations.SET_TITLE, newTitle);
      };

      onMounted(() => {
        updateTitle("");

        if (orderId.value) {
          OrderServiceV2.getOrder(orderId.value)
            .then(response => {
              updateTitle(response.data.name ?? "");
              state.order = Object.assign(new Order(), response.data);
            });
        } else {
          updateTitle("Neuer Markt");
        }
      });

      const isShowOperatorsTab = computed(() => {
        return !isOrderCreateMode.value
          && isInventory.value
          && isAuthorized("Order_Overview_EditOrders");
      });

      return {
        ...toRefs(state),
        isOrderCreateMode,
        isInventory,
        isShowOperatorsTab
      };
    }
  });
