import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(_setup.isDay)?[_c(_setup.OrderWorklogsListDate,{attrs:{"items":_setup.dayItems}})]:_c(VList,{attrs:{"three-line":""}},[_vm._l((_vm.items),function(worklogDateEntry){return [_c(VListItem,{key:worklogDateEntry.date,attrs:{"ripple":""},on:{"click":function($event){return _setup.selectDate(worklogDateEntry.date)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm._f("formatDate")(worklogDateEntry.date,"dddd, Do MMMM YYYY"))+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(worklogDateEntry.employeeCount)+" Mitarbeiter ")]),_c(VListItemSubtitle,[_vm._v(" Arbeit: "+_vm._s(_vm._f("toTimeFormat")(_setup.getMinutes(worklogDateEntry, "WORK")))+"h Pause: "+_vm._s(_vm._f("toTimeFormat")(_setup.getMinutes(worklogDateEntry, "PAUSE")))+"h Reise: "+_vm._s(_vm._f("toTimeFormat")(_setup.getMinutes(worklogDateEntry, "TRAVEL")))+"h ")])],1),_c(VListItemAction,[_c(VIcon,[_vm._v(" mdi-arrow-right ")])],1)],1)]})],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }