import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"10"}},[_c(VFileInput,{attrs:{"disabled":_vm.statusSuccess,"accept":"image/*,.pdf","prepend-icon":"mdi-camera","label":"Dokument hochladen","placeholder":"Dokument hochladen","error-messages":_vm.error},on:{"change":_vm.uploadFile},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c(VCol,{attrs:{"cols":"2"}},[(_vm.statusInProgress)?_c(VProgressCircular,{attrs:{"size":"24","indeterminate":"","color":"primary"}}):(_vm.statusSuccess)?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-check-circle-outline ")]):(_vm.statusError)?_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" mdi-cancel ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }