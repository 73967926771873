
  import Vue from "vue";
  import Component from "vue-class-component";
  import moment from "moment";
  import { DocumentTypes } from "@/enums";

  @Component
  export default class PayrollUploadDialog extends Vue {
    private valid = true;
    private dialog = false;
    private result: any = null;
    private title = "Dokumenten-Upload";
    private menuDate = false;
    private selectedDate = "";
    private documentType = "";

    get documentTypes () {
      return DocumentTypes;
    }

    mounted () {
      this.selectedDate = moment().subtract(1, "months").format("YYYY-MM");
    }

    open () {
      // @ts-ignore
      // this.$refs.form.resetValidation();
      this.documentType = "";
      this.selectedDate = moment().subtract(1, "months").format("YYYY-MM");
      this.dialog = true;
      const vue = this;
      return new Promise(function (resolve) {
        vue.result = resolve;
      });
    }

    confirm () {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.result({
          success: true,
          yearMonth: this.selectedDate,
          documentType: this.documentType
        });
        this.dialog = false;
      }
    }

    cancel () {
      // @ts-ignore
      this.$refs.form.resetValidation();
      this.result(false);
      this.dialog = false;
    }
  }
