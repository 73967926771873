import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{ref:"timePickerDialog",staticClass:"timepicker",attrs:{"persistent":"","return-value":_vm.selectedTime,"max-width":"290px"},on:{"update:returnValue":function($event){_vm.selectedTime=$event},"update:return-value":function($event){_vm.selectedTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VTextField,_vm._g({attrs:{"append-outer-icon":"mdi-clock-outline","readonly":"","hide-details":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.modal)?_c(VTimePicker,{ref:"timePicker",attrs:{"format":"24hr"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Abbrechen ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.timePickerDialog.save(_vm.time)}}},[_vm._v(" Speichern ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }