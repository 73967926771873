import axios from "axios";
import CashFlow from "@/model/CashFlow";

const PATH = "/timecontrolling";

export default {
  getClockedIn () {
    return axios.get(`${PATH}/clocked-in`);
  },
  getTimesWithoutTeamlead () {
    return axios.get(`${PATH}/without-teamlead`);
  }
};
