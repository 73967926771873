
  import Vue from "vue";
  import Component from "vue-class-component";

  import {Getter, Mutation} from "vuex-class";
  import {ClockingParameters} from "@/store/modules/timeclock";
  import {Watch} from "vue-property-decorator";
  import {HeaderGetters, HeaderModule, HeaderMutations} from "@/store/modules/header";

  import TimeclockOrderTabClockIn from "@/views/Timeclock/TimeclockOrderTabClockIn.vue";
  import TimeclockOrderTabPause from "@/views/Timeclock/TimeclockOrderTabPause.vue";
  import TimeclockOrderTabClockedOut from "@/views/Timeclock/TimeclockOrderTabClockOut.vue";
  import TimeclockService from "@/service/TimeclockService";
  import {MessageTypes} from "@/components/message-types";
  import Order from "@/model/Order";
  import {OrderOperator} from "@/views/Orders/Operators/OrderOperators.vue";
  import OrderOperatorService from "@/service/OrderOperatorService";
  import PageUtils from "@/components/PageUtils";
  import {OrderType} from "@/enums";

  @Component({
    components: {
      TimeclockOrderTabClockIn,
      TimeclockOrderTabPause,
      TimeclockOrderTabClockedOut
    }
  })
  export default class TimeclockOrder extends Vue {
    @Getter(HeaderGetters.GET_DATE, { namespace: HeaderModule }) date: string;

    @Getter(HeaderGetters.GET_TIME, { namespace: HeaderModule }) time: string;

    @Mutation(HeaderMutations.SET_TITLE_EXTENSION, { namespace: HeaderModule })
    private mutationTitleExtension: any;

    private items: Array<any> = [];

    private errors: Array<any> = [];

    private operators: Array<OrderOperator> = [];

    private activeTab = 0;

    private order: Order = new Order();

    private tabs: Array<any> = [
      { name: "ClockIn", icon: "mdi-clock" },
      { name: "Pause", icon: "mdi-pause-circle-outline" },
      { name: "ClockOut", icon: "mdi-exit-run" }
    ];

    @Watch("date")
    onDateChanged () {
      this.load();
    }

    mounted () {
      this.load();
    }

    get orderId (): number {
      return Number(this.$route.params.orderId);
    }

    load () {
      this.errors = Array<any>();
      TimeclockService.getOrder(this.orderId, this.date)
        .then(response => {
          this.items = response.data.employees;
          if (response.data.order) {
            this.mutationTitleExtension(response.data.order.name);
            this.order = response.data.order;

            return this.order;
          }
        })
        .then(order => {
          // Operators only relevant in the inventory context
          if(order && order.orderType == OrderType.INV) {
            OrderOperatorService.getOrderOperators(this.orderId)
              .then(response => {
                this.operators = PageUtils.extractCollection(response.data, "operators");
              });
          }
        });
    }

    doClock (params: ClockingParameters) {
      this.$eventBus.$emit(MessageTypes.LOADING, params.messageProgress);

      TimeclockService.clock(params.orderId, params)
        .then(response => {
          this.items = response.data.employees;
          this.errors = Array<any>();
          this.$eventBus.$emit(MessageTypes.SUCCESS, params.messageSuccess);
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.$eventBus.$emit(MessageTypes.WARNING, params.messageValidation);
            this.items = error.response.data.employees;
            this.errors = error.response.data.validationErrors;
          } else {
            this.$eventBus.$emit(MessageTypes.ERROR, "Fehler! Bitte Admin kontaktieren");
          }
        });
    }
  }
