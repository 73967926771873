import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VListItem,{class:{'pl-1': _vm.order.orderType === 'INV'}},[(_vm.order.orderType === 'INV' && (_vm.order.checklist && _vm.order.checklist.length > 0))?_c(VListItemAction,{staticClass:"mr-1",on:{"click":function($event){return _vm.openChecklist(_vm.order)}}},[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{attrs:{"color":_vm.getChecklistStatusColor(_vm.order)}},[_vm._v(" mdi-circle ")])],1)],1):_vm._e(),_c(VListItemContent,{on:{"click":function($event){return _vm.openDetails(_vm.order)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.order.name)+" ")]),(_vm.showFromToSubtitle)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.$moment(_vm.order.validFrom).format("DD-MM-YYYY"))+" - "+_vm._s(_vm.$moment(_vm.order.validTo).format("DD-MM-YYYY"))+" ")]):_vm._e(),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.toAddressString(_vm.order))+" ")])],1),_c(VListItemIcon,{on:{"click":function($event){return _vm.openDetails(_vm.order)}}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }