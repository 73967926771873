import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{attrs:{"flat":""}},[_c('span',{staticClass:"header-title"},[_vm._v(" Neue Rolle hinzufügen ")]),_c(VSpacer),_c(VBtn,{staticClass:"close-button",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog=false}}},[_c(VIcon,{staticClass:"close-button-icon"},[_vm._v(" mdi-close ")])],1)],1),_c(VCardText,[_c('b',[_vm._v(" "+_vm._s(_vm.getUserName())+" ")]),_vm._v(" hat die folgenden Rollen "),_c('ul',_vm._l((_vm.assignedRoles),function(assignedRole){return _c('li',{key:assignedRole.id},[_vm._v(" "+_vm._s(assignedRole.name)+" ")])}),0),_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.availableRoles,"label":"Neue Rolle","item-text":"name","item-value":"id","no-data-text":"Keine weiteren Rollen verfügbar"},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}}),_c(VAlert,{attrs:{"value":_vm.showWarning,"type":"warning"}},[_vm._v(" Es wurde keine Rolle ausgewählt. ")])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog=false}}},[_vm._v(" Abbrechen ")]),_c(VBtn,{attrs:{"color":"primary","text":""},nativeOn:{"click":function($event){return _vm.assignRole()}}},[_vm._v(" Hinzufügen ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }