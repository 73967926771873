
  import {computed, defineComponent, reactive, SetupContext, toRefs, watch} from "vue";
  import moment from "moment";
  import {mobile} from "@/CompositionUtils";
  import {yearMonth} from "@/DateUtils";

  export default defineComponent({
    name: "MonthPicker",
    props: {
      customNextMonth: {
        type: Function,
        required: false,
        default: null,
      },
      customPrevMonth: {
        type: Function,
        required: false,
        default: null,
      },
      min: {
        type: String,
        default: null,
        validator(value: string) {
          return value == null || value == "" || moment(value, "YYYY-MM", true).isValid();
        }
      },
      max: {
        type: String,
        default: null,
        validator(value: string) {
          return value == null || value == "" || moment(value, "YYYY-MM", true).isValid();
        }
      },
      value: {
        type: String,
        default: null,
        validator(value: string) {
          return value == null || value == "" || moment(value, "YYYY-MM", true).isValid();
        }
      }
    },
    setup(props: any, context: SetupContext) {
      const initialState = {
        date: (props.value != null && props.value != "" ? yearMonth(props.value) : moment()).format("YYYY-MM"),
        menu: false,
      };

      const state = reactive({...initialState});

      const formattedDate = computed(() => {
        return moment(state.date).format("MMMM YYYY");
      });

      const onDateSelected = (newDate: string) => {
        context.emit("input", newDate);
        state.menu = false;
      };

      const previousMonth = () => {
        state.date = yearMonth(state.date).subtract(1, "months").format("YYYY-MM");
        context.emit("input", state.date);
      };

      const nextMonth = () => {
        state.date = yearMonth(state.date).add(1, "months").format("YYYY-MM");
        context.emit("input", state.date);
      };

      const handleNextMonth = () => {
        if (props.customNextMonth) {
          props.customNextMonth();
        } else {
          nextMonth();
        }
      };

      const handlePrevMonth = () => {
        if (props.customPrevMonth) {
          props.customPrevMonth();
        } else {
          previousMonth();
        }
      };

      const hasPreviousMonth = computed(() => {
        return !props.min || yearMonth(state.date).isAfter(yearMonth(props.min));
      });

      const hasNextMonth = computed(() => {
        return !props.max || yearMonth(state.date).isBefore(yearMonth(props.max));
      });

      watch(() => props.value, (newValue, oldValue) => {
        state.date = newValue;
      });

      return {
        ...toRefs(state),
        formattedDate,
        onDateSelected,
        mobile,
        hasPreviousMonth,
        hasNextMonth,
        handleNextMonth,
        handlePrevMonth
      };
    }
  });
