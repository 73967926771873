import axios from "axios";
import {AxiosResponse} from "axios/index";
import {Role} from "@/model/Role";

const PATH = "/rights";

export default {
    getUsers() {
        return axios.get(`${PATH}/users`);
    },
    getUser(employeeId: number) {
        return axios.get(`${PATH}/users/${employeeId}`);
    },
    assignRightToRole(roleId: number, rightId: number) {
        return axios.put(`${PATH}/roles/${roleId}/assign/${rightId}`);
    },
    unassignRightFromRole(roleId: number, rightId: number) {
        return axios.put(`${PATH}/roles/${roleId}/unassign/${rightId}`);
    },
    assignRoleToUser(userId: number, roleId: number) {
        return axios.post(`${PATH}/users/${userId}/assignRole/${roleId}`);
    },
    unassignRoleFromUser(userId: number, roleId: number) {
        return axios.post(`${PATH}/users/${userId}/unassignRole/${roleId}`);
    },
    getRoles() {
        return axios.get(`${PATH}/roles`);
    },
    getOperations() {
        return axios.get(`${PATH}/authorities`);
    },
    assignAuthority(roleId: number, authority: string) {
        return axios.post(`${PATH}/roles/${roleId}/authorities/${authority}`);
    },
    unassignAuthority(roleId: number, authority: string) {
        return axios.delete(`${PATH}/roles/${roleId}/authorities/${authority}`);
    },
    getRole(roleId: number): Promise<AxiosResponse<Role>> {
        return axios.get(`${PATH}/roles/${roleId}`);
    },
    getRoleUsers(roleId: number) {
        return axios.get(`${PATH}/roles/${roleId}/users`);
    }
};
