import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{ref:"addEmployeeDialog",attrs:{"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardText,{staticStyle:{"height":"500px"}},[_c(VAutocomplete,{ref:"autocomplete",attrs:{"label":"Mitarbeiter hinzufügen","no-data-text":"Keine Daten verfügbar","loading":_vm.loading,"items":_vm.items,"item-text":_vm.fullName,"item-value":"id","return-object":"","search-input":_vm.search,"hide-details":"","hide-selected":"","text":"","filter":_vm.filter},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":selected => _vm.employeeSelected(selected)},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.fullName)+" ")])],1)]}}]),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}}),_c(VList,_vm._l((_vm.selectedItems),function(item){return _c(VListItem,{key:item.id},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.fullName)+" ")])],1),_c(VListItemAction,[_c(VIcon,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.removeSelected(item)}}},[_vm._v(" mdi-close-circle ")])],1)],1)}),1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Abbrechen ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Speichern ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }