<template>
  <VContainer>
    <RightsBreadcrumbs />

    <VList
      two-line
      class="py-0"
    >
      <div
        v-for="link in links"
        :key="link.title"
      >
        <VListItem @click="open(link.to)">
          <VListItemContent>
            <VListItemTitle>
              {{ link.title }}
            </VListItemTitle>
            <VListItemSubtitle>
              {{ link.subtitle }}
            </VListItemSubtitle>
          </VListItemContent>

          <VListItemAction>
            <VIcon>
              mdi-chevron-right
            </VIcon>
          </VListItemAction>
        </VListItem>
        <VDivider />
      </div>
    </VList>
  </VContainer>
</template>

<script>
  import RightsBreadcrumbs from "./RightsBreadcrumbs.vue";

  export default {
    components: {
      RightsBreadcrumbs: RightsBreadcrumbs
    },
    data: () => ({
      links: [{
        title: "User",
        subtitle: "Übersicht über alle User und deren Rechte und Rollen.",
        to: "/rights/users"
      }, {
        title: "Rollen",
        subtitle: "Übersicht über alle Rollen.",
        to: "/rights/roles"
      }]
    }),
    mounted () {
      this.$store.commit("header/setTitle", "Rechtemanagement");
    },
    methods: {
      open: function (to) {
        this.$router.push(to);
      }
    }
  };
</script>
