
  import Vue from "vue";
  import { Prop } from "vue-property-decorator";
  import Component from "vue-class-component";
  import { EmploymentType } from "@/enums";
  import Employment from "@/model/Employment";
  import FormDatePicker from "@/components/FormDatePicker.vue";

  @Component({
    components: {
      FormDatePicker
    }
  })
  export default class EmployeeEmploymentForm extends Vue {
    @Prop({ default: false })
    public disabled: boolean;

    @Prop({ default: () => new Map<string, string>() })
    public errors: Map<string, string>;

    @Prop({ default: () => new Employment() })
    public employment: Employment;

    get futureEmployment (): boolean {
      if (this.employment) {
        return this.$moment(this.employment.validFrom).isAfter(this.$moment());
      } else {
        return true;
      }
    }

    get earliestValidFrom () {
      return this.$store.state.general.earliestValidFrom;
    }

    get employmentTypes (): Array<any> {
      return Object.keys(EmploymentType)
        .map(key => ({ value: key, text: this.$vuetify.lang.t("$vuetify.enum.employmentType." + key) }));
    }
  }
