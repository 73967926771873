import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.SwitchView,{attrs:{"hide-search":"","hide-extended-toolbar":"","hide-table-view":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_setup.isValidDate(_setup.date))?_c(_setup.MonthPicker,{attrs:{"min":_setup.minMonth,"max":_setup.maxMonth},model:{value:(_setup.state.yearMonth),callback:function ($$v) {_vm.$set(_setup.state, "yearMonth", $$v)},expression:"state.yearMonth"}}):_vm._e()]},proxy:true},{key:"title",fn:function({ view }){return [(view === 'list' && _setup.date)?_c(VBreadcrumbs,{attrs:{"large":"","items":_setup.breadcrumbs,"divider":"/"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(VBreadcrumbsItem,{attrs:{"to":item.to,"link":"","exact":"","disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text.toUpperCase())+" ")])]}}],null,true)}):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_setup.state.yearMonth,"MMMM YYYY"))+" ")])]}},{key:"list",fn:function(){return [(_setup.isDayView)?[_c(_setup.OrderPerDiemsDate,{attrs:{"order":_vm.order,"date-entry":_setup.currentDateEntry}})]:_c(VList,{attrs:{"two-line":""}},[_vm._l((_setup.state.perDiemOrderDates),function(item){return [_c(VListItem,{key:item.date,on:{"click":function($event){return _setup.edit(item)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date,"dddd, D. MMMM YYYY"))+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.order.name)+" - "+_vm._s(item.employees.length)+" Mitarbeiter ")])],1),_c(VListItemAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-food-fork-drink ")])],1)],1)]})],2)]},proxy:true},{key:"table",fn:function(){return undefined},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }