
  import {defineComponent, PropType, toRefs} from "vue";
  import {icon} from "@/WorklogUtils";
  import Worklog from "@/model/Worklog";
  import AccountingEmployeeWorklogsSummaryChips
    from "@/views/Accounting/Employee/Worklogs/AccountingEmployeeWorklogsSummaryChips.vue";
  import {init} from "@/views/Accounting/Employee/Worklogs/AccountingEmployeeWorklogsComposable";

  export default defineComponent({
    name: "AccountingEmployeeWorklogsList",
    components: {
      AccountingEmployeeWorklogsSummaryChips
    },
    props: {
      editable: {
        type: Boolean,
        default: () => false
      },
      worklogs: {
        type: Array as PropType<Array<Worklog>>,
        default: () => new Array<Worklog>()
      },
    },
    setup(props) {
      const { worklogs } = toRefs(props);
      const { groups, dates, editWorklog } = init(worklogs);

      return {
        groups,
        dates,
        icon,
        editWorklog
      };
    }
  });
