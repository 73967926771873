
  import Vue from "vue";
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import RegistrationService from "@/service/RegistrationService";

  export default Vue.extend({
    name: "RegistrationFinishedCard",
    components: {
      RegistrationCard
    },
    props: {
      files: {
        type: Array,
        default: () => new Array<any>()
      }
    },
    computed: {
      registrationUuid(): string {
        return this.$route.params.registrationUuid;
      }
    },
    mounted() {
      if (this.files.length > 0) {
        this.files.forEach(file => this.download(file.key));
      }
    },
    methods: {
      download(key: string) {
        RegistrationService.downloadFile(this.registrationUuid, key)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              // @ts-ignore
              link.setAttribute("download", response.headers.filename);
              document.body.appendChild(link);
              link.click();
            }
          });
      }
    }
  });
