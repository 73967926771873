
  import Vue from "vue";
  import { AuthGetters } from "@/store/modules/auth";

  export default Vue.extend({
    name: "TheFooter",
    data: () => ({
      date: "",
      time: ""
    }),
    computed: {
      fullName (): string {
        return this.$store.getters["auth/" + AuthGetters.GET_FULL_NAME];
      }
    },
    created () {
      this.updateTime();
      this.updateDate();
    },
    mounted () {
      window.setInterval(() => {
        this.updateTime();
        this.updateDate();
      }, 1000);
    },
    methods: {
      updateTime () {
        this.time = this.$moment().format("HH:mm:ss");
      },
      updateDate () {
        this.date = this.$moment().format("DD.MM.YYYY");
      }
    }
  });
