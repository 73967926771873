
  import Vue from "vue";

  export default Vue.extend({
    name: "SwitchView",
    props: {
      hideExtendedToolbar: {
        type: Boolean,
        default: false
      },
      hideHeader: {
        type: Boolean,
        default: false
      },
      hideTableView: {
        type: Boolean,
        default: false
      },
      hideSearch: {
        type: Boolean,
        default: false
      },
      fixedView: {
        type: String,
        default: null
      }
    },
    data: () => ({
      search: "",
      searchFieldActive: false,
      view: null as unknown as string
    }),
    computed: {
      isTableViewAvailable(): boolean {
        return !this.hideTableView && !this.fixedView && this.$vuetify.breakpoint.mdAndUp;
      },
      isShowExtendedToolbar(): boolean {
        return this.$vuetify.breakpoint.smAndDown && !this.hideExtendedToolbar;
      }
    },
    watch: {
      "$vuetify.breakpoint"() {
        // force view switch if table is not available
        if (!this.isTableViewAvailable) {
          this.switchToListView();
        }
      },
      search(val) {
        this.$emit("search", val);
      }
    },
    mounted() {
      if (this.fixedView) {
        this.view = this.fixedView;
      } else {
        if (this.$route.query.view) {
          if (this.$route.query.view === "table") {
            this.switchToTableView(false);
          } else if (this.$route.query.view === "list") {
            this.switchToListView(false);
          }
        } else {
          if (this.isTableViewAvailable) {
            this.switchToTableView(true);
          } else {
            this.switchToListView(true);
          }
        }
      }
    },
    methods: {
      expandSearch() {
        this.searchFieldActive = true;
        this.$nextTick(() => {
          // @ts-ignore
          this.$refs.searchField.focus();
        });
      },
      clearSearch() {
        this.search = "";
      },
      switchToListView(replace = false) {
        this.view = "list";
        this.$emit("viewChange", "list");

        if (replace) {
          this.$router.replace({
            query: {...this.$route.query, view: "list"},
          });
        } else {
          this.$router.push({
            query: {...this.$route.query, view: "list"},
          })
            .catch(() => {

            }); // Do nothing with "NavigationDuplicated: Avoided redundant navigation to current location"
        }
      },
      switchToTableView(replace = false) {
        this.view = "table";

        if (replace) {
          this.$router.replace({
            query: {...this.$route.query, view: "table"},
          });
        } else {
          this.$router.push({
            query: {...this.$route.query, view: "table"},
          })
            .catch(() => {

            }); // Do nothing with "NavigationDuplicated: Avoided redundant navigation to current location"
        }
      },
      onSearchFocusLost() {
        // Close the search field again if focus is lost and nothing is entered
        if (!this.search || this.search == "") {
          this.searchFieldActive = false;
        }
      }
    }
  });
