
  import {defineComponent, onMounted, reactive, toRefs, watch} from "vue";
  import SwitchView from "@/components/SwitchView.vue";
  import InventoriesMarketAreasTable from "./InventoriesMarketAreasTable.vue";
  import InventoriesMarketAreasDialog from "./dialog/InventoriesMarketAreasDialog.vue";
  import {MarketArea} from "@/model/MarketArea/MarketArea";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import InventoriesService from "@/service/InventoriesService";
  import {MarketAreaWithCategories} from "@/model/MarketArea/MarketAreaWithCategories";
  import InventoriesMarketAreaCategoriesDialog from "./dialog/InventoriesMarketAreaCategoriesDialog.vue";
  import {MarketAreaCategory} from "@/model/MarketArea/MarketAreaCategory";
  import PageUtils from "@/components/PageUtils";

  export interface MarketAreaWithCategoriesModel {
    marketArea: MarketArea;
    marketAreaCategories: MarketAreaCategory[] | undefined;
  }

  export default defineComponent({
    name: "InventoriesMarketAreas",
    components: {
      SwitchView,
      InventoriesMarketAreasTable,
      InventoriesMarketAreasDialog,
      InventoriesMarketAreaCategoriesDialog
    },
    setup() {

      const initialState = {
        marketAreasWithCategories: new Array<MarketAreaWithCategoriesModel>(),
        search: ""
      };

      const state = reactive({...initialState});

      onMounted(() => {
        findMarketAreasWithCategoriesByTerm(state.search);

        eventBus.$on(MessageTypes.MARKET_AREA_CREATED, (marketArea: MarketArea) => {
          let exists = state.marketAreasWithCategories.find(mac => mac.marketArea.id == marketArea.id);
          if (exists) {
            state.marketAreasWithCategories = state.marketAreasWithCategories.map(mac => {
              if (mac.marketArea.id == marketArea.id) {mac.marketArea = marketArea;}
              return mac;
            });
          } else {
            const newMarketAreaWithCategory : MarketAreaWithCategoriesModel = {
              marketArea: {
                id: marketArea.id,
                active: true,
                name: marketArea.name
              },
              marketAreaCategories: []
            };
            state.marketAreasWithCategories.push(newMarketAreaWithCategory);
          }
        });
        eventBus.$on(MessageTypes.MARKET_AREA_DELETED, (marketAreaId: number) => {
          state.marketAreasWithCategories = state.marketAreasWithCategories.filter(mac => {
            return mac.marketArea.id != marketAreaId;
          });
        });
        eventBus.$on(MessageTypes.MARKET_AREA_CATEGORY_CREATED, (marketAreaCategory: MarketAreaCategory) => {
          state.marketAreasWithCategories = state.marketAreasWithCategories.map(mac => {
            if (mac.marketArea.id == marketAreaCategory.marketArea.id) {
              let exists = mac.marketAreaCategories?.find(cat => cat.id == marketAreaCategory.id);
              if (exists) {
                mac.marketAreaCategories = mac.marketAreaCategories?.map(cat => {
                  if (cat.id == marketAreaCategory.id) {cat = marketAreaCategory;}
                  return cat;
                });
              } else {
                mac.marketAreaCategories?.push(marketAreaCategory);
              }
            }
            return mac;
          });
        });
        eventBus.$on(MessageTypes.MARKET_AREA_CATEGORY_DELETED, (marketAreaCategoryId: number) => {
          state.marketAreasWithCategories.forEach(mac => {
            mac.marketAreaCategories = mac.marketAreaCategories?.filter(cat => {
              return cat.id != marketAreaCategoryId;
            });
          });
        });
      });

      const findMarketAreasWithCategoriesByTerm = (term: string) => {
        InventoriesService.findMarketAreasWithCategoriesByTerm(term)
          .then(response => {
            const marketAreasWithCategories = PageUtils.extractCollection<MarketAreaWithCategories>(response.data, "marketAreasWithCategories");
            state.marketAreasWithCategories = [];
            marketAreasWithCategories.forEach(ma => {
              ma.categories?.forEach(c => c.marketArea.id = ma.id);
              const mac : MarketAreaWithCategoriesModel = {
                marketArea: {
                  id: ma.id,
                  active: true,
                  name: ma.name
                },
                marketAreaCategories: ma.categories
              };
              state.marketAreasWithCategories.push(mac);
            });
          });
      };

      const openMarketAreaDialog = (marketArea: MarketArea | undefined = undefined) => {
        eventBus.$emit(MessageTypes.OPEN_MARKET_AREA_DIALOG, marketArea);
      };

      let timeout = 200;
      watch(() => state.search, (newVal) => {
        clearTimeout(timeout);

        if (newVal != null) {
          if (newVal != "") {
            if (newVal.length > 2) {
              timeout = setTimeout(() => {
                findMarketAreasWithCategoriesByTerm(newVal);
              }, timeout);
            }
          } else {
            findMarketAreasWithCategoriesByTerm(newVal);
          }
        }
      });

      return {
        ...toRefs(state),
        openMarketAreaDialog
      };
    }
  });
