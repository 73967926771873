import axios, {AxiosResponse} from "axios";
import {PagedResult} from "@/service/PagedResult";

const PATH = "/v1/cost-centers";

class CostCenterService {

    getCostCenters(): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH}`);
    }
}

export default new CostCenterService();
