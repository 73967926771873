
  import Vue from "vue";
  import RegistrationService from "@/service/RegistrationService";
  import { MessageTypes } from "@/components/message-types";

  enum DeleteStatus {
    DELETABLE,
    IN_PROGRESS,
    SUCCESS,
    ERROR
  }

  export default Vue.extend({
    name: "RegistrationCitizenshipFormDeletableFile",
    components: {
    },
    props: {
      fileName: {
        type: String,
        default: null
      }
    },
    data: () => ({
      status: DeleteStatus.DELETABLE
    }),
    computed: {
      registrationUuid (): string {
        return this.$route.params.registrationUuid;
      },
      statusDeletable (): boolean {
        return this.status === DeleteStatus.DELETABLE;
      },
      statusInProgress (): boolean {
        return this.status === DeleteStatus.IN_PROGRESS;
      },
      statusSuccess (): boolean {
        return this.status === DeleteStatus.SUCCESS;
      },
      statusError (): boolean {
        return this.status === DeleteStatus.ERROR;
      }
    },
    methods: {
      deleteFile () {
        const title = this.$vuetify.lang.t("$vuetify.file.confirmDelete.title");
        const text = this.$vuetify.lang.t("$vuetify.file.confirmDelete.text");

        this.$root.$confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              this.status = DeleteStatus.IN_PROGRESS;
              RegistrationService.deleteFile(this.registrationUuid, "citizenship", this.fileName)
                .then(() => {
                  this.status = DeleteStatus.SUCCESS;
                  this.$eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, this.$vuetify.lang.t("$vuetify.file.delete.success"));
                  setTimeout(() => {
                    this.$emit("fileDeleted", this.fileName);
                  }, 1000);
                })
                .catch(() => {
                  this.status = DeleteStatus.ERROR;
                  this.$eventBus.$emit(MessageTypes.SNACKBAR_ERROR, this.$vuetify.lang.t("$vuetify.file.delete.error"));
                });
            }
          });
      }
    }
  });
