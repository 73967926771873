
  import {computed, defineComponent} from "vue";
  import RegistrationsTable from "@/views/registrations/RegistrationsTable.vue";
  import RegistrationsList from "@/views/registrations/RegistrationsList.vue";
  import vuetify from "@/plugins/vuetify";

  export default defineComponent({
    name: "FinishedRegistrations",
    components: {
      RegistrationsList,
      RegistrationsTable
    },
    setup (props, context) {
      const mobile = computed(() => {
        return vuetify.framework.breakpoint.smAndDown;
      });

      return {
        mobile
      };
    }
  });
