import Vue from "vue";
import Router from "vue-router";
import store from "@/store/store";

import AccountingEmployees from "@/views/Accounting/AccountingEmployees.vue";
import AccountingEmployee from "@/views/Accounting/Employee/AccountingEmployee.vue";
import AccountingEmployeeWorklogs from "@/views/Accounting/Employee/Worklogs/AccountingEmployeeWorklogs.vue";
import AccountingEmployeeTabPerDiem from "@/views/Accounting/Employee/PerDiems/AccountingEmployeePerDiems.vue";
import AccountingOrderDate from "@/views/Orders/Worklogs/OrderWorklogsListDate.vue";
import Login from "@/views/Login/Login.vue";
import AccessDenied from "@/components/AccessDenied.vue";
import Employees from "@/views/Employee/Employees.vue";
import EmployeeDetails from "@/views/Employee/details/EmployeeDetails.vue";
import Registrations from "@/views/Registration/Registrations.vue";
import FinishedRegistrations from "@/views/registrations/Registrations.vue";
import Registration from "@/views/Registration/Registration.vue";
import Timeclock from "@/views/Timeclock/Timeclock.vue";
import TimeclockOrder from "@/views/Timeclock/TimeclockOrder.vue";
import HourOverview from "@/views/HourOverview/HourOverview.vue";
import Inventories from "@/views/Inventories/Inventories.vue";

import Rights from "@/views/Rights/Rights.vue";
import RightsUsers from "@/views/Rights/Users.vue";
import RightsUserDetails from "@/views/Rights/UserDetails.vue";
import RightsRoles from "@/views/Rights/Roles.vue";
import RightsRoleDetails from "@/views/Rights/RoleDetails.vue";
import OrderInfo from "@/views/Orders/Info/OrderInfo.vue";
import Order from "@/views/Orders/Order.vue";
import OrdersV2 from "@/views/Orders/OrdersV2.vue";
import OrderPerDiems from "@/views/Orders/PerDiems/OrderPerDiems.vue";
import OrderPerDiemsDate from "@/views/Orders/PerDiems/OrderPerDiemsDate.vue";
import OrderOperators from "@/views/Orders/Operators/OrderOperators.vue";
import OrderWorklogs from "@/views/Orders/Worklogs/OrderWorklogs.vue";
import OrderAssignments from "@/views/Orders/Assignments/OrderAssignments.vue";
import TimeControlling from "@/views/TimeControlling/TimeControlling.vue";
import ClockingHistory from "@/views/TimeControlling/TimeControllingClockingHistory.vue";
import Statistic from "@/views/Statistic/Statistic.vue";
import StatisticOrder from "@/views/Statistic/StatisticOrder.vue";
import Archive from "@/views/Archive/Archive.vue";
import MonthEndClosing from "@/views/MonthEndClosing/MonthEndClosing.vue";
import TheHeaderSearchLookup from "@/components/TheHeaderSearchLookup.vue";
import {Route} from "vue-router/types/router";
import Authentication from "@/model/Authentication";
import {AuthGetters} from "@/store/modules/auth";
import CustomerDetails from "@/views/Customers/details/CustomerDetails.vue";
import CustomerOrders from "@/views/Customers/orders/CustomerOrders.vue";
import CustomerDetailsInfo from "@/views/Customers/details/CustomerDetailsInfo.vue";
import {getCustomerFieldIcon} from "@/views/Customers/CustomerUtils";
import Customers from "@/views/Customers/Customers.vue";
import {isAuthorized} from "@/mixin/mixins";
import OrderEditHistory from "@/views/Orders/EditHistory/OrderEditHistory.vue";

const isAuthorizedForRoute = (route: Route): boolean => {
    if (route) {
        if (!route.meta || !route.meta.authorities || route.meta.authorities.length === 0) {
            return true;
        } else {
            const authorities = route.meta.authorities as Array<string>;
            const authorizedForRoute = authorities.some(authority => isAuthorized(authority));
            return authorizedForRoute;
        }
    } else {
        return false;
    }
};

const beforeEach = (to: Route, from: Route, next: any) => {
    // @ts-ignore
    if (store.getters["auth/" + AuthGetters.IS_LOGGED_IN] || to.path.startsWith("/register")) {
        if (isAuthorizedForRoute(to)) {
            next();
        } else {
            next("/accessDenied");
        }
    } else {
        store.dispatch("auth/authenticate")
          .then((response: Authentication) => {
              if (response && response.authenticated) {
                  // The app was entered regularly, so open the default view (depending on the given authorities)
                  if (to.path === "/" || to.path === "/login" || to.path === "/timeclock") {
                      if (isAuthorized("Timeclock")) {
                          // @ts-ignore
                          const orderId = store.state.auth.clockedInOrder;
                          if (orderId) {
                              next({
                                  name: "timeclockOrder",
                                  params: {orderId: orderId}
                              });
                          } else {
                              next("/timeclock");
                          }
                      } else {
                          // @ts-ignore
                          next({path: `/houroverview/${store.state.auth.authentication.employeeId}`});
                      }
                  } else {
                      if (isAuthorizedForRoute(to)) {
                          next();
                      } else {
                          next("/accessDenied");
                      }
                  }
              } else if (to.path !== "/login") {
                  next("/login");
              } else {
                  next();
              }
          });
    }
};

Vue.use(Router);

const router: Router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: "/timeclock"
        },
        {
            path: "/login",
            name: "Login",
            component: Login
        },
        {
            path: "/accessDenied",
            name: "AccessDenied",
            component: AccessDenied
        },
        {
            path: "/registrations",
            name: "FinishedRegistrations",
            component: FinishedRegistrations,
            meta: {
                header: {
                    widget: "Title",
                    title: "Registrierungen",
                    icon: "mdi-account-plus",
                    dynamicTitle: false
                }
            }
        },
        {
            path: "/register",
            name: "Registrations",
            component: Registrations
        },
        {
            path: "/register/:registrationUuid",
            component: Registration
        },
        {
            name: "Customer",
            path: "/customers",
            component: Customers,
            meta: {
                header: {
                    widget: "Title",
                    title: "Kunden",
                    icon: getCustomerFieldIcon("name"),
                    dynamicTitle: false
                }
            }
        },
        {
            path: "/customers/:customerId",
            name: "CustomerDetails",
            component: CustomerDetails,
            meta: {
                parentRoute: "Customer",
                header: {
                    widget: "Title",
                    icon: "mdi-account-voice",
                    dynamicTitle: true
                }
            },
            children: [
                {
                    name: "CustomerInfo",
                    path: "info",
                    component: CustomerDetailsInfo,
                    meta: {
                        parentRoute: "CustomerDetails",
                        header: {
                            widget: "Title",
                            icon: "mdi-account-voice",
                            dynamicTitle: true
                        }
                    }
                },
                {
                    name: "CustomerOrders",
                    path: "orders",
                    component: CustomerOrders,
                    meta: {
                        parentRoute: "CustomerDetails",
                        header: {
                            widget: "Title",
                            icon: "mdi-account-voice",
                            dynamicTitle: true
                        }
                    }
                }
            ]
        },
        {
            path: "/archive/:employeeId",
            component: Archive,
            meta: {
                authorities: ["Documentation_ViewDocuments"],
                header: {
                    widget: "Title",
                    title: "Archiv",
                    icon: "mdi-archive",
                    dynamicTitle: false
                }
            }
        },
        {
            name: "Timeclock",
            path: "/timeclock",
            component: Timeclock,
            meta: {
                header: {
                    widget: "Date"
                }
            }
        },
        {
            name: "timeclockOrder",
            path: "/timeclock/:orderId",
            component: TimeclockOrder,
            meta: {
                parentRoute: "Timeclock",
                header: {
                    widget: "DateAndTime",
                    title: "Stempeluhr",
                    icon: "clock",
                    extension: true
                }
            }
        },
        {
            name: "Accounting",
            path: "/accounting",
            component: AccountingEmployees,
            meta: {
                authorities: ["Accounting"],
                header: {
                    widget: "Title",
                    title: "Abrechnung",
                    icon: "mdi-wallet",
                    dynamicTitle: false
                }
            },
            children: [
                {
                    name: "AccountingEmployees",
                    path: "employees",
                    component: AccountingEmployees,
                    meta: {
                        header: {
                            widget: "Title",
                            title: "Abrechnung",
                            icon: "mdi-wallet",
                            dynamicTitle: false
                        }
                    }
                }
            ]
        },
        {
            name: "Inventories",
            path: "/inventories",
            component: Inventories,
            meta: {
                header: {
                    widget: "Title",
                    title: "Inventuren",
                    icon: "mdi-format-list-checks",
                    dynamicTitle: false
                }
            }
        },
        {
            name: "AccountingEmployee",
            path: "/accounting/employees/:employeeId",
            component: AccountingEmployee,
            meta: {
                parentRoute: "AccountingEmployees",
                authorities: ["Accounting"],
                header: {
                    widget: "Title",
                    title: "Abrechnung",
                    icon: "mdi-wallet",
                    dynamicTitle: false
                }
            },
            children: [
                {
                    name: "AccountingEmployeeWorklogs",
                    path: "worklogs",
                    component: AccountingEmployeeWorklogs,
                    meta: {
                        parentRoute: "AccountingEmployees",
                        header: {
                            widget: "Title",
                            title: "Abrechnung",
                            icon: "mdi-wallet",
                            dynamicTitle: false
                        }
                    },
                },
                {
                    path: "perdiems",
                    component: AccountingEmployeeTabPerDiem,
                    meta: {
                        parentRoute: "AccountingEmployees",
                        header: {
                            widget: "Title",
                            title: "Abrechnung",
                            icon: "mdi-wallet",
                            dynamicTitle: false
                        }
                    }
                }
            ]
        },
        {
            path: "/houroverview/:employeeId",
            component: HourOverview,
            meta: {
                header: {
                    widget: "MonthNavigation"
                }
            }
        },
        {
            path: "/clockingHistory",
            component: ClockingHistory
        },
        {
            name: "Employee",
            path: "/employees",
            component: Employees,
            meta: {
                header: {
                    widget: "Title",
                    title: "Mitarbeiter",
                    icon: "mdi-account",
                    dynamicTitle: false
                }
            }
        },
        {
            path: "/employees/:employeeId",
            name: "EmployeeDetails",
            component: EmployeeDetails,
            meta: {
                parentRoute: "Employee",
                header: {
                    widget: "Title",
                    icon: "mdi-account",
                    dynamicTitle: true
                }
            }
        },
        {
            name: "OrdersV2",
            path: "/v2/orders",
            component: OrdersV2,
            meta: {
                authorities: ["Order_Overview"],
                header: {
                    widget: "Title",
                    title: "Aufträge",
                    dynamicTitle: true
                }
            }
        },
        {
            name: "OrderNew",
            path: "/orders",
            component: Order,
            meta: {
                parentRoute: "Orders",
                authorities: ["Order_Overview"],
                header: {}
            },
            children: [
                {
                    name: "OrderNewInfo",
                    path: "new",
                    component: OrderInfo,
                    meta: {
                        parentRoute: "Orders",
                        header: {
                            widget: "Title",
                            dynamicTitle: true
                        }
                    }
                }
            ]
        },
        {
            name: "Order",
            path: "/orders/:orderId",
            component: Order,
            meta: {
                parentRoute: "Orders",
                authorities: ["Order_Overview"],
                header: {}
            },
            children: [
                {
                    name: "OrderInfo",
                    path: "info",
                    component: OrderInfo,
                    meta: {
                        parentRoute: "Orders",
                        header: {
                            widget: "Title",
                            dynamicTitle: true
                        }
                    }
                },
                {
                    name: "OrderWorklogs",
                    path: "worklogs",
                    component: OrderWorklogs,
                    meta: {
                        parentRoute: "Orders",
                        header: {
                            widget: "Title",
                            dynamicTitle: true
                        }
                    }
                },
                {
                    path: "assignments",
                    component: OrderAssignments,
                    meta: {
                        parentRoute: "Orders",
                        header: {
                            widget: "Title",
                            dynamicTitle: true
                        }
                    }
                },
                {
                    path: "perdiems",
                    component: OrderPerDiems,
                    meta: {
                        parentRoute: "Orders",
                        header: {
                            widget: "Title",
                            dynamicTitle: true
                        }
                    }
                },
                {
                    path: "operators",
                    component: OrderOperators,
                    meta: {
                        parentRoute: "Orders",
                        header: {
                            widget: "Title",
                            dynamicTitle: true
                        }
                    }
                },
                {
                    path: "editHistory",
                    component: OrderEditHistory,
                    meta: {
                        parentRoute: "Orders",
                        header: {
                            widget: "Title",
                            dynamicTitle: true
                        }
                    }
                }
            ]
        },
        {
            path: "/orders/:orderId/worklogs/:date",
            component: AccountingOrderDate,
            meta: {
                parentRoute: "previous",
                header: {
                    widget: "Date",
                    dynamicTitle: false
                }
            }
        },
        {
            path: "/orders/:orderId/perdiems/:date",
            component: OrderPerDiemsDate,
            meta: {
                parentRoute: "previous",
                header: {
                    widget: "Title",
                    dynamicTitle: true
                }
            }
        },
        {
            path: "/monthendclosing",
            component: MonthEndClosing,
            meta: {
                authorities: ["MonthEndClosing"],
                header: {
                    widget: "Title",
                    title: "Monatsabschluss",
                    icon: "mdi-bank",
                    dynamicTitle: false
                }
            }
        },
        {
            path: "/rights",
            component: Rights,
            meta: {
                header: {
                    widget: "Title",
                    title: "Rechtemanagement",
                    icon: "mdi-account-supervisor",
                    dynamicTitle: false
                }
            }
        },
        {
            path: "/rights/users",
            component: RightsUsers,
            meta: {
                authorities: ["Authorization_ViewUsers"],
                header: {
                    widget: "Title",
                    icon: "mdi-group",
                    dynamicTitle: true
                }
            }
        },
        {
            path: "/rights/user/:employeeId",
            name: "rightsUserDetails",
            component: RightsUserDetails,
            meta: {
                authorities: ["Authorization_ViewUsers"],
                header: {
                    widget: "Title",
                    icon: "mdi-group",
                    dynamicTitle: true
                }
            }
        },
        {
            path: "/rights/roles",
            component: RightsRoles,
            meta: {
                authorities: ["Authorization_ViewRoles"],
                header: {
                    widget: "Title",
                    icon: "mdi-group",
                    dynamicTitle: true
                }
            }
        },
        {
            path: "/rights/role/:roleId",
            name: "rightsRoleDetails",
            component: RightsRoleDetails,
            meta: {
                authorities: ["Authorization_ViewRoles"],
                header: {
                    widget: "Title",
                    icon: "mdi-group",
                    dynamicTitle: true
                }
            }
        },
        {
            path: "/rights/role/:roleId/users",
            name: "rightsRoleUsers",
            component: RightsUsers,
            meta: {
                authorities: ["Authorization_ViewRoles"],
                header: {
                    widget: "Title",
                    icon: "mdi-group",
                    dynamicTitle: true
                }
            }
        },
        {
            path: "/timecontrolling",
            component: TimeControlling,
            meta: {
                authorities: ["Superadmin"],
                header: {
                    widget: "Title",
                    title: "Zeitkontrolle",
                    icon: "mdi-timetable",
                    dynamicTitle: false
                }
            }
        },
        {
            path: "/search",
            component: TheHeaderSearchLookup
        },
        {
            name: "Statistic",
            path: "/statistic",
            component: Statistic,
            meta: {
                authorities: ["Statistic"],
                header: {
                    widget: "MonthNavigation"
                }
            }
        },
        {
            name: "StatisticOrder",
            path: "/statistic/:orderId",
            component: StatisticOrder,
            meta: {
                parentRoute: "Statistic",
                authorities: ["Statistic"],
                header: {
                    widget: "Title",
                    icon: "mdi-store",
                    dynamicTitle: true
                }
            }
        }
    ]
});
router.beforeEach(beforeEach);
export default router;
