
  import Vue from "vue";
  import Component from "vue-class-component";

  import Login from "@/views/Login/Login.vue";
  import TheHeader from "./components/TheHeader.vue";
  import TheMenu from "@/components/TheMenu.vue";
  import TheFooter from "./components/TheFooter.vue";
  import LoadingDialog from "@/components/LoadingDialog.vue";
  import {ProvideReactive, Watch} from "vue-property-decorator";
  import {Mutation} from "vuex-class";
  import {AuthGetters} from "@/store/modules/auth";
  import ConfirmDialog from "@/components/ConfirmDialog.vue";
  import {HeaderModule, HeaderMutations} from "@/store/modules/header";
  import {MessageTypes} from "@/components/message-types";
  import UserProfile from "@/views/profile/UserProfile.vue";
  import InfoDialog from "@/components/InfoDialog.vue";
  import store from "@/store/store";
  import ConfirmDialogComposition from "@/components/ConfirmDialogComposition.vue";

  @Component({
    components: {
      InfoDialog,
      ConfirmDialog,
      ConfirmDialogComposition,
      Login,
      TheHeader,
      TheMenu,
      TheFooter,
      LoadingDialog,
      UserProfile
    },
  })
  export default class App extends Vue {
    private loading = false;
    private headerTitle = "Zeiterfassung";
    private snackbar = false;
    private snackbarText = "";
    private isSnackbarError = false;
    private isSnackbarSuccess = false;
    private isSnackbarWarning = false;
    private showClockIcon = false;
    private showInfoIcon = false;
    private menu = false;
    private userProfileDialog = false;
    private keepAliveRoutes = [
      "TheHeaderSearchLookup"
    ];

    @Mutation(HeaderMutations.SET_MONTHPICKER_MONTH, { namespace: HeaderModule })
    private mutationSetMonthPickerMonth: any;

    @ProvideReactive("app-root") appRoot = this.$root;

    get snackbarColor (): string {
      let color = "";
      if (this.isSnackbarSuccess) {
        color = "primary";
      } else if (this.isSnackbarWarning) {
        color = "warning";
      }

      return color;
    }

    get isLoggedIn(): boolean {
      return store.getters["auth/" + AuthGetters.IS_LOGGED_IN];
    }

    get isFormPath (): boolean {
      return this.$router.currentRoute.path.startsWith("/register");
    }

    @Watch("$route")
    onRouteChanged (value: any) {
      this.showClockIcon = false;
      this.showInfoIcon = false;
      this.headerTitle = "";

      if (value.query.yearMonth) {
        const yearMonth = value.query.yearMonth;
        if (this.$moment(yearMonth, "YYYY-MM").isValid()) {
          this.mutationSetMonthPickerMonth(yearMonth);
        }
      }

      if (value.path === "/" || value.path === "/timeclock") {
        this.showClockIcon = true;
        this.headerTitle = "Zeiterfassung";
      } else if (value.path === "/overview") {
        this.headerTitle = "Stundenübersicht";
      } else if (value.path === "/info") {
        this.showInfoIcon = true;
        this.headerTitle = "Projekt-/Marktübersicht";
      } else if (value.path === "/login") {
        this.headerTitle = "GoodSigns - MobileApp";
      }
    }

    created () {
      this.$eventBus.$on(MessageTypes.LOAD, () => {
        this.loading = true;
      });

      this.$eventBus.$on("openUserProfile", () => {
        this.userProfileDialog = true;
      });

      this.$eventBus.$on(MessageTypes.LOADING_FINISHED, () => {
        this.loading = false;
      });

      this.$eventBus.$on(MessageTypes.SNACKBAR_ERROR, (message: string) => {
        this.showSnackbar(MessageTypes.SNACKBAR_ERROR, message);
      });

      this.$eventBus.$on(MessageTypes.SNACKBAR_SUCCESS, (message: string) => {
        this.showSnackbar(MessageTypes.SNACKBAR_SUCCESS, message);
      });

      this.$eventBus.$on(MessageTypes.SNACKBAR_WARNING, (message: string) => {
        this.showSnackbar(MessageTypes.SNACKBAR_WARNING, message);
      });
    }

    mounted () {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.menu = true;
      } else {
        this.menu = false;
      }

      // @ts-ignore
      this.$root.$confirmDialog = this.$refs.confirmDialog;
      // @ts-ignore
      this.$root.confirmDialogComposition = this.$refs.confirmDialogComposition;
    }

    menuClicked () {
      this.menu = !this.menu;
    }

    showSnackbar (type: string, message: string) {
      this.isSnackbarError = type === MessageTypes.SNACKBAR_ERROR;
      this.isSnackbarSuccess = type === MessageTypes.SNACKBAR_SUCCESS;
      this.isSnackbarWarning = type === MessageTypes.SNACKBAR_WARNING;

      this.snackbar = true;
      this.snackbarText = message;
    }
  }
