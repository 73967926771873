import axios, {AxiosResponse} from "axios";
import Authentication from "@/model/Authentication";

const PATH = "/auth";

export default {
  authenticate (): Promise<AxiosResponse<Authentication>> {
    return axios.get(`${PATH}`);
  },
  login (username: string, password: string): Promise<AxiosResponse<Authentication>> {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    return axios.post("/login", formData);
  },
  logout () {
    return axios.post("/logout");
  },
  updatePassword (currentPassword: string, newPassword: string): Promise<AxiosResponse<void>> {
    return axios.patch(`${PATH}/password`, {
      currentPassword: currentPassword,
      newPassword: newPassword
    });
  }
};
