import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalDialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c(VBtn,_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},{ ...tooltip }),[_c(VIcon,[_vm._v(" mdi-history ")])],1)]}}])},[_c('span',[_vm._v("Gehaltshistorie anzeigen")])])]},proxy:true},{key:"header-title",fn:function(){return [_vm._v(" Gehaltshistorie ")]},proxy:true},{key:"content",fn:function(){return _vm._l((_vm.sortedWages),function(item){return _c(VRow,{key:item.id},[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"2"}},[_vm._v(" Betrag: ")]),_c(VCol,{staticClass:"pb-0",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.wage))+" ")]),_c(VCol,{staticClass:"pb-0",attrs:{"cols":"7"}},[_vm._v(" Typ: "+_vm._s(_vm.$vuetify.lang.t("$vuetify.enum.wageType." + item.wageType))+" ")]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[(item.validTo != null)?_c('span',[_vm._v(" Von "+_vm._s(_vm._f("formatDate")(item.validFrom,"MMMM YYYY"))+" bis "+_vm._s(_vm._f("formatDate")(item.validTo,"MMMM YYYY"))+" ")]):_c('span',[_vm._v(" Seit "+_vm._s(_vm._f("formatDate")(item.validFrom,"MMMM YYYY"))+" ")])])],1)})},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }