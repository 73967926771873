
  import {defineComponent, onMounted, PropType, reactive, toRefs, watch} from "vue";
  import Customer from "@/model/Customer";
  import CustomerDetailsCard from "./CustomerDetailsCard.vue";
  import CustomerDetailsCardListEntry from "./CustomerDetailsCardListEntry.vue";
  import {getCustomerFieldIcon} from "@/views/Customers/CustomerUtils";
  import CustomersGeneralInformationForm, {FORM_ID} from "../forms/CustomersGeneralInformationForm.vue";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";

  class ListEntry {
    public label: string;
    public text: string;
    public icon?: string;
    public note?: string;
  }

  export default defineComponent({
    name: "CustomerDetailsCardGeneralInformation",
    components: {
      CustomersGeneralInformationForm,
      CustomerDetailsCardListEntry,
      CustomerDetailsCard,
    },
    props: {
      customer: {
        type: Object as PropType<Customer>,
        default: () => new Customer()
      },
      editable: {
        type: Boolean,
        default: false
      },
    },
    setup(props, context) {
      const initialState = {
        listEntries: [] as Array<ListEntry>
      };
      const state = reactive({...initialState});

      const formId = FORM_ID;

      const updateListEntries = (customer: Customer) => {
        state.listEntries = [
          {label: "Marktleiter", text: customer.storeManager, icon: getCustomerFieldIcon("storeManager")},
          {label: "Assistenz", text: customer.storeAssistant, icon: getCustomerFieldIcon("storeAssistant")},
          {label: "O&G", text: `${customer.og ? "Ja" : "Nein"}`, icon: getCustomerFieldIcon("og"), note: customer.notesOg},
          {label: "Floristik", text: `${customer.flowers ? "Ja" : "Nein"}`, icon: getCustomerFieldIcon("flowers"), note: customer.notesFlowers},
          {label: "Lager", text: `${customer.storage ? "Ja" : "Nein"}`, icon: getCustomerFieldIcon("storage"), note: customer.notesStorage},
          {label: "Extra GM", text: `${customer.drinks ? "Ja" : "Nein"}`, icon: getCustomerFieldIcon("drinks"), note: customer.notesDrinks},
          {label: "Soll/IST", text: `${customer.targetActualComparison ? "Ja" : "Nein"}`, icon: getCustomerFieldIcon("targetActualComparison")},
          {label: "Etiketten", text: `${customer.labels ? "Ja" : "Nein"}`, icon: getCustomerFieldIcon("labels")},
          {label: "Türcode", text: customer.doorcode, icon: getCustomerFieldIcon("doorcode")},
          {label: "Sonstiges", text: customer.additionalInformation, icon: getCustomerFieldIcon("additionalInformation")},
          {label: "Warenwert", text: customer.valueFormatted, icon: getCustomerFieldIcon("valueFormatted")},
          {label: "Positionen", text: customer.positions?.toString(), icon: getCustomerFieldIcon("positions")}
        ];
      };

      const openNote = (listEntry: ListEntry) => {
        eventBus.$emit(MessageTypes.INFO, `Notizen ${listEntry.label}`, listEntry.note);
      };

      onMounted(() => {
        updateListEntries(props.customer);
      });

      watch(() => props.customer, (newValue) => {
        updateListEntries(newValue);
      });

      return {
        ...toRefs(state),
        formId,
        openNote
      };
    }
  });
