import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.items.length > 0)?_c(VList,{attrs:{"three-line":""}},[_c(VSubheader,[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VListItem,{staticClass:"list-tile-select-all"},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.t("selectAll"))+" ")])],1),_vm._t("header")],2),_vm._l((_vm.items),function(item,index){return [_c(VListItem,{key:item.id},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.name(item))+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.statusMessage(item))+" ")]),_c(VListItemSubtitle,{key:`validation-${index}`,staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.errorMessage(item))+" ")])],1),_vm._t("default",null,{"item":item})],2)]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }