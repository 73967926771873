
  import Vue from "vue";
  import Component from "vue-class-component";
  import {Getter, Mutation} from "vuex-class";
  import {HeaderGetters, HeaderModule, HeaderMutations} from "@/store/modules/header";
  import {Watch} from "vue-property-decorator";

  @Component
  export default class TheHeaderMonthPicker extends Vue {
    private menu = false;
    private month = "";

    @Getter(HeaderGetters.GET_MONTH, { namespace: HeaderModule })
    private headerMonth: string;

    @Mutation(HeaderMutations.SET_MONTHPICKER_MONTH, { namespace: HeaderModule })
    private mutationSetMonthPickerMonth: any;

    get maxDate () {
      return this.$moment().format("YYYY-MM");
    }

    mounted () {
      if (!this.month) {
        this.month = this.headerMonth;
      }
    }

    @Watch("month")
    onMonthSelected (newMonth: string) {
      this.mutationSetMonthPickerMonth(newMonth);
    }

    @Watch("headerMonth")
    onHeaderMonthUpdated (newMonth: string) {
      if(this.month != newMonth) {
        this.month = newMonth;
      }
    }

    previousMonth () {
      const month = this.$moment(this.month).subtract(1, "months").format("YYYY-MM");
      this.month = month;
    }

    nextMonth () {
      const month = this.$moment(this.month).add(1, "months").format("YYYY-MM");
      this.month = month;
    }
  }
