import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"d-flex flex-column",attrs:{"height":"100%","hover":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_vm._t("default")],2),_c(VSpacer),_c(VCardActions,[_vm._t("actions")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }