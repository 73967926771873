import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"5"}},[_c('CustomerDetailsCardContact',{attrs:{"customer":_vm.customer,"editable":_vm.editable},on:{"update":function($event){return _vm.$emit('update', $event)}}}),_c('div',{staticClass:"pt-6"},[_c('CustomerDetailsCardSpecificInformation',{attrs:{"customer":_vm.customer,"customer-types":_vm.customerTypes,"editable":_vm.editable},on:{"update":function($event){return _vm.$emit('update', $event)}}})],1),_c('div',{staticClass:"pt-6"},[_c('CustomerDetailsCardTravelTimes',{attrs:{"customer":_vm.customer,"customer-types":_vm.customerTypes,"editable":_vm.editable},on:{"update":function($event){return _vm.$emit('update', $event)}}})],1)],1),_c(VCol,{attrs:{"cols":"12","lg":"5"}},[_c('CustomerDetailsCardGeneralInformation',{attrs:{"customer":_vm.customer,"editable":_vm.editable},on:{"update":function($event){return _vm.$emit('update', $event)}}})],1)],1),(_vm.customer.active)?_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"10"}},[_c(VRow,{attrs:{"justify":"end"}},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.deleteCustomer}},[_vm._v(" "+_vm._s(_vm.t("delete"))+" ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }