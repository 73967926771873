import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VList,{attrs:{"three-line":""}},[_c(VDivider),_vm._l((_vm.items),function(item){return [_c(VListItem,{key:item.id,attrs:{"to":{ path: `/customers/${item.id}/info`}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,[_c(VRow,[_c(VCol,{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.addressAsString)+" ")])],1)],1)],1),_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-right ")])],1)],1)]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }