import Vue from "vue";
import Vuex, {ActionTree, GetterTree, MutationTree, StoreOptions} from "vuex";
import {auth} from "@/store/modules/auth";
import {header} from "@/store/modules/header";
import {timeclock} from "@/store/modules/timeclock";
import {RootState} from "@/store/types";
import DefaultService from "@/service/DefaultService";
import moment from "moment";
import {EmploymentTypes, OrderTypes} from "@/enums";
import EmployeeService from "@/service/EmployeeService";
import {registration} from "@/store/modules/registration";

Vue.use(Vuex);

export const GeneralGetters = Object.freeze({
  GET_EARLIEST_VALID_FROM: "getEarliestValidFrom",
  GET_EMPLOYEE_SUMMARY_BY_ID: "getEmployeeSummaryById"
});

const getters: GetterTree<RootState, RootState> = {
  [GeneralGetters.GET_EARLIEST_VALID_FROM]: (state) => {
    return state.general.earliestValidFrom;
  },
  [GeneralGetters.GET_EMPLOYEE_SUMMARY_BY_ID]: (state) => (employeeId: number) => {
    employeeId = Number(employeeId);
    const matchingEmployees = state.employees.filter((employee: any) => employee.id === employeeId);
    if (!employeeId || matchingEmployees.length > 1) {
    } else if (matchingEmployees.length === 1) {
      return matchingEmployees[0];
    } else {
      return null;
    }
  },
  enumOrderType: () => {
    return OrderTypes;
  },
  enumEmploymentType: () => {
    return EmploymentTypes;
  }
};

export const Mutations = Object.freeze({
  SET_EARLIEST_VALID_FROM: "setEarliestValidFrom",
  PUT_EMPLOYEE: "putEmployee"
});

const mutations: MutationTree<RootState> = {
  [Mutations.SET_EARLIEST_VALID_FROM] (state, earliestValidFrom: string) {
    state.general.earliestValidFrom = earliestValidFrom;
  },
  [Mutations.PUT_EMPLOYEE] (state, employee: any) {
    const index = state.employees.findIndex((storedEmployee: any) => storedEmployee.id === employee.id);
    if (index !== -1) {
      state.employees[index] = employee;
    } else {
      state.employees.push(employee);
    }
  }
};

export const Actions = Object.freeze({
  GET_EARLIEST_VALID_FROM: "getEarliestValidFrom",
  GET_EMPLOYEE_SUMMARY_BY_ID: "getEmployeeSummaryById"
});

const actions: ActionTree<RootState, RootState> = {
  [Actions.GET_EARLIEST_VALID_FROM] ({ commit }) {
    DefaultService.getDefaults()
      .then(response => {
        commit(Mutations.SET_EARLIEST_VALID_FROM, response.data.earliestValidFrom);
      });
  },
  [Actions.GET_EMPLOYEE_SUMMARY_BY_ID] ({ commit, getters }, params) {
    const employee = getters.getEmployeeSummaryById(params.employeeId);
    if (employee == null || params.forceUpdate) {
      EmployeeService.getEmployeeSummaryById(params.employeeId)
        .then(response => {
          commit(Mutations.PUT_EMPLOYEE, response.data);
        });
    }
  }
};

const store: StoreOptions<RootState> = {
  modules: {
    auth,
    header,
    registration,
    timeclock
  },
  state: {
    general: {
      earliestValidFrom: moment().format("YYYY-MM-01")
    },
    employees: []
  },
  actions,
  mutations,
  getters
};

const vuexStore = new Vuex.Store<RootState>(store);

export function useStore() {
  return store;
}

export default vuexStore;
