import axios, {AxiosResponse} from "axios";

export interface Defaults {
  earliestValidFrom: string;
}

export default {
  getDefaults(): Promise<AxiosResponse<Defaults>> {
    return axios.get("defaults");
  }
};
