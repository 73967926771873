
  import {defineComponent, onMounted, reactive, toRefs} from "vue";
  import {MessageTypes} from "@/components/message-types";
  import eventBus from "@/eventbus";

  export default defineComponent({
    name: "InfoDialog",
    setup(props, context) {
      const initialState = {
        dialog: false,
        title: "",
        text: "",
      };

      const state = reactive({...initialState});

      onMounted(() => {
        eventBus.$on(MessageTypes.INFO, (title: string, text: string) => {
          state.dialog = true;
          state.title = title;
          state.text = text;
        });
      });

      return {
        ...toRefs(state)
      };
    }
  });
