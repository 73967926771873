
  import {computed, defineComponent, onMounted, reactive, toRefs} from "vue";
  import EmployeeCreateDialog from "@/views/Employee/create/EmployeeCreateDialog.vue";
  import EmployeesList from "./EmployeesList.vue";
  import EmployeesTable from "./EmployeesTable.vue";
  import FabButton from "@/components/FabButton.vue";
  import SwitchView from "@/components/SwitchView.vue";
  import {MessageTypes} from "@/components/message-types";
  import eventBus from "@/eventbus";
  import {isAuthorized} from "@/mixin/mixins";

  export default defineComponent({
    name: "Employees",
    components: {
      EmployeeCreateDialog,
      EmployeesList,
      EmployeesTable,
      FabButton,
      SwitchView
    },
    setup(props, context) {
      const initialState = {
        scrolledToBottom: false
      };

      const state = reactive({...initialState});

      const isAllowedToCreateEmployee = computed(() => {
        return isAuthorized("Employee_Create_Create");
      });

      const openCreateEmployeeDialog = () => {
        eventBus.$emit(MessageTypes.OPEN_EMPLOYEE_CREATE);
      };

      const scroll = () => {
        window.onscroll = () => {
          state.scrolledToBottom = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;
        };
      };

      onMounted(() => {
        scroll();
      });

      return {
        ...toRefs(state),
        isAllowedToCreateEmployee,
        openCreateEmployeeDialog
      };
    }
  });
