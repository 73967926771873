
  import Vue from "vue";
  import { MessageTypes } from "@/components/message-types";

  export default Vue.extend({
    name: "RegistrationCard",
    props: {
      previousButton: {
        type: Boolean,
        default: true
      },
      nextButton: {
        type: Boolean,
        default: true
      }
    },
    data: () => ({
      loading: false
    }),
    mounted () {
      this.$eventBus.$on(MessageTypes.LOAD, () => {
        this.loading = true;
      });

      this.$eventBus.$on(MessageTypes.LOADING_FINISHED, () => {
        this.loading = false;
      });
    }
  });
