
  import Vue from "vue";
  import TheHeaderDatePicker from "@/components/TheHeaderDatePicker.vue";
  import TheHeaderTimePicker from "@/components/TheHeaderTimePicker.vue";
  import TheHeaderMonthPicker from "@/components/TheHeaderMonthPicker.vue";
  import {AuthActions, AuthGetters} from "@/store/modules/auth";
  import store from "@/store/store";

  export default Vue.extend({
    name: "TheHeader",
    components: {
      TheHeaderDatePicker,
      TheHeaderTimePicker,
      TheHeaderMonthPicker
    },
    props: {
      showClockIcon: {
        type: Boolean,
        default: false
      },
      showInfoIcon: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ""
      }
    },
    computed: {
      isShowAppAvatar (): boolean {
        return this.$vuetify.breakpoint.smAndUp;
      },
      isShowAppTitle (): boolean {
        return this.$vuetify.breakpoint.smAndUp;
      },
      isShowRouteTitle (): boolean {
        if (this.$route.meta != null && this.$route.meta.header != null) {
          return this.$route.meta.header.widget === "Title";
        } else {
          return false;
        }
      },
      isShowDate (): boolean {
        if (this.$route.meta != null && this.$route.meta.header != null) {
          return this.$route.meta.header.widget === "Date";
        } else {
          return false;
        }
      },
      isShowDateAndTime (): boolean {
        if (this.$route.meta != null && this.$route.meta.header != null) {
          return this.$route.meta.header.widget === "DateAndTime";
        } else {
          return false;
        }
      },
      isShowMonthNavigation (): boolean {
        if (this.$route.meta != null && this.$route.meta.header != null) {
          return this.$route.meta.header.widget === "MonthNavigation";
        } else {
          return false;
        }
      },
      getHeaderTitle (): string {
        if (this.$route.meta && this.$route.meta.header && this.$route.meta.header.dynamicTitle) {
          // @ts-ignore
          return store.state.header.title;
        } else {
          return this.$route?.meta?.header.title;
        }
      },
      getHeaderIcon (): string {
        if (this.$route.meta != null && this.$route.meta.header != null) {
          return this.$route.meta.header.icon;
        } else {
          return "";
        }
      },
      fullName (): string {
        return this.$store.getters["auth/" + AuthGetters.GET_FULL_NAME];
      }
    },
    methods: {
      menuClicked () {
        this.$emit("menuClicked");
      },
      logout () {
        this.$store.dispatch("auth/" + AuthActions.LOGOUT)
          .then(() => {
            this.$router.push("/login");
          });
      },
      openSearch () {
        this.$router.push("/search");
      },
      openUserProfile () {
        this.$eventBus.$emit("openUserProfile");
      }
    }
  });
