
  import {computed, defineComponent, onMounted, reactive, toRefs, watch} from "vue";
  import {t} from "@/mixin/mixins";
  import store from "@/store/store";
  import {HeaderGetters} from "@/store/modules/header";
  import {EmployeeStatisticPerformance} from "@/model/EmployeeStatisticPerformance";
  import PageUtils from "@/components/PageUtils";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import StatisticService from "@/service/StatisticService";
  import router from "@/router";
  import moment from "moment/moment";

  export default defineComponent({
    name: "StatisticPerformance",
    setup() {
      const initialState = {
        yearMonth: null as unknown as string,
        performances: new Array<EmployeeStatisticPerformance>()
      };
      const state = reactive({...initialState});

      const headers = [
        {
          text: t("statistic.performance.table.header.employeeId"),
          value: "employeeId"
        },
        {
          text: t("statistic.performance.table.header.employeeName"),
          value: "employeeName"
        },
        {
          text: t("statistic.performance.table.header.companyLocation"),
          value: "companyLocation"
        },
        {
          text: t("statistic.performance.table.header.workingMinutes"),
          value: "workingMinutes"
        },
        {
          text: t("statistic.performance.table.header.positions"),
          value: "positions"
        },
        {
          text: t("statistic.performance.table.header.positionsPerHour"),
          value: "positionsPerHour"
        },
        {
          text: t("statistic.performance.table.header.standardDeviation"),
          value: "standardDeviation"
        },
        {
          text: t("statistic.performance.table.header.median"),
          value: "median"
        }
      ];

      onMounted(() => {
        state.yearMonth = moment().format("YYYY-MM");
      });

      const selectedMonth = computed(() => store.getters["header/" + HeaderGetters.GET_MONTH]);

      watch(() => selectedMonth, () => {
        state.yearMonth = selectedMonth.value;
      }, {deep: true});

      watch(() => state.yearMonth, () => {
        loadStatisticPerformances();
      }, {deep: true});

      const loadStatisticPerformances = () => {
        if (state.yearMonth) {
          StatisticService.getEmployeePerformances(state.yearMonth)
            .then(response => {
              const performances: Array<EmployeeStatisticPerformance> = PageUtils.extractCollection(response.data, "performances");
              state.performances = calcPerformances(performances);
            })
            .catch(() => {
              eventBus.$emit(MessageTypes.ERROR, t("errors.generalError"));
            });
        }
      };

      const calcPerformances = (performances: Array<EmployeeStatisticPerformance>) => {
        return performances.map(p => {
          p.positionsPerHour = positionsPerHour(p.workingMinutes, p.positions);
          p.standardDeviation = p.standardDeviation ? Math.round(p.standardDeviation * 100) / 100 : 0;
          p.median = p.median ? Number.parseFloat(p.median.toFixed(2)) : 0;
          return p;
        });
      };

      const positionsPerHour = (minutes: number | undefined, positions: number | undefined) => {
        if (minutes && minutes > 0) {
          return Math.round(positions ? positions / (minutes / 60) : 0);
        } else {
          return 0;
        }
      };

      const toTimeFormat = (minutes: number) => {
        const h = Math.floor(minutes / 60);
        const m = minutes % 60;
        const hour = h < 10 ? "0" + h : h;
        const minute = m < 10 ? "0" + m : m;
        return hour + ":" + minute;
      };

      const openCountingPerformance = (employeeId: number) => {
        router.push({
          path: "inventories",
          query: {
            tab: "1",
            employeeId: employeeId.toString()
          }
        });
      };

      return {
        ...toRefs(state),
        headers,
        positionsPerHour,
        toTimeFormat,
        openCountingPerformance
      };
    }
  });
