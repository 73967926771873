
  import Vue from "vue";
  import RegistrationStepper from "@/views/Registration/RegistrationStepper.vue";
  import {AxiosError} from "axios";
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import {RegistrationActions} from "@/store/modules/registration";
  import store from "@/store/store";

  export default Vue.extend({
    name: "Registration",
    components: {
      RegistrationCard,
      RegistrationStepper
    },
    data: () => ({
      loading: true,
      registrationFinished: false
    }),
    computed: {
      registrationUuid (): string {
        return this.$route.params.registrationUuid;
      }
    },
    mounted () {
      this.loading = true;

      store.dispatch(RegistrationActions.GET_REGISTRATION, this.registrationUuid)
        .catch((error: AxiosError) => {
          if (error && error.response) {
            if (error.response.status === 404) {
              this.$router.push("/register");
            } else if (error.response.status === 410) {
              this.registrationFinished = true;
            }
          }
        }).finally(() => {
          this.loading = false;
        });
    }
  });
