import { MessageTypes } from "@/components/message-types";
import Order from "@/model/Order";
import eventBus from "@/eventbus";

export function getChecklistStatusColor (order: Order): string {
  if (order.checklist?.every(entry => entry.done)) {
    return "green";
  } else {
    const beforeInventoryDone = order.checklist?.filter(entry => entry.code === "10" || entry.code === "11")
      .every(entry => entry.done);
    return beforeInventoryDone ? "yellow" : "red";
  }
}

export function openChecklist (order: Order): void {
  eventBus.$emit(MessageTypes.OPEN_ORDER_CHECKLIST_DETAILS, order.id);
}
