
  import {computed, defineComponent, onMounted, reactive, Ref, toRefs} from "vue";
  import ModalDialog from "@/components/ModalDialog.vue";
  import {MessageTypes} from "@/components/message-types";
  import {MarketAreaCategory} from "@/model/MarketArea/MarketAreaCategory";
  import InventoriesMarketAreasFormCard from "./InventoriesMarketAreasFormCard.vue";
  import InventoriesMarketAreaCategoriesGeneralForm from "../forms/InventoriesMarketAreaCategoriesGeneralForm.vue";
  import {MarketArea} from "@/model/MarketArea/MarketArea";
  import InventoriesService from "@/service/InventoriesService";
  import {AxiosError} from "axios";
  import ValidationError from "@/model/ValidationError";
  import eventBus from "@/eventbus";
  import {helpers, required} from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import {t} from "@/mixin/mixins";

  export default defineComponent({
    name: "InventoriesMarketAreaCategoriesDialog",
    components: {
      InventoriesMarketAreasFormCard,
      InventoriesMarketAreaCategoriesGeneralForm,
      ModalDialog
    },
    setup() {
      const state = reactive({
        dialog: false,
        edit: false,
        marketArea: {} as MarketArea,
        marketAreaCategory: {} as MarketAreaCategory,
        oldMarketAreaCategoryName: "",
        serverErrors: new Map<string, string>()
      });

      onMounted(() => {
        eventBus.$on(MessageTypes.OPEN_MARKET_AREA_CATEGORY_DIALOG, (editedMarketAreaCategory: MarketAreaCategory, marketArea: MarketArea) => {
          state.marketArea = marketArea;

          if (editedMarketAreaCategory) {
            state.edit = true;
            state.marketAreaCategory = {...editedMarketAreaCategory};
            if(state.marketAreaCategory.name) state.oldMarketAreaCategoryName = state.marketAreaCategory.name;
          } else {
            state.edit = false;
            state.marketAreaCategory = {} as MarketAreaCategory;
            state.marketAreaCategory.id = -1;
            state.marketAreaCategory.marketArea = state.marketArea;
            state.oldMarketAreaCategoryName = "";
          }
          state.dialog = true;
        });
      });

      const onMarketAreaCategoryChanged = (newMarketAreaCategory: MarketAreaCategory) => {
        state.marketAreaCategory = newMarketAreaCategory;
      };

      const save = () => {
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          if (!state.marketAreaCategory.name || !state.marketAreaCategory.name.trim()) state.marketAreaCategory.name = null;

          if (state.marketAreaCategory.id == -1) {
            InventoriesService.createMarketAreaCategory(state.marketAreaCategory)
              .then(response => {
                const newMarketAreaCategory = response.data;
                eventBus.$emit(MessageTypes.MARKET_AREA_CATEGORY_CREATED, newMarketAreaCategory);
                closeDialog();
                eventBus.$emit(MessageTypes.SUCCESS, t("inventories.marketAreaCategories.message.success.create"), 1000);
              })
              .catch((error: AxiosError) => {
                if (error && error.response && error.response.status === 422) {
                  eventBus.$emit(MessageTypes.WARNING, t("checkInput"));
                  const errorMap = new Map<string, string>();
                  // @ts-ignore
                  error.response.data.forEach((error: ValidationError) => {
                    errorMap.set(error.path, error.message);
                  });
                  state.serverErrors = errorMap;
                } else {
                  eventBus.$emit(MessageTypes.ERROR, t("inventories.marketAreaCategories.message.error.create"));
                }
              });
          } else {
            InventoriesService.updateMarketAreaCategory(state.marketAreaCategory)
              .then(response => {
                const newMarketAreaCategory = response.data;
                eventBus.$emit(MessageTypes.MARKET_AREA_CATEGORY_CREATED, newMarketAreaCategory);
                closeDialog();
              })
              .catch((error: AxiosError) => {
                if (error && error.response && error.response.status === 422) {
                  eventBus.$emit(MessageTypes.WARNING, t("checkInput"));
                  const errorMap = new Map<string, string>();
                  // @ts-ignore
                  error.response.data.forEach((error: ValidationError) => {
                    errorMap.set(error.path, error.message);
                  });
                  state.serverErrors = errorMap;
                } else {
                  eventBus.$emit(MessageTypes.ERROR, t("inventories.marketAreaCategories.message.error.create"));
                }
              });
          }
        }
      };

      const closeDialog = () => {
        state.dialog = false;
        state.marketAreaCategory = {} as MarketAreaCategory;
        state.oldMarketAreaCategoryName = "";
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
      };

      const rules = computed(() => ({
        marketAreaCategory: {
          name: {
            required: helpers.withMessage(t("inventories.marketAreaCategories.validation.name"), required),
          }
        }
      }));

      // @ts-ignore
      const v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (state.serverErrors.size > 0) {
          return state.serverErrors;
        } else {
          const model = v$.value.marketAreaCategory;
          if (model.$dirty) {
            if(model.name.$error) {
              errors.set("name", model.name.$errors[0].$message);
            }
          }
        }
        return errors;
      });

      return {
        ...toRefs(state),
        onMarketAreaCategoryChanged,
        save,
        closeDialog,
        errors
      };
    }
  });
