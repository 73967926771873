import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pa-2"},[_c(VRow,[_c(VCol,[_c('div',{staticClass:"headline"},[_vm._v(" Dokumentenübersicht ")])])],1),_c(VRow,[_c(VCol,[_c(VExpansionPanels,{attrs:{"flat":"","hover":""}},_vm._l((_setup.state.folders),function(folder){return _c(VExpansionPanel,{key:folder.name},[_c(VExpansionPanelHeader,[_c(VRow,{attrs:{"justify":"start","align":"center"}},[_c(VIcon,{staticClass:"mr-4"},[_vm._v(" mdi-folder ")]),_vm._v(" "+_vm._s(_setup.formatFolderName(folder.name))+" ")],1)],1),_c(VExpansionPanelContent,[_c(VList,{class:{'pl-5' : _vm.$vuetify.breakpoint.lgAndUp}},_vm._l((folder.files),function(file){return _c(VListItem,{key:file.fileName,on:{"click":function($event){return _setup.download(file)}}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-file-document-box ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_setup.formatTitle(file).title)+" ")]),(_vm.$vuetify.breakpoint.mdAndDown)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_setup.formatTitle(file).subtitle)+" ")]):_vm._e()],1)],1)}),1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }