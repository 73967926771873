import eventBus from "@/eventbus";
import {MessageTypes} from "@/components/message-types";

export const loading = (loadingIdentifier = "", dialog = false, message = "") => {
  eventBus.$emit(MessageTypes.LOADING, loadingIdentifier, dialog, message);
};

export const loadingFinished = (loadingIdentifier = "") => {
  eventBus.$emit(MessageTypes.LOADING_FINISHED, loadingIdentifier);
};

export const success = (message: string) => {
  eventBus.$emit(MessageTypes.SUCCESS, message);
};
