import moment from "moment";
import {computed, ComputedRef} from "vue";

export function isValidDate(value: string): boolean {
  return moment(value, "YYYY-MM-DD", true).isValid();
}

export function isValidMonth(value: string): boolean {
  return moment(value, "YYYY-MM", true).isValid();
}

export const today: ComputedRef<string> = computed(() => {
  return moment().format("YYYY-MM-DD");
});

export function yearMonth(value: string) {
  return moment(value, "YYYY-MM");
}

export default {
  today(): string {
    return moment().format("YYYY-MM-DD");
  },
};
