
  import {computed, defineComponent, onMounted, onUnmounted, reactive, toRefs, watch} from "vue";
  import {isAuthorized} from "@/mixin/mixins";
  import EmployeeName from "@/model/EmployeeName";
  import EmployeeService from "@/service/EmployeeService";
  import {employeeId} from "@/RouterUtils";
  import SwitchView from "@/components/SwitchView.vue";
  import MonthPicker from "@/components/MonthPicker.vue";
  import {date, earliestValidFrom} from "@/store/StoreUtils";
  import moment from "moment";
  import {useRoute} from "vue-router/composables";
  import PageUtils from "@/components/PageUtils";
  import PerDiem from "@/model/PerDiem";
  import Worklog from "@/model/Worklog";
  import router from "@/router";
  import {yearMonth} from "@/DateUtils";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";

  export default defineComponent({
    name: "AccountingEmployee",
    components: {
      MonthPicker,
      SwitchView
    },
    setup() {
      const initialState = {
        active: null as unknown as string,
        employee: null as unknown as EmployeeName,
        loading: false,
        perDiems: new Array<PerDiem>(),
        worklogs: new Array<Worklog>(),
        yearMonth: null as unknown as string,
        maxYearMonth: moment().format("YYYY-MM")
      };

      const state = reactive({...initialState});

      const route = useRoute();

      const fullName = computed(() => {
        return state.employee ? state.employee.firstName + " " + state.employee.lastName : "";
      });

      const isEditable = computed(() => {
        return yearMonth(state.yearMonth).isSameOrAfter(yearMonth(earliestValidFrom.value));
      });

      watch(() => state.active, (newTab, oldTab) => {
        // oldTab can only be null in the first invocation and is triggered after mounted()
        if(oldTab == null) {
          load();
        } else if(newTab == "perdiems") {
          if (state.perDiems.length == 0 || !yearMonth(state.perDiems[0].date).isSame(yearMonth(state.yearMonth))) {
            // In case we jump directly into the perdiems tab without first loading any worklogs
            if(state.worklogs.length == 0) {
              load();
            } else {
              state.loading = true;
              loadPerDiems()
                .finally(() => {
                  state.loading = false;
                });
            }
          }
        }
      });

      const dateChanged = () => {
        load();

        router.push({ query: Object.assign({}, router.currentRoute.query, { yearMonth: state.yearMonth })});
      };

      const load = () => {
        state.loading = true;

        if(state.active == "worklogs") {
          loadWorklogs()
            .finally(() => {
              state.loading = false;
            });
        } else if(state.active == "perdiems") {
          Promise.all([loadWorklogs(), loadPerDiems()])
            .finally(() => {
              state.loading = false;
            });
        }
      };

      const loadPerDiems = () => {
        return EmployeeService.getEmployeePerDiemsV2(employeeId.value, state.yearMonth)
          .then((response: any) => {
            state.perDiems = PageUtils.extractCollection(response.data, "perdiems");
          });
      };

      const loadWorklogs = () => {
        return EmployeeService.getEmployeeWorklogsV2(employeeId.value, state.yearMonth)
          .then(response => {
            state.worklogs = PageUtils.extractCollection(response.data, "worklogs");
          });
      };

      onMounted(() => {
        if(route.query.yearMonth && yearMonth(route.query.yearMonth as string).isValid()) {
          state.yearMonth = route.query.yearMonth as string;
        } else {
          state.yearMonth = moment().format("YYYY-MM");
        }

        EmployeeService.getEmployeeV2(employeeId.value)
          .then(response => {
            state.employee = response.data;
          });

        eventBus.$on(MessageTypes.PERDIEM_UPDATED, () => load());

        eventBus.$on(MessageTypes.WORKLOG_CREATED, () => {
          load();
        });

        eventBus.$on(MessageTypes.WORKLOG_DELETED, () => {
          load();
        });

        eventBus.$on(MessageTypes.WORKLOG_UPDATED, () => {
          load();
        });

        onUnmounted(() => {
          eventBus.$off(MessageTypes.WORKLOG_CREATED);
          eventBus.$off(MessageTypes.DELETE_WORKLOG);
          eventBus.$off(MessageTypes.WORKLOG_DELETED);
          eventBus.$off(MessageTypes.WORKLOG_UPDATED);
          eventBus.$off(MessageTypes.PERDIEM_UPDATED, () => load());
        });
      });

      return {
        ...toRefs(state),
        date,
        dateChanged,
        fullName,
        isAuthorized,
        isEditable
      };
    }
  });
