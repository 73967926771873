
  import {defineComponent, onMounted, PropType, reactive, toRefs} from "vue";
  import CustomerOrdersUpcoming from "@/views/Customers/orders/CustomerOrdersUpcoming.vue";
  import CustomerOrdersPast from "@/views/Customers/orders/CustomerOrdersPast.vue";
  import {customerId} from "@/RouterUtils";
  import CustomerService from "@/service/CustomerService";
  import PageUtils from "@/components/PageUtils";
  import router from "@/router";
  import Customer from "@/model/Customer";
  import PlanOrderDialog from "@/views/Customers/orders/PlanOrderDialog.vue";
  import CustomerOrderDate from "@/views/Customers/model/CustomerOrderDate";

  export default defineComponent({
    name: "CustomerOrders",
    components: {
      PlanOrderDialog,
      CustomerOrdersUpcoming,
      CustomerOrdersPast
    },
    props: {
      customer: {
        type: Object as PropType<Customer>,
        default: () => new Customer(),
      }
    },
    setup(props, context) {

      const initialState = {
        panel: [0, 1],
        loading: false,
        past: [],
        upcoming: [],
        planOrderDialog: false,
        pastOrdersAvgPos: 0
      };
      const state = reactive({...initialState});

      const newOrder = () => {
        router.push({ path: `/orders/new?customerId=${customerId.value}` });
      };
      const planOrder = () => {
        state.planOrderDialog = true;
      };
      const savePlanOrder = (positions: number) => {
        if (positions) {
          router.push({ path: `/v2/orders?customerId=${customerId.value}&positions=${positions}` });
          state.planOrderDialog = false;
        }
      };
      const closePlanOrder = () => {
        state.planOrderDialog = false;
      };

      onMounted(() => {
        state.loading = true;
        CustomerService.getOrders(customerId.value)
          .then(response => {
            state.past =  PageUtils.extractCollection(response.data, "past");
            state.upcoming =  PageUtils.extractCollection(response.data, "upcoming");
          })
          .finally(() =>  state.loading = false);
      });

      const pastOrdersAvgPosChanged = (pastOrdersAvgPos: number) => {
        state.pastOrdersAvgPos = pastOrdersAvgPos;
      };

      return {
        ...toRefs(state),
        customerId,
        newOrder,
        planOrder,
        savePlanOrder,
        closePlanOrder,
        pastOrdersAvgPosChanged
      };
    }
  });
