
  import Vue from "vue";
  import RegistrationService from "@/service/RegistrationService";
  import {MessageTypes} from "@/components/message-types";
  import {t} from "@/mixin/mixins";

  enum UploadStatus {
    NOT_STARTED,
    IN_PROGRESS,
    SUCCESS,
    ERROR
  }

  declare interface ComponentData {
    file: File | null;
    status: UploadStatus;
    fileTooBig: boolean
  }

  export default Vue.extend({
    name: "RegistrationFileUpload",
    props: {
      clearFileAfterUpload: {
        type: Boolean,
        default: () => false
      },
      context: {
        type: String,
        required: true,
        validator(value: string) {
          // The value must match one of these strings
          return ["citizenship", "disability", "health_insurance", "misc"].indexOf(value) !== -1;
        }
      },
      errorMessage: {
        type: String
      },
    },
    data(): ComponentData {
      return {
        file: null,
        status: UploadStatus.NOT_STARTED,
        fileTooBig: false
      };
    },
    computed: {
      registrationUuid(): string {
        return this.$route.params.registrationUuid;
      },
      statusNotStarted(): boolean {
        return this.status === UploadStatus.NOT_STARTED;
      },
      statusInProgress(): boolean {
        return this.status === UploadStatus.IN_PROGRESS;
      },
      statusSuccess(): boolean {
        return this.status === UploadStatus.SUCCESS;
      },
      statusError(): boolean {
        return this.status === UploadStatus.ERROR;
      },
      error(): string {
        if (this.fileTooBig) {
          return t("file.upload.fileTooBig");
        } else {
          return this.errorMessage;
        }
      }
    },
    methods: {
      uploadFile(file: File) {
        this.fileTooBig = false;

        if (file) {
          if (file.size > 2097152) {
            this.fileTooBig = true;
            this.$eventBus.$emit(MessageTypes.SNACKBAR_WARNING, t("file.upload.fileTooBig"));
            this.file = null;
          } else {

            this.status = UploadStatus.IN_PROGRESS;
            RegistrationService.uploadFile(this.registrationUuid, file, this.context)
              .then(() => {
                if (this.clearFileAfterUpload) {
                  this.file = null;
                }

                this.status = UploadStatus.SUCCESS;
                this.$eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("file.upload.success"));
                setTimeout(() => {
                  this.status = UploadStatus.NOT_STARTED;
                  this.$emit("fileUploaded", file);
                }, 1000);
              })
              .catch(() => {
                this.status = UploadStatus.ERROR;
                this.$eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("file.upload.success"));
              });
          }
        }
      }
    }
  });
