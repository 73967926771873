import axios, {AxiosResponse} from "axios";
import {CompanyLocation}  from "@/model/CompanyLocation";
import {MarketArea} from "@/model/MarketArea/MarketArea";
import {MarketAreaCategory} from "@/model/MarketArea/MarketAreaCategory";
import {PagedResult} from "@/service/PagedResult";

const PATH = "/inventories";
const PATH_COMPANY_LOCATIONS = `${PATH}/company-locations`;
const PATH_MARKET_AREAS = `${PATH}/market-areas`;
const PATH_MARKET_AREAS_CATEGORIES = `${PATH}/market-area-categories`;
const PATH_MARKET_AREAS_WITH_CATEGORIES = `${PATH}/market-areas-with-categories`;

class InventoriesService {
    // region COMPANY LOCATIONS
    findCompanyLocations (): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH_COMPANY_LOCATIONS}`, {
            params: {
                size: 1000,
                sort: "name,asc"
            }
        });
    }
    findCompanyLocationsByTerm (term: string): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH_COMPANY_LOCATIONS}`, {
            params: {
                term: term,
                size: 1000
            }
        });
    }
    getCompanyLocationById (id: number): Promise<AxiosResponse<CompanyLocation>> {
        return axios.get(`${PATH_COMPANY_LOCATIONS}/${id}`);
    }
    createCompanyLocation (payload: CompanyLocation): Promise<AxiosResponse<CompanyLocation>> {
        return axios.post(`${PATH_COMPANY_LOCATIONS}`, payload, {headers: {"Content-Type": "application/json"}});
    }
    updateCompanyLocation (payload: CompanyLocation) {
        return axios.put(`${PATH_COMPANY_LOCATIONS}/${payload.id}`, payload);
    }
    deleteCompanyLocation(companyLocationId: number): Promise<AxiosResponse<null>> {
        return axios.delete(`${PATH_COMPANY_LOCATIONS}/${companyLocationId}`);
    }
    // endregion

    // region MARKET AREAS
    findMarketAreasByTerm (term: string): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH_MARKET_AREAS}`, {
            params: {
                term: term,
                size: 1000
            }
        });
    }
    findMarketAreasWithCategoriesByTerm (term: string): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH_MARKET_AREAS_WITH_CATEGORIES}`, {
            params: {
                term: term,
                size: 1000
            }
        });
    }
    getMarketAreaById (id: number): Promise<AxiosResponse<MarketArea>> {
        return axios.get(`${PATH_MARKET_AREAS}/${id}`);
    }
    createMarketArea (payload: MarketArea): Promise<AxiosResponse<MarketArea>> {
        return axios.post(`${PATH_MARKET_AREAS}`, {...payload}, {headers: {"Content-Type": "application/json"}});
    }
    updateMarketArea (payload: MarketArea) {
        return axios.put(`${PATH_MARKET_AREAS}/${payload.id}`, payload);
    }
    deleteMarketArea(marketAreaId: number): Promise<AxiosResponse<null>> {
        return axios.delete(`${PATH_MARKET_AREAS}/${marketAreaId}`);
    }
    // endregion

    // region MARKET AREA CATEGORIES
    getMarketAreaCategories (): Promise<AxiosResponse<Array<MarketAreaCategory>>> {
        return axios.get(`${PATH_MARKET_AREAS_CATEGORIES}`);
    }
    getMarketAreaCategoryById (id: number): Promise<AxiosResponse<MarketAreaCategory>> {
        return axios.get(`${PATH_MARKET_AREAS_CATEGORIES}/${id}`);
    }
    createMarketAreaCategory (payload: MarketAreaCategory): Promise<AxiosResponse<MarketAreaCategory>> {
        return axios.post(PATH_MARKET_AREAS_CATEGORIES, payload, {headers: {"Content-Type": "application/json"}});
    }
    updateMarketAreaCategory (payload: MarketAreaCategory) {
        return axios.put(PATH_MARKET_AREAS_CATEGORIES, payload);
    }
    deleteMarketAreaCategory(marketAreaCategoryId: number): Promise<AxiosResponse<null>> {
        return axios.delete(`${PATH_MARKET_AREAS_CATEGORIES}/${marketAreaCategoryId}`);
    }
    // endregion
}

export default new InventoriesService();
