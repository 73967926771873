
  import {defineComponent, onMounted, PropType} from "vue";
  import WorklogDetailsDialog from "../../WorklogDetailsDialog/WorklogDetailsDialog.vue";
  import WorklogService from "@/service/WorklogService";
  import FabButton from "@/components/FabButton.vue";
  import CreateWorklogParameters from "@/views/Accounting/WorklogDetailsDialog/CreateWorklogParameters";
  import {MessageTypes} from "@/components/message-types";
  import Worklog from "@/model/Worklog";
  import DateUtils from "@/DateUtils";
  import eventBus from "@/eventbus";
  import AccountingEmployeeWorklogsList from "@/views/Accounting/Employee/Worklogs/AccountingEmployeeWorklogsList.vue";
  import AccountingEmployeeWorklogsTable from "@/views/Accounting/Employee/Worklogs/AccountingEmployeeWorklogsTable.vue";
  import AccountingEmployeeWorklogsSummaryChips
    from "@/views/Accounting/Employee/Worklogs/AccountingEmployeeWorklogsSummaryChips.vue";
  import EmployeeName from "@/model/EmployeeName";
  import Employee from "@/model/Employee";

  export default defineComponent({
    name: "AccountingEmployees",
    components: {
      AccountingEmployeeWorklogsList,
      AccountingEmployeeWorklogsTable,
      AccountingEmployeeWorklogsSummaryChips,
      FabButton,
      WorklogDetailsDialog
    },
    props: {
      editable: {
        type: Boolean,
        default: () => false
      },
      employee: {
        type: Object as PropType<EmployeeName>,
        default: () => new EmployeeName()
      },
      loading: {
        type: Boolean,
        default: false
      },
      view: {
        type: String,
        default: () => "list",
        validator: (value: string) => {
          return [
            "list",
            "table"
          ].indexOf(value) !== -1;
        }
      },
      worklogs: {
        type: Array as PropType<Array<Worklog>>,
        default: () => new Array<Worklog>()
      }
    },
    setup(props) {
      const create = () => {
        const createWorklogParams = new CreateWorklogParameters();
        createWorklogParams.date = DateUtils.today();
        createWorklogParams.employee = props.employee as Employee;

        eventBus.$emit(MessageTypes.CREATE_WORKLOG, createWorklogParams);
      };

      onMounted(() => {
        eventBus.$on(MessageTypes.DELETE_WORKLOG, (worklog: Worklog) => {
          WorklogService.delete(worklog)
            .then((deleted: boolean) => {
              if (deleted) {
                eventBus.$emit(MessageTypes.WORKLOG_DELETED, worklog);
              }
            });
        });
      });

      return {
        create
      };
    }
  });
