
  import ModalDialog from "@/components/ModalDialog.vue";
  import Employment from "@/model/Employment";
  import EmployeeEmploymentForm from "../forms/EmployeeEmploymentForm.vue";
  import {computed, defineComponent, reactive, Ref, SetupContext, toRefs, watch} from "vue";
  import {helpers, required} from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import {employeeId} from "@/RouterUtils";
  import moment from "moment";
  import EmployeeService from "@/service/EmployeeService";
  import {AxiosError} from "axios";
  import store from "@/store/store";
  import {isAuthorized} from "@/mixin/mixins";

  export default defineComponent({
    name: "EmploymentDialog",
    components: {
      EmployeeEmploymentForm,
      ModalDialog
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      employment: {
        type: Employment,
        default: () => new Employment(),
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context: SetupContext) {
      const initialState = {
        editItem: new Employment() as Employment,
        serverErrors: new Map<string, string>()
      };

      const state = reactive({...initialState});

      const rules = computed(() => ({
        editItem: {
          validFrom: {
            required: helpers.withMessage("Gültig ab ist erforderlich.", required),
          },
          employmentType: {
            required: helpers.withMessage("Beschäftigungsart ist erforderlich.", required),
          }
        }
      }));

      // @ts-ignore
      const v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (state.serverErrors.size > 0) {
          return state.serverErrors;
        } else {
          const model = v$.value.editItem;
          if (model.$dirty) {
            if(model.validFrom.$error) {
              errors.set("validFrom", model.validFrom.$errors[0].$message);
            }
            if(model.employmentType.$error) {
              errors.set("employmentType", model.employmentType.$errors[0].$message);
            }
          }
        }

        return errors;
      });

      const deletable = computed(() => {
        // @ts-ignore
        return props.editable && isAuthorized("Superadmin") && !!props.employment && !!props.employment.id;
      });

      const earliestValidFrom = computed(() => {
        return store.state.general.earliestValidFrom;
      });

      const isEditable = computed(() => {
        return props.editable && props.employment && moment(props.employment.validFrom).isSameOrAfter(moment(earliestValidFrom.value));
      });

      const smartphone = computed(() => {
        return store.state.general.earliestValidFrom;
      });

      const close = () => {
        context.emit("input", false);
        context.emit("close");
      };

      const deleteEmployment = () => {
        EmployeeService.deleteEmploymentById(employeeId.value, props.employment.id)
          .then(response => {
            context.emit("delete", props.employment);
            close();
          })
          .catch((error: AxiosError) => {
            if (error && error.response && error.response.status === 422) {
              const errorMap = new Map<string, string>();
              // @ts-ignore
              error.response.data.forEach((error: ValidationError) => {
                errorMap.set(error.path, error.message);
              });

              // Needed to trigger reactivity
              state.serverErrors = errorMap;
            }
          });
      };

      const save = () => {
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          let promise = null;
          let eventType = "";
          if (props.employment.id) {
            promise = EmployeeService.updateEmployment(employeeId.value, state.editItem);
            eventType = "update";
          } else {
            promise = EmployeeService.createEmployment(employeeId.value, state.editItem);
            eventType = "create";
          }

          promise
            .then(response => {
              context.emit(eventType, response.data);
              close();
            })
            .catch((error: AxiosError) => {
              if (error && error.response && error.response.status === 422) {
                const errorMap = new Map<string, string>();
                // @ts-ignore
                error.response.data.forEach((error: ValidationError) => {
                  errorMap.set(error.path, error.message);
                });

                // Needed to trigger reactivity
                state.serverErrors = errorMap;
              }
            });
        }
      };

      watch(() => props.employment, (newValue, oldValue) => {
        v$.value.$reset();
        state.editItem = Object.assign(new Employment(), newValue);
      });

      return {...toRefs(state),
              v$,
              errors,
              employeeId,
              deletable,
              isEditable,
              smartphone,
              close,
              deleteEmployment,
              save
      };
    }
  });
