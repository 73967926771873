
  import Worklog from "@/model/Worklog";
  import {computed, defineComponent, PropType, ref, toRefs, watch} from "vue";
  import AccountingEmployeeWorklogsSummaryChips
    from "@/views/Accounting/Employee/Worklogs/AccountingEmployeeWorklogsSummaryChips.vue";
  import {minutes} from "@/WorklogUtils";
  import {t} from "@/mixin/mixins";
  import {init} from "@/views/Accounting/Employee/Worklogs/AccountingEmployeeWorklogsComposable";

  export default defineComponent({
    name: "AccountingEmployeeWorklogsTable",
    components: {
      AccountingEmployeeWorklogsSummaryChips
    },
    props: {
      editable: {
        type: Boolean,
        default: () => false
      },
      loading: {
        type: Boolean,
        default: false
      },
      worklogs: {
        type: Array as PropType<Array<Worklog>>,
        default: () => new Array<Worklog>()
      }
    },
    setup(props) {
      const { worklogs } = toRefs(props);
      const {
        dates,
        groups,
        deleteWorklog,
        editWorklog
      } = init(worklogs);

      const openPanels = ref([]);

      const headers = computed(() => [
        {
          text: t("start"),
          align: "left",
          value: "start",
          width: 100
        },
        {
          text: t("end"),
          align: "left",
          value: "end",
          width: 100
        },
        {
          text: t("duration"),
          align: "left",
          value: "duration",
          width: 100
        },
        {
          text: t("name"),
          align: "left",
          value: "order.name"
        },
        {
          text: t("worklogType"),
          align: "left",
          value: "worklogType"
        },
        {
          text: t("comment"),
          align: "left",
          value: "comment"
        },
        {
          text: "",
          align: "right",
          value: "id",
          sortable: false,
          width: 72
        }
      ]);

      const footerProps = {
        "items-per-page-options": [-1]
      };

      watch(() => props.worklogs, () => {
        openPanels.value = [];
      });

      return {
        openPanels,
        headers,
        footerProps,
        dates,
        groups,
        minutes,
        deleteWorklog,
        editWorklog
      };
    }
  });
