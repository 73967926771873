import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VList,{attrs:{"two-line":""}},_vm._l((_vm.items),function(item,index){return _c(VListGroup,{key:index,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItem,[_c(VListItemContent,{attrs:{"dense":""}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.employee.firstName + " " + item.employee.lastName)+" "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_setup.hasAutomaticPause(item))?_c(VAvatar,_vm._g({attrs:{"color":"primary","size":"16"}},on),[_c('span',{staticClass:"white--text"},[_vm._v(" A ")])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.t("automaticPause"))+" ")])])],1)],1)],1)]},proxy:true}],null,true)},_vm._l((item.worklogs),function(worklog){return _c(VListItem,{key:worklog.id,on:{"click":function($event){return _setup.edit(worklog)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.t("enum.worklogType." + worklog.worklogType))+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.t("start"))+": "+_vm._s(worklog.start)+" "+_vm._s(_vm.t("end"))+": "+_vm._s(worklog.end)+" ")])],1),_c(VListItemAction,[_c(VIcon,[_vm._v(" "+_vm._s(_setup.getIcon(worklog.worklogType))+" ")])],1)],1)}),1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }