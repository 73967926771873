import * as validators from "@vuelidate/validators";
import {createI18nMessage} from "@vuelidate/validators";
import vuetify from "@/plugins/vuetify";

const withI18nMessage = createI18nMessage({
    t(path: string, params: { model: string; property: string; [p: string]: any }): string {
        return vuetify.framework.lang.t("$vuetify." + path, params.property);
    }
});
export const required = withI18nMessage(validators.required);
