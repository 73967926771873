
  import {computed, defineComponent, PropType, reactive, toRefs, watch} from "vue";
  import OrderInfoCard from "@/views/Orders/Info/OrderInfoCard.vue";
  import Order from "@/model/Order";
  import moment from "moment";
  import {OrderTypes} from "@/enums";

  export default defineComponent({
    name: "OrderInfoData",
    components: {
      OrderInfoCard
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      errors: {
        type: Map as PropType<Map<string, string>>,
        default: () => new Map<string, string>()
      },
      value: {
        type: Object as PropType<Order>,
        required: true
      }
    },
    emits: ["input"],
    setup(props, context) {
      const initialState = {
        menuDateValidFrom: "" as string | null,
        menuDateValidTo: "" as string | null,
        menuStartTime: "" as string | null,
        orderTypes: OrderTypes
      };

      const state = reactive({...initialState});

      const model = computed({
        get: () => props.value,
        set: (value) => {
          context.emit("input", value);
        }
      });

      watch(() => model, (newValue, oldValue) => {
        context.emit("input", newValue.value);
      }, { deep: true});

      const minDate = computed(() => {
        return moment().startOf("month").format("YYYY-MM-DD");
      });

      return {...toRefs(state), model, minDate};
    }
  });
