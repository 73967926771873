import moment from "moment";

export class DateRangeUpdateVO {
  dateRange: DateRange | null;
  from: string | null;
  to: string | null;

  constructor (dateRange: DateRange | null, from: string | null, to: string | null) {
    this.dateRange = dateRange;
    this.from = from;
    this.to = to;
  }
}

export enum DateRange {
  LAST_TWO_WEEKS,
  CURRENT_MONTH,
  LAST_MONTH,
  CURRENT_YEAR,
  LAST_YEAR
}

export type I18nCallback = (i18n: string) => string;

export default {
  dateText (t: I18nCallback, dateRange: DateRange | null, from: string | null, to: string | null): string {
    switch (dateRange) {
      case DateRange.LAST_TWO_WEEKS:
        return t("date.dateRange.lastTwoWeeks");
      case DateRange.CURRENT_MONTH:
        return t("date.dateRange.currentMonth");
      case DateRange.LAST_MONTH:
        return t("date.dateRange.lastMonth");
      case DateRange.CURRENT_YEAR:
        return t("date.dateRange.currentYear");
      case DateRange.LAST_YEAR:
        return t("date.dateRange.lastYear");
      default:
        if (!from || !to) {
          return "";
        } else {
          return moment(from).format("DD.MM.YY") + "-" + moment(to).format("DD.MM.YY");
        }
    }
  }
};
