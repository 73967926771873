
  import Vue from "vue";
  import { Getter, Mutation } from "vuex-class";
  import { HeaderGetters, HeaderModule } from "@/store/modules/header";
  import TimeclockEmployeeEntry from "@/model/TimeclockEmployeeEntry";
  import { Watch } from "vue-property-decorator";
  import Component from "vue-class-component";
  import OrderService from "@/service/OrderService";
  import Utils from "@/Utils";
  import TimeclockService from "@/service/TimeclockService";
  import { AxiosResponse } from "axios";

  @Component
  export default class TimeclockOrderTabEmployeesAddDialog extends Vue {
    @Getter(HeaderGetters.GET_DATE, { namespace: HeaderModule }) date: string;

    private dialog = false;
    private loading = false;
    private items: Array<TimeclockEmployeeEntry> = [];
    private selectedItems: Array<TimeclockEmployeeEntry> = [];
    private select: TimeclockEmployeeEntry | null = null;
    private search = "";
    private typeTimeout = 400;
    private result: any = null;

    get orderId (): number {
      return Number(this.$route.params.orderId);
    }

    @Watch("search")
    onSearchChanged (term: string) {
      clearTimeout(this.typeTimeout);

      const vue = this;
      if (term && term.length > 2) {
        this.typeTimeout = setTimeout(() => {
          term && vue.findEmployees(term);
        }, this.typeTimeout);
      }
    }

    open (addedEmployees: Array<any>) {
      this.dialog = true;
      this.search = "";
      this.selectedItems = Array.from(addedEmployees);
      this.select = null;

      const vue = this;
      return new Promise(function (resolve) {
        // @ts-ignore
        vue.result = resolve;
      });
    }

    save () {
      this.result(this.selectedItems);

      this.dialog = false;
    }

    findEmployees (term: string) {
      this.loading = true;

      TimeclockService.findEmployees(this.orderId, this.date, term)
        .then((response: AxiosResponse<Array<TimeclockEmployeeEntry>>) => {
          this.items = response.data.map(item => Object.assign(new TimeclockEmployeeEntry(), item));
        })
        .finally(() => (this.loading = false));
    }

    filter (item: TimeclockEmployeeEntry) {
      return !this.selectedItems.some(selectedItem => selectedItem.id === item.id);
    }

    employeeSelected (item: TimeclockEmployeeEntry) {
      this.selectedItems.push(item);
      this.$nextTick(() => {
        this.search = "";
        this.select = null;
        this.items = [];

        // @ts-ignore
        this.$refs.autocomplete.blur();
      });
    }

    removeSelected (item: TimeclockEmployeeEntry) {
      Utils.remove(this.selectedItems, item);
    }

    fullName (item: TimeclockEmployeeEntry) {
      return item.fullName;
    }
  }
