
  import WorklogDetailsDialog from "@/views/Accounting/WorklogDetailsDialog/WorklogDetailsDialog.vue";
  import Vue from "vue";
  import {MessageTypes} from "@/components/message-types";
  import CreateWorklogParameters from "@/views/Accounting/WorklogDetailsDialog/CreateWorklogParameters";
  import {isAuthorized} from "@/mixin/mixins";
  import OrderServiceV2 from "@/service/OrderServiceV2";
  import store, {GeneralGetters} from "@/store/store";
  import moment from "moment/moment";

  export default Vue.extend({
    name: "OrderWorklogsCreateFab",
    components: {
      WorklogDetailsDialog
    },
    props: {
      order: {
        type: Object,
        default: (): any => null
      }
    },
    data: () => ({
      createAllowed: false
    }),
    computed: {
      date(): string | null {
        return this.$route.query.date ? this.$route.query.date as string : null;
      },
      isDate(): boolean {
        return this.$moment(this.date as string, "YYYY-MM-DD", true).isValid();
      },
      isVisible(): boolean {
        if (this.orderId) {
          return this.createAllowed && isAuthorized("Order_Worklogs_CreateWorklog");
        } else {
          return isAuthorized("Accounting_Employee_CreateWorklogs");
        }
      },
      isYearMonth(): boolean {
        return this.$moment(this.date as string, "YYYY-MM", true).isValid();
      },
      orderId(): number {
        return Number(this.$route.params.orderId);
      }
    },
    mounted() {
      if (this.orderId) {
        let authorized = isAuthorized("Accounting_Employee_CreateWorklogs")
          || isAuthorized("Order_Worklogs_CreateWorklog");

        if (authorized) {
          let earliestValidFrom = store.getters[GeneralGetters.GET_EARLIEST_VALID_FROM];
          OrderServiceV2.getOrder(this.orderId)
            .then(response => {
              let order = response.data;
              this.createAllowed = order.validTo == null || moment(order.validTo).isSameOrAfter(earliestValidFrom);
            });
        }
      }
    },
    methods: {
      create() {
        const createWorklogParams = new CreateWorklogParameters();
        if (this.order) {
          createWorklogParams.order = this.order;
        }

        if (this.orderId) {
          createWorklogParams.orderId = this.orderId;
        }

        if (this.date) {
          if (this.isDate) {
            createWorklogParams.date = this.date;
          } else if (this.isYearMonth) {
            const date = this.$moment(this.date as string, "YYYY-MM").format("YYYY-MM-01");
            createWorklogParams.date = date;
          }
        }

        this.$eventBus.$emit(MessageTypes.CREATE_WORKLOG, createWorklogParams);
      }
    }
  });
