import axios, {AxiosResponse} from "axios";
import {Setting} from "@/model/Setting";

const PATH = "/settings";

class SettingService {
    getById (id: number): Promise<AxiosResponse<Setting>> {
        return axios.get(`${PATH}/${id}`);
    }
    getByName (name: string): Promise<AxiosResponse<Setting>> {
        return axios.get(`${PATH}/name/${name}`);
    }
}

export default new SettingService();
