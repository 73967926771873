
  import OrderService from "@/service/OrderService";
  import TheHeaderSearchLookupResultList from "@/components/TheHeaderSearchLookupResultList.vue";

  import Vue from "vue";
  import { AxiosResponse } from "axios";
  import Order from "@/model/Order";
  import EmployeeService from "@/service/EmployeeService";
  import Employee from "@/model/Employee";
  import Utils from "@/Utils";

  export default Vue.extend({
    name: "TheHeaderSearchLookup",
    components: {
      TheHeaderSearchLookupResultList
    },
    data: () => ({
      dialog: false,
      loading: false,
      search: null,
      model: null,
      typeTimeout: 0,
      timeoutDuration: 400,
      employees: new Array<Employee>(),
      orders: new Array<Order>(),
      recent: new Array<any>()
    }),
    watch: {
      search (term) {
        clearTimeout(this.typeTimeout);

        const vue = this;

        const isNum = /^\d+$/.test(term);
        if (term && (term.length > 2 || isNum)) {
          this.typeTimeout = setTimeout(() => {
            vue.loading = true;
            term && vue.findOrders(term);
            term && vue.findEmployees(term);
          }, vue.timeoutDuration);
        } else {
          this.employees = [];
          this.orders = [];
        }
      },
      dialog (val) {
        if (val) {
          this.$nextTick(() => {
            // @ts-ignore
            this.$refs.searchInputField.focus();
          });
        }
      }
    },
    activated () {
      this.dialog = true;
    },
    deactivated () {
      this.dialog = false;
    },
    methods: {
      closeDialog () {
        this.dialog = false;
        this.$router.go(-1);
      },
      findEmployees (term: string) {
        EmployeeService.findEmployeeByNameOrEmployeeId(term)
          .then((response: AxiosResponse<Array<Employee>>) => {
            this.employees = response.data
              .map(item => Object.assign(new Employee(), item));
          });
      },
      findOrders (term: string) {
        this.loading = true;

        OrderService.findOrdersByTerm(term)
          .then((response: AxiosResponse<Array<Order>>) => {
            this.orders = response.data
              .map(item => Object.assign(new Order(), item));
          })
          .finally(() => {
            this.loading = false;
            if (this.$refs.searchInputField) {
              // @ts-ignore
              this.$nextTick(() => this.$refs.searchInputField.blur());
            }
          });
      },
      goTo (item: any) {
        Utils.removeIfExists(this.recent, item);
        this.recent.unshift(item);

        if (item instanceof Employee) {
          this.$router.push(`/employees/${item.id}`);
        } else if (item instanceof Order) {
          this.$router.push(`/orders/${item.id}/worklogs`);
        }
      },
      getItemName (item: any) {
        // @ts-ignore
        const searchString = this.search != null ? this.search.toUpperCase() : "";
        if (item.name.toUpperCase().includes(searchString)) {
          return item.name;
        } else if (item.city != null && item.city.toUpperCase().includes(searchString)) {
          return item.city;
        } else if (item.address != null && item.address.toUpperCase().includes(searchString)) {
          return item.address;
        } else if (item.zip != null && item.zip.toUpperCase().includes(searchString)) {
          return item.zip;
        } else {
          return "";
        }
      },
      getAvatarColorForOrderType (orderType: string): string {
        let color = "";

        switch (orderType) {
        case "INV":
          color = "orange lighten-2";
          break;
        case "MOD":
          color = "blue lighten-2";
          break;
        case "DRY":
          color = "grey lighten-1";
          break;
        case "MIS":
          color = "lime  lighten-1";
          break;
        }

        return color;
      }
    }
  });
