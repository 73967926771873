
  import {RegistrationDataAddressAndContact} from "@/model/Registration";
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    SetupContext,
    toRefs,
    watch
  } from "vue";
  import {useDialog} from "@/views/Registration/RegistrationComposable";
  import {email, helpers, maxLength, minLength, numeric, required} from "@vuelidate/validators";
  import useVuelidate, {ErrorObject} from "@vuelidate/core";
  import {ValidationResult} from "@/model/ValidationResult";
  import {t, tWithParms} from "@/mixin/mixins";

  export default defineComponent({
    name: "RegistrationAddressAndContactForm",
    components: {
      RegistrationCard
    },
    props: {
      step: {
        type: Number,
        default: null
      }
    },
    setup(props, context: SetupContext) {

      const {
        state: generalState,
        registration,
        mobile,
        next
      } = useDialog(context);

      const initialState = {
        model: {
          addressAndContact: new RegistrationDataAddressAndContact()
        }
      };

      const state = reactive({...initialState});

      const rules = computed(() => ({
        model: {
          addressAndContact: {
            street: {
              required: helpers.withMessage(tWithParms("validation.required", t("address.street")), required),
            },
            zip: {
              numeric,
              required: helpers.withMessage(tWithParms("validation.required", t("address.zip")), required),
              minLength: minLength(5),
              maxLength: maxLength(5)
            },
            city: {
              required: helpers.withMessage(tWithParms("validation.required", t("address.city")), required),
            },
            email: {
              email: helpers.withMessage(t("validation.invalidEmailFormat"), email),
              required: helpers.withMessage(tWithParms("validation.required", t("contact.email")), required),
            },
            phone: {
              required: helpers.withMessage(tWithParms("validation.required", t("contact.phone")), required),
            }
          }
        }
      }
      ));

      // @ts-ignore
      let v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (generalState.serverErrors.length > 0) {
          generalState.serverErrors.forEach((error: ValidationResult) => {
            errors.set(error.path, error.message);
          });

          return errors;
        } else {
          const model = v$.value;
          if (model.$dirty) {
            model.$errors.forEach((error: ErrorObject) => {
              if(!errors.has(error.$propertyPath)) {
                // @ts-ignore
                errors.set(error.$propertyPath, error.$message);
              }
            });
          }
        }

        return errors;
      });

      watch(() => registration.addressAndContact, (newValue, oldValue) => {
        if (newValue) {
          state.model.addressAndContact = Object.assign(state.model.addressAndContact, newValue);
        }
      });

      onMounted(() => {
        if(registration.value.addressAndContact) {
          state.model.addressAndContact = registration.value.addressAndContact;
        } else {
          generalState.dirty = true;
        }
      });

      const onNext = () => {
        next(v$, state.model);
      };

      return {
        ...toRefs(state),
        errors,
        blur,
        mobile,
        onNext
      };
    }
  });
