
  import {computed, defineComponent, onMounted, reactive, toRefs, watch} from "vue";
  import {CompanyLocation} from "@/model/CompanyLocation";
  import {isAuthorized, t} from "@/mixin/mixins";
  import router from "@/router";
  import PageUtils from "@/components/PageUtils";
  import {DataOptions} from "vuetify";
  import FilterUtils from "@/FilterUtils";
  import { EmployeeCompetencies } from "@/model/EmployeeCompetencies";
  import EmployeeCompetenciesService, {EmployeeCompetenciesFilter} from "@/service/EmployeeCompetenciesService";
  import InventoriesService from "@/service/InventoriesService";

  export default defineComponent({
    name: "InventoriesCompetencies",
    setup () {
      const initialState = {
        filterRowVisible: true,
        filters: {
          employeeId: null as null | string,
          firstName: null as null | string,
          lastName: null as null | string,
          companyLocationId: null as null | number,
          fruitAndVegetables: null as null | boolean,
          checkout: null as null | boolean,
          flowers: null as null | boolean,
          storage: null as null | boolean,
          storageManagement: null as null | boolean,
          weigh: null as null | boolean,
          reuse: null as null | boolean,
          check: null as null | boolean,
          targetActualComparison: null as null | boolean,
          allrounder: null as null | boolean,
          quickCounter: null as null | boolean
        },
        headers: [
          {
            text: t("inventories.competencies.table.employeeId"),
            align: "left",
            value: "employeeId",
            authorization: undefined,
            visible: true,
            selectable: true
          },
          {
            text: t("inventories.competencies.table.firstName"),
            align: "left",
            value: "firstName",
            visible: true,
            selectable: true
          },
          {
            text: t("inventories.competencies.table.lastName"),
            align: "left",
            value: "lastName",
            visible: true,
            selectable: true
          },
          {
            text: t("inventories.competencies.table.companyLocation"),
            align: "left",
            value: "companyLocationId",
            visible: true,
            selectable: true,
            companyLocations: [] as Array<CompanyLocation>
          },
          {
            text: t("inventories.competencies.table.fruitAndVegetables"),
            align: "left",
            value: "fruitAndVegetables",
            visible: true,
            selectable: true,
            boolean: true
          },
          {
            text: t("inventories.competencies.table.checkout"),
            align: "left",
            value: "checkout",
            visible: true,
            selectable: true,
            boolean: true
          },
          {
            text: t("inventories.competencies.table.flowers"),
            align: "left",
            value: "flowers",
            visible: true,
            selectable: true,
            boolean: true
          },
          {
            text: t("inventories.competencies.table.storage"),
            align: "left",
            value: "storage",
            visible: true,
            selectable: true,
            boolean: true
          },
          {
            text: t("inventories.competencies.table.storageManagement"),
            align: "left",
            value: "storageManagement",
            visible: true,
            selectable: true,
            boolean: true
          },
          {
            text: t("inventories.competencies.table.weigh"),
            align: "left",
            value: "weigh",
            visible: true,
            selectable: true,
            boolean: true
          },
          {
            text: t("inventories.competencies.table.reuse"),
            align: "left",
            value: "reuse",
            visible: true,
            selectable: true,
            boolean: true
          },
          {
            text: t("inventories.competencies.table.check"),
            align: "left",
            value: "check",
            visible: true,
            selectable: true,
            boolean: true
          },
          {
            text: t("inventories.competencies.table.targetActualComparison"),
            align: "left",
            value: "targetActualComparison",
            visible: true,
            selectable: true,
            boolean: true
          },
          {
            text: t("inventories.competencies.table.allrounder"),
            align: "left",
            value: "allrounder",
            visible: true,
            selectable: true,
            boolean: true
          },
          {
            text: t("inventories.competencies.table.quickCounter"),
            align: "left",
            value: "quickCounter",
            visible: true,
            selectable: true,
            boolean: true
          },
          {
            text: "",
            align: "right",
            value: "settings",
            sortable: false,
            width: 40,
            visible: true,
            selectable: false
          }
        ],
        options: {
          itemsPerPage: 25
        } as DataOptions,
        loading: false,
        columnDialog: false,
        columns: [],
        employeeCompetencies: [] as Array<EmployeeCompetencies>,
        companyLocations: [] as Array<CompanyLocation>,
        serverItemsLength: -1,
        typeTimeout: 300
      };

      const state = reactive({...initialState});

      const footerProps = {
        "items-per-page-options": [25, 50, 100, 250, -1]
      };

      const availableHeaders = computed(() => {
        return state.headers.filter(header => header.authorization === undefined || isAuthorized(header.authorization));
      });

      const visibleHeaders = computed(() => {
        return availableHeaders.value.filter(header => header.visible);
      });

      const columnVisibilityMap = computed(() => {
        // @ts-ignore
        return Object.assign(...availableHeaders.value.map(({ value, visible }) => ({ [value]: visible })));
      });

      const editEmployee = (employeeId: number) => {
        router.push({ path: `/employees/${employeeId}` });
      };

      const openColumnDialog = () => {
        state.columns = JSON.parse(JSON.stringify(availableHeaders.value));
        state.columnDialog = true;
      };

      const saveColumnDialog = (newColumns: any) => {
        state.headers = newColumns;
        state.columnDialog = false;
      };

      const getHeaderCheckBoxColor = (headerVal: string) => {
        return headerVal == "allrounder" ? "light-green lighten-1" :
          headerVal == "quickCounter" ? "light-blue lighten-1" : "disabled";
      };

      const onHeaderCheckboxClick = (headerVal: string, event : Event) => {
        event.stopPropagation();

        let f: keyof typeof state.filters;
        for (f in state.filters) {
          if (f == headerVal) {
            if (state.filters[f] == null) {
              (state.filters[f] as boolean) = true;
            } else {
              state.filters[f] == true ? (state.filters[f] as boolean) = false : state.filters[f] = null;
            }
          }
        }
      };

      watch(() => state.options, (newValue, oldValue) => {
        loadInventoriesEmployees();
      }, { deep: true });

      watch(() => state.filters, (newValue, oldValue) => {
        clearTimeout(state.typeTimeout);

        state.typeTimeout = setTimeout(function () {
          loadInventoriesEmployees();
        }, 300);
      }, { deep: true });

      const loadInventoriesEmployees = () => {
        if(state.loading) {
          return;
        }

        state.loading = true;

        let sort = null;
        if(state.options.sortBy && state.options.sortBy.length === 1) {
          sort = state.options.sortDesc[0] ? state.options.sortBy[0] + ",desc" : state.options.sortBy[0];
        }

        let filter: EmployeeCompetenciesFilter = {
          employeeId: FilterUtils.isNum(state.filters.employeeId) ? Number(state.filters.employeeId) : null,
          firstName: state.filters.firstName == "" ? null : state.filters.firstName,
          lastName: state.filters.lastName == "" ? null : state.filters.lastName,
          companyLocationId: state.filters.companyLocationId,
          fruitAndVegetables: state.filters.fruitAndVegetables,
          checkout: state.filters.checkout,
          flowers: state.filters.flowers,
          storage: state.filters.storage,
          storageManagement: state.filters.storageManagement,
          weigh: state.filters.weigh,
          reuse: state.filters.reuse,
          check: state.filters.check,
          targetActualComparison: state.filters.targetActualComparison,
          allrounder: state.filters.allrounder,
          quickCounter: state.filters.quickCounter
        };

        EmployeeCompetenciesService.findEmployeeCompetencies(filter, state.options.itemsPerPage, state.options.page-1, sort)
          .then(response => {
            state.employeeCompetencies = PageUtils.extractCollection<EmployeeCompetencies>(response.data, "employeeCompetencies");
            state.serverItemsLength = response.data.page.totalElements;
          })
          .finally(() => state.loading = false);
      };

      const loadCompanyLocations = () => {
        InventoriesService.findCompanyLocationsByTerm("")
          .then(response => {
            state.companyLocations =  PageUtils.extractCollection(response.data, "companyLocations");
            const headerCompanyLocationId = state.headers.find(h => h.value == "companyLocationId");
            if (headerCompanyLocationId) headerCompanyLocationId.companyLocations = state.companyLocations;
          });
      };

      onMounted(() => {
        loadInventoriesEmployees();
        loadCompanyLocations();
      });

      return {
        ...toRefs(state),
        footerProps,
        availableHeaders,
        visibleHeaders,
        columnVisibilityMap,
        openColumnDialog,
        saveColumnDialog,
        editEmployee,
        getHeaderCheckBoxColor,
        onHeaderCheckboxClick
      };
    }
  });
