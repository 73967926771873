
  import OrderWorklogsCreateFab from "@/views/Orders/Worklogs/OrderWorklogsCreateFab.vue";
  import OrderWorklogsList from "@/views/Orders/Worklogs/OrderWorklogsList.vue";
  import OrderWorklogsTable from "@/views/Orders/Worklogs/OrderWorklogsTable.vue";
  import SwitchView from "@/components/SwitchView.vue";
  import {computed, defineComponent, onBeforeUnmount, onMounted, PropType, reactive, toRefs, watch} from "vue";
  import {OrderWorklogsDate} from "@/model/OrderWorklogs";
  import MonthPicker from "@/components/MonthPicker.vue";
  import {MessageTypes} from "@/components/message-types";
  import OrderServiceV2 from "@/service/OrderServiceV2";
  import eventBus from "@/eventbus";
  import {t} from "@/mixin/mixins";
  import Worklog from "@/model/Worklog";
  import WorklogService from "@/service/WorklogService";
  import {orderId} from "@/RouterUtils";
  import router from "@/router";
  import Order from "@/model/Order";
  import store from "@/store/store";
  import moment from "moment";

  export default defineComponent({
    name: "OrderWorklogs",
    components: {
      OrderWorklogsCreateFab,
      MonthPicker,
      OrderWorklogsList,
      OrderWorklogsTable,
      SwitchView
    },
    props: {
      order: {
        type: Object as PropType<Order>,
        default: () => null,
      }
    },
    setup (props, context) {
      const initialState = {
        worklogs: [] as Array<OrderWorklogsDate>,
        loading: false,
        yearMonth: null as unknown as string
      };

      const state = reactive({...initialState});

      const date = computed(() => {
        return router.currentRoute.query.date ? router.currentRoute.query.date as string : null;
      });

      const isDate = computed(() => {
        return moment(date.value as string, "YYYY-MM-DD", true).isValid();
      });

      const breadcrumbs = computed(() => {
        const breadcrumbs = [];
        breadcrumbs.push({
          text: moment(state.yearMonth as string, "YYYY-MM").format("MMMM YYYY"),
          disabled: !isDate.value,
          exact: true,
          to: {path: router.currentRoute.path, query: {view: "list", date: state.yearMonth}}
        });
        if (isDate.value) {
          breadcrumbs.push({
            text: moment(date.value as string).format("DD. MMMM YYYY"),
            disabled: true
          });
        }

        return breadcrumbs;
      });

      const min = computed(() => {
        return props.order && props.order.validFrom ? moment(props.order.validFrom).format("YYYY-MM") : "";
      });

      const max = computed(() => {
        return props.order && props.order.validTo ? moment(props.order.validTo).format("YYYY-MM") : "";
      });

      watch(() => state.yearMonth, (newValue, oldValue) => {
        // oldValue is initially not set and is populated by the first invocation of loadWorklogs, so do not reload it here again
        if(oldValue != null && oldValue !== "") {
          loadWorklogs();
        }
        router.push({query: Object.assign({}, router.currentRoute.query, {date: newValue}), replace: true});
      });

      const loadWorklogs = () => {
        state.loading = true;

        OrderServiceV2.getOrderWorklogs(orderId.value, state.yearMonth)
          .then(response => {
            state.yearMonth = response.data.yearMonth;
            state.worklogs = response.data.dates;
          })
          .finally(() => state.loading = false);
      };

      onMounted(() => {
        store.commit("header/setTitle", props.order.name);

        loadWorklogs();
        eventBus.$on(MessageTypes.DELETE_WORKLOG, (worklog: Worklog) => {
          WorklogService.delete(worklog)
            .then((deleted: boolean) => {
              if (deleted) {
                eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, t("entryDeleted"));
                eventBus.$emit(MessageTypes.WORKLOG_DELETED);
              }
            });
        });

        eventBus.$on(MessageTypes.WORKLOG_CREATED, (worklog: Worklog) => {
          loadWorklogs();
        });
        eventBus.$on(MessageTypes.WORKLOG_DELETED, (worklog: Worklog) => {
          loadWorklogs();
        });
        eventBus.$on(MessageTypes.WORKLOG_UPDATED, (worklog: Worklog) => {
          loadWorklogs();
        });
      });

      onBeforeUnmount(() => {
        eventBus.$off(MessageTypes.WORKLOG_CREATED);
        eventBus.$off(MessageTypes.WORKLOG_UPDATED);
        eventBus.$off(MessageTypes.WORKLOG_DELETED);
        eventBus.$off(MessageTypes.DELETE_WORKLOG);
      });

      return {
        ...toRefs(state),
        breadcrumbs,
        date,
        isDate,
        min,
        max
      };
    }
  });
