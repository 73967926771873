
  import Vue from "vue";
  import Employment from "@/model/Employment";
  import EmployeeDetailsCardEmploymentsHistory from "@/views/Employee/details/EmployeeDetailsCardEmploymentsHistory.vue";
  import EmployeeDetailsCardListEntry from "@/views/Employee/details/EmployeeDetailsCardListEntry.vue";
  import EmployeeDetailsCardEmploymentsDialog from "./EmployeeDetailsCardEmploymentsDialog.vue";
  import EmploymentUtils from "@/views/Employee/details/EmploymentUtils";
  import Employee from "@/model/Employee";

  class ListEntry {
    public label: string;
    public text: string;
    public icon?: string;
  }

  export default Vue.extend({
    name: "EmployeeDetailsCardEmployments",
    components: {
      EmployeeDetailsCardListEntry,
      EmployeeDetailsCardEmploymentsHistory,
      EmployeeDetailsCardEmploymentsDialog
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      employee: {
        type: Employee,
        default: () => new Employee()
      }
    },
    data: () => ({
      dialog: false
    }),
    computed: {
      getTemporary(): boolean {
        const temporary = this.employee.temporary;
        return temporary ? temporary : false;
      },
      employeeId (): number {
        return Number(this.$route.params.employeeId);
      },
      currentEmployment (): Employment {
        return EmploymentUtils.getCurrentEmployment(this.employee.employments);
      },
      nextEmployment (): Employment | null {
        return EmploymentUtils.getNextEmployment(this.employee.employments);
      },
      currentEmploymentListEntries (): Array<ListEntry> {
        const listEntries = new Array<ListEntry>();
        const employmentTypeI18N = this.currentEmployment.employmentType ? this.$vuetify.lang.t("$vuetify.enum.employmentType." + this.currentEmployment.employmentType) : "";
        const validFrom = this.$moment(this.currentEmployment.validFrom).format("MMMM YYYY");
        listEntries.push({ label: "Beschäftigungsart", text: employmentTypeI18N });
        listEntries.push({ label: "Gültig seit", text: validFrom });

        return listEntries;
      },
      nextEmploymentListEntries (): Array<ListEntry> {
        const listEntries = new Array<ListEntry>();
        if (this.nextEmployment) {
          const employmentTypeI18N = this.$vuetify.lang.t("$vuetify.enum.employmentType." + this.nextEmployment.employmentType);
          const validFrom = this.$moment(this.nextEmployment.validFrom).format("MMMM YYYY");
          listEntries.push({ label: "Beschäftigungsart", text: employmentTypeI18N });
          listEntries.push({ label: "Gültig ab", text: validFrom });
        }

        return listEntries;
      }
    }
  });
