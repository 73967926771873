import axios from "axios";
import vue from "@/main";
import Worklog from "@/model/Worklog";

const PATH = "/worklogs";

export default {
  findById (worklogId: number) {
    return axios.get(`${PATH}/${worklogId}`);
  },
  create (worklog: Worklog) {
    return axios.post(`${PATH}`, {
      date: worklog.date,
      start: worklog.start,
      end: worklog.end,
      worklogType: worklog.worklogType,
      comment: worklog.comment,
      orderId: worklog.order?.id,
      employeeId: worklog.employee?.id
    });
  },
  update (worklog: Worklog) {
    return axios.put(`${PATH}/${worklog.id}`, {
      start: worklog.start,
      end: worklog.end,
      worklogType: worklog.worklogType,
      comment: worklog.comment ? worklog.comment : null
    });
  },
  createOrUpdate (worklog: Worklog) {
    if (worklog.id) {
      return this.update(worklog);
    } else {
      return this.create(worklog);
    }
  },
  delete (worklog: Worklog): Promise<boolean> {
    const title = "Arbeitszeiteintrag löschen";
    // const orderName = worklog.order ? worklog.order.name : "";
    // @ts-ignore
    const date = vue.$options.filters.formatDate(worklog.date, "dddd, Do MMMM YYYY");
    // @ts-ignore
    const start = vue.$options.filters.normalizeTimeString(worklog.start);
    // @ts-ignore
    const end = vue.$options.filters.normalizeTimeString(worklog.end);
    const text = "Soll der Arbeitszeiteintrag <br><br><b>Datum</b>: " + date
        + "<br><b>Start</b>: " + start
        + "<br><b>Ende</b>: " + end
        // + "<br><b>Name</b>: " + orderName
        + "<br><br> wirklich gelöscht werden? ";

    // @ts-ignore
    return vue.$root.$confirmDialog.open(title, text)
    // @ts-ignore
      .then(confirmResult => {
        if (confirmResult) {
          return axios.delete(`${PATH}/${worklog.id}`);
        }
      });
  }
};
