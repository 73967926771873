import PerDiem from "@/model/PerDiem";
import TimeSpent from "@/model/TimeSpent";
import EmployeeName from "@/model/EmployeeName";

export class OrderPerDiemMonth {
  public dates: Array<OrderPerDiemDateEntry> = new Array<OrderPerDiemDateEntry>();
}

export class OrderPerDiemDateEntry {
  public date: string;

  public employees: Array<OrderPerDiemDateEmployeeEntry> = new Array<OrderPerDiemDateEmployeeEntry>();
}

export class OrderPerDiemDateEmployeeEntry {
  public perDiem: PerDiem;

  public employee: EmployeeName;

  public timeSpent: TimeSpent;
}
