import Order from "@/model/Order";
import Employee from "@/model/Employee";

export default class CreateWorklogParameters {
    public date?: string;
    public employeeId?: number;
    public employee?: Employee;
    public orderId?: number;
    public order?: Order;
}
