<template>
  <VDialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <VCard>
      <VCardText class="pa-0">
        <VRow class="mx-0">
          <VCol>
            <VList two-line>
              <template v-for="(item, index) in entry.items">
                <VListItem
                  :key="item.id"
                  ripple
                >
                  <VListItemIcon>
                    <VIcon color="primary">
                      {{ getIcon(item.worklogType) }}
                    </VIcon>
                  </VListItemIcon>

                  <VListItemContent>
                    <VListItemTitle>
                      {{ item.order.name }}
                    </VListItemTitle>
                    <VListItemSubtitle class="text--primary">
                      {{ t("enum.worklogType." + item.worklogType) }}
                    </VListItemSubtitle>
                    <VListItemSubtitle>
                      {{ item.start | normalizeTimeString }} - {{ item.end | normalizeTimeString }}
                    </VListItemSubtitle>
                  </VListItemContent>

                  <VListItemAction>
                    <VListItemActionText>
                      {{ toCreatedString(item) }}
                    </VListItemActionText>
                    <VListItemActionText>
                      {{ toUpdatedString(item.updateDate, item.updatedBy) }}
                    </VListItemActionText>
                  </VListItemAction>
                </VListItem>
                <VDivider
                  v-if="index + 1 < entry.items.length"
                  :key="index"
                />
              </template>
            </VList>
          </VCol>
        </VRow>
        <VRow
          dense
          class="mx-0"
        >
          <VCol
            cols="3"
            xs3
            class="text-right font-weight-bold"
          >
            Arbeit:
          </VCol>
          <VCol
            cols="3"
            class="text-left"
          >
            {{ entry.timeSpent.work | toTimeFormat }}h
          </VCol>
          <VCol
            cols="3"
            class="text-right font-weight-bold"
          >
            Pause:
          </VCol>
          <VCol
            cols="3"
            class="text-left"
          >
            {{ entry.timeSpent.pause | toTimeFormat }}h
          </VCol>
        </VRow>
        <VRow
          dense
          class="mx-0"
        >
          <VCol
            cols="3"
            class="text-right font-weight-bold"
          >
            Reise:
          </VCol>
          <VCol
            cols="3"
            class="text-left"
          >
            {{ entry.timeSpent.travel | toTimeFormat }}h
          </VCol>
          <VCol
            v-if="isAuthorized('Accounting_Employee_ViewPassengerTimes')"
            cols="3"
            class="text-right font-weight-bold"
          >
            Mitfahrer:
          </VCol>
          <VCol
            v-if="isAuthorized('Accounting_Employee_ViewPassengerTimes')"
            cols="3"
            class="text-left"
          >
            {{ entry.timeSpent.passenger | toTimeFormat }}h
          </VCol>
        </VRow>
      </VCardText>

      <VCardActions>
        <VSpacer />
        <VBtn
          text
          color="primary"
          @click="close()"
        >
          Schließen
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
  import moment from "moment";

  export default {
    data: () => ({
      dialog: false,
      entry: { timeSpent: {} }
    }),
    methods: {
      open (entry) {
        this.entry = entry;
        this.dialog = true;
      },
      close: function () {
        this.dialog = false;
        this.$emit("closed");
      },
      toCreatedString (item) {
        if (item.creationDate != null && item.createdBy != null) {
          return "Erstellt am " + moment(item.creationDate).format("DD.MM.YYYY HH:mm") + " von " + item.createdBy;
        } else {
          return "";
        }
      },
      toUpdatedString: function (updateDate, updatedBy) {
        if (updatedBy != null && updateDate != null) {
          return "Geändert am " + moment(updateDate).format("DD.MM.YYYY HH:mm") + " von " + updatedBy;
        } else {
          return "";
        }
      },
      getIcon (worklogType) {
        switch (worklogType) {
        case "WORK":
          return "mdi-wrench";
        case "PAUSE":
          return "mdi-pause";
        case "TRAVEL":
          return "mdi-steering";
        case "PASSENGER":
          return "mdi-van-passenger";
        }

        return "";
      }
    }
  };
</script>
