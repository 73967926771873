
  import Vue from "vue";
  import Component from "vue-class-component";
  import {MessageTypes} from "@/components/message-types";
  import PayrollUploadDragAndDrop from "@/views/MonthEndClosing/PayrollUploadDragAndDrop.vue";
  import PayrollUploadDialog from "@/views/MonthEndClosing/PayrollUploadDialog.vue";
  import MonthEndClosingService from "@/service/MonthEndClosingService";

  @Component({
    components: {
      PayrollUploadDialog,
      PayrollUploadDragAndDrop
    }
  })
  export default class MonthEndClosing extends Vue {
    items: Array<any> = [];
    uploadedFiles: Array<any> = [];

    processSage () {
      this.$eventBus.$emit(MessageTypes.LOADING, "Erzeuge SAGE Datei");
      MonthEndClosingService.createSageExport()
        .then(response => {
          if (response) {
            this.$eventBus.$emit(MessageTypes.SUCCESS, "SAGE Datei erzeugt");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const contentDisposition = response.headers["content-disposition"];
            // @ts-ignore
            const match = contentDisposition.match(/filename=(.+)/);
            if (match && match[1]) {
              const filename = match[1];
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
            }
          } else {
            this.$eventBus.$emit(MessageTypes.ERROR, "SAGE Datei konnte nicht erzeugt werden");
          }
        })
        .catch(() => {
          this.$eventBus.$emit(MessageTypes.ERROR, "SAGE Datei konnte nicht erzeugt werden");
        });
    }

    uploadClicked () {
      // @ts-ignore
      this.$refs.uploadButton.click();
    }

    upload (event: Event | DragEvent) {
      let files: Array<File> = [];

      if (event) {
        // @ts-ignore
        files = (event instanceof DragEvent) ? (event as DragEvent).dataTransfer.files : event.target.files;
      }

      if (files.length) {
        // @ts-ignore
        this.$refs.uploadDialog.open()
          // @ts-ignore
          .then(confirmResult => {
            if (confirmResult && confirmResult.success) {
              this.$eventBus.$emit(MessageTypes.LOADING, "Datei wird hochgeladen");
              MonthEndClosingService.upload(files[0], confirmResult.yearMonth, confirmResult.documentType)
                .then(() => {
                  this.clearInput();
                  this.$eventBus.$emit(MessageTypes.SUCCESS, "Datei erfolgreich hochgeladen.");
                })
                .catch(() => {
                  this.clearInput();
                  this.$eventBus.$emit(MessageTypes.ERROR, "Datei konnte nicht hochgeladen werden.");
                });
            } else {
              this.clearInput();
              this.$eventBus.$emit(MessageTypes.WARNING, "Upload abgebrochen");
            }
          });
      } else {
        this.$eventBus.$emit(MessageTypes.WARNING, "Keine Datei ausgewählt");
      }
    }

    private clearInput () {
      // @ts-ignore
      this.$refs.uploadButton.value = null;
    }
  }
