import OrderGroup from "@/model/OrderGroup";
import Vue from "vue";
import Vuex, { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import { OrderType } from "@/enums";
import TimeclockModel from "@/model/TimeclockModel";
import TimeclockClockingRequest from "@/model/TimeclockClockingRequest";
import TimeclockService from "@/service/TimeclockService";

Vue.use(Vuex);

export class TimeclockState {
  data: TimeclockModel = new TimeclockModel();
  orderGroups: Array<OrderGroup> = [];
}

export const TimeclockMutations = Object.freeze({
  SET_ORDER_GROUPS: "setOrderGroups"
});

const mutations: MutationTree<TimeclockState> = {
  [TimeclockMutations.SET_ORDER_GROUPS] (state, orderGroups: Array<OrderGroup>) {
    state.orderGroups = orderGroups;
  }
};

export const TimeclockActions = Object.freeze({
  LIST_ORDERS: "listsOrders"
});

const actions: ActionTree<TimeclockState, RootState> = {
  [TimeclockActions.LIST_ORDERS] ({ commit }, date: string) {
    commit(TimeclockMutations.SET_ORDER_GROUPS, []);

    TimeclockService.findAvailableOrders(date)
      .then(response => {
        commit(TimeclockMutations.SET_ORDER_GROUPS, (response.data as Array<OrderGroup>));
      });
  }
};

export const TimeclockGetters = Object.freeze({
  GET_GROUP_BY_TYPE: "getGroupByType",
  GET_SORTED_GROUPS: "getSortedGroups"
});

const getters: GetterTree<TimeclockState, RootState> = {
  [TimeclockGetters.GET_GROUP_BY_TYPE]: (state) => (orderType: OrderType): Array<OrderGroup> => {
    return state.orderGroups
      .filter(group => group.orderType === orderType);
  },
  [TimeclockGetters.GET_SORTED_GROUPS] (state, getters) {
    return getters[TimeclockGetters.GET_GROUP_BY_TYPE](OrderType.INV)
      .concat(getters[TimeclockGetters.GET_GROUP_BY_TYPE](OrderType.MOD))
      .concat(getters[TimeclockGetters.GET_GROUP_BY_TYPE](OrderType.DRY))
      .concat(getters[TimeclockGetters.GET_GROUP_BY_TYPE](OrderType.BEV))
      .concat(getters[TimeclockGetters.GET_GROUP_BY_TYPE](OrderType.MIS))
      .concat(getters[TimeclockGetters.GET_GROUP_BY_TYPE](OrderType.MFR));
  }
};

export class ClockingParameters {
  public orderId: number;

  public date: string;

  public time: string;

  public employees: Array<TimeclockClockingRequest>;

  public messageProgress: string;

  public messageSuccess: string;

  public messageValidation: string;
}

export const Namespace = "timeclock";
export const TimeclockModule = "timeclock";

export const timeclock: Module<TimeclockState, RootState> = {
  namespaced: true,
  state: new TimeclockState(),
  getters,
  actions,
  mutations
};
