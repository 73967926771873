
  import {defineComponent, reactive, toRefs} from "vue";
  import StatisticReportsWorkingHoursDialog from "@/views/Statistic/StatisticReportsWorkingHoursDialog.vue";
  import StatisticReportsInventoryPerformancesDialog
    from "@/views/Statistic/StatisticReportsInventoryPerformancesDialog.vue";

  export default defineComponent({
    name: "StatisticReports",
    components: {
      StatisticReportsInventoryPerformancesDialog,
      StatisticReportsWorkingHoursDialog
    },
    setup(props, context) {
      const state = reactive({
        workingHoursDialog: false,
        inventoryPerformancesDialog: false
      });

      const showInventoryPerformancesDialog = () => {
        state.inventoryPerformancesDialog = true;
      };

      const showWorkingHoursDialog = () => {
        state.workingHoursDialog = true;
      };

      return {
        ...toRefs(state),
        showInventoryPerformancesDialog,
        showWorkingHoursDialog
      };
    }
  });
