
  import Vue from "vue";
  import Component from "vue-class-component";
  import FabBlockButton from "@/components/FabBlockButton.vue";
  import TimeclockEmployeeEntry from "@/model/TimeclockEmployeeEntry";
  import {
    ClockingParameters,
    TimeclockActions,
    TimeclockGetters,
    TimeclockModule
  } from "@/store/modules/timeclock";
  import { Action, Getter } from "vuex-class";
  import TimeclockClockingRequest from "@/model/TimeclockClockingRequest";
  import { HeaderGetters, HeaderModule } from "@/store/modules/header";
  import TimeclockOrderEmployeeList from "@/views/Timeclock/TimeclockOrderEmployeeList.vue";
  import TimeclockOrderTabSelectableList
    from "@/views/Timeclock/TimeclockOrderSelectableEmployeeList.vue";
  import { Prop, Watch } from "vue-property-decorator";
  import WorklogUtils from "@/WorklogUtils";
  import Utils from "@/Utils";

  @Component({
    components: {
      FabBlockButton,
      TimeclockOrderEmployeeList,
      TimeclockOrderTabSelectableList
    }
  })
  export default class TimeclockOrderTabClockOut extends Vue {
    @Getter(HeaderGetters.GET_DATE, { namespace: HeaderModule }) date: string;

    @Getter(HeaderGetters.GET_TIME, { namespace: HeaderModule }) time: string;

    @Prop({ default: () => [] })
    public items: Array<any>;

    @Prop({ default: () => [] })
    public errors: Array<any>;

    private selectedItems = [];

    @Watch("items")
    onItemsChanged () {
      this.selectedItems.length = 0;
    }

    get clockedIn () {
      return this.items
        .filter(item => WorklogUtils.getOpenWorklog(this.filterOrderRelevantWorklogs(item.worklogs)));
    }

    get clockedOut () {
      return this.items
        .filter(item => item.worklogs && item.worklogs.length > 0)
        .filter(item => !WorklogUtils.getOpenWorklog(item.worklogs));
    }

    get allItemsOut () {
      // @ts-ignore
      return this.selectedItems.length === this.clockedIn.length && this.selectedItems.every(item => item.clockingAction === "OUT");
    }

    get orderId (): number {
      return Number(this.$route.params.orderId);
    }

    filterOrderRelevantWorklogs (worklogs: any) {
      return worklogs ? worklogs.filter((worklog: any) => worklog.order.id === this.orderId) : [];
    }

    isOptionAvailable (item: TimeclockEmployeeEntry, worklogType: string) {
      // @ts-ignore
      const found = WorklogUtils.getOpenWorklog(item.worklogs);
      if (found) {
        // @ts-ignore
        return found.worklogType !== worklogType;
      } else {
        return true;
      }
    }

    onClick (item: TimeclockEmployeeEntry, worklogType: string, clockingAction: string) {
      const found = this.findItemInSelection(item);
      if (found) {
        this.deselectItem(item);
        // @ts-ignore
        if (found.worklogType !== worklogType) {
          this.selectItem(item, worklogType, clockingAction);
        }
      } else {
        this.selectItem(item, worklogType, clockingAction);
      }
    }

    findItemInSelection (item: TimeclockEmployeeEntry) {
      return this.selectedItems.find(selectedItem => {
        // @ts-ignore
        return selectedItem.employeeId === item.id;
      });
    }

    selectItem (item: TimeclockEmployeeEntry, worklogType: string, clockingAction: string) {
      const found = this.findItemInSelection(item);
      if (!found) {
        // @ts-ignore
        this.selectedItems.push({ employeeId: item.id, worklogType: worklogType, clockingAction: clockingAction });
      }
    }

    deselectItem (item: TimeclockEmployeeEntry) {
      const found = this.findItemInSelection(item);
      if (found) {
        Utils.remove(this.selectedItems, found);
      }
    }

    itemColor (item: TimeclockEmployeeEntry, worklogType: string) {
      const found = this.findItemInSelection(item);
      // @ts-ignore
      return found && found.worklogType === worklogType ? "primary" : "";
    }

    clockOut () {
      const employees: Array<TimeclockClockingRequest> = [];
      this.selectedItems.forEach((item: TimeclockEmployeeEntry) => {
        const clockingRequest = new TimeclockClockingRequest();
        // @ts-ignore
        clockingRequest.employeeId = item.employeeId;
        if (item.worklogType) {
          clockingRequest.worklogType = item.worklogType;
        }
        if (item.clockingAction) {
          clockingRequest.clockingAction = item.clockingAction;
        }
        employees.push(clockingRequest);
      });

      const params = new ClockingParameters();
      params.orderId = Number(this.$route.params.orderId);
      params.date = this.date;
      params.time = this.time;
      params.employees = employees;
      params.messageProgress = "Stemple...";
      params.messageSuccess = "Ein- und Ausstempeln erfolgreich!";
      params.messageValidation = "Ein- und Ausstempeln teilweise erfolgreich.";

      this.$emit("clockOut", params);
    }

    selectAll (worklogType: string, clockingAction: string) {
      if (clockingAction === "OUT") {
        // This is the case when all items have already been selected for that type and we hit the button again
        // to deselect them
        const deselectAllItems = this.allItemsOut;

        this.selectedItems = [];
        if (!deselectAllItems) {
          this.clockedIn.forEach(item => {
            // @ts-ignore
            this.selectedItems.push({ employeeId: item.id, worklogType: null, clockingAction: clockingAction });
          });
        }
      } else {
        // This is the case when all items have already been selected for that type and we hit the button again
        // to deselect them
        const deselectAllItems = this.allItemsEqualWorklogType(worklogType);

        this.selectedItems = [];
        if (!deselectAllItems) {
          this.clockedIn
            .filter(item => this.isOptionAvailable(item, worklogType))
            .forEach(item => {
              // @ts-ignore
              this.selectedItems.push({ employeeId: item.id, worklogType: worklogType, clockingAction: clockingAction });
            });
        }
      }
    }

    allItemsEqualWorklogType (worklogType: string) {
      // @ts-ignore
      return this.selectedItems.length === this.clockedIn.length && this.selectedItems.every(item => item.worklogType === worklogType);
    }
  }
