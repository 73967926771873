
  import Vue from "vue";

  export default Vue.extend({
    name: "TheBottomSheet",
    props: {
      title: {
        type: String,
        default: ""
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      dialog: false
    }),
    watch: {
      dialog (val) {
        // re-sync parents v-model if opened via button on activator slot
        this.$emit("input", val);
      },
      value (val) {
        this.dialog = val;
      }
    },
    methods: {
      close () {
        this.dialog = false;
        this.$emit("input", false);
        this.$emit("close");
      }
    }
  });
