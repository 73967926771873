
  import {computed, defineComponent, onMounted, reactive, Ref, toRefs} from "vue";
  import ModalDialog from "@/components/ModalDialog.vue";
  import {MessageTypes} from "@/components/message-types";
  import {MarketArea} from "@/model/MarketArea/MarketArea";
  import InventoriesMarketAreasFormCard from "./InventoriesMarketAreasFormCard.vue";
  import InventoriesMarketAreasGeneralForm from "../forms/InventoriesMarketAreasGeneralForm.vue";
  import InventoriesService from "@/service/InventoriesService";
  import {AxiosError} from "axios";
  import ValidationError from "@/model/ValidationError";
  import eventBus from "@/eventbus";
  import {helpers, required} from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import {t} from "@/mixin/mixins";

  export default defineComponent({
    name: "InventoriesMarketAreasDialog",
    components: {
      InventoriesMarketAreasFormCard,
      InventoriesMarketAreasGeneralForm,
      ModalDialog
    },
    setup() {

      const state = reactive({
        dialog: false,
        edit: false,
        marketArea: {} as MarketArea,
        oldMarketAreaName: "",
        serverErrors: new Map<string, string>()
      });

      onMounted(() => {
        eventBus.$on(MessageTypes.OPEN_MARKET_AREA_DIALOG, (editedMarketArea: MarketArea) => {
          state.serverErrors = new Map<string, string>();

          if (editedMarketArea) {
            state.edit = true;
            state.marketArea = {...editedMarketArea};
            if (state.marketArea.name) state.oldMarketAreaName = state.marketArea.name;
          } else {
            state.edit = false;
            state.marketArea = {} as MarketArea;
            state.marketArea.id = -1;
            state.oldMarketAreaName = "";
          }
          state.dialog = true;
        });
      });

      const onMarketAreaChanged = (newMarketArea: MarketArea) => {
        state.marketArea = newMarketArea;
      };

      const save = () => {
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          if (!state.marketArea.name || !state.marketArea.name.trim()) state.marketArea.name = null;

          if (state.marketArea.id == -1) {
            InventoriesService.createMarketArea(state.marketArea)
              .then(response => {
                const newMarketArea = response.data;
                eventBus.$emit(MessageTypes.MARKET_AREA_CREATED, newMarketArea);
                closeDialog();
                eventBus.$emit(MessageTypes.SUCCESS, t("inventories.marketAreas.message.success.create"), 1000);
              })
              .catch((error: AxiosError) => {
                if (error && error.response && error.response.status === 422) {
                  eventBus.$emit(MessageTypes.WARNING, t("checkInput"));
                  const errorMap = new Map<string, string>();
                  // @ts-ignore
                  error.response.data.forEach((error: ValidationError) => {
                    errorMap.set(error.path, error.message);
                  });
                  state.serverErrors = errorMap;
                } else {
                  eventBus.$emit(MessageTypes.ERROR, t("inventories.marketAreas.message.error.create"));
                }
              });
          } else {
            InventoriesService.updateMarketArea(state.marketArea)
              .then(response => {
                const newMarketArea = response.data;
                eventBus.$emit(MessageTypes.MARKET_AREA_CREATED, newMarketArea);
                closeDialog();
              })
              .catch((error: AxiosError) => {
                if (error && error.response && error.response.status === 422) {
                  eventBus.$emit(MessageTypes.WARNING, t("checkInput"));
                  const errorMap = new Map<string, string>();
                  // @ts-ignore
                  error.response.data.forEach((error: ValidationError) => {
                    errorMap.set(error.path, error.message);
                  });
                  state.serverErrors = errorMap;
                } else {
                  eventBus.$emit(MessageTypes.ERROR, t("inventories.marketAreas.message.error.edit"));
                }
              });
          }
        }
      };

      const closeDialog = () => {
        state.dialog = false;
        state.marketArea = {} as MarketArea;
        state.oldMarketAreaName = "";
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
      };

      const rules = computed(() => ({
        marketArea: {
          name: {
            required: helpers.withMessage(t("inventories.marketAreas.validation.name"), required),
          }
        }
      }));

      // @ts-ignore
      const v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (state.serverErrors.size > 0) {
          return state.serverErrors;
        } else {
          const model = v$.value.marketArea;
          if (model.$dirty) {
            if(model.name.$error) {
              errors.set("name", model.name.$errors[0].$message);
            }
          }
        }
        return errors;
      });

      return {
        ...toRefs(state),
        onMarketAreaChanged,
        save,
        closeDialog,
        errors
      };
    },
  });
