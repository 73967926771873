
  import {defineComponent} from "vue";

  export default defineComponent({
    name: "FormListEntry",
    props: {
      icon: {
        type: Boolean
      }
    }
  });
