
  import Vue from "vue";
  import moment, { Moment } from "moment";
  import DateRangeUtils, { DateRange, DateRangeUpdateVO } from "@/components/DateRangeUtils";

  export default Vue.extend({
    name: "DateRangeSelection",
    props: {
      format: {
        type: String,
        default: "DD.MM.YYYY"
      }
    },
    data: () => ({
      panel: 0,
      selectedDateRange: (null as unknown as DateRange | null),
      selectedItem: 0,
      fromDialog: false,
      toDialog: false,
      from: "",
      to: ""
    }),
    computed: {
      dateText (): string {
        return DateRangeUtils.dateText(this.t, this.selectedDateRange as DateRange, this.from, this.to);
      }
    },
    mounted () {
      this.selectedDateRange = DateRange.LAST_TWO_WEEKS;
      this.from = moment().subtract(14, "days").format("YYYY-MM-DD");
      this.to = moment().format("YYYY-MM-DD");
    },
    methods: {
      applyDates () {
        this.selectedDateRange = null;
        const from = moment(this.from, "YYYY-MM-DD", true);
        const to = moment(this.to, "YYYY-MM-DD", true);
        this.updateDates(from, to);
      },
      lastTwoWeeks () {
        this.selectedDateRange = DateRange.LAST_TWO_WEEKS;
        this.updateDates(moment().subtract(14, "days"), moment());
      },
      currentMonth () {
        this.selectedDateRange = DateRange.CURRENT_MONTH;
        this.updateDates(moment().startOf("month"), moment().endOf("month"));
      },
      lastMonth () {
        this.selectedDateRange = DateRange.LAST_MONTH;
        const from = moment().subtract(1, "month").startOf("month");
        const to = moment().subtract(1, "month").endOf("month");
        this.updateDates(from, to);
      },
      currentYear () {
        this.selectedDateRange = DateRange.CURRENT_YEAR;
        const from = moment().startOf("year");
        const to = moment().endOf("year");
        this.updateDates(from, to);
      },
      lastYear () {
        this.selectedDateRange = DateRange.LAST_YEAR;
        const from = moment().subtract(1, "years").startOf("year");
        const to = moment().subtract(1, "years").endOf("year");
        this.updateDates(from, to);
      },
      updateDates (from: Moment, to: Moment) {
        const fromString = from.format("YYYY-MM-DD");
        const toString = to.format("YYYY-MM-DD");
        const dateRangeSelection = new DateRangeUpdateVO(this.selectedDateRange, fromString, toString);
        this.$emit("date-change", dateRangeSelection);
      }
    }
  });
