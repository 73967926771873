export interface OrderEmployeeAssignment {
  assignmentType: OrderEmployeeAssignmentType;
  employee: Employee;
}

export interface Employee {
  employeeId: number;
  firstName: boolean;
  lastName: string;
}

export enum OrderEmployeeAssignmentType {
  SUP = "SUP",
  EMP = "EMP"
}
