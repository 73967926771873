
  import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, toRefs} from "vue";
  import CustomersList from "@/views/Customers/CustomersList.vue";
  import CustomersTable from "@/views/Customers/CustomersTable.vue";
  import CustomersCreateDialog from "@/views/Customers/create/CustomersCreateDialog.vue";
  import FabButton from "@/components/FabButton.vue";
  import SwitchView from "@/components/SwitchView.vue";
  import Customer from "@/model/Customer";
  import CustomerService from "@/service/CustomerService";
  import {MessageTypes} from "@/components/message-types";
  import FilterUtils from "@/FilterUtils";
  import eventBus from "@/eventbus";
  import {useRouter} from "@/RouterUtils";

  export default defineComponent({
    name: "Customers",
    components: {
      CustomersCreateDialog,
      CustomersList,
      CustomersTable,
      FabButton,
      SwitchView
    },
    setup(_props, context) {

      const router = useRouter();

      const initialState = {
        items: new Array<Customer>(),
        loading: false,
        scrolledToBottom: false,
        search: ""
      };
      const state = reactive({...initialState});

      const load = () => {
        state.loading = true;
        Promise.all([CustomerService.getCustomer()]).then((values) => {
          state.items = values[0];
          state.loading = false;
        });
      };

      const scroll = () => {
        window.onscroll = () => {
          state.scrolledToBottom = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;
        };
      };

      const openCreateCustomerDialog = () => {
        eventBus.$emit(MessageTypes.OPEN_CUSTOMER_CREATE);
      };

      const filteredCustomers = computed(() => {
        let filtered = state.items.slice(0);

        if (!state.search || state.search === "") {
          return filtered;
        } else {
          return filtered.filter(customer => {
            return FilterUtils.compare(customer.name, state.search)
              || FilterUtils.compare(customer.company, state.search)
              || FilterUtils.compare(customer.addressAsString, state.search);
          });
        }
      });

      const isListView = computed(() => {
        return router.currentRoute.query.view === "list";
      });

      onMounted(() => {
        load();
        scroll();

        eventBus.$on(MessageTypes.CUSTOMER_CREATE_SUCCESS, (customer: Customer) => {
          router.push({ path: `/customers/${customer.id}/info` });
        });
      });

      onBeforeUnmount(() => {
        eventBus.$off(MessageTypes.CUSTOMER_CREATE_SUCCESS);
      });

      return {...toRefs(state), filteredCustomers, isListView, openCreateCustomerDialog};
    }
  });
