import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"10"}},[_c(VTextField,{attrs:{"value":_vm.fileName,"disabled":"","prepend-icon":"mdi-camera"}})],1),_c(VCol,{attrs:{"cols":"2"}},[(_vm.statusDeletable)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":_vm.deleteFile}},on),[_c(VIcon,[_vm._v(" mdi-delete ")])],1)]}}],null,false,394538276)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.delete')))])]):(_vm.statusInProgress)?_c(VProgressCircular,{attrs:{"size":"24","indeterminate":"","color":"primary"}}):(_vm.statusSuccess)?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-check-circle-outline ")]):(_vm.statusError)?_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" mdi-cancel ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }