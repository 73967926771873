<template>
  <VDialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <VCard>
      <VCardTitle class="pb-0">
        <span class="header-title">
          <h2>
            {{ title }}
          </h2>
        </span>
        <VSpacer />
        <VBtn
          class="close-button"
          icon
          @click.stop="close(false)"
        >
          <VIcon class="close-button-icon">
            mdi-close
          </VIcon>
        </VBtn>
      </VCardTitle>

      <VCardText class="pa-0">
        <VList two-line>
          <VListItem
            v-for="(item) in items"
            :key="item.worklogId"
            ripple
          >
            <VListItemIcon>
              <VIcon
                v-if="item.active"
                color="primary"
              >
                mdi-check
              </VIcon>
            </VListItemIcon>

            <VListItemContent>
              <VListItemTitle>
                {{ item.employeeName }}
              </VListItemTitle>
              <VListItemSubtitle class="text--primary">
                {{ item.worklogType }}
              </VListItemSubtitle>
              <VListItemSubtitle>
                {{ item.orderName }} - {{ item.start | normalizeTimeString }} - {{ item.end | normalizeTimeString }}
              </VListItemSubtitle>
            </VListItemContent>

            <VListItemAction>
              <VListItemActionText>
                {{ toCreatedString(item) }}
              </VListItemActionText>
              <VListItemActionText>
                {{ toUpdatedString(item.updateDate, item.updatedBy) }}
              </VListItemActionText>
            </VListItemAction>
          </VListItem>
        </VList>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
  export default {
    data: () => ({
      items: [],
      dialog: false,
      title: ""
    }),
    methods: {
      open: function (employee, date, orderId = null) {
        this.dialog = true;
        this.title = employee.firstName + " " + employee.lastName;

        let requestString = "/clockingHistory/" + employee.id + "/" + date;
        if (orderId != null) {
          requestString += "/" + orderId;
        }

        this.$axios
          .get(requestString)
          .then(response => {
            this.items = response.data;
          });
      },
      close: function () {
        this.dialog = false;
      },
      toCreatedString (item) {
        return "Erstellt am " + this.$moment(item.creationDate).format("DD.MM.YYYY HH:mm") + " von " + item.createdBy;
      },
      toUpdatedString: function (updateDate, updatedBy) {
        if (updatedBy != null && updateDate != null) {
          return "Geändert am " + this.$moment(updateDate).format(
            "DD.MM.YYYY HH:mm") + " von " + updatedBy;
        } else {
          return "";
        }
      }
    }
  };
</script>
