import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,{attrs:{"justify":"start"}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{attrs:{"hover":"","height":"100%"}},[_c(VCardTitle,[_vm._v(" Sage Datei erzeugen ")]),_c(VCardText,[_vm._v(" Erzeugt die Sage-Datei für den vorangegangen Monat. ")]),_c(VCardSubtitle,[_c('strong',[_vm._v(" Hinweis: ")]),_vm._v(" Nach Erzeugen der Sage-Datei können keine Änderungen an Gehältern, Arbeitszeiten etc. für den Monat vorgenommen werden. ")]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.processSage()}}},[_vm._v(" Datei erzeugen ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{attrs:{"hover":"","height":"100%"}},[_c(VCardTitle,[_vm._v(" Abrechnungen hochladen ")]),_c(VCardText,[_vm._v(" Abrechnungen hochladen. ")]),_c(VCardSubtitle,[_c('strong',[_vm._v(" Hinweis: ")]),_c('br'),_vm._v(" Dateien können auch per Drag & Drop hochgeladen werden. ")]),_c(VSpacer),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.uploadClicked}},[_vm._v(" Dateien hochladen ")])],1)],1)],1)],1),_c('input',{ref:"uploadButton",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"*"},on:{"change":_vm.upload}}),_c('PayrollUploadDragAndDrop',{on:{"drop":_vm.upload}}),_c('PayrollUploadDialog',{ref:"uploadDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }