<template>
  <VList
    v-if="items.length > 0"
    dense
  >
    <VSubheader>
      {{ title }}
    </VSubheader>
    <template v-for="item in items">
      <VListItem
        :key="item.id"
        disabled
      >
        <VListItemContent>
          <VListItemTitle>
            {{ (item.fullName ) ? item.fullName : item.firstName + " " + item.lastName }}
          </VListItemTitle>
          <VListItemSubtitle>
            {{ (item.statusMessage) ? item.statusMessage : getStatusMessage(item) }}
          </VListItemSubtitle>
        </VListItemContent>
      </VListItem>
    </template>
  </VList>
</template>

<script>
  import WorklogUtils from "@/WorklogUtils";

  export default {
    name: "TimeclockOrderEmployeeList",
    props: {
      items: {
        type: Array,
        default: () => []
      },
      title: {
        type: String,
        default: ""
      }
    },
    methods: {
      getStatusMessage (item) {
        const openWorklog = WorklogUtils.getOpenWorklog(item.worklogs);

        let statusMessage = "";
        if (openWorklog) {
          switch (openWorklog.worklogType) {
          case "WORK":
            statusMessage += "Arbeit";
            break;
          case "PAUSE":
            statusMessage += "Pause";
            break;
          case "TRAVEL":
            statusMessage += "Reise";
            break;
          case "PASSENGER":
            statusMessage += "Mitfahrer";
            break;
          }

          statusMessage += " seit " + openWorklog.start;
        } else if (item.worklogs.length > 0) {
          const lastWorklog = item.worklogs[item.worklogs.length - 1];
          statusMessage = "Ausgestempelt seit " + lastWorklog.end;
        }

        return statusMessage;
      }
    }
  };
</script>
