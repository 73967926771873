import axios, {AxiosResponse} from "axios";
import {EmployeeCompetencies} from "@/model/EmployeeCompetencies";
import {PagedResult} from "@/service/PagedResult";

const PATH = "/employee-competencies";

export interface EmployeeCompetenciesFilter {
    employeeId?: number | null;
    firstName?: string | null;
    lastName?: string | null;
    companyLocationId?: number | null;
    fruitAndVegetables?: boolean | null;
    checkout?: boolean | null;
    flowers?: boolean | null;
    storage?: boolean | null;
    storageManagement?: boolean | null;
    weigh?: boolean | null;
    reuse?: boolean | null;
    check?: boolean | null;
    targetActualComparison?: boolean | null;
    allrounder?: boolean | null;
    quickCounter?: boolean | null;
}

export default {
    findEmployeeCompetencies (filter: EmployeeCompetenciesFilter | null, size: number | null, page: number | null, sort: string | null): Promise<AxiosResponse<PagedResult>> {
        return axios.get(PATH, {
            params: {
                employeeId: filter?.employeeId,
                firstName: filter?.firstName,
                lastName: filter?.lastName,
                companyLocationId: filter?.companyLocationId,
                allrounder: filter?.allrounder,
                quickCounter: filter?.quickCounter,
                fruitAndVegetables: filter?.fruitAndVegetables,
                checkout: filter?.checkout,
                flowers: filter?.flowers,
                storage: filter?.storage,
                storageManagement: filter?.storageManagement,
                weigh: filter?.weigh,
                reuse: filter?.reuse,
                check: filter?.check,
                targetActualComparison: filter?.targetActualComparison,
                size: size ? size : 250,
                page: page ? page : 0,
                sort: sort ? sort : null
            }
        });
    },
    createEmployeeCompetencies (employeeId: number, employeeCompetencies: EmployeeCompetencies) {
        return axios.post(
            `${PATH}/${employeeId}`,
            employeeCompetencies
        );
    },
    updateEmployeeCompetencies (employeeCompetenciesId: number, employeeCompetencies: EmployeeCompetencies) {
        return axios.put(
            `${PATH}/${employeeCompetenciesId}`,
            employeeCompetencies
        );
    },
};
