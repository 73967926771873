

  import {defineComponent, PropType} from "vue";
  import Customer from "@/model/Customer";

  export default defineComponent({
    name: "CustomersList",
    components: {

    },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      items: {
        type: Array as PropType<Array<Customer>>,
        default: () => []
      }
    }
  });
