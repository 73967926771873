
  import {defineComponent, onMounted, reactive, toRefs} from "vue";
  import InventoriesCompanyLocations from "./InventoriesCompanyLocations/InventoriesCompanyLocations.vue";
  import InventoriesMarketAreas from "./InventoriesMarketAreas/InventoriesMarketAreas.vue";
  import InventoriesCountingPerformance from "./InventoriesCountingPerformance/InventoriesCountingPerformance.vue";
  import router from "@/router";
  import InventoriesCompetencies from "./InventoriesCompetencies/InventoriesCompetencies.vue";

  export default defineComponent({
    name: "Inventories",
    components: {
      InventoriesCompanyLocations,
      InventoriesMarketAreas,
      InventoriesCountingPerformance,
      InventoriesCompetencies
    },
    setup() {
      const initialState = {
        activeTab: 0
      };

      const state = reactive({...initialState});

      onMounted(() => {
        const queryTab = router.currentRoute.query.tab?.toString();
        if (queryTab) {
          state.activeTab = parseInt(queryTab);
        }
      });

      return {
        ...toRefs(state)
      };
    }
  });
