
  import Component from "vue-class-component";

  import AccountingService from "../../../service/AccountingService";
  import {Prop, Vue, Watch} from "vue-property-decorator";
  import Employee from "@/model/Employee";
  import EmployeeName from "@/model/EmployeeName";

  @Component
  export default class WorklogDetailsDialogEmployeeField extends Vue {
    @Prop({default: () => new EmployeeName()})
    public value: EmployeeName;

    @Prop({default: false})
    public disabled: boolean;

    @Prop({default: () => []})
    public errorMessages: Array<string>;

    private loading = false;
    private search = "";
    private searchItems: Array<EmployeeName> = [];
    private typeTimeout = 200;

    @Watch("search")
    onSearchChanged(term: string) {
      clearTimeout(this.typeTimeout);

      const vue = this;
      if (term != null && term.length > 2) {
        this.typeTimeout = setTimeout(() => {
          term && vue.findEmployees(term);
        }, vue.typeTimeout);
      }
    }

    findEmployees(term: string) {
      this.loading = true;

      AccountingService.findEmployees(term)
        .then(response => {
          this.searchItems = response.data
            .map(employeeName => Object.assign(new EmployeeName(), employeeName));
        })
        .finally(() => {
          this.loading = false;
        });
    }

    employeeSelected(item: Employee) {
      this.search = "";
      this.$emit("input", item);
    }
  }
