import axios from "axios";
import { ClockingParameters } from "@/store/modules/timeclock";

const PATH = "/timeclock";

export default {
  findAvailableOrders (date: string) {
    return axios.get(`${PATH}`, {
      params: {
        date: date
      }
    });
  },
  getOrder (orderId: number, date: string) {
    return axios.get(`${PATH}/${orderId}`, {
      params: {
        date: date
      }
    });
  },
  findEmployees (orderId: number, date: string, term: string) {
    return axios.get(`${PATH}/${orderId}/employees`, {
      params: {
        date: date,
        term: term
      }
    });
  },
  clock (orderId: number, params: ClockingParameters) {
    return axios.post(`${PATH}/${orderId}/clock`, {
      date: params.date,
      time: params.time,
      employees: params.employees
    });
  }
};
