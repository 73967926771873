import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,{staticClass:"text-xs-center"},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VSelect,{attrs:{"items":_vm.documentTypes,"label":"Dokumententyp","required":"","item-text":"name","item-value":"code","rules":[v => !!v || 'Dokumententyp muss gesetzt sein']},model:{value:(_vm.documentType),callback:function ($$v) {_vm.documentType=$$v},expression:"documentType"}}),_c(VMenu,{attrs:{"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VTextField,_vm._g({attrs:{"label":"Monat","append-icon":"mdi-calendar","readonly":"","value":_vm._f("formatDate")(_vm.selectedDate,'MMMM YYYY'),"rules":[v => !!v || 'Datum muss gesetzt sein'],"required":""}},on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c(VDatePicker,{attrs:{"locale":"de-DE","type":"month","color":"primary","required":""},on:{"input":function($event){_vm.menuDate = false}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},nativeOn:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Abbrechen ")]),_c(VBtn,{attrs:{"color":"primary","text":""},nativeOn:{"click":function($event){return _vm.confirm()}}},[_vm._v(" Hochladen ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }