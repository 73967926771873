
  import {defineComponent, reactive, toRefs} from "vue";

  export default defineComponent({
    name: "ConfirmDialogComposition",
    setup (props, context) {
      const initialState = {
        dialog: false,
        title: "",
        text: "",
        result: (updated: boolean | PromiseLike<boolean>) => {
        }
      };

      const state = reactive({...initialState});

      const resetState = () => {
        Object.assign(state, initialState);
      };

      const cancel = () => {
        state.dialog = false;
        state.result(false);
        resetState();
      };

      const confirm = () => {
        state.dialog = false;
        state.result(true);
        resetState();
      };

      const open = (title: string, text: string)   => {
        resetState();

        state.title = title;
        state.text = text;
        state.dialog = true;
        return new Promise((resolve, reject) => {
          state.result = resolve;
        });
      };

      return {
        ...toRefs(state),
        cancel,
        confirm,
        open
      };
    }
  });
