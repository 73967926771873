
  import {defineComponent, watch} from "vue";
  import {useCurrencyInput} from "vue-currency-input";

  export default defineComponent({
    name: "CurrencyField",
    props: {
      value: {
        type: Number,
        default: 0
      },
      label: {
        type: String,
        default: ""
      },
      disabled: {
        type: Boolean,
        default: false
      },
      errorMessage: {
        type: String,
        default: ""
      }
    },
    setup (props, context) {
      const { inputRef, formattedValue, setValue } = useCurrencyInput({
        autoDecimalDigits: true,
        currency: "EUR",
        locale: "de-DE"
      });

      watch(() => props.value, (value) => {
        setValue(value);
      });

      return {
        formattedValue,
        inputRef
      };
    }
  });
