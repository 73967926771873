
  import {defineComponent, onMounted, reactive, toRefs, watch} from "vue";
  import {isAuthorized, t} from "@/mixin/mixins";
  import {EmployeeCountingPerformance} from "@/model/EmployeeCountingPerformance";
  import EmployeeService from "@/service/EmployeeService";
  import PageUtils from "@/components/PageUtils";
  import {employeeId as storeEmployeeId} from "@/store/StoreUtils";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import MonthPicker from "@/components/MonthPicker.vue";
  import router from "@/router";
  import moment from "moment";

  export default defineComponent({
    name: "InventoriesCountingPerformance",
    components: {
      MonthPicker
    },
    setup() {
      const initialState = {
        yearMonth: null as unknown as string,
        countingPerformances: new Array<EmployeeCountingPerformance>(),
        totalPerformance: {} as EmployeeCountingPerformance
      };
      const state = reactive({...initialState});

      watch(() => state.yearMonth, (newValue) => {
        refreshData();
        router.push({
          query: Object.assign({}, router.currentRoute.query, {date: newValue}),
          replace: true
        }).catch(() => {
        });
      });

      const headers = [
        {
          text: t("inventories.countingPerformance.table.header.date"),
          value: "date"
        },
        {
          text: t("inventories.countingPerformance.table.header.name"),
          value: "name"
        },
        {
          text: t("inventories.countingPerformance.table.header.workingHours"),
          value: "workingMinutes"
        },
        {
          text: t("inventories.countingPerformance.table.header.positions"),
          value: "positions"
        },
        {
          text: t("inventories.countingPerformance.table.header.positionsPerHour"),
          value: "positionsPerHour"
        },
        {
          text: t("inventories.countingPerformance.table.header.positionsPerHourInventory"),
          value: "positionsPerHourInventory"
        }
      ];

      onMounted(() => {
        state.yearMonth = moment().format("YYYY-MM");
      });

      const refreshData = () => {
        let employeeId = getEmployeeId();
        if (employeeId) loadCountingPerformancesOfEmployee(employeeId);
      };

      const getEmployeeId = () => {
        const employeeIdParam = router.currentRoute.query.employeeId?.toString();
        if (employeeIdParam && isAuthorized("Statistic")) {
          return parseInt(employeeIdParam);
        } else {
          return parseInt(storeEmployeeId?.value);
        }
      };

      const loadCountingPerformancesOfEmployee = (employeeId: number) => {
        if (employeeId && state.yearMonth) {
          EmployeeService.getCountingPerformances(employeeId, state.yearMonth)
            .then(response => {
              const countingPerformances: Array<EmployeeCountingPerformance> = PageUtils.extractCollection(response.data, "countingPerformances");
              state.countingPerformances = calcPerformances(countingPerformances);
            })
            .catch(() => {
              eventBus.$emit(MessageTypes.ERROR, t("errors.generalError"));
            });
        }
      };

      const calcPerformances = (performances: Array<EmployeeCountingPerformance>) => {
        return performances.map(p => {
          p.positionsPerHour = positionsPerHour(p.workingMinutes, p.positions);
          p.positionsPerHourInventory = positionsPerHour(p.workingMinutesTotal, p.positionsTotal);
          return p;
        });
      };

      watch(() => state.countingPerformances, (newValue) => {
        calcTotalPerformance(newValue);
      });

      const calcTotalPerformance = (performances: EmployeeCountingPerformance[]) => {
        const employeeWorkingMinutes = performances.reduce((a, b) => a + (b.workingMinutes ?? 0), 0);
        const employeePositions = performances.reduce((a, b) => a + (b.positions ?? 0), 0);
        const totalWorkingMinutes = performances.reduce((a, b) => a + (b.workingMinutesTotal ?? 0), 0);
        const totalPositions = performances.reduce((a, b) => a + (b.positionsTotal ?? 0), 0);
        state.totalPerformance = {
          workingMinutes: employeeWorkingMinutes,
          positions: employeePositions,
          positionsPerHour: positionsPerHour(employeeWorkingMinutes, employeePositions),
          positionsPerHourInventory: positionsPerHour(totalWorkingMinutes, totalPositions)
        };
      };

      const positionsPerHour = (minutes: number | undefined, positions: number | undefined) => {
        if (minutes && minutes > 0) {
          return Math.round(positions ? positions / (minutes / 60) : 0);
        } else {
          return 0;
        }
      };

      const toTimeFormat = (minutes: number) => {
        const h = Math.floor(minutes / 60);
        const m = minutes % 60;
        const hour = h < 10 ? "0" + h : h;
        const minute = m < 10 ? "0" + m : m;
        return hour + ":" + minute;
      };

      return {
        ...toRefs(state),
        headers,
        positionsPerHour,
        toTimeFormat
      };
    }
  });
