import OrderGroup from "@/model/OrderGroup";
import Vue from "vue";
import Vuex, { ActionTree, MutationTree, Module, GetterTree } from "vuex";
import { RootState } from "@/store/types";
import moment from "moment";

Vue.use(Vuex);

class HeaderDatePickerState {
  date: string = moment().format("YYYY-MM-DD");
}

class HeaderTimePickerState {
  time: string = moment().format("HH:mm");
}

class HeaderMonthPickerState {
  yearMonth: string = moment().format("YYYY-MM");
}

export class HeaderState {
  datePicker: HeaderDatePickerState = new HeaderDatePickerState();
  timePicker: HeaderTimePickerState = new HeaderTimePickerState();
  monthPicker: HeaderMonthPickerState = new HeaderMonthPickerState();
  orderGroups: Array<OrderGroup> = [];
  title = "";
  titleExtension = "";
}

export const HeaderMutations = Object.freeze({
  SET_DATEPICKER_DATE: "setDatePickerDate",
  SET_TIMEPICKER_TIME: "setTimePickerTime",
  SET_MONTHPICKER_MONTH: "setMonthPickerMonth",
  SET_TITLE: "setTitle",
  SET_TITLE_EXTENSION: "setTitleExtension"
});

const mutations: MutationTree<HeaderState> = {
  [HeaderMutations.SET_DATEPICKER_DATE] (state, date: string) {
    state.datePicker.date = date;
  },
  [HeaderMutations.SET_TIMEPICKER_TIME] (state, time: string) {
    state.timePicker.time = time;
  },
  [HeaderMutations.SET_MONTHPICKER_MONTH] (state, yearMonth: string) {
    state.monthPicker.yearMonth = yearMonth;
  },
  [HeaderMutations.SET_TITLE] (state, title: string) {
    state.title = title;
  },
  [HeaderMutations.SET_TITLE_EXTENSION] (state, titleExtension: string) {
    state.titleExtension = titleExtension;
  }
};

export const HeaderActions = Object.freeze({
});

const actions: ActionTree<HeaderState, RootState> = {
};

export const HeaderGetters = Object.freeze({
  GET_DATE: "getDate",
  GET_TIME: "getTime",
  GET_MONTH: "getMonth",
  GET_TITLE: "getTitle",
  GET_TITLE_EXTENSION: "getTitleExtension"
});

const getters: GetterTree<HeaderState, RootState> = {
  [HeaderGetters.GET_DATE]: (state) => {
    return state.datePicker.date;
  },
  [HeaderGetters.GET_TIME]: (state) => {
    return state.timePicker.time;
  },
  [HeaderGetters.GET_MONTH]: (state) => {
    return state.monthPicker.yearMonth;
  },
  [HeaderGetters.GET_TITLE]: (state) => {
    return state.title;
  },
  [HeaderGetters.GET_TITLE_EXTENSION]: (state) => {
    return state.titleExtension;
  }
};

export const Namespace = "header";
export const HeaderModule = "header";

export const header: Module<HeaderState, RootState> = {
  namespaced: true,
  state: new HeaderState(),
  getters,
  actions,
  mutations
};
