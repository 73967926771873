
  import {RegistrationDataEmployment} from "@/model/Registration";
  import {helpers, minValue, numeric, required, requiredIf} from "@vuelidate/validators";
  import {VALUE} from "@/plugins/i18n";
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    SetupContext,
    toRefs,
    watch
  } from "vue";
  import {useDialog} from "@/views/Registration/RegistrationComposable";
  import useVuelidate, {ErrorObject} from "@vuelidate/core";
  import {ValidationResult} from "@/model/ValidationResult";
  import {t, tWithParms} from "@/mixin/mixins";

  export default defineComponent({
    name: "RegistrationEmploymentForm",
    components: {
      RegistrationCard
    },
    props: {
      step: {
        type: Number,
        default: null
      }
    },
    setup(props, context: SetupContext) {

      const {
        state: generalState,
        registration,
        shortTerm,
        falseValue,
        trueValue,
        mobile,
        next
      } = useDialog(context);

      const initialState = {
        model: {
          employment: new RegistrationDataEmployment()
        }
      };

      const state = reactive({...initialState});

      const rules = computed(() => ({
        model: {
          employment: {
            mainEmployer: {
              required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required)
            },
            otherEmployments: {
              required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required)
            },
            otherEmployerInCurrentYear: {
              required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required)
            },
            daysShortTermEmployment: {
              required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), requiredIf(function (): boolean {
                return state.model.employment.otherEmployerInCurrentYear && shortTerm.value;
              })),
              numeric: helpers.withMessage(tWithParms("validation.numeric", t(VALUE)), numeric),
              minValue: helpers.withMessage(tWithParms("validation.greaterThanOrEqualTo", t(VALUE), "1"), minValue(1))
            }
          }
        }
      }
      ));

      let v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (generalState.serverErrors.length > 0) {
          generalState.serverErrors.forEach((error: ValidationResult) => {
            errors.set(error.path, error.message);
          });

          return errors;
        } else {
          const model = v$.value;
          if (model.$dirty) {
            model.$errors.forEach((error: ErrorObject) => {
              if(!errors.has(error.$propertyPath)) {
                // @ts-ignore
                errors.set(error.$propertyPath, error.$message);
              }
            });
          }
        }

        return errors;
      });

      watch(() => registration.employment, (newValue, oldValue) => {
        if (newValue) {
          state.model.employment = Object.assign(state.model.employment, newValue);
        }
      });

      watch(() => state.model.employment.otherEmployerInCurrentYear, (newValue, oldValue) => {
        if (newValue && shortTerm.value) {
          generalState.dirty = true;
        }
      });

      onMounted(() => {
        if(registration.value.employment) {
          state.model.employment = registration.value.employment;
        } else {
          generalState.dirty = true;
        }
      });

      const onNext = () => {
        next(v$, state.model);
      };

      return {
        ...toRefs(state),
        errors,
        falseValue,
        trueValue,
        mobile,
        shortTerm,
        onNext
      };
    }
  });