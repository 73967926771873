
  import Order from "@/model/Order";
  import {defineComponent, PropType} from "vue";
  import {
    getChecklistStatusColor,
    openChecklist
  } from "@/views/Orders/Checklist/OrderChecklistService";

  export default defineComponent({
    name: "OrdersTableV2",
    props: {
      orders: {
        type: Array as PropType<Array<Order>>,
        default: () => new Array<Order>()
      }
    },
    setup (props, context) {
      const footerProps = {
        "items-per-page-options": [-1]
      };

      const headers = [
        {
          text: "",
          align: "center",
          value: "checklist"
        },
        {
          text: "Typ",
          align: "left",
          value: "orderType"
        },
        {
          text: "Name",
          align: "left",
          value: "name"
        },
        {
          text: "Adresse",
          align: "left",
          value: "address"
        },
        {
          text: "PLZ",
          align: "left",
          value: "zip"
        },
        {
          text: "Stadt",
          align: "left",
          value: "city"
        },
        {
          text: "# Mitarbeiter",
          align: "right",
          value: "employeeCount"
        },
        {
          text: "Gültig seit",
          align: "left",
          value: "validFrom"
        },
        {
          text: "Gültig bis",
          align: "left",
          value: "validTo"
        },
        {
          text: "",
          align: "right",
          value: "id",
          sortable: false,
          width: 40
        }
      ];

      const openOrder = (order: Order) => {
        context.emit("orderClicked", order);
      };

      return {
        footerProps,
        getChecklistStatusColor,
        headers,
        openChecklist,
        openOrder
      };
    }
  });
