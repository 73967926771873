<template>
  <VBreadcrumbs
    divider=">"
    :items="breadcrumbs"
  />
</template>
<script>
  export default {
    data: () => ({
    }),
    computed: {
      breadcrumbs () {
        const breadcrumbs = [];
        if (this.$route.path === "/rights") {
          breadcrumbs.push(
            this.rightsmanagement(true));
        } else if (this.$route.path === "/rights/users") {
          breadcrumbs.push(
            this.rightsmanagement(false),
            this.user(true));
        } else if (this.$route.path === "/rights/roles") {
          breadcrumbs.push(
            this.rightsmanagement(false),
            this.roles(true));
        } else if (this.$route.name === "rightsUserDetails") {
          breadcrumbs.push(
            this.rightsmanagement(false),
            this.user(false),
            this.queryName(true));
        } else if (this.$route.name === "rightsRoleUsers") {
          breadcrumbs.push(
            this.rightsmanagement(false),
            this.roles(false),
            this.queryName(false, this.getRoleDetailsLink()),
            this.user(true));
        } else if (this.$route.name === "rightsRoleDetails") {
          breadcrumbs.push(
            this.rightsmanagement(false),
            this.roles(false),
            this.queryName(false));
        }

        return breadcrumbs;
      }
    },
    methods: {
      getRoleDetailsLink: function () {
        const route = this.$route;
        return {
          name: "rightsRoleDetails",
          params: { roleId: route.params.roleId },
          query: { web: true, name: route.query.name }
        };
      },
      rightsmanagement: function (disabled) {
        return {
          text: "Rechtemanagement",
          disabled: disabled,
          to: "/rights",
          exact: true
        };
      },
      user: function (disabled) {
        return {
          text: "User",
          disabled: disabled,
          to: "/rights/users",
          exact: true
        };
      },
      roles: function (disabled) {
        return {
          text: "Rollen",
          disabled: disabled,
          to: "/rights/roles",
          exact: true
        };
      },
      queryName: function (disabled, to) {
        return {
          text: this.$store.state.header.title,
          disabled: disabled,
          to: to
        };
      }
    }
  };
</script>
