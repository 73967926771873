import axios from "axios";

const PATH = "/monthendclosing";

export default {
  createSageExport () {
    return axios.post(`${PATH}/sage`, {
      responseType: "blob"
    });
  },
  listFiles () {
    return axios.get(`${PATH}/list`);
  },
  download (fileKey: string) {
    return axios.get(`${PATH}/download?key=${fileKey}`, {
      responseType: "blob"
    });
  },
  upload (file: File, yearMonth: string, documentType: string) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("yearMonth", yearMonth);
    formData.append("documentType", documentType);

    return axios.post(`${PATH}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
};
