import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('SwitchView',{attrs:{"hide-search":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.fullName)+" "),(!_vm.isEditable)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({attrs:{"color":"primary"}},on),[_vm._v(" mdi-check-circle ")])]}}],null,false,2007487456)},[_c('span',[_vm._v(" "+_vm._s(_vm.t("accounting.monthClosed"))+" ")])]):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [_c('MonthPicker',{attrs:{"max":_vm.maxYearMonth},on:{"input":_vm.dateChanged},model:{value:(_vm.yearMonth),callback:function ($$v) {_vm.yearMonth=$$v},expression:"yearMonth"}})]},proxy:true},{key:"content",fn:function({view}){return [_c(VTabs,{attrs:{"color":"primary"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c(VTab,{attrs:{"to":"worklogs"}},[_vm._v(" "+_vm._s(_vm.t("worklogs"))+" ")]),(_vm.isAuthorized('PerDiem_View_Order'))?_c(VTab,{attrs:{"to":"perdiems"}},[_vm._v(" "+_vm._s(_vm.t("perDiems"))+" ")]):_vm._e(),_c(VTabsItems,[_c('keep-alive',[_c('router-view',{attrs:{"employee":_vm.employee,"editable":_vm.isEditable,"loading":_vm.loading,"per-diems":_vm.perDiems,"view":view,"worklogs":_vm.worklogs,"year-month":_vm.yearMonth}})],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }