import axios, {AxiosResponse} from "axios";
import {PagedResult} from "@/service/PagedResult";
import {CalendarData} from "@/model/CalendarData";

const PATH = "/calendar-data";

class CalendarDataService {
    findCalendarData (from: string, to: string): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH}`, {
            params: {
                from: from,
                to: to,
                size: 1000
            }
        });
    }
    getCalendarDataById (id: number): Promise<AxiosResponse<CalendarData>> {
        return axios.get(`${PATH}/${id}`);
    }
    processCalendarData (payload: CalendarData): Promise<AxiosResponse<CalendarData>> {
        return axios.post(`${PATH}`, payload, {headers: {"Content-Type": "application/json"}});
    }
}

export default new CalendarDataService();
