
  import {computed, defineComponent, onMounted, reactive, toRefs, watch} from "vue";
  import {HeaderGetters} from "@/store/modules/header";
  import StatisticService from "@/service/StatisticService";
  import moment, {Moment} from "moment";
  import {EmployeeMonthSummaryView, EmployeeSummaryView} from "@/model/EmployeeSummaryView";
  import {DataTableHeader} from "vuetify";
  import store from "@/store/store";

  export default defineComponent({
    name: "StatisticEmployeesV2",
    setup(props, context) {

      const initialState = {
        items: new Array<EmployeeSummaryView>(),
        loading: false,
        twelveMonthsView: false
      };

      const headers = computed(() => {
        let result = new Array<DataTableHeader>();
        result.push(
          {
            text: "ID",
            align: "start",
            width: 80,
            value: "employeeId"
          },
          {
            text: "Vorname",
            align: "start",
            width: 120,
            value: "firstName"
          },
          {
            text: "Nachname",
            align: "start",
            width: 120,
            value: "lastName"
          },
          {
            text: "Ø Stunden/Tag",
            align: "end",
            width: 120,
            sortable: false,
            value: ""
          },
          {
            text: "Ø Tagessatz",
            align: "end",
            width: 120,
            sortable: false,
            value: ""
          }
        );

        let numberOfMonths = state.twelveMonthsView ? 12 : 3;
        for (let i = 0; i < numberOfMonths; i++) {
          result.push(
            {
              text: "# Tage",
              align: "end",
              width: 80,
              sortable: false,
              value: ""
            },
            {
              text: "# Stunden",
              align: "end",
              width: 120,
              sortable: false,
              value: ""
            },
            {
              text: "Gehalt",
              align: "start",
              sortable: false,
              value: "wage"
            },
            {
              text: "Gehaltsart",
              align: "start",
              sortable: false,
              value: "wageType"
            }
          );
        }

        return result;
      });

      const state = reactive({...initialState});

      const selectedMonth = computed(() => store.getters["header/" + HeaderGetters.GET_MONTH]);

      const from = computed(() => {
        const monthsToSubstract = state.twelveMonthsView ? 11 : 2;
        return moment(selectedMonth.value).subtract(monthsToSubstract, "months");
      });

      const to = computed(() => {
        return moment(selectedMonth.value);
      });

      const months = computed(() => {
        let result = [];

        let numberOfMonths = state.twelveMonthsView ? 12 : 3;
        for (let i = 0; i < numberOfMonths; i++) {
          let month = to.value.clone();
          result.push(month.subtract(i, "months"));
        }

        return result;
      });

      const fetchEmployeeSummary = (fromDate: Moment, toDate: Moment) => {
        const fromString = fromDate.format("YYYY-MM");
        const toString = toDate.format("YYYY-MM");

        state.items = [];
        state.loading = true;
        StatisticService.getEmployeeSummaries(fromString, toString)
          .then(response => {
            state.items = response.data;
            state.items.forEach(item => {
              // Only keep Wage employees
              item.months = item.months.filter((month: EmployeeMonthSummaryView) => month.wageType === "WAG");
              item.months.sort((a: EmployeeMonthSummaryView, b: EmployeeMonthSummaryView) => moment(b.yearMonth, "YYYY-MM").diff(moment(a.yearMonth, "YYYY-MM")));
            });

            // Remove those entries where no month entry has a wage entry, e.g. in case all entries are salaried
            state.items = state.items.filter(item => item.months.length > 0);
          })
          .finally(() => state.loading = false);
      };

      watch(() => selectedMonth, () => {
        fetchEmployeeSummary(from.value, to.value);
      }, {deep: true});

      watch(() => state.twelveMonthsView, () => {
        fetchEmployeeSummary(from.value, to.value);
      });

      const sumMinutes = (month: EmployeeMonthSummaryView) => {
        let minutes = 0;
        if (month.minutesWork) {
          minutes += month.minutesWork;
        }

        if (month.minutesTravel) {
          minutes += month.minutesTravel;
        }

        return minutes;
      };

      const calculateAverageHoursPerDay = (item: EmployeeSummaryView) => {
        const totalMinutes = item.months.reduce((currentValue, month) => sumMinutes(month) + currentValue, 0);
        const totalWorkingDays = item.months.reduce((currentValue, month) => {
          if (month.workingDays) {
            return currentValue + month.workingDays;
          } else {
            return currentValue;
          }
        }, 0);
        return totalWorkingDays > 0 ? totalMinutes / 60 / totalWorkingDays : 0;
      };

      const calculateAverageWage = (item: EmployeeSummaryView) => {
        const wageSum = item.months.reduce((currentValue, month) => month.wage + currentValue, 0);

        return wageSum / item.months.length;
      };

      const hasMonthData = (month: Moment, employeeSummaryView: EmployeeSummaryView) => {
        return employeeSummaryView.months.some(item => item.yearMonth === month.format("YYYY-MM"));
      };

      const getMonthData = (month: Moment, employeeSummaryView: EmployeeSummaryView) => {
        return employeeSummaryView.months.find(item => item.yearMonth === month.format("YYYY-MM"));
      };

      onMounted(() => {
        fetchEmployeeSummary(from.value, to.value);
      });

      return { ...toRefs(state), months, headers, from, to, hasMonthData, getMonthData, calculateAverageHoursPerDay, calculateAverageWage };
    }
  });
