
  import {defineComponent, PropType} from "vue";
  import Worklog from "@/model/Worklog";
  import {icon, sumMinutes} from "@/WorklogUtils";

  export default defineComponent({
    name: "AccountingEmployeeWorklogsSummaryChips",
    props: {
      worklogs: {
        type: Array as PropType<Array<Worklog>>,
        default: () => new Array<Worklog>()
      },
    },
    setup () {
      return {
        icon,
        sumMinutes
      };
    }
  });
