
  import Vue from "vue";
  import Component from "vue-class-component";
  import { ConfirmDialogInterface } from "@/components/ConfirmDialogInterface";

  @Component
  export default class ConfirmDialogImpl extends Vue implements ConfirmDialogInterface {
    private dialog = false;
    private result: Function = (confirmed: boolean) => confirmed;
    private title = "Löschen bestätigen";
    private text = "Soll der Eintrag wirklich gelöscht werden?";

    cancel (): void {
      this.result(false);
      this.dialog = false;
    }

    confirm (): void {
      this.result(true);
      this.dialog = false;
    }

    public open (title: string, text: string): Promise<boolean> {
      if (typeof title !== "undefined") {
        this.title = title;
      }

      if (typeof text !== "undefined") {
        this.text = text;
      }

      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.result = resolve;
      });
    }
  }
