import { VAlert } from 'vuetify/lib/components/VAlert';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c('RightsBreadcrumbs',{ref:"breadcrumbs"}),_c('h1',{staticClass:"pl-3"},[_vm._v(" "+_vm._s(_vm.$route.query.name)+" ")]),_c(VSubheader,[_c('span',{staticClass:"pr-4"},[_vm._v(" Rollen ")]),_c(VFlex,{staticClass:"divider-line"})],1),_c(VRow,{staticClass:"pl-5"},[_c(VAlert,{attrs:{"value":_vm.assignedRoles.length == 0,"type":"warning"}},[_vm._v(" Dem User sind keine Rollen zugeordnet. ")]),_vm._l((_vm.assignedRoles),function(assignedRole){return _c(VChip,{key:assignedRole.id,staticClass:"mx-1",attrs:{"close":_vm.isAuthorized('Authorization_EditUsers')},on:{"click:close":function($event){return _vm.deleteRole(assignedRole)}}},[_vm._v(" "+_vm._s(assignedRole.name)+" ")])})],2),_c(VRow,{staticClass:"pl-5 mt-2"},[(_vm.isAuthorized('Authorization_EditUsers'))?_c('a',{on:{"click":function($event){return _vm.openAssignRoleDialog()}}},[_vm._v(" Rolle hinzufügen ")]):_vm._e()]),_c('UserDetailsAssignRoleDialog',{ref:"userDetailsAssignRoleDialog"}),_c('ConfirmDialog',{ref:"confirmDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }