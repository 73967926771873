import Order from "@/model/Order";
import Employee from "@/model/Employee";

export default class Worklog {
  public id: number;

  public date: string;

  public start: string;

  public end: string;

  public worklogType: string;

  public comment: string;

  public order: Order | null;

  public employee: Employee | null;

  public insertUser!: string;
  public insertTime!: string;
  public updateUser?: string;
  public updateTime?: string;

  public static fromJson (obj: Record<string, any>) {
    return Object.assign(new Worklog(), obj, {
      employee: obj.employee ? Object.assign(new Employee(), obj.employee) : null,
      order: obj.order ? Object.assign(new Order(), obj.order) : null
    });
  }

  public toJsonString () {
    return JSON.stringify(this);
  }

  public toJsonObject () {
    return JSON.parse(this.toJsonString());
  }
}
