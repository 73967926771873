import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VList,{attrs:{"subheader":""}},[_c(VSubheader,[_vm._v(" "+_vm._s(_vm.title)+" ("+_vm._s(_vm.items.length)+") ")]),_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.employee.firstName)+" "+_vm._s(item.employee.lastName)+" ")])],1),_c(VListItemAction,[_c('div',[_c(VBtn,{class:_vm.getTeamLeaderClass(item),attrs:{"icon":"","color":_vm.getTeamLeaderColor(item)},on:{"click":function($event){return _vm.assignTeamLeader(item)}}},[_c(VIcon,[_vm._v("mdi-account-tie")])],1),(_vm.showDelete)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAssignment(item)}}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1)])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }