import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{ref:"datepicker",attrs:{"persistent":"","return-value":_vm.selectedDate,"max-width":"290px"},on:{"update:returnValue":function($event){_vm.selectedDate=$event},"update:return-value":function($event){_vm.selectedDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VTextField,_vm._g({staticStyle:{"max-width":"140px"},attrs:{"value":_vm._f("formatDate")(_vm.date,'DD.MM.YYYY'),"append-outer-icon":"mdi-calendar","readonly":"","hide-details":""}},on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c(VDatePicker,{attrs:{"type":"date","locale":"de-DE","scrollable":"","first-day-of-week":"1"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Abbrechen ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.datepicker.save(_vm.date)}}},[_vm._v(" Ok ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }