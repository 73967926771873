
  import {computed, defineComponent, inject, PropType} from "vue";
  import Customer from "@/model/Customer";
  import CustomerDetailsCardContact from "./CustomerDetailsCardContact.vue";
  import CustomerDetailsCardSpecificInformation from "./CustomerDetailsCardSpecificInformation.vue";
  import CustomerDetailsCardGeneralInformation from "./CustomerDetailsCardGeneralInformation.vue";
  import CustomerDetailsCardTravelTimes from "./CustomerDetailsCardTravelTimes.vue";
  import {OrderType} from "@/enums";
  import {t} from "@/mixin/mixins";
  import {customerId} from "@/RouterUtils";
  import CustomerService from "@/service/CustomerService";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";

  export default defineComponent({
    name: "CustomerDetailsInfo",
    components: {
      CustomerDetailsCardContact,
      CustomerDetailsCardSpecificInformation,
      CustomerDetailsCardGeneralInformation,
      CustomerDetailsCardTravelTimes
    },
    props: {
      customer: {
        type: Object as PropType<Customer>,
        default: () => new Customer(),
      },
      customerTypes: {
        type: Array as PropType<Array<OrderType>>,
        default: () => new Array<OrderType>()
      }
    },
    setup (props, context) {

      let appRoot = inject("app-root");
      // @ts-ignore
      const confirmDialog = appRoot.confirmDialogComposition as InstanceType<typeof ConfirmDialogComposition>;

      const editable = computed(() => {
        return props.customer?.active;
      });

      const deleteCustomer = () => {
        const title = t("customer.confirmDelete.title");
        const text = t("customer.confirmDelete.text");
        confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              CustomerService.deleteCustomer(customerId.value)
                .then(response => {
                  eventBus.$emit(MessageTypes.CUSTOMER_DELETED);
                })
                .catch(() => {
                });
            }
          });
      };

      return {
        deleteCustomer,
        editable
      };
    }});
