
  import Component from "vue-class-component";
  import { Prop, Vue } from "vue-property-decorator";

  @Component
  export default class WorklogDetailsDialogDateField extends Vue {
    @Prop({ default: "" })
    public min: string;

    @Prop({ default: "" })
    public max: string;

    @Prop({ default: "" })
    public value: string;

    @Prop({ default: false })
    public disabled: boolean;

    @Prop({ default: () => [] })
    public errorMessages: Array<string>;

    private datePicker = false;
  }
