
  import {RegistrationDataCitizenship} from "@/model/Registration";
  import {helpers, required, requiredIf} from "@vuelidate/validators";
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import FormDatePicker from "@/views/Registration/Forms/RegistrationCitizenshipFormDatePicker.vue";
  import RegistrationService from "@/service/RegistrationService";
  import Utils from "@/Utils";
  import RegistrationCitizenshipFormDeletableFile
    from "@/views/Registration/Forms/RegistrationCitizenshipFormDeletableFile.vue";
  import RegistrationFileUpload from "@/views/Registration/Forms/RegistrationFileUpload.vue";
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    SetupContext,
    toRefs,
    watch
  } from "vue";
  import {useDialog} from "@/views/Registration/RegistrationComposable";
  import useVuelidate, {ErrorObject} from "@vuelidate/core";
  import {ValidationResult} from "@/model/ValidationResult";
  import {t, tWithParms} from "@/mixin/mixins";
  import {MessageTypes} from "@/components/message-types";
  import eventBus from "@/eventbus";
  import {HINT} from "@/plugins/i18n";

  export default defineComponent({
    name: "RegistrationCitizenshipForm",
    components: {
      FormDatePicker,
      RegistrationCitizenshipFormDeletableFile,
      RegistrationFileUpload,
      RegistrationCard
    },
    props: {
      step: {
        type: Number,
        default: null
      }
    },
    setup(props, context: SetupContext) {

      const {
        state: generalState,
        falseValue,
        trueValue,
        registration,
        registrationUuid,
        mobile,
        next
      } = useDialog(context, () => afterValidation.call(this));

      const initialState = {
        model: {
          citizenship: new RegistrationDataCitizenship()
        },
        uploadedFiles: new Array<string>()
      };

      const state = reactive({...initialState});

      const isNonEuCitizen = computed(() => {
        return state.model.citizenship.euCitizen === false;
      });

      const rules = computed(() => ({
        model: {
          citizenship: {
            citizenship: {
              required: helpers.withMessage(tWithParms("validation.required", t("citizenship.citizenship")), required)
            },
            birthplace: {
              required: helpers.withMessage(tWithParms("validation.required", t("citizenship.birthplace")), required)
            },
            euCitizen: {
              required: helpers.withMessage(tWithParms("validation.required", "Antwort"), required)
            },
            residencePermitValidTo: {
              required: helpers.withMessage(tWithParms("validation.required", t("citizenship.residencePermitValidTo")), requiredIf(function (): boolean {
                return isNonEuCitizen.value;
              }))
            },
            passportValidTo: {
              required: helpers.withMessage(tWithParms("validation.required", t("citizenship.passportValidTo")), requiredIf(function (): boolean {
                return isNonEuCitizen.value;
              }))
            },
            workPermitValidTo: {
              required: helpers.withMessage(tWithParms("validation.required", t("citizenship.workPermitValidTo")), requiredIf(function (): boolean {
                return isNonEuCitizen.value;
              }))
            }
          }
        }
      }));

      let v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (generalState.serverErrors.length > 0) {
          generalState.serverErrors.forEach((error: ValidationResult) => {
            errors.set(error.path, error.message);
          });

          return errors;
        } else {
          const model = v$.value;
          if (model.$dirty) {
            model.$errors.forEach((error: ErrorObject) => {
              if(!errors.has(error.$propertyPath)) {
                // @ts-ignore
                errors.set(error.$propertyPath, error.$message);
              }
            });
          }
        }

        return errors;
      });

      watch(() => registration.citizenship, (newValue, oldValue) => {
        if (newValue) {
          state.model.citizenship = Object.assign(state.model.citizenship, newValue);
        }
      });

      onMounted(() => {
        if(registration.value.citizenship) {
          state.model.citizenship = registration.value.citizenship;

          RegistrationService.getFiles(registrationUuid.value, "citizenship")
            .then(response => {
              state.uploadedFiles = response.data.files;
            });
        } else {
          generalState.dirty = true;
        }
      });

      const deleteFile =  (file: string) => {
        Utils.remove(state.uploadedFiles, file);
      };

      const onFileUploaded = (file: File) => {
        state.uploadedFiles.push(file.name);
      };

      const onNext = () => {
        next(v$, state.model);
      };

      const afterValidation = () => {
        if (isNonEuCitizen.value) {
          eventBus.$emit(MessageTypes.INFO, t(HINT), t("citizenship.workPermitRequiredHint"));
        }
      };

      return {
        ...toRefs(state),
        v$,
        errors,
        falseValue,
        trueValue,
        mobile,
        isNonEuCitizen,
        deleteFile,
        onFileUploaded,
        onNext
      };
    }
  });
