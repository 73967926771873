import axios, {AxiosResponse} from "axios";

const PATH = "/archive";

export interface ArchiveFile {
  path: string,
  lastModified: string,
  fileName: string
}

export interface ArchiveFolder {
  name: string,
  files: Array<ArchiveFile>
}

export default {
  getFiles(employeeId: number): Promise<AxiosResponse<Array<ArchiveFolder>>> {
    return axios.get(`${PATH}/${employeeId}`);
  },
  download(fileKey: string) {
    return axios.get(`${PATH}/download?key=${fileKey.replace(/\\/g, "/")}`, {
      responseType: "blob"
    });
  }
};
