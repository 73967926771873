
  import TimeControllingClockedInOverviewTable from "./TimeControllingClockedInOverviewTable.vue";
  import TimeControllingWithoutTeamleadTable from "./TimeControllingWithoutTeamleadTable.vue";

  export default {
    components: {
      TimeControllingClockedInOverviewTable: TimeControllingClockedInOverviewTable,
      TimeControllingWithoutTeamleadTable: TimeControllingWithoutTeamleadTable
    },
    data: () => ({
      active: 0
    })
  };
