import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"px-0 pt-0"},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._t("title")],2),_c(VCardText,[_vm._t("content")],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }