import {Ref} from "vue";
import Worklog from "@/model/Worklog";
import eventBus from "@/eventbus";
import {MessageTypes} from "@/components/message-types";
import {init as accountingEmployeeInit} from "@/views/Accounting/Employee/AccountingEmployeeComposable";

export function init(worklogs: Ref<Array<Worklog>>) {
    const {
        dates,
        groups
    } = accountingEmployeeInit(worklogs);

    const deleteWorklog = (worklog: Worklog) => {
        eventBus.$emit(MessageTypes.DELETE_WORKLOG, worklog);
    };

    const editWorklog = (worklog: Worklog) => {
        eventBus.$emit(MessageTypes.UPDATE_WORKLOG, worklog);
    };

    return {
        groups,
        dates,
        deleteWorklog,
        editWorklog
    };
}
