
  import Vue from "vue";
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";

  export default Vue.extend({
    name: "RegistrationWelcomeCard",
    components: {
      RegistrationCard
    },
    computed: {
      currentUrl () {
        return window.location.href;
      }
    }
  });
