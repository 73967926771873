
  import Vue from "vue";
  import {Prop} from "vue-property-decorator";
  import Component from "vue-class-component";
  import Customer from "@/model/Customer";
  import {getCustomerFieldIcon} from "@/views/Customers/CustomerUtils";
  import {OrderType} from "@/enums";
  import {MessageTypes} from "@/components/message-types";

  export const FORM_ID = "CustomersSpecificInformationForm";

  @Component({
    components: {},
    methods: {
      getCustomerFieldIcon
    },
    props: {
      customerTypes: {
        type: Array,
        default: () => new Array<OrderType>()
      }
    }
  })
  export default class CustomersSpecificInformationForm extends Vue {
    @Prop({default: () => new Customer()})
    public customer: Customer;

    @Prop({default: () => new Map<string, string>()})
    public errors: Map<string, string>;

    get customerTypesWithNew() {
      return [
        ...this.$props.customerTypes
      ];
    }

    mounted() {
      this.$eventBus.$on(MessageTypes.CUSTOMER_CREATE_VALIDATE, () => {
        const form: any = this.$refs.form;
        let isValid = true;
        if (form) {
          isValid = form.validate();
        }
        if (isValid) {
          if (this.customer.customerTypeId === -1) {
            delete this.customer.costCenter;
            delete this.customer.region;
            delete this.customer.sellingArea;
          } else if (this.isCustomerTypeRewe()) {
            delete this.customer.customerId;
            delete this.customer.newCustomerType;
          } else {
            delete this.customer.newCustomerType;
            delete this.customer.costCenter;
            delete this.customer.region;
            delete this.customer.sellingArea;
          }
          this.$eventBus.$emit(MessageTypes.CUSTOMER_CREATE_VALID, FORM_ID);
        }
      });
    }

    beforeDestroy() {
      this.$eventBus.$off(MessageTypes.CUSTOMER_CREATE_VALIDATE);
    }

    isCustomerTypeRewe() {
      if (this.customer.customerTypeId !== -1) {
        const customerType = this.$props.customerTypes.find((item: { id: number }) => item.id === this.customer.customerTypeId);
        return customerType && customerType.name.toLowerCase() === "rewe";
      } else if (this.customer.newCustomerType) {
        return this.customer.newCustomerType.toLowerCase() === "rewe";
      }
      return false;
    }

    customerTypeRule() {
      return [(val: number) => {
        if (!val) {
          return "Wählen Sie einen Kundentyp";
        }
        return true;
      }];
    }

    newCustomerTypeRule() {
      return [(val: string) => {
        if (this.customer.customerTypeId === -1 && !val) {
          return "Geben Sie eine Bezeichnung für den neuen Kundentyp an";
        }
        return true;
      }];
    }

    digitRule(digits = 2, numeric = true) {
      return [(val: string) => {
        if (val && `${val}`.length === digits) {
          if (!numeric || (numeric && !isNaN(parseInt(val)))) {
            return true;
          }
        }
        if (numeric) {
          return `Geben Sie eine ${digits}-Stellige Nummer an`;
        } else {
          return `Geben Sie einen ${digits}-Stelligen Wert an`;
        }
      }];
    }
  }
