import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"8"}},[_c(VToolbar,{attrs:{"color":"white","extension-height":"100","flat":""}},[_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.t("order.operators.table.title"))+" ")])],1)],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"8"}},[_c(VDataTable,{class:{ 'table-disabled': false },attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.tableOptions,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.operatorNumber",fn:function({ item }){return [_c('OrderOperatorsListOperatorNumber',{attrs:{"editable":_vm.isEditable,"item":item},on:{"updated":updated => item = updated}})]}},{key:"item.name",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.fullName(item))+" ")]}},{key:"item.workingTime",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.toTimeFormat(_vm.employeeMap.get(item.employee.id)))+" ")]}},{key:"item.numberOfPositions",fn:function({ item }){return [_c('OrderOperatorsListNumberOfPositions',{attrs:{"editable":_vm.isEditable,"item":item},on:{"updated":updated => _vm.updateItem(item, updated)}})]}},{key:"item.positionsPerHour",fn:function({ item }){return [_c('span',{staticClass:"px-4"},[_vm._v(" "+_vm._s(Math.round(_vm.positionsPerHour(item)))+" ")])]}},{key:"foot",fn:function(){return [_c('tfoot',[_c('tr',[_c('td',{staticClass:"footer-cell"}),_c('td',{staticClass:"footer-cell"}),_c('td',{staticClass:"footer-cell px-4"},[_vm._v(" "+_vm._s(_vm.toTimeFormat(_vm.workingTimeSummary))+" ")]),_c('td',{staticClass:"footer-cell px-7"},[_vm._v(" "+_vm._s(_vm.positionCountSummary)+" ")]),_c('td',{staticClass:"footer-cell px-7 text-right"},[_vm._v(" "+_vm._s(Math.round(_vm.positionsPerHourSummary))+" ")])])])]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }