<template>
  <VContainer fluid>
    <RightsBreadcrumbs ref="breadcrumbs" />

    <h1 class="pl-3">
      {{ $route.query.name }}
    </h1>
    <VSubheader>
      <span class="pr-4">
        Rollen
      </span>
      <VFlex class="divider-line" />
    </VSubheader>

    <VRow class="pl-5">
      <VAlert
        :value="assignedRoles.length == 0"
        type="warning"
      >
        Dem User sind keine Rollen zugeordnet.
      </VAlert>

      <VChip
        v-for="assignedRole in assignedRoles"
        :key="assignedRole.id"
        class="mx-1"
        :close="isAuthorized('Authorization_EditUsers')"
        @click:close="deleteRole(assignedRole)"
      >
        {{ assignedRole.name }}
      </VChip>
    </VRow>
    <VRow class="pl-5 mt-2">
      <a
        v-if="isAuthorized('Authorization_EditUsers')"
        @click="openAssignRoleDialog()"
      >
        Rolle hinzufügen
      </a>
    </VRow>

    <UserDetailsAssignRoleDialog ref="userDetailsAssignRoleDialog" />
    <ConfirmDialog ref="confirmDialog" />
  </VContainer>
</template>

<script>
  import RightsBreadcrumbs from "./RightsBreadcrumbs.vue";
  import UserDetailsAssignRoleDialog from "./UserDetailsAssignRoleDialog.vue";
  import ConfirmDialog from "@/components/ConfirmDialog.vue";
  import RightsService from "@/service/RightsService";
  import {MessageTypes} from "@/components/message-types";

  export default {
    components: {
      UserDetailsAssignRoleDialog: UserDetailsAssignRoleDialog,
      ConfirmDialog: ConfirmDialog,
      RightsBreadcrumbs: RightsBreadcrumbs
    },
    data: () => ({
      user: null,
      assignedRoles: [],
      availableRoles: []
    }),
    watch: {
      user(val) {
        this.$store.commit("header/setTitle", val.name);
      }
    },
    mounted: function () {
      this.load();
    },
    methods: {
      load: function () {
        this.$store.commit("header/setTitle", "");

        RightsService.getUser(this.$route.params.employeeId)
          .then(response => {
            this.user = response.data.user;
            this.assignedRoles = response.data.user.roles;
            this.availableRoles = response.data.availableRoles;
          });
      },
      openAssignRoleDialog: function () {
        const vue = this;
        this.$refs.userDetailsAssignRoleDialog.open(this.user, this.assignedRoles, this.availableRoles).then(selectedRole => {
          const assignedRole = vue.availableRoles.filter(item => item.id == selectedRole);
          vue.assignedRoles.push(assignedRole[0]);
          vue.availableRoles = vue.availableRoles.filter(item => item.id != selectedRole);
        });
      },
      deleteRole: function (role) {
        const vue = this;
        const title = "Rolle " + role.name + " löschen";
        const text = "Soll die Rolle <i>" + role.name + "</i> wirklich gelöscht werden? ";
        this.$refs.confirmDialog.open(title, text).then(confirmResult => {
          if (confirmResult) {
            RightsService.unassignRoleFromUser(this.user.id, role.id)
              .then(response => {
                this.assignedRoles = vue.assignedRoles.filter(item => item.id != role.id);
                this.availableRoles.push(role);
                this.$eventBus.$emit(MessageTypes.SNACKBAR_SUCCESS, this.$vuetify.lang.t("$vuetify.roleRemoved"));
              });
          }
        });
      }
    }
  };
</script>
