import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[(_vm.inactiveCustomer && !_vm.alertDismissed)?_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12","lg":"10"}},[_c(VAlert,{staticClass:"mb-0",attrs:{"text":"","outlined":"","dense":"","color":"orange","dismissible":""},on:{"click":function($event){_vm.alertDismissed = true}}},[_vm._v(" "+_vm._s(_vm.t("customer.inactiveCustomer"))+" ")])],1)],1):_vm._e(),_c(VRow,[_c(VCol,[_c(VTabs,{attrs:{"centered":"","color":"primary"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTab,{attrs:{"ripple":"","to":"info"}},[_vm._v(" "+_vm._s(_vm.t("customer.tabs.overview"))+" ")]),_c(VTab,{attrs:{"to":"orders"}},[_vm._v(" "+_vm._s(_vm.t("customer.tabs.appointments"))+" ")]),_c(VTabsItems,[_c('keep-alive',[_c('router-view',{attrs:{"customer":_vm.customer,"customer-types":_vm.customerTypes}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }