import {computed, ComputedRef} from "vue";
import router from "@/router";

export function useRouter() {
  return router;
}

export const customerId: ComputedRef<number> = computed(() => {
  return Number(router.app.$route.params.customerId);
});

export const date: ComputedRef<string> = computed(() => {
  return router.app.$route.query.date as string;
});

export const employeeId: ComputedRef<number> = computed(() => {
  return Number(router.app.$route.params.employeeId);
});

export const orderId: ComputedRef<number> = computed(() => {
  return Number(router.app.$route.params.orderId);
});

export const registrationUuid: ComputedRef<string> = computed(() => {
  return router.app.$route.params.registrationUuid;
});

export const roleId: ComputedRef<number> = computed(() => {
  return Number(router.app.$route.params.roleId);
});

export const isOrderCreateMode: ComputedRef<boolean> = computed(() => {
  return router.app.$route.name === "OrderNewInfo";
});
