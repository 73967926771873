
  import useVuelidate from "@vuelidate/core";
  import {helpers, required} from "@vuelidate/validators";
  import {computed, defineComponent, onMounted, onUnmounted, reactive, Ref, toRefs} from "vue";
  import moment from "moment";
  import PerDiem from "@/model/PerDiem";
  import PerDiemDetailsDialogParameters from "@/views/PerDiem/PerDiemDetailsDialogParameters";
  import EmployeeName from "@/model/EmployeeName";
  import OrderName from "@/model/OrderName";
  import {MessageTypes} from "@/components/message-types";
  import PerDiemUpdateRequest from "@/service/PerDiemUpdateRequest";
  import PerDiemService from "@/service/PerDiemService";
  import {AxiosError} from "axios";
  import eventBus from "@/eventbus";
  import {isAuthorized} from "@/mixin/mixins";
  import store from "@/store/store";

  export default defineComponent({
    name: "PerDiemDetailsDialog",
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context) {

      const initialState = {
        availableOrders: new Array<OrderName>(),
        dialog: false,
        employees: new Array<EmployeeName>(),
        orderEditable: true,
        perDiem: new PerDiem(),
        perDiemEditable: true,
        serverErrors: new Map<string, string>()
      };

      const state = reactive({...initialState});

      const rules = computed(() => ({
        perDiem: {
          catering: {
            required: helpers.withMessage("Verpflegungszuschuss ist erforderlich.", required),
          },
          date: {
            required: helpers.withMessage("Datum ist erforderlich.", required),
          },
          misc: {
            required: helpers.withMessage("Sonstiger Zuschuss ist erforderlich.", required),
          },
          order: {
            required: helpers.withMessage("Auftrag ist erforderlich.", required),
          }
        }
      }));

      // @ts-ignore
      const v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (state.serverErrors.size > 0) {
          return state.serverErrors;
        } else {
          if (v$.value.$dirty) {
            if (v$.value.perDiem.catering.$error) {
              errors.set("catering", v$.value.perDiem.catering.$errors[0].$message);
            }
            if (v$.value.perDiem.misc.$error) {
              errors.set("misc", v$.value.perDiem.misc.$errors[0].$message);
            }
            if (v$.value.perDiem.date.$error) {
              errors.set("date", v$.value.perDiem.date.$errors[0].$message);
            }
            if (v$.value.perDiem.order.$error) {
              errors.set("order", v$.value.perDiem.order.$errors[0].$message);
            }
          }
        }

        return errors;
      });

      const disabled = computed(() => {
        if (!isAuthorized("PerDiem_Edit_Edit")) {
          return true;
        } else if (state.perDiem && state.perDiem.id) {
          const date = state.perDiem.date;
          return moment(date).isBefore(moment(store.state.general.earliestValidFrom)) || !state.perDiemEditable;
        } else {
          return false;
        }
      });

      const cateringPerDiems = computed(() => [0, 8, 16, 32]);

      const miscPerDiems = computed(() => [0, 8, 16, 24, 32]);

      const cancel = () => {
        state.dialog = false;
      };

      const save = () => {
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          eventBus.$emit(MessageTypes.LOADING, "Setze Zuschüsse...");

          const request = new PerDiemUpdateRequest();
          // @ts-ignore
          const isNum = /^\d+$/.test(state.perDiem.order);
          const orderId = isNum ? state.perDiem.order : state.perDiem.order.id;
          // @ts-ignore
          request.orderId = orderId;
          request.date = state.perDiem.date;
          request.misc = state.perDiem.misc;
          request.catering = state.perDiem.catering;
          request.breakfast = state.perDiem.breakfast;

          if (state.employees.length === 1) {
            PerDiemService.createOrUpdate(state.employees[0].id, request)
              .then(response => {
                eventBus.$emit(MessageTypes.SUCCESS, "Zuschüsse gesetzt!");
                eventBus.$emit(MessageTypes.PERDIEM_UPDATED, response.data);
                state.dialog = false;
              })
              .catch((error: AxiosError) => {
                if (error && error.response && error.response.status === 422) {
                  eventBus.$emit(MessageTypes.WARNING, "Zuschüsse konnten nicht gesetzt werden!");
                }
              });
          } else if (state.employees.length > 1) {
            const employeeIds = state.employees.map(employee => employee.id);
            PerDiemService.createOrUpdateBulk(orderId as number, request, employeeIds)
              .then(response => {
                eventBus.$emit(MessageTypes.SUCCESS, "Zuschüsse gesetzt!");
                eventBus.$emit(MessageTypes.PERDIEM_UPDATED, response.data);
                state.dialog = false;
              });
          }
        }
      };

      const openDialogCallback = (params: PerDiemDetailsDialogParameters) => {
        if (!state.dialog) {
          v$.value.$reset();
          state.perDiemEditable = true;

          state.availableOrders = params.orders;
          state.orderEditable = state.availableOrders.length > 1;

          state.employees = params.employees;

          if (params.perDiem) {
            state.perDiem = Object.assign(new PerDiem(), params.perDiem);
            const orderAlreadyAdded = state.availableOrders.some(order => params.perDiem.order && order.id === params.perDiem.order.id);
            if (!orderAlreadyAdded) {
              // The order was not available but set, because it was assigned somewhere else. So this
              // one should not be editable anymore
              state.perDiemEditable = false;
              state.availableOrders.push(params.perDiem.order);
            }
          } else {
            state.perDiem = new PerDiem();
            state.perDiem.date = params.date;
            if (state.employees.length === 1) {
              state.perDiem.employee = state.employees[0];
            }

            if (state.availableOrders.length === 1) {
              state.perDiem.order = state.availableOrders[0];
            }
          }

          state.dialog = true;
        }
      };

      onMounted(() => {
        eventBus.$on(MessageTypes.OPEN_PERDIEM_DETAILS, openDialogCallback);
      });

      onUnmounted(() => {
        eventBus.$off(MessageTypes.OPEN_PERDIEM_DETAILS, openDialogCallback);
      });

      return {
        ...toRefs(state),
        v$,
        errors,
        disabled,
        cateringPerDiems,
        miscPerDiems,
        cancel,
        save
      };
    }
  });
