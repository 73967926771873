
  import Vue from "vue";
  import Component from "vue-class-component";

  @Component
  export default class Payroll extends Vue {
    mounted () {
      const vue = this;
      window.addEventListener("dragenter", function (e) {
        vue.showDropZone();
      });

      window.addEventListener("dragleave", function (e) {
        e.preventDefault();
        vue.hideDropZone();
      });

      window.addEventListener("dragover", function (e) {
        e.preventDefault();
        vue.showDropZone();
      });

      window.addEventListener("drop", function (e) {
        e.preventDefault();
        vue.hideDropZone();

        vue.onDrop(e);
      });
    }

    showDropZone () {
      // @ts-ignore
      document.querySelector(".dropzone").style.visibility = "";
      // @ts-ignore
      document.querySelector(".dropzone").style.opacity = 1;
      // @ts-ignore
      document.querySelector(".dropzone-text").style.fontSize = "48px";
    }

    hideDropZone () {
      // @ts-ignore
      document.querySelector(".dropzone").style.visibility = "hidden";
      // @ts-ignore
      document.querySelector(".dropzone").style.opacity = 0;
      // @ts-ignore
      document.querySelector(".dropzone-text").style.fontSize = "42px";
    }

    onDrop (event: DragEvent) {
      this.$emit("drop", event);
    }
  }
