<template>
  <VContainer>
    <RightsBreadcrumbs />

    <VRow class="pl-4">
      <VCol lg="4">
        <VTextField
          v-model="search"
          append-icon="mdi-magnify"
          label="Mitarbeiter suchen"
          single-line
          hide-details
        />
      </VCol>
    </VRow>

    <VRow class="mt-4">
      <VCol>
        <div
          v-for="item in items"
          :key="item.title"
        >
          <VList
            v-if="isItemVisible(item)"
            subheader
            class="thumbsup-list"
          >
            <VSubheader>
              <span class="pr-4">
                {{ item.title }}
              </span>
              <VFlex class="divider-line" />
            </VSubheader>

            <VListItem
              v-for="user in filterVisibleUsers(item.users)"
              :key="user.employeeId"
              class="thumbsup-list-item"
              @click="openUser(user)"
            >
              <VListItemAvatar>
                <VIcon x-large>
                  mdi-account-box
                </VIcon>
              </VListItemAvatar>

              <VListItemContent>
                <VListItemTitle>
                  {{ user.lastName + ", " + user.firstName }}
                </VListItemTitle>
                <VListItemSubtitle>
                  {{ toRoleString(user.roles) }}
                </VListItemSubtitle>
              </VListItemContent>

              <VListItemAction>
                <VIcon>
                  mdi-chevron-right
                </VIcon>
              </VListItemAction>
            </VListItem>
          </VList>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
  import RightsBreadcrumbs from "./RightsBreadcrumbs.vue";
  import RightsService from "@/service/RightsService";

  export default {
    components: {
      RightsBreadcrumbs
    },
    data: () => ({
      search: "",
      items: [],
      originalItems: []
    }),
    watch: {
      items (val) {
        this.$store.commit("header/setTitle", "User (" + this.getNumberOfUsers() + ")");
      }
    },
    created () {
      if (this.$route.name === "rightsRoleUsers") {
        this.loadUsersForRole(this.$route.params.roleId);
      } else {
        this.load();
      }
    },
    methods: {
      load () {
        RightsService.getUsers()
          .then(response => {
            this.items = response.data;
          });
      },
      loadUsersForRole (roleId) {
        RightsService.getRoleUsers(roleId)
          .then(response => {
            this.items = response.data;
          });
      },
      toRoleString: function (roles) {
        return roles.map(role => role.name).join(", ");
      },
      getNumberOfUsers () {
        let count = 0;

        this.items.forEach(function (item) {
          if (item.users != null) {
            count += item.users.length;
          }
        });

        return count;
      },
      openUser: function (user) {
        this.$router.push({
          name: "rightsUserDetails",
          params: { employeeId: user.employeeId }
        });
      },
      isUserVisible: function (user) {
        return user.name.toLowerCase().includes(this.search.toLowerCase());
      },
      filterVisibleUsers: function (users) {
        return users.filter(user => this.isUserVisible(user));
      },
      isItemVisible: function (item) {
        const vue = this;
        return item.users.some(function (user) {
          return user.name.toLowerCase().includes(vue.search.toLowerCase());
        });
      }
    }
  };
</script>
<style>
  .search-field {
    width: 20%;
  }
</style>
