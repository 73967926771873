import TimeclockEmployeeEntry from "@/model/TimeclockEmployeeEntry";

export default class TimeclockModel {
  public employees: Array<TimeclockEmployeeEntry> = [];

  public available: Array<TimeclockEmployeeEntry> = [];

  public clockedIn: Array<TimeclockEmployeeEntry> = [];

  public clockedOut: Array<TimeclockEmployeeEntry> = [];

  public paused: Array<TimeclockEmployeeEntry> = [];

  public added: Array<TimeclockEmployeeEntry> = [];

  public constructor (init?: Partial<TimeclockModel>) {
    Object.assign(this, init);
  }
}
