import axios, {AxiosResponse} from "axios";
import {Registration, RegistrationData} from "@/model/Registration";
import {PagedResult} from "@/service/PagedResult";

const PATH = "/registrations";

export default {
  startRegistration (code: string): Promise<AxiosResponse> {
    return axios.post(`${PATH}`, {
      registrationCode: code
    });
  },
  finishRegistration (uuid: string, registration: RegistrationData) {
    return axios.post(`${PATH}/${uuid}/finish`, registration);
  },
  downloadFile (uuid: string, fileKey: string) {
    return axios.get(`${PATH}/${uuid}/files/${fileKey}`, {
      responseType: "blob"
    });
  },
  getRegistrations (): Promise<AxiosResponse<PagedResult>> {
    return axios.get(`${PATH}`);
  },
  getRegistration (uuid: string): Promise<AxiosResponse<Registration>> {
    return axios.get(`${PATH}/${uuid}`);
  },
  deleteRegistration (uuid: string): Promise<AxiosResponse> {
    return axios.delete(`${PATH}/${uuid}`);
  },
  updateRegistration (uuid: string, registration: RegistrationData) {
    return axios.patch(`${PATH}/${uuid}`, registration, {
      headers: {
        "Content-Type": "application/merge-patch+json"
      }
    });
  },
  getFiles (uuid: string, context: string): Promise<AxiosResponse<any>> {
    return axios.get(`${PATH}/${uuid}/files`, {
      params: {
        context: context
      }
    });
  },
  uploadFile (uuid: string, file: File, context: string) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("context", context.toUpperCase());

    return axios.post(`${PATH}/${uuid}/files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  deleteFile (uuid: string, context: string, fileName: string) {
    return axios.delete(`${PATH}/${uuid}/files/${context}/${fileName}`);
  }
};
