import PerDiem from "@/model/PerDiem";
import OrderName from "@/model/OrderName";
import EmployeeName from "@/model/EmployeeName";

export default class PerDiemDetailsDialogParameters {
    public date!: string;

    public employees: Array<EmployeeName> = new Array<EmployeeName>();

    public perDiem: PerDiem;

    public orders: Array<OrderName> = new Array<OrderName>();
}
