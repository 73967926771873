
  import Vue from "vue";
  import Component from "vue-class-component";
  import CustomersCreateDialogFormCard from "./CustomersCreateDialogFormCard.vue";
  import ModalDialog from "@/components/ModalDialog.vue";
  import {MessageTypes} from "@/components/message-types";
  import Customer from "@/model/Customer";
  import CustomerService from "@/service/CustomerService";
  import {AxiosError} from "axios";
  import ValidationError from "@/model/ValidationError";
  import CustomersContactForm, {
    FORM_ID as FORM_ID_CONTACT
    } from "@/views/Customers/forms/CustomersContactForm.vue";
  import CustomersGeneralInformationForm, {
    FORM_ID as FORM_ID_GENERAL
    } from "@/views/Customers/forms/CustomersGeneralInformationForm.vue";
  import CustomersSpecificInformationForm, {
    FORM_ID as FORM_ID_SPECIFIC
    } from "@/views/Customers/forms/CustomersSpecificInformationForm.vue";
  import CustomerType from "@/model/CustomerType";

  const FORM_IDS = [FORM_ID_CONTACT, FORM_ID_GENERAL, FORM_ID_SPECIFIC];

  @Component({
    components: {
      CustomersGeneralInformationForm,
      CustomersSpecificInformationForm,
      CustomersContactForm,
      CustomersCreateDialogFormCard,
      ModalDialog
    }
  })
  export default class CustomerCreateDialog extends Vue {
    private customer: Customer = new Customer();
    private errors: Map<string, string> = new Map<string, string>();
    private dialog = false;
    private formValidation = new Map<string, boolean>();
    private customerTypes = new Array<CustomerType>();

    mounted () {
      CustomerService.getCustomerTypes().then(data => {
        this.customerTypes = data;
      });
      this.$eventBus.$on(MessageTypes.OPEN_CUSTOMER_CREATE, () => {
        this.errors = new Map<string, string>();
        this.customer = new Customer();
        this.customer.active = true;
        this.dialog = true;
      });
      this.$eventBus.$on(MessageTypes.CUSTOMER_CREATE_VALID, (formId: string) => {
        this.formValidation.set(formId, true);
        if (this.allFormsValid()) {
          this.saveValid();
        }
      });
    }

    allFormsValid () {
      return !FORM_IDS.find(FORM_ID => !this.formValidation.get(FORM_ID));
    }

    beforeDestroy () {
      this.$eventBus.$off(MessageTypes.OPEN_CUSTOMER_CREATE, () => {
        this.closeDialog();
      });
      this.$eventBus.$off(MessageTypes.CUSTOMER_CREATE_VALID);
    }

    closeDialog () {
      this.dialog = false;
      this.customer = new Customer();
      this.customer.active = true;
      this.errors = new Map<string, string>();
    }

    get customersErrors (): Map<string, string> {
      const errors = new Map<string, string>();
      this.errors.forEach((value, key) => {
        errors.set(key, value);
      });

      return errors;
    }

    save () {
      this.errors = new Map<string, string>();

      this.formValidation = new Map<string, boolean>();
      this.$eventBus.$emit(MessageTypes.CUSTOMER_CREATE_VALIDATE);
    }

    saveValid () {
      CustomerService.createCustomer(this.customer)
        .then((response) => {
          this.$eventBus.$emit(MessageTypes.CUSTOMER_CREATE_SUCCESS, response.data);
          this.$eventBus.$emit(MessageTypes.SUCCESS, "Kunde erstellt.", 1000);
          this.closeDialog();
        })
        .catch((error: AxiosError) => {
          if (error && error.response && error.response.status === 422) {
            this.$eventBus.$emit(MessageTypes.WARNING, "Bitte Eingaben prüfen.");

            const errorMap = new Map<string, string>();
            // @ts-ignore
            error.response.data.forEach((error: ValidationError) => {
              errorMap.set(error.path, error.message);
            });

            // Needed to trigger reactivity
            this.errors = errorMap;
          } else {
            this.$eventBus.$emit(MessageTypes.ERROR, "Kunde konnte nicht erstellt werden.");
          }
        });
    }
  }
