
  import {defineComponent, onMounted, reactive, toRefs, watch} from "vue";
  import SwitchView from "@/components/SwitchView.vue";
  import InventoriesCompanyLocationsList from "./InventoriesCompanyLocationsList.vue";
  import InventoriesCompanyLocationsTable from "./InventoriesCompanyLocationsTable.vue";
  import InventoriesCompanyLocationsDialog from "./dialog/InventoriesCompanyLocationsDialog.vue";
  import {CompanyLocation} from "@/model/CompanyLocation";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import InventoriesService from "@/service/InventoriesService";
  import PageUtils from "@/components/PageUtils";
  import {t} from "@/mixin/mixins";

  export default defineComponent({
    name: "InventoriesCompanyLocations",
    components: {
      SwitchView,
      InventoriesCompanyLocationsList,
      InventoriesCompanyLocationsTable,
      InventoriesCompanyLocationsDialog
    },
    setup() {

      const initialState = {
        companyLocations: new Array<CompanyLocation>(),
        search: ""
      };

      const state = reactive({...initialState});

      onMounted(() => {
        findCompanyLocationsByTerm(state.search);

        eventBus.$on(MessageTypes.COMPANY_LOCATION_CREATED, (companyLocation: CompanyLocation) => {
          let exists = state.companyLocations.find(location => location.id == companyLocation.id);
          if (exists) {
            state.companyLocations = state.companyLocations.map(location => {
              if (location.id == companyLocation.id) {location = companyLocation;}
              return location;
            });
          } else {
            state.companyLocations.push(companyLocation);
          }
        });
        eventBus.$on(MessageTypes.COMPANY_LOCATION_DELETED, (companyLocationId: number) => {
          state.companyLocations = state.companyLocations.filter(location => {
            return location.id != companyLocationId;
          });
        });
      });

      let timeout = 200;
      watch(() => state.search, (newVal) => {
        clearTimeout(timeout);

        if (newVal != null) {
          if (newVal != "") {
            if (newVal.length > 2) {
              timeout = setTimeout(() => {
                findCompanyLocationsByTerm(newVal);
              }, timeout);
            }
          } else {
            findCompanyLocationsByTerm(newVal);
          }
        }
      });

      const findCompanyLocationsByTerm = (term: string) => {
        InventoriesService.findCompanyLocationsByTerm(term)
          .then(response => {
            state.companyLocations =  PageUtils.extractCollection(response.data, "companyLocations");
          })
          .catch(() => {
            eventBus.$emit(MessageTypes.ERROR, t("errors.generalError"));
          });
      };

      const openCompanyLocationDialog = (companyLocation: CompanyLocation | null = null) => {
        eventBus.$emit(MessageTypes.OPEN_COMPANY_LOCATION_DIALOG, companyLocation);
      };

      return {
        ...toRefs(state),
        openCompanyLocationDialog
      };
    }
  });
