
  import ModalDialog from "@/components/ModalDialog.vue";
  import {computed, defineComponent, reactive, toRefs} from "vue";
  import {RegistrationDataSocialSecurityChild} from "@/model/Registration";
  import FormDatePickerV2 from "@/components/FormDatePickerV2.vue";
  import {today} from "@/DateUtils";
  import {helpers, required} from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import {t, tWithParms} from "@/mixin/mixins";
  import {VALUE} from "@/plugins/i18n";

  export default defineComponent({
    name: "RegistrationTaxAndSocialSecurityChildrenDialog",
    components: {
      FormDatePickerV2,
      ModalDialog
    },
    setup(props, context) {
      const initialState = {
        dialog: false,
        model: new RegistrationDataSocialSecurityChild(),
        result: (child: RegistrationDataSocialSecurityChild | PromiseLike<RegistrationDataSocialSecurityChild>) => {
        }
      };

      const state = reactive({...initialState});

      const rules = computed(() => ({
        model: {
          firstName: {
            required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required)
          },
          lastName: {
            required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required)
          },
          birthdate: {
            required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required)
          }
        }
      }));

      // @ts-ignore
      const v$ = useVuelidate(rules, state, { $stopPropagation: true});

      const resetState = () => {
        Object.assign(state, initialState);
        v$.value.$reset();
      };

      const addChild = () => {
        resetState();
        state.dialog = true;
        state.model = new RegistrationDataSocialSecurityChild();

        return new Promise<RegistrationDataSocialSecurityChild>((resolve) => {
          state.result = resolve;
        });
      };

      const close = () => {
        resetState();
        state.dialog = false;
      };

      const save = () => {
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          state.dialog = false;
          state.result(state.model);
          resetState();
        }
      };

      const errors = computed(() => {
        const map = new Map<string, string>();
        v$.value.$errors.forEach(error => map.set(error.$property, error.$message as string));
        return map;
      });

      return {...toRefs(state), errors, addChild, close, save, today};
    }
  });
