
  import {computed, ComputedRef, defineComponent, reactive, toRefs} from "vue";
  import {AuthActions, AuthGetters} from "@/store/modules/auth";
  import Authentication from "@/model/Authentication";
  import store from "@/store/store";
  import router from "@/router";
  import ModalDialog from "@/components/ModalDialog.vue";
  import UserProfilePasswordDialog from "@/views/profile/UserProfilePasswordDialog.vue";

  export default defineComponent({
    name: "UserProfile",
    components: {
      ModalDialog,
      UserProfilePasswordDialog
    },
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context) {
      const initialState = {
        updatePasswordDialog: false
      };

      const state = reactive({...initialState});

      const authentication: ComputedRef<Authentication> = computed(() => {
        return store.getters["auth/" + AuthGetters.GET_AUTHENTICATION];
      });

      function logout() {
        store.dispatch("auth/" + AuthActions.LOGOUT)
          .then(() => {
            router.push("/login");
            context.emit("input", false);
          });
      }

      function openPasswordDialog() {
        state.updatePasswordDialog = true;
        context.emit("input", false);
      }

      function closePasswordDialog() {
        state.updatePasswordDialog = false;
        context.emit("input", true);
      }

      return {
        ...toRefs(state),
        authentication,
        logout,
        openPasswordDialog,
        closePasswordDialog
      };
    }
  });
