import axios, {AxiosResponse} from "axios";
import {CashFlowInterface} from "@/model/CashFlow";
import {EmployeeSummaryView} from "@/model/EmployeeSummaryView";
import {PagedResult} from "@/service/PagedResult";

const PATH = "/statistics";

export default {
    getOrderMonthlySummaries (orderId: number): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH}/orders/${orderId}/monthly-summaries`);
    },
    createCashFlow (orderId: number, cashFlow: CashFlowInterface): Promise<AxiosResponse<CashFlowInterface>> {
        return axios.post(`${PATH}/orders/${orderId}/cashflow`, cashFlow);
    },
    defaultCashFlow (orderId: number): Promise<AxiosResponse<CashFlowInterface>> {
        return axios.post(`${PATH}/orders/${orderId}/cashflow/default`);
    },
    updateCashFlow (orderId: number, cashFlowId: number, cashFlow: CashFlowInterface) {
        return axios.put(`${PATH}/orders/${orderId}/cashflow/${cashFlowId}`, cashFlow);
    },
    deleteCashFlow (orderId: number, cashFlowId: number) {
        return axios.delete(`${PATH}/orders/${orderId}/cashflow/${cashFlowId}`);
    },
    getCashFlows (orderId: number): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH}/orders/${orderId}/cashflows`);
    },
    getOrderSummaries (yearMonth: string): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH}/orders`, {
            params: {
                yearMonth: yearMonth
            }
        });
    },
    refreshOrders (yearMonth: string, orderType: string | null) {
        return axios.post(`${PATH}/orders/refresh`, {
            yearMonth: yearMonth,
            orderType: orderType
        });
    },
    refreshOrder (yearMonth: string, orderId: number) {
        return axios.post(`${PATH}/orders/${orderId}/refresh`, { yearMonth: yearMonth });
    },
    getEmployeeSummaries (from: string, to: string): Promise<AxiosResponse<Array<EmployeeSummaryView>>> {
        return axios.get(`${PATH}/employees`, {
            params: {
                from: from,
                to: to
            }
        });
    },
    getEmployeePerformances(yearMonth: string) {
        return axios.get(`${PATH}/employee-performances`, {
            params: {
                yearMonth: yearMonth
            }
        });
    }
};
