
  import Vue from "vue";
  import Component from "vue-class-component";
  import {MessageTypes} from "@/components/message-types";

  @Component
  export default class LoadingDialog extends Vue {
    private dialog = false;
    private loading = false;
    private loadingIdentifier = "";
    private loadingTimeout = 0;
    private success = false;
    private error = false;
    private warning = false;
    private message = "";

    created () {
      this.$eventBus.$on(MessageTypes.LOADING, (loadingIdentifier = "", dialog = false, message = "") => {
        if(dialog) {
          this.open(loadingIdentifier, message);
        }
      });
      this.$eventBus.$on(MessageTypes.LOADING_FINISHED, (loadingIdentifier: string) => {
        if(this.loadingIdentifier) {
          if(this.loadingIdentifier === loadingIdentifier) {
            this.dialog = false;
            this.loading = false;
            this.loadingIdentifier = "";
            clearTimeout(this.loadingTimeout);
          }
        } else {
          this.loading = false;
          // In case of errors, warnings or success messages these should handle the dialog state itself
          if(!this.error && !this.warning && !this.success) {
            this.dialog = false;
            clearTimeout(this.loadingTimeout);
          }
        }
      });
      this.$eventBus.$on(MessageTypes.ERROR, (message: string) => {
        this.showError(message);
      });
      this.$eventBus.$on(MessageTypes.SUCCESS, (message: string, duration: number) => {
        this.showSuccess(message, duration);
      });
      this.$eventBus.$on(MessageTypes.WARNING, (message: string) => {
        this.showWarning(message);
      });
    }

    open (loadingIdentifier: string, text: string) {
      this.dialog = true;
      if(text) {
        this.message = text;
      } else {
        this.message = this.t("loading");
      }

      this.loading = true;
      this.loadingIdentifier = loadingIdentifier;
      this.success = false;
      this.error = false;
      this.warning = false;

      this.loadingTimeout = setTimeout( () => {
        this.showError("Timeout");
      }, 30000);
    }

    showSuccess (text: string, duration: number | null) {
      this.dialog = true;
      this.loading = false;
      this.success = true;
      this.error = false;
      this.warning = false;

      const timeout = duration || 2000;
      const vue = this;
      setTimeout(function () {
        vue.dialog = false;
        vue.success = false;
      }, timeout);
      this.message = text;
    }

    showError (text: string) {
      this.dialog = true;
      this.loading = false;
      this.success = false;
      this.error = true;
      this.warning = false;

      this.message = text;

      const vue = this;
      setTimeout(function () {
        vue.dialog = false;
        vue.error = false;
      }, 2000);
    }

    showWarning (text: string) {
      this.dialog = true;
      this.loading = false;
      this.success = false;
      this.error = false;
      this.warning = true;

      this.message = text;

      const vue = this;
      setTimeout(function () {
        vue.dialog = false;
        vue.warning = false;
      }, 2000);
    }
  }
