
  import {defineComponent, inject, ref} from "vue";
  import {Registration, RegistrationDataPersonal} from "@/model/Registration";
  import EmployeeCreateDialog from "@/views/Employee/create/EmployeeCreateDialogCompositionApi.vue";
  import {initDialog} from "@/views/registrations/RegistrationsSetup";
  import RegistrationService from "@/service/RegistrationService";
  import {MessageTypes} from "@/components/message-types";
  import {isAuthorized, t, tWithParms} from "@/mixin/mixins";
  import eventBus from "@/eventbus";

  export default defineComponent({
    name: "RegistrationsTable",
    components: {
      EmployeeCreateDialog
    },
    setup(props, context) {

      const search = ref<string>("");

      const fullName = (personal: RegistrationDataPersonal) => {
        if (personal) {
          return personal.firstName + " " + personal.lastName;
        } else {
          return "";
        }
      };

      const filter = (value: string, search: string, item: Registration) => {
        return search != null
          && fullName(item.registrationData.personal as RegistrationDataPersonal).toLowerCase().indexOf(search.toLowerCase()) !== -1;
      };

      const headers = [
        {
          text: "Name",
          align: "left",
          value: "registrationData.personal.name"
        },
        {
          text: "ZIP",
          align: "left",
          value: "registrationData.addressAndContact.zip"
        },
        {
          text: "City",
          align: "left",
          value: "registrationData.addressAndContact.city"
        },
        {
          text: "EU-Citizenship",
          align: "center",
          value: "registrationData.citizenship.euCitizen"
        },
        {
          text: "Disability",
          align: "center",
          value: "registrationData.misc.disabled"
        },
        {
          text: "",
          align: "center",
          value: "create-employee-button"
        },
        {
          text: "",
          align: "center",
          value: "delete-registration-button"
        }
      ];

      const {employeeCreateDialog, createEmployee, fetchRegistrations, registrations} = initDialog(context);

      let appRoot = inject("app-root");
      // @ts-ignore
      const confirmDialog = appRoot.confirmDialogComposition as InstanceType<typeof ConfirmDialogComposition>;

      const deleteRegistration = (registration: Registration) => {
        const title = t("registration.deleteRegistration.title");
        const fullName = registration.registrationData.personal?.fullName ?? "";
        const text = tWithParms("registration.deleteRegistration.text", fullName);
        confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              RegistrationService.deleteRegistration(registration.uuid)
                .then(() => {
                  eventBus.$emit(MessageTypes.SUCCESS, t("registration.deletedRegistration"), 1000);
                  fetchRegistrations();
                });
            }
          });
      };

      return {
        confirmDialog,
        createEmployee,
        deleteRegistration,
        employeeCreateDialog,
        filter,
        headers,
        isAuthorized,
        t,
        registrations,
        search
      };
    }
  });
