
  import {defineComponent, onMounted, reactive, toRefs, watch} from "vue";
  import Employee from "@/model/Employee";
  import EmployeeService from "@/service/EmployeeService";
  import EmployeeCompetenciesService from "@/service/EmployeeCompetenciesService";
  import { EmployeeCompetencies } from "@/model/EmployeeCompetencies";
  import ModalDialog from "@/components/ModalDialog.vue";

  export default defineComponent({
    name: "EmployeeDetailsCardCompetencies",
    components: {ModalDialog},
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      employee: {
        type: Employee,
        default: () => new Employee()
      }
    },
    setup(props) {
      const initialState = {
        dialog: false,
        competencies: {} as EmployeeCompetencies
      };

      const state = reactive({...initialState});

      onMounted(() => {
        loadCompetencies();
      });

      const loadCompetencies = () => {
        EmployeeService.getEmployeeCompetencies(props.employee.id)
          .then(response => {
            state.competencies = response.data;
          });
      };

      const save = () => {
        if (state.competencies.id == null) {
          EmployeeCompetenciesService.createEmployeeCompetencies(
            props.employee.employeeId,
            state.competencies
          )
            .then((response) => {
              state.competencies = response.data;
              state.dialog = false;
            })
            .catch(() => { });
        } else {
          EmployeeCompetenciesService.updateEmployeeCompetencies(
            state.competencies.id,
            state.competencies
          )
            .then((response) => {
              state.competencies = response.data;
              state.dialog = false;
            })
            .catch(() => { });
        }
      };

      watch(() => props.employee, () => {
        loadCompetencies();
      });

      return {
        ...toRefs(state),
        save
      };
    }
  });
