import axios, {AxiosResponse} from "axios";
import Wage from "@/model/Wage";
import Employment from "@/model/Employment";
import Employee from "@/model/Employee";
import {PagedResult} from "@/service/PagedResult";
import {EmployeeStatus, EmploymentType, WageType} from "@/enums";
import EmployeeName from "@/model/EmployeeName";
import {EmployeeCompetencies} from "@/model/EmployeeCompetencies";

const PATH = "/employees";
const PATH_V2 = "/v2/employees";

export interface EmployeeFilter {
  employeeId?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  employmentType?: EmploymentType | null;
  wage?: number | null;
  wageType?: WageType | null;
  search?: string | null;
  status?: EmployeeStatus | null;
  operatorNumber?: number | null;
}

export default {
  findEmployees(filter: EmployeeFilter | null, size: number | null, page: number | null, sort: string | null): Promise<AxiosResponse<PagedResult>> {
    return axios.get(`${PATH_V2}`, {
      params: {
        employeeId: filter?.employeeId,
        firstName: filter?.firstName,
        lastName: filter?.lastName,
        employmentType: filter?.employmentType,
        wage: filter?.wage,
        wageType: filter?.wageType,
        search: filter?.search,
        status: filter?.status,
        operatorNumber: filter?.operatorNumber,
        size: size ? size : 250,
        page: page ? page : 0,
        sort: sort ? sort : null
      }
    });
  },
  findByTerm(term: string): Promise<AxiosResponse<PagedResult>> {
    return axios.get(`${PATH_V2}`, {
      params: {
        term: term,
        projection: "LOOKUP"
      }
    });
  },
  findEmployeeByNameOrEmployeeId(term: string): Promise<AxiosResponse<Array<Employee>>> {
    return axios.get(`${PATH}/search`, {
      params: {
        term: term
      }
    });
  },
  getDefaultData(registrationUuid?: string): Promise<AxiosResponse<Employee>> {
    return axios.get(`${PATH}/default`, {
      params: {
        registrationUuid: registrationUuid
      }
    });
  },
  getEmployeeById(employeeId: number): Promise<AxiosResponse<Employee>> {
    return axios.get(`${PATH}/${employeeId}`);
  },
  getEmployeeV2(employeeId: number): Promise<AxiosResponse<EmployeeName>> {
    return axios.get(`${PATH_V2}/${employeeId}`);
  },
  getEmployeeSummaryById(employeeId: number) {
    return axios.get(`${PATH}/${employeeId}/summary`);
  },
  createEmployee(employee: Employee, registrationUuid?: string): Promise<AxiosResponse<Employee>> {
    return axios.post(`${PATH}`, {
      employeeId: employee.employeeId,
      firstName: employee.firstName,
      lastName: employee.lastName,
      birthdate: employee.birthdate,
      email: employee.email,
      companyLocation: employee.companyLocation,
      contractExpiryDate: employee.contractExpiryDate,
      employment: employee.employments[0],
      wage: employee.wages[0],
      operatorNumber: employee.operatorNumber,
      inventoryBonusEligible: employee.inventoryBonusEligible,

      registrationUuid: registrationUuid
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  createWelcomePdf(employeeId: number) {
    return axios.post(`${PATH}/${employeeId}/welcome`, {}, {
      responseType: "blob"
    });
  },
  updateEmployee(employeeId: number, payload: Employee) {
    return axios.put(`${PATH}/${employeeId}`, payload);
  },
  updateEmployeePatch(employeeId: number, payload: Employee) {
    return axios.patch(`${PATH}/${employeeId}`, payload, {
      headers: {
        "Content-Type": "application/merge-patch+json"
      }
    });
  },
  deactivateEmployee(employeeId: number) {
    return axios.patch(`${PATH}/${employeeId}`, {active: false}, {
      headers: {
        "Content-Type": "application/merge-patch+json"
      }
    });
  },
  reactivateEmployee(employeeId: number): Promise<AxiosResponse<Employee>> {
    return axios.patch(`${PATH}/${employeeId}`, {active: true}, {
      headers: {
        "Content-Type": "application/merge-patch+json"
      }
    });
  },
  getEmployeeWages(employeeId: number) {
    return axios.get(`${PATH}/${employeeId}/wages`);
  },
  createWage(employeeId: number, wage: Wage): Promise<AxiosResponse<Wage>> {
    return axios.post(`${PATH}/${employeeId}/wages`, wage);
  },
  updateWage(employeeId: number, wage: Wage): Promise<AxiosResponse<Wage>> {
    return axios.patch(`${PATH}/${employeeId}/wages/${wage.id}`, wage);
  },
  deleteWageById(employeeId: number, wageId: number) {
    return axios.delete(`${PATH}/${employeeId}/wages/${wageId}`);
  },
  createEmployment(employeeId: number, employment: Employment): Promise<AxiosResponse<Employment>> {
    return axios.post(`${PATH}/${employeeId}/employments`, employment);
  },
  updateEmployment(employeeId: number, employment: Employment): Promise<AxiosResponse<Employment>> {
    return axios.patch(`${PATH}/${employeeId}/employments/${employment.id}`, employment);
  },
  deleteEmploymentById(employeeId: number, employmentId: number) {
    return axios.delete(`${PATH}/${employeeId}/employments/${employmentId}`);
  },
  getEmployeeWorklogsV2(employeeId: number, yearMonth: string): Promise<AxiosResponse<PagedResult>> {
    return axios.get(`${PATH_V2}/${employeeId}/worklogs`, {
      params: {
        yearMonth: yearMonth
      }
    });
  },
  getEmployeePerDiemsV2(employeeId: number, yearMonth: string): Promise<AxiosResponse<PagedResult>> {
    return axios.get(`${PATH_V2}/${employeeId}/perdiems`, {
      params: {
        yearMonth: yearMonth
      }
    });
  },
  getEmployeeCompetencies(employeeId: number): Promise<AxiosResponse<EmployeeCompetencies>> {
    return axios.get(`${PATH_V2}/${employeeId}/competencies`);
  },
  createEmployeeCompetencies(employeeCompetencies: EmployeeCompetencies) {
    return axios.post(`${PATH_V2}/${employeeCompetencies.employee.employeeId}/competencies`, employeeCompetencies);
  },
  updateEmployeeCompetencies(employeeCompetencies: EmployeeCompetencies) {
    return axios.put(
      `${PATH_V2}/${employeeCompetencies.employee.employeeId}/competencies/${employeeCompetencies.id}`,
      employeeCompetencies
    );
  },
  getCountingPerformances(employeeId: number, yearMonth: string): Promise<AxiosResponse<PagedResult>> {
    return axios.get(`${PATH_V2}/${employeeId}/counting-performances`, {
      params: {
        size: 1000,
        yearMonth: yearMonth
      }
    });
  }
};
