<template>
  <VDataTable
    :headers="headers"
    :items="items"
    no-data-text="Keine Daten verfügbar"
    :loading="loading"
  >
    <template #body="{ items }">
      <tbody>
        <tr
          v-for="item in items"
          :key="item.data"
        >
          <td class="cell no-padding text-left">
            {{ item.firstName }}
          </td>
          <td class="cell no-padding text-left">
            {{ item.lastName }}
          </td>
          <td class="cell no-padding text-left">
            {{ item.orderName }}
          </td>
          <td class="cell no-padding text-right">
            {{ item.date | formatDate("dddd, Do MMMM YYYY") }}
          </td>
          <td class="cell no-padding text-left">
            {{ $vuetify.lang.t("$vuetify.enum.worklogType." + item.worklogType) }}
          </td>
          <td class="cell no-padding text-right">
            {{ item.time }}
          </td>
          <td class="cell no-padding text-left">
            {{ item.clockInUsername }}
          </td>
          <td class="cell no-padding text-right">
            {{ item.clockInTime | formatDate("dddd, D. MMMM YYYY HH:mm:ss") }}
          </td>
        </tr>
      </tbody>
    </template>
  </VDataTable>
</template>

<script>
  import TimecontrollingService from "@/service/TimecontrollingService";

  export default {
    data: () => ({
      items: [],
      loading: false,
      headers: [
        {
          text: "Vorname",
          align: "left",
          value: "firstName"
        },
        {
          text: "Nachname",
          align: "left",
          value: "lastName"
        },
        {
          text: "Auftrag",
          align: "left",
          value: "orderName"
        },
        {
          text: "Datum",
          align: "right",
          value: "date"
        },
        {
          text: "Typ",
          align: "left",
          value: "worklogType"
        },
        {
          text: "Uhrzeit",
          align: "right",
          value: "time"
        },
        {
          text: "Von",
          align: "left",
          value: "clockInUsername"
        },
        {
          text: "Einstempeluhrzeit",
          align: "right",
          value: "clockInTime"
        }
      ]
    }),
    created () {
      this.init();
    },
    methods: {
      init: function () {
        this.loading = true;
        TimecontrollingService.getClockedIn()
          .then(response => {
            this.items = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  };
</script>
