import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{attrs:{"hover":"","outlined":""},on:{"click":_vm.edit}},[_c(VCardTitle,{staticClass:"pt-0"},[_c(VRow,[_c(VCol,{staticStyle:{"min-height":"28px"},attrs:{"cols":"10"}},[_vm._t("card-title")],2),_c(VCol,{attrs:{"cols":"2"}},[_c(VCardActions,{staticClass:"pa-0"},[_c(VSpacer),(_vm.icon)?_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openIcon()}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1):_vm._e()],1)],1)],1)],1),_c(VCardSubtitle,[_vm._t("subtitle")],2),_c(VCardText,{staticClass:"pt-0 px-8"},[_c(VRow,[_c(VCol,[_vm._t("card-content",null,{"employee":_vm.employee})],2)],1)],1),_c(VCardActions,[_c(VSpacer),_c('ModalDialog',{attrs:{"editable":_vm.editable,"crud":"","max-width":"500px","persistent":""},on:{"save":_vm.save,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"color":"primary","text":""},on:{"click":_vm.edit}},on),[_vm._v(" "+_vm._s(_vm.editable ? "Bearbeiten" : "Anzeigen")+" ")])]}},{key:"header-title",fn:function(){return [_vm._t("dialog-title")]},proxy:true},{key:"content",fn:function(){return [_vm._t("dialog-content",null,{"employee":_vm.editItem,"errors":_vm.errors})]},proxy:true}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }