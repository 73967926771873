<template>
  <VRow justify="center">
    <VBtn
      large
      rounded
      fixed
      bottom
      color="primary"
      :class="[ $vuetify.breakpoint.lgAndUp ? 'menu-open' : '', 'px-2']"
      px-2
      style="margin-bottom: 20px; width: 90%;"
      @click="$emit('click')"
    >
      <slot />
    </VBtn>
  </VRow>
</template>

<script>
  export default {
    name: "FabBlockButton"
  };
</script>

<style scoped>
    .menu-open {
        max-width: calc(100vw - 316px) !important;
    }
</style>
