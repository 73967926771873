
  import Vue from "vue";
  import Customer from "@/model/Customer";
  import ModalDialog from "@/components/ModalDialog.vue";
  import {MessageTypes} from "@/components/message-types";
  import CustomerService from "@/service/CustomerService";
  import {AxiosError} from "axios";
  import ValidationError from "@/model/ValidationError";

  export default Vue.extend({
    name: "CustomerDetailsCard",
    components: {
      ModalDialog
    },
    props: {
      editFormId: {
        type: String,
        default: ""
      },
      editable: {
        type: Boolean,
        default: false
      },
      customer: {
        type: Customer,
        default: () => new Customer()
      },
      icon: {
        type: String,
        default: null
      }
    },
    data: () => ({
      dialog: false,
      editItem: new Customer(),
      errors: new Map<string, string>()
    }),
    mounted() {
      this.$eventBus.$on(MessageTypes.CUSTOMER_CREATE_VALID, (formId: string) => {
        if (this.dialog && formId === this.$props.editFormId) {
          this.saveValid();
        }
      });
    },
    beforeDestroy() {
      this.$eventBus.$off(MessageTypes.CUSTOMER_CREATE_VALID);
    },
    methods: {
      openIcon() {
        if (this.icon) {
          this.$emit("iconClicked");
        }
      },
      edit() {
        this.errors = new Map<string, string>();
        this.editItem = Object.assign({}, this.customer);
        this.dialog = true;
      },
      save() {
        this.errors = new Map<string, string>();
        this.$eventBus.$emit(MessageTypes.CUSTOMER_CREATE_VALIDATE);
      },
      saveValid() {
        CustomerService.editCustomer(this.editItem)
          .then(() => {
            this.$eventBus.$emit(MessageTypes.CUSTOMER_EDIT_SUCCESS);
            this.$eventBus.$emit(MessageTypes.SUCCESS, "Kunde bearbeitet.", 1000);
            this.dialog = false;
          })
          .catch((error: AxiosError) => {
            if (error && error.response && error.response.status === 422) {
              this.$eventBus.$emit(MessageTypes.WARNING, "Bitte Eingaben prüfen.");

              const errorMap = new Map<string, string>();
              // @ts-ignore
              error.response.data.forEach((error: ValidationError) => {
                errorMap.set(error.path, error.message);
              });

              // Needed to trigger reactivity
              this.errors = errorMap;
            } else {
              this.$eventBus.$emit(MessageTypes.ERROR, "Kunde konnte nicht bearbeitet werden.");
            }
          });
      },
      cancel() {
        this.dialog = false;
      }
    }
  });
