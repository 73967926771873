export const MessageTypes = Object.freeze({
  CLOSE_ALL_DIALOGS: "closeAllDialogs",
  LOAD: "load",
  LOADING: "loading",
  LOADING_FINISHED: "loadingFinished",
  SNACKBAR_ERROR: "snackbarError",
  SNACKBAR_SUCCESS: "snackbarSuccess",
  SNACKBAR_WARNING: "snackbarWarning",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  AUTHENTICATED: "authenticated",
  // Customer
  OPEN_CUSTOMER_CREATE: "openCustomerCreate",
  CUSTOMER_CREATE_VALIDATE: "CustomerCreateValidate",
  CUSTOMER_CREATE_VALID: "CustomerCreateValid",
  CUSTOMER_CREATE_SUCCESS: "CustomerCreateSuccess",
  CUSTOMER_EDIT_SUCCESS: "CustomerEditSuccess",
  CUSTOMER_DELETED: "CustomerDeleted",
  // Employee
  OPEN_EMPLOYEE_CREATE: "openEmployeeCreate",
  // Company Location
  OPEN_COMPANY_LOCATION_DIALOG: "openCompanyLocationDialog",
  COMPANY_LOCATION_CREATED: "companyLocationCreated",
  COMPANY_LOCATION_DELETED: "companyLocationDeleted",
  // Market Area
  OPEN_MARKET_AREA_DIALOG: "openMarketAreaDialog",
  OPEN_MARKET_AREA_CATEGORY_DIALOG: "openMarketAreaCategoryDialog",
  MARKET_AREA_CREATED: "marketAreaCreated",
  MARKET_AREA_DELETED: "marketAreaDeleted",
  MARKET_AREA_CATEGORY_CREATED: "marketAreaCategoryCreated",
  MARKET_AREA_CATEGORY_DELETED: "marketAreaCategoryDeleted",
  // Order
  OPEN_ORDER_CHECKLIST_DETAILS: "openOrderChecklistDetails",
  // PerDiem
  OPEN_PERDIEM_DETAILS: "openPerDiemDetails",
  PERDIEM_DELETED: "perdiemDeleted",
  PERDIEM_UPDATED: "perdiemUpdated",
  // Worklog
  CREATE_WORKLOG: "createWorklog",
  WORKLOG_CREATED: "worklogCreated",
  DELETE_WORKLOG: "deleteWorklog",
  WORKLOG_DELETED: "worklogDeleted",
  UPDATE_WORKLOG: "updateWorklog",
  WORKLOG_UPDATED: "worklogUpdated"
});
