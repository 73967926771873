
  import Vue from "vue";

  export default Vue.extend({
    name: "RegistrationSummaryCardListEntry",
    props: {
      icon: {
        type: Boolean
      }
    }
  });
