import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.ModalDialog,{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c(VBtn,_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_setup.dialog = true}}},{ ...tooltip }),[_c(VIcon,[_vm._v(" mdi-history ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.t("employee.showEmploymentHistory")))])])]},proxy:true},{key:"header-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.t("employee.employmentHistory"))+" ")]},proxy:true},{key:"content",fn:function(){return _vm._l((_setup.sortedEmployments),function(item){return _c(VRow,{key:item.id},[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.t("enum.employmentType." + item.employmentType))+" ")]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[(item.validTo != null)?_c('span',[_vm._v(" "+_vm._s(_vm.t("from"))+" "+_vm._s(_vm._f("formatDate")(item.validFrom,"MMMM YYYY"))+" "+_vm._s(_vm.t("to"))+" "+_vm._s(_vm._f("formatDate")(item.validTo,"MMMM YYYY"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.t("since"))+" "+_vm._s(_vm._f("formatDate")(item.validFrom,"MMMM YYYY"))+" ")])])],1)})},proxy:true}]),model:{value:(_setup.dialog),callback:function ($$v) {_setup.dialog=$$v},expression:"dialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }