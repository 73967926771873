import {computed, ComputedRef} from "vue";
import store from "@/store/store";
import Authentication from "@/model/Authentication";

export const earliestValidFrom: ComputedRef<string> = computed(() => {
  return store.state.general.earliestValidFrom;
});

export const authentication: ComputedRef<Authentication> = computed(() => {
  // @ts-ignore
  return store.state.auth.authentication;
});

export const employeeId: ComputedRef<string> = computed(() => {
  // @ts-ignore
  return store.state.auth.authentication.employeeId;
});

export const date: ComputedRef<string> = computed(() => {
  // @ts-ignore
  return store.state.header.monthPicker.yearMonth;
});
