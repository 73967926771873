
  import Vue from "vue";
  import {Prop} from "vue-property-decorator";
  import Component from "vue-class-component";
  import Customer from "@/model/Customer";
  import {getCustomerFieldIcon} from "@/views/Customers/CustomerUtils";
  import {MessageTypes} from "@/components/message-types";

  export const FORM_ID = "CustomersContactForm";

  @Component({
    components: {},
    methods: {
      getCustomerFieldIcon
    }
  })
  export default class CustomersContactForm extends Vue {
    @Prop({default: () => new Customer()})
    public customer: Customer;

    @Prop({default: () => new Map<string, string>()})
    public errors: Map<string, string>;

    mounted() {
      this.$eventBus.$on(MessageTypes.CUSTOMER_CREATE_VALIDATE, () => {
        const form: any = this.$refs.form;
        let isValid = true;
        if (form) {
          isValid = form.validate();
        }
        if (isValid) {
          this.$eventBus.$emit(MessageTypes.CUSTOMER_CREATE_VALID, FORM_ID);
        }
      });
    }

    beforeDestroy() {
      this.$eventBus.$off(MessageTypes.CUSTOMER_CREATE_VALIDATE);
    }
  }
