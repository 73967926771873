import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VTabs,{attrs:{"slot":"extension","color":"primary","centered":""},slot:"extension",model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c(VTab,[_vm._v(" "+_vm._s(_vm.t("statistic.tabs.ordersAndProjects"))+" ")]),_c(VTab,[_vm._v(" "+_vm._s(_vm.t("statistic.tabs.employees"))+" ")]),_c(VTab,[_vm._v(" "+_vm._s(_vm.t("statistic.tabs.reports"))+" ")]),_c(VTab,[_vm._v(" "+_vm._s(_vm.t("statistic.tabs.performance"))+" ")]),_c(VTabItem,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"10"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.activeType ? _vm.activeType.name : "")+" ")])]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"2"}},[_c(VSelect,{attrs:{"items":_vm.availableTypes,"item-text":"name","item-value":"code","label":"Auftragstyp","single-line":"","return-object":""},model:{value:(_vm.activeType),callback:function ($$v) {_vm.activeType=$$v},expression:"activeType"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"pt-0"},[_c('StatisticOrderSummariesTable',{attrs:{"items":_vm.activeItems,"items-previous-year":_vm.activeItemsPreviousYear,"overview":_vm.isOverview},on:{"itemClicked":(entry) => _vm.itemClicked(entry),"itemRefreshed":(entry) => _vm.itemRefreshed(entry)}})],1)],1),_c(VRow)],1),_c(VTabItem,[_c(VRow,{attrs:{"justify":"center"}},[_c('StatisticEmployeesV2')],1)],1),_c(VTabItem,[_c(VRow,{attrs:{"justify":"center"}},[_c('StatisticReports')],1)],1),_c(VTabItem,[_c(VRow,{attrs:{"justify":"center"}},[_c('StatisticPerformance')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }