
  import EmployeeService from "@/service/EmployeeService";
  import EmployeeWageForm from "../forms/EmployeeWageForm.vue";
  import {AxiosError} from "axios";
  import ValidationError from "@/model/ValidationError";
  import Wage from "@/model/Wage";
  import ModalDialog from "@/components/ModalDialog.vue";
  import {computed, defineComponent, reactive, Ref, SetupContext, toRefs, watch} from "vue";
  import {helpers, required, requiredIf} from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import {employeeId} from "@/RouterUtils";
  import moment from "moment";
  import store from "@/store/store";
  import {isAuthorized} from "@/mixin/mixins";

  export default defineComponent({
    name: "WageDialog",
    components: {
      EmployeeWageForm,
      ModalDialog
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      wage: {
        type: Wage,
        default: () => new Wage(),
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context: SetupContext) {

      const initialState = {
        editItem: new Wage() as Wage,
        serverErrors: new Map<string, string>()
      };

      const state = reactive({...initialState});

      const rules = computed(() => ({
        editItem: {
          validFrom: {
            required: helpers.withMessage("Gültig ab ist erforderlich.", required),
          },
          wage: {
            required: helpers.withMessage("Gehalt ist erforderlich.", required),
          },
          wageType: {
            required: helpers.withMessage("Lohnart ist erforderlich.", required),
          },
          targetHours: {
            required: helpers.withMessage("Sollstunden ist erforderlich.", requiredIf(function () {
              return isSalary.value;
            })),
          }
        }
      }));

      // @ts-ignore
      const v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (state.serverErrors.size > 0) {
          return state.serverErrors;
        } else {
          const model = v$.value.editItem;
          if (model.$dirty) {
            if(model.validFrom.$error) {
              errors.set("validFrom", model.validFrom.$errors[0].$message);
            }
            if(model.wage.$error) {
              errors.set("wage", model.wage.$errors[0].$message);
            }
            if(model.wageType.$error) {
              errors.set("wageType", model.wageType.$errors[0].$message);
            }
            if(model.targetHours.$error) {
              errors.set("targetHours", model.targetHours.$errors[0].$message);
            }
          }
        }

        return errors;
      });

      const deletable = computed(() => {
        // @ts-ignore
        return props.editable && isAuthorized("Superadmin") && !!props.wage && !!props.wage.id;
      });

      const earliestValidFrom = computed(() => {
        return store.state.general.earliestValidFrom;
      });

      const isEditable = computed(() => {
        return props.editable && props.wage && moment(props.wage.validFrom).isSameOrAfter(moment(earliestValidFrom.value));
      });

      const isSalary = computed(() => {
        return state.editItem && state.editItem.wageType && state.editItem.wageType === "SAL";
      });

      const smartphone = computed(() => {
        return store.state.general.earliestValidFrom;
      });

      const close = () => {
        context.emit("input", false);
        context.emit("close");
      };

      const deleteWage = () => {
        EmployeeService.deleteWageById(employeeId.value, props.wage.id)
          .then(response => {
            context.emit("delete", props.wage);
            close();
          })
          .catch((error: AxiosError) => {
            if (error && error.response && error.response.status === 422) {
              const errorMap = new Map<string, string>();
              // @ts-ignore
              error.response.data.forEach((error: ValidationError) => {
                errorMap.set(error.path, error.message);
              });

              // Needed to trigger reactivity
              state.serverErrors = errorMap;
            }
          });
      };

      const save = () => {
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          let promise = null;
          let eventType = "";
          if (props.wage.id) {
            promise = EmployeeService.updateWage(employeeId.value, state.editItem);
            eventType = "update";
          } else {
            promise = EmployeeService.createWage(employeeId.value, state.editItem);
            eventType = "create";
          }

          promise
            .then(response => {
              context.emit(eventType, response.data);
              close();
            })
            .catch((error: AxiosError) => {
              if (error && error.response && error.response.status === 422) {
                const errorMap = new Map<string, string>();
                // @ts-ignore
                error.response.data.forEach((error: ValidationError) => {
                  errorMap.set(error.path, error.message);
                });

                // Needed to trigger reactivity
                state.serverErrors = errorMap;
              }
            });
        }
      };

      watch(() => props.wage, (newValue, oldValue) => {
        v$.value.$reset();
        state.editItem = Object.assign(new Wage(), newValue);
      });

      return {
        ...toRefs(state),
        v$,
        errors,
        employeeId,
        deletable,
        isEditable,
        smartphone,
        close,
        deleteWage,
        save
      };
    }
  });
