
  import {computed, defineComponent, PropType, Ref} from "vue";
  import {MarketArea} from "@/model/MarketArea/MarketArea";

  export default defineComponent({
    name: "InventoriesMarketAreasGeneralForm",
    props: {
      marketArea: {
        required: true,
        type: Object as PropType<MarketArea>
      },
      errors: {
        required: false,
        type: Map as PropType<Map<string, string | Ref<string>>>,
        default: () => new Map<string, string>()
      }
    },
    setup(props, context) {

      const marketAreaInputModel = computed({
        get(): MarketArea {
          return props.marketArea;
        },
        set(newValue: MarketArea) {
          context.emit("marketAreaChanged", newValue);
        },
      });

      return {
        marketAreaInputModel
      };
    }
  });
