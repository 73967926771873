import RegistrationService from "@/service/RegistrationService";
import {onMounted, ref, SetupContext} from "vue";
import {Registration} from "@/model/Registration";
import EmployeeCreateDialog from "@/views/Employee/create/EmployeeCreateDialogCompositionApi.vue";
import {isAuthorized} from "@/mixin/mixins";
import PageUtils from "@/components/PageUtils";

export const initDialog = (context: SetupContext) => {
    const registrations = ref<Array<Registration>>(new Array<Registration>());

    const fetchRegistrations = () => {
        RegistrationService.getRegistrations()
          .then(response => {
              registrations.value = PageUtils.extractCollection(response.data, "registrations");
          });
    };

    const employeeCreateDialog = ref<InstanceType<typeof EmployeeCreateDialog>>();
    const createEmployee = (registration: Registration): void => {
        if (isAuthorized("Registration_EditRegistrations")) {
            employeeCreateDialog.value?.open(registration.uuid)
              .then(result => {
                  if (result) {
                      setTimeout(function () {
                          fetchRegistrations();
                      }, 200);
                  }
              });
        }
    };

    onMounted(fetchRegistrations);

    return {
        employeeCreateDialog,
        createEmployee,
        fetchRegistrations,
        registrations
    };
};
