import moment from "moment";
import Wage from "@/model/Wage";

export default {
  getCurrentWage (wages: Array<Wage>): Wage {
    const currentWage = wages.find(wage => {
      const validFromIsSameOrBeforeCurrentMonth = moment(wage.validFrom).isSameOrBefore(moment().startOf("month"));
      const validToIsSameOrAfterCurrentDay = !wage.validTo || moment(wage.validTo).isSameOrAfter(moment(), "day");
      return validFromIsSameOrBeforeCurrentMonth && validToIsSameOrAfterCurrentDay;
    });

    return Object.assign(new Wage(), currentWage) || new Wage();
  },
  getNextWage (wages: Array<Wage>): Wage | null {
    const currentWage = this.getCurrentWage(wages);
    const indexCurrentWage = wages.findIndex(wage => wage.id === currentWage.id);
    if (wages.length > indexCurrentWage + 1) {
      return Object.assign(new Wage(), wages[indexCurrentWage + 1]);
    } else {
      return null;
    }
  },
  getFutureWages (wages: Array<Wage>): Array<Wage> {
    let futureWages = new Array<Wage>();
    const nextWage = this.getNextWage(wages);
    if (nextWage != null) {
      const nextWageId = nextWage.id;
      const indexNextWage = wages.findIndex(wage => wage.id === nextWageId);
      if (wages.length > indexNextWage + 1) {
        futureWages = wages.slice(indexNextWage + 1);
      }
    }

    return futureWages;
  }
};
