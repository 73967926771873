import { render, staticRenderFns } from "./CustomerDetailsCardTravelTimes.vue?vue&type=template&id=d49e7482&scoped=true&"
import script from "./CustomerDetailsCardTravelTimes.vue?vue&type=script&lang=ts&"
export * from "./CustomerDetailsCardTravelTimes.vue?vue&type=script&lang=ts&"
import style0 from "./CustomerDetailsCardTravelTimes.vue?vue&type=style&index=0&id=d49e7482&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d49e7482",
  null
  
)

export default component.exports