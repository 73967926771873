import CustomerType from "@/model/CustomerType";

export default class Customer {
    constructor(obj = {}) {
        this.og = false;
        this.flowers = false;
        this.storage = false;
        this.drinks = false;
        this.targetActualComparison = false;
        this.labels = false;
        this.customerType = new CustomerType();
        this.address = {} as CustomerAddress;
        Object.assign(this, obj);
        // This must be after the assign as otherwise the Update will not work
        this.customerTypeId = this.customerType.id;
    }

    public id: number;
    public name: string;
    public customerTypeId: number;
    public newCustomerType?: string;
    public customerType: CustomerType;
    public email: string;
    public region?: number;
    public sellingArea?: number;
    public costCenter?: number;
    public customerId?: string;
    public company: string;
    public phone: string;
    public mobile: string;
    public fax: string;
    public storeManager: string;
    public storeAssistant: string;
    public og: boolean;
    public notesOg: string;
    public flowers: boolean;
    public notesFlowers: string;
    public storage: boolean;
    public notesStorage: string;
    public drinks: boolean;
    public notesDrinks: string;
    public targetActualComparison: boolean;
    public labels: boolean;
    public additionalInformation: string;
    public doorcode: string;
    public inventoryValue: string;
    public active: boolean;
    public address: CustomerAddress;
    public positions: number;

    public get addressAsString() {
        const output = [];
        if (this.address) {
            if (this.address.street) output.push(this.address.street);
            if (this.address.zip) output.push(this.address.zip);
            if (this.address.city) output.push(this.address.city);
        }
        return output.join(" ");
    }

    public get costCenterFormatted() {
        const output = [];
        if (this.region) output.push(this.region);
        if (this.sellingArea) output.push(this.sellingArea);
        if (this.costCenter) output.push(this.costCenter);
        if (output.length > 0) {
            return output.join(" ");
        } else {
            return "";
        }
    }

    public get valueFormatted() {
        return `${(this.inventoryValue ? this.inventoryValue + " €" : "")}`;
    }

    public isCustomerTypeRewe() {
        if (this.customerType && this.customerType.name) {
            return this.customerType.name.toLowerCase() === "rewe";
        }
        return false;
    }
}

export interface CustomerAddress {
    street: string;
    zip?: number;
    city?: string;
}
