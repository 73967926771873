
  import {defineComponent} from "vue";
  import EmployeeDetailsCardListEntry from "./EmployeeDetailsCardListEntry.vue";
  import EmployeeDetailsCard from "./EmployeeDetailsCard.vue";
  import Employee from "@/model/Employee";
  import moment from "moment";
  import {t} from "@/mixin/mixins";
  import EmployeePersonalForm from "@/views/Employee/forms/EmployeePersonalForm.vue";

  // ToDo: List Entry Authorizations!
  class ListEntry {
    public label: string;
    public text: any;
    public icon?: string;
    public auth? = "";
    public isMail?: boolean;
  }

  function listEntries(employee: Employee): Array<ListEntry> {
    const listEntries: Array<ListEntry> = [];
    listEntries.push({label: "Mitarbeiter-ID", text: employee.employeeId, icon: "mdi-card-account-details"});
    listEntries.push({label: "Vorname", text: employee.firstName, icon: "mdi-account"});
    listEntries.push({label: "Nachname", text: employee.lastName, icon: "mdi-account"});
    const birthdayFormatted = employee.birthdate ? moment(employee.birthdate).format("DD.MM.YYYY") : "";
    listEntries.push({label: "Geburtstag", text: birthdayFormatted, icon: "mdi-cake-variant"});
    listEntries.push({label: "E-Mail", text: employee.email, icon: "mdi-email", isMail: true});
    listEntries.push({
      label: "Bedienernummer",
      text: employee.operatorNumber,
      icon: "mdi-barcode-scan",
      auth: "Superadmin"
    });
    listEntries.push({label: "Standort", text: employee.companyLocation?.name, icon: "mdi-home"});
    const teamLeadDateFormatted = employee.teamleadSince ? moment(employee.teamleadSince).format("DD.MM.YYYY") : "";
    listEntries.push({
      label: "Teamleiter seit",
      text: teamLeadDateFormatted,
      icon: "mdi-account-tie-outline",
      auth: "Superadmin"
    });
    listEntries.push({
      label: "Leitender Angestellter",
      text: employee.manager,
      icon: "mdi-account-tie",
      auth: "Superadmin"
    });
    listEntries.push({
      label: "Erste Hilfe",
      text: showFirstAidText(employee.firstAidCourseDate, employee.firstAidValidUntil),
      icon: "mdi-medical-bag",
      auth: "Superadmin"
    });
    const contractExpiryDateFormatted = employee.contractExpiryDate ? moment(employee.contractExpiryDate).format("DD.MM.YYYY") : "";
    listEntries.push({
      label: t("contractExpiryDate"),
      text: contractExpiryDateFormatted,
      icon: "mdi-file-document-edit"
    });

    return listEntries;
  }

  function showFirstAidText(from: string, until: string) {
    let txt = "";
    if (from) {
      txt = moment(from).format("DD.MM.YYYY") + " - ";
      txt += until ? moment(until).format("DD.MM.YYYY") : "?";
    }
    return txt;
  }

  function showText(txt: any) {
    if (typeof txt == "boolean") {
      return txt ? "Ja" : "Nein";
    } else {
      if (txt) {
        return txt;
      } else {
        return "–";
      }
    }
  }

  export default defineComponent({
    name: "EmployeeDetailsCardPersonal",
    components: {
      EmployeePersonalForm,
      EmployeeDetailsCard,
      EmployeeDetailsCardListEntry,
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      employee: {
        type: Employee,
        default: () => new Employee()
      }
    },
    setup(props, context) {
      const toMailTo = (email: string) => {
        return "mailto:" + email;
      };

      return {
        listEntries,
        toMailTo,
        showText
      };
    }
  });
