
  import {ErrorObject, useVuelidate} from "@vuelidate/core";
  import {computed, defineComponent, reactive, Ref, toRefs, watch} from "vue";
  import {VALUE} from "@/plugins/i18n";
  import {helpers, required} from "@vuelidate/validators";
  import {t, tWithParms} from "@/mixin/mixins";
  import ModalDialog from "@/components/ModalDialog.vue";
  import moment from "moment";
  import FormDatePickerV2 from "@/components/FormDatePickerV2.vue";
  import ReportService from "@/service/ReportService";
  import {handleDownload} from "@/CompositionUtils";

  export default defineComponent({
    name: "StatisticReportsWorkingHoursDialog",
    components: {
      ModalDialog,
      FormDatePickerV2
    },
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context) {

      const initialState = {
        loading: false,
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().endOf("month").format("YYYY-MM-DD"),
      };

      const state = reactive({...Object.assign({}, initialState)});


      const rules = {
        from: {required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required)},
        to: {required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required)},
      };

      const v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        const model = v$.value;
        if (model.$dirty) {
          model.$errors.forEach((error: ErrorObject) => {
            if (!errors.has(error.$propertyPath)) {
              errors.set(error.$propertyPath, error.$message);
            }
          });
        }

        return errors;
      });

      watch(() => props.value, (newValue, oldValue) => {
        // dialog is reopened
        if (!oldValue && newValue) {
          v$.value.$reset();
        }
      });

      const close = () => {
        context.emit("input", false);
      };

      const generateReport = () => {
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          state.loading = true;

          ReportService.generateInventoryPerformancesReport({
            from: state.from,
            to: state.to,
          })
            .then(response => {
              handleDownload(response);
              close();
            })
            .finally(() => state.loading = false);
        }
      };

      return {
        ...toRefs(state),
        close,
        errors,
        generateReport
      };
    }
  });
