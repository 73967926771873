
  import Order from "@/model/Order";
  import {defineComponent, PropType} from "vue";
  import {getChecklistStatusColor, openChecklist} from "@/views/Orders/Checklist/OrderChecklistService";
  import {useRouter} from "@/RouterUtils";

  export default defineComponent({
    name: "OrdersListTile",
    props: {
      icon: {
        type: String,
        default: "mdi-folder"
      },
      order: {
        type: Object as PropType<Order>,
        required: true,
        default: () => new Order()
      },
      showFromToSubtitle: {
        type: Boolean,
        default: false
      },
      to: {
        type: String,
        default: ""
      }
    },
    setup(props, context) {
      const router = useRouter();

      const openDetails = (order: Order) => {
        if (props.to && props.to !== "") {
          router.push({name: props.to, params: {orderId: order.id.toString()}});
        } else {
          context.emit("click", order);
        }
      };

      const toAddressString = (order: Order) => {
        if (order.address) {
          if (order.zip) {
            return order.zip + " " + order.city;
          } else {
            return order.city;
          }
        } else if (order.zip && order.city) {
          return order.address;
        } else if (order.address && order.zip && order.city) {
          return order.address + ", " + order.zip + " " + order.city;
        } else {
          return order.name;
        }
      };

      return {
        getChecklistStatusColor,
        openChecklist,
        openDetails,
        toAddressString
      };
    }
  });
