
  import Vue from "vue";
  import Component from "vue-class-component";
  import FormDatePicker from "@/components/FormDatePicker.vue";
  import {Prop} from "vue-property-decorator";
  import Customer from "@/model/Customer";
  import CustomerDetailsCard from "@/views/Customers/details/CustomerDetailsCard.vue";
  import CustomerDetailsCardListEntry
    from "@/views/Customers/details/CustomerDetailsCardListEntry.vue";
  import CustomersSpecificInformationForm, {
    FORM_ID
    } from "@/views/Customers/forms/CustomersSpecificInformationForm.vue";
  import {getCustomerFieldIcon} from "@/views/Customers/CustomerUtils";
  import {OrderType} from "@/enums";

  class ListEntry {
    public label: string;
    public text: string;
    public icon?: string;
  }

  @Component({
    components: {
      CustomersSpecificInformationForm,
      CustomerDetailsCardListEntry,
      CustomerDetailsCard,
      FormDatePicker
    },
    props: {
      customerTypes: {
        type: Array,
        default: () => new Array<OrderType>()
      }
    }
  })
  export default class CustomerDetailsCardContact extends Vue {
    @Prop({default: () => new Customer()})
    public customer: Customer;

    @Prop({default: false})
    public editable: boolean;

    public formId = FORM_ID;

    listEntries(customer: Customer): Array<ListEntry> {
      const listEntries: Array<ListEntry> = [];
      listEntries.push({
        label: "Kundentyp",
        text: customer.customerType.name,
        icon: getCustomerFieldIcon("customerTypeId")
      });
      if (customer.isCustomerTypeRewe()) {
        listEntries.push({
          label: "Region",
          text: `${customer.region}`,
          icon: getCustomerFieldIcon("region")
        });
        listEntries.push({
          label: "VB",
          text: `${customer.sellingArea}`,
          icon: getCustomerFieldIcon("sellingArea")
        });
        listEntries.push({
          label: "KST",
          text: `${customer.costCenter}`,
          icon: getCustomerFieldIcon("costCenter")
        });
      } else {
        listEntries.push({
          label: "Kundennummer",
          text: `${customer.customerId ? customer.customerId : ""}`,
          icon: getCustomerFieldIcon("customerId")
        });
      }
      return listEntries;
    }
  }
