
  import {computed, defineComponent, onMounted, PropType, reactive, SetupContext, toRefs, watch} from "vue";
  import Employee from "@/model/Employee";
  import FormDatePickerV2 from "@/components/FormDatePickerV2.vue";
  import {earliestValidFrom} from "@/store/StoreUtils";
  import {today} from "@/DateUtils";
  import {CompanyLocation} from "@/model/CompanyLocation";
  import InventoriesService from "@/service/InventoriesService";
  import PageUtils from "@/components/PageUtils";
  import LocationUtils from "@/views/Inventories/InventoriesCompanyLocations/LocationUtils";

  export default defineComponent({
    name: "EmployeePersonalForm",
    components: {
      FormDatePickerV2
    },
    props: {
      employee: {
        type: Object as PropType<Employee>,
        default: () => new Employee()
      },
      errors: {
        type: Map as PropType<Map<string, string>>,
        default: () => new Map<string, string>()
      }
    },
    setup(props, context: SetupContext) {
      const initialState = {
        // This is a hack where we directly mutate a prop, but since this form is tightly coupled with the main employee
        // model in the parent classes
        model: props.employee,
        companyLocations: null as Array<CompanyLocation> | null
      };

      const state = reactive({...initialState});

      onMounted(() => {
        loadCompanyLocations();
      });

      const loadCompanyLocations = () => {
        if (state.companyLocations == null) {
          InventoriesService.findCompanyLocations()
            .then(response => {
              state.companyLocations = PageUtils.extractCollection(response.data, "companyLocations");
            });
        }
      };

      const showBirthdayPicker = computed(() => {
        return !state.model.birthdate;
      });

      watch(() => props.employee, (newValue) => {
        state.model = newValue;
      });

      return {
        ...toRefs(state),
        earliestValidFrom,
        showBirthdayPicker,
        toAddressString: LocationUtils.toAddressString,
        today
      };
    }
  });
