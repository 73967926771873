import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VBottomSheet,{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]}),_c(VSpacer),_vm._t("close-button",function(){return [_c(VBtn,{attrs:{"color":"primary","text":"","small":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.t("close"))+" ")])]})],2),_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }