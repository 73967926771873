
  import Vue from "vue";
  import RegistrationsStartCard from "@/views/Registration/RegistrationsStartCard.vue";

  export default Vue.extend({
    name: "Registrations",
    components: {
      RegistrationsStartCard
    }
  });
