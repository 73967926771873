
  import {computed, defineComponent, PropType, watch} from "vue";
  import OrderInfoCard from "@/views/Orders/Info/OrderInfoCard.vue";
  import Order from "@/model/Order";

  export default defineComponent({
    name: "OrderInfoData",
    components: {
      OrderInfoCard
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      errors: {
        type: Map as PropType<Map<string, string>>,
        default: () => new Map<string, string>()
      },
      value: {
        type: Object as PropType<Order>,
        required: true
      }
    },
    emits: ["input"],
    setup(props, context) {
      const model = computed({
        get: () => props.value,
        set: (value) => {
          context.emit("input", value);
        }
      });

      watch(() => model, (newValue, oldValue) => {
        context.emit("input", newValue.value);
      }, { deep: true});

      return {model};
    }
  });
