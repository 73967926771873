import {computed, ComputedRef} from "vue";
import vuetify from "@/plugins/vuetify";
import {AxiosResponse} from "axios/index";

export const mobile: ComputedRef<boolean> = computed(() => {
  return vuetify.framework.breakpoint.smAndDown;
});

export const handleDownload = (response: AxiosResponse<BlobPart>) => {
  if(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;

    const contentDisposition = response.headers["content-disposition"];
    // @ts-ignore
    const filename = contentDisposition.split("filename=")[1];
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }
};
