
  import Vue from "vue";
  import Component from "vue-class-component";

  import { Getter, Mutation } from "vuex-class";
  import { HeaderMutations, HeaderModule, HeaderGetters } from "@/store/modules/header";
  import { Watch } from "vue-property-decorator";

  @Component
  export default class TheHeaderDatePicker extends Vue {
    private date = "";
    private selectedDate = "";
    private modal = false;

    @Getter(HeaderGetters.GET_DATE, { namespace: HeaderModule })
    private headerDate: string;

    @Mutation(HeaderMutations.SET_DATEPICKER_DATE, { namespace: HeaderModule })
    private mutationSetDatePickerDate: any;

    mounted () {
      if (!this.date) {
        this.date = this.headerDate;
      }
    }

    @Watch("selectedDate")
    onDateSelected (newDate: string) {
      this.mutationSetDatePickerDate(newDate);
    }
  }
