
  import {computed, defineComponent, reactive, ref, toRefs} from "vue";
  import EmployeeCreateDialog from "@/views/Employee/create/EmployeeCreateDialogCompositionApi.vue";
  import {initDialog} from "@/views/registrations/RegistrationsSetup";
  import {Registration, RegistrationDataPersonal} from "@/model/Registration";
  import {t} from "@/mixin/mixins";

  export default defineComponent({
    name: "RegistrationsList",
    components: {
      EmployeeCreateDialog
    },
    setup (props, context) {
      const state = reactive({
        sortingCriteria: [
          { value: "fullName", localizedName: t("fullName") }
        ],
        activeSort: {
          criterion: { value: "fullName", localizedName: t("fullName") },
          direction: "ASC"
        },
        sheet: false,
        search: ""
      });

      const changeSort = (selectedCriterion: any) => {
        state.sheet = false;

        const direction = (state.activeSort.direction === "ASC" ? "DESC" : "ASC");

        state.activeSort = {
          criterion: selectedCriterion,
          direction: direction
        };
      };

      const searchField = ref();

      const searchCleared = () => {
        state.search = "";
      };

      const { employeeCreateDialog, createEmployee, registrations } = initDialog(context);

      const fullName = (personal: RegistrationDataPersonal) => {
        if(personal) {
          return personal.firstName + " " + personal.lastName;
        } else {
          return "";
        }
      };

      const sortedRegistrations = computed(() => {
        let filteredRegistrations: Registration[];
        if (state.search != null) {
          filteredRegistrations = registrations.value
            .filter(registration => fullName(registration.registrationData.personal as RegistrationDataPersonal).toLowerCase().indexOf(state.search.toLowerCase()) !== -1);
        } else {
          filteredRegistrations = registrations.value;
        }

        return filteredRegistrations.sort((a, b) => {
          const fullNameA = fullName(a.registrationData.personal as RegistrationDataPersonal);
          const fullNameB = fullName(b.registrationData.personal as RegistrationDataPersonal);

          if (fullNameA != null && fullNameB != null) {
            return state.activeSort.direction === "ASC" ? fullNameA.localeCompare(fullNameB) : fullNameB.localeCompare(fullNameA);
          } else {
            return 0;
          }
        });
      });
      return {
        ...toRefs(state),
        createEmployee,
        employeeCreateDialog,
        fullName,
        registrations: sortedRegistrations,
        changeSort,
        searchCleared,
        searchField
      };
    }
  });
