
  import FilterUtils from "@/FilterUtils";
  import router from "@/router";
  import {computed, defineComponent, PropType, reactive, toRefs} from "vue";
  import {isAuthorized, t} from "@/mixin/mixins";
  import {AccountingEmployee} from "@/service/AccountingService";
  import {EmploymentTypes, WageTypes} from "@/enums";
  import {access} from "@/Utils";

  export default defineComponent({
    name: "AccountingEmployeesTable",
    props: {
      items: {
        type: Array as PropType<Array<AccountingEmployee>>,
        default: () => new Array<AccountingEmployee>()
      },
      loading: {
        type: Boolean,
        default: false
      },
    },
    setup(props) {
      const initialState = {
        filters: {
          employmentType: ""
        },
        pagination: {
          sortBy: "id",
          descending: false,
          rowsPerPage: -1
        }
      };

      const state = reactive({...initialState});

      const footerProps = {
        "items-per-page-options": [-1]
      };

      const headers = computed(() => {
        let headers = [
          {
            text: "ID",
            align: "right",
            value: "employee.employeeId"
          },
          {
            text: t("firstName"),
            align: "left",
            value: "employee.firstName"
          },
          {
            text: t("lastName"),
            align: "left",
            value: "employee.lastName"
          },
          {
            text: t("wageType"),
            align: "left",
            value: "wage.wageType",
            authorization: "Accounting_Overview_ViewWageAndCosts",
            enumTypes: WageTypes
          },
          {
            text: t("accounting.wageOrSalary"),
            align: "right",
            value: "wage.wage",
            authorization: "Accounting_Overview_ViewWageAndCosts"
          },
          {
            text: t("accounting.numberOfHours"),
            align: "right",
            value: "minutesTotal",
            filter: false
          },
          {
            text: t("employmentType"),
            align: "left",
            value: "employmentType",
            enumTypes: EmploymentTypes
          },
          {
            text: "",
            align: "right",
            value: "id",
            sortable: false,
            width: 72
          }
        ];

        return headers.filter(header => header.authorization === undefined || isAuthorized(header.authorization));
      });

      const filteredItems = computed(() => {
        let filteredItems = props.items.slice(0);

        for (const filterProp in state.filters) {
          // @ts-ignore
          if (state.filters[filterProp] && state.filters[filterProp].trim() !== "") {
            filteredItems = filteredItems.filter(
              // @ts-ignore
              item => FilterUtils.compare(access(item, filterProp), state.filters[filterProp]));
          }
        }

        return filteredItems;
      });

      const changeSort = (column: any) => {
        if (state.pagination.sortBy === column) {
          state.pagination.descending = !state.pagination.descending;
        } else {
          state.pagination.sortBy = column;
          state.pagination.descending = false;
        }
      };

      // @ts-ignore
      const customSort = (items, index, isDesc) => {
        var sortIndex = index[0];
        // @ts-ignore
        items.sort((a, b) => {
          if (sortIndex === "minutesTotal") {
            const aMinutesTotal = a.minutes.minutesWork + a.minutes.minutesTravel;
            const bMinutesTotal = b.minutes.minutesWork + b.minutes.minutesTravel;

            if (!isDesc[0]) {
              return aMinutesTotal < bMinutesTotal ? -1 : 1;
            } else {
              return bMinutesTotal < aMinutesTotal ? -1 : 1;
            }
          } else {
            if (!isDesc[0]) {
              return access(a, sortIndex) < access(b, sortIndex) ? -1 : 1;
            } else {
              return access(b, sortIndex) < access(a, sortIndex) ? -1 : 1;
            }
          }
        });

        return items;
      };

      const openWorklogs = (item: AccountingEmployee) => {
        router.push({path: `/accounting/employees/${item.employee.employeeId}/worklogs`});
      };

      return {
        ...toRefs(state),
        footerProps,
        filteredItems,
        headers,
        changeSort,
        customSort,
        openWorklogs
      };
    }
  });
