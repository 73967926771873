
  import {computed, defineComponent, PropType, ref} from "vue";
  import OrderInfoCard from "@/views/Orders/Info/OrderInfoCard.vue";
  import Order from "@/model/Order";
  import OrderInfoNotesDialog from "@/views/Orders/Info/OrderInfoNotesDialog.vue";

  export default defineComponent({
    name: "OrderInfoNotes",
    components: {
      OrderInfoCard,
      OrderInfoNotesDialog
    },
    props: {
      value: {
        type: Object as PropType<Order>,
        required: false,
        default: () => null
      }
    },
    setup(props, context) {

      const orderInfoNotesDialog = ref<InstanceType<typeof OrderInfoNotesDialog>>();

      const notes = computed(() => {
        return props.value ? props.value.notes : null;
      });

      const editNotes = (): void => {
        orderInfoNotesDialog.value?.open(props.value.id, props.value.notes)
          .then(result => {
            if (result) {
              context.emit("notesUpdated");
            }
          });
      };

      return {notes, editNotes, orderInfoNotesDialog};
    }
  });
