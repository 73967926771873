import axios, {AxiosResponse} from "axios";
import Customer from "@/model/Customer";
import CustomerType from "@/model/CustomerType";
import {PagedResult} from "@/service/PagedResult";
import {CustomerTravelTime} from "@/model/CustomerTravelTime";
import {MarketArea} from "@/model/MarketArea/MarketArea";

const PATH = "/customers";

export default {

    createCustomer(customer: Customer): Promise<AxiosResponse<Customer>> {
        const {customerTypeId, customerType, ...originalData} = customer;
        const modifiedData: Record<string, any> = {};
        if (customerTypeId !== -1) {
            modifiedData.customerTypeId = customerTypeId;
        }
        return axios.post(`${PATH}`, {
            ...originalData,
            ...modifiedData
        });
    },
    editCustomer(customer: Customer): Promise<AxiosResponse<Customer>> {
        const {customerTypeId, customerType, ...originalData} = customer;
        const modifiedData: Record<string, any> = {};
        if (customerTypeId !== -1) {
            modifiedData.customerTypeId = customerTypeId;
        }
        return axios.put(`${PATH}/${customer.id}`, {
            ...originalData,
            ...modifiedData
        });
    },
    getCustomerTypes(): Promise<Array<CustomerType>> {
        return new Promise((resolve, reject) => {
            axios.get(`${PATH}/types`).then(response => {
                resolve(response.data.map((item: Record<string, any>) => new CustomerType(item)));
            }).catch(error => {
                reject(error);
            });
        });
    },
    getCustomer(): Promise<Array<Customer>> {
        return new Promise((resolve, reject) => {
            axios.get(`${PATH}`).then(response => {
                resolve(response.data.map((item: Record<string, any>) => new Customer(item)));
            }).catch(error => {
                reject(error);
            });
        });
    },
    getCustomerById(customerId: number): Promise<Customer> {
        return new Promise((resolve, reject) => {
            axios.get(`${PATH}/${customerId}`).then(response => {
                resolve(new Customer(response.data));
            }).catch(error => {
                reject(error);
            });
        });
    },
    findCustomer(search: string): Promise<Array<Customer>> {
        return new Promise((resolve, reject) => {
            axios.get(`${PATH}/search?term=${search}`).then(response => {
                resolve(Object.values(response.data));
            }).catch(error => {
                reject(error);
            });
        });
    },
    deleteCustomer(customerId: number): Promise<AxiosResponse<null>> {
        return axios.delete(`${PATH}/${customerId}`);
    },
    getOrders (customerId: number): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH}/${customerId}/orders`);
    },
    getTravelTimes (customerId: number): Promise<AxiosResponse<PagedResult>> {
        return axios.get(`${PATH}/${customerId}/travel-times`, {
            params: {
                size: 1000
            }
        });
    }
};
