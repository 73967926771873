
  import {defineComponent, PropType} from "vue/";
  import Order from "@/model/Order";
  import {MessageTypes} from "@/components/message-types";
  import {t} from "@/mixin/mixins";
  import eventBus from "@/eventbus";
  import router from "@/router";

  export default defineComponent({
    name: "CustomerOrdersUpcoming",
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      items: {
        type: Array as PropType<Array<Order>>,
        required: true,
        default: () => new Array<Order>()
      }
    },
    setup(props, context) {

      const headers = [
        {
          text: "Typ",
          value: "orderType",
          align: "left",
        },
        {
          text: t("orderName"),
          value: "name",
          align: "left"
        },
        {
          text: t("date.text"),
          value: "validFrom",
          align: "left"
        },
        {
          value: "actions",
          width: 104,
          sortable: false
        }
      ];

      const openOrderInNew = (item: Order) => {
        let routeData = router.resolve({path: `/orders/${item.id}/info`});
        window.open(routeData.href, "_blank");
      };

      const openNotes = (item: Order) => {
        eventBus.$emit(MessageTypes.INFO, t("order.orderInfo.notes.title"), item.notes);
      };

      return {
        headers,
        openOrderInNew,
        openNotes
      };
    }
  });
