import axios from "axios";
import PerDiemUpdateRequest from "@/service/PerDiemUpdateRequest";

const PATH = "/perdiems";

export default {
  createOrUpdateBulk (orderId: number, perDiemUpdateRequest: PerDiemUpdateRequest, employees: Array<number>) {
    return axios.put(`${PATH}/order/${orderId}`, {
      orderId: orderId,
      date: perDiemUpdateRequest.date,
      misc: perDiemUpdateRequest.misc,
      catering: perDiemUpdateRequest.catering,
      breakfast: perDiemUpdateRequest.breakfast,
      employees: employees
    });
  },
  createOrUpdate (employeeId: number, perDiemUpdateRequest: PerDiemUpdateRequest) {
    return axios.put(`${PATH}/employee/${employeeId}`, perDiemUpdateRequest);
  }
};
