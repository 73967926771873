import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c('RightsBreadcrumbs'),_c(VRow,{staticClass:"pl-4"},[_c(VCol,{attrs:{"lg":"4"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Mitarbeiter suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VRow,{staticClass:"mt-4"},[_c(VCol,_vm._l((_vm.items),function(item){return _c('div',{key:item.title},[(_vm.isItemVisible(item))?_c(VList,{staticClass:"thumbsup-list",attrs:{"subheader":""}},[_c(VSubheader,[_c('span',{staticClass:"pr-4"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VFlex,{staticClass:"divider-line"})],1),_vm._l((_vm.filterVisibleUsers(item.users)),function(user){return _c(VListItem,{key:user.employeeId,staticClass:"thumbsup-list-item",on:{"click":function($event){return _vm.openUser(user)}}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"x-large":""}},[_vm._v(" mdi-account-box ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(user.lastName + ", " + user.firstName)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.toRoleString(user.roles))+" ")])],1),_c(VListItemAction,[_c(VIcon,[_vm._v(" mdi-chevron-right ")])],1)],1)})],2):_vm._e()],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }