import { EmploymentType } from "@/enums";

export default class Employment {
  public id: number;

  public employmentType: EmploymentType;

  public validFrom: string;

  public validTo: string;
}
