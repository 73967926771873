
  import ModalDialog from "@/components/ModalDialog.vue";

  import Employee from "@/model/Employee";
  import {computed, defineComponent, reactive, ref, toRefs} from "vue";
  import {OrderOperator} from "@/views/Orders/Operators/OrderOperators.vue";
  import OrderOperatorService, {OrderOperatorCreateRequest} from "@/service/OrderOperatorService";
  import {integer, maxValue, minValue, required} from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";

  export default defineComponent({
    name: "TimeclockOperatorNumberAssignmentDialog",
    components: {
      ModalDialog
    },
    setup () {
      const initialState = {
        dialog: false,
        employee: null as unknown as Employee,
        orderId: null as unknown as number,
        operatorNumber: null as unknown as number,
        result: (resolved: OrderOperator | PromiseLike<OrderOperator>) => {
        }
      };

      const state = reactive({ ...initialState });
      const $externalResults = ref({});

      const resetState = () => {
        Object.assign(state, initialState);
        v$.value.$reset();
      };

      const rules = computed(() => ({
        operatorNumber: {
          required,
          integer,
          minValue: minValue(1010),
          maxValue: maxValue(2200)
        }
      }));

      const v$ = useVuelidate(rules, state, { $externalResults });

      const close = () => {
        state.dialog = false;

        setTimeout(() => {
          state.result(Promise.reject("No operator number assignd"));
        }, 300);
      };

      const open = (orderId: number, employee: Employee) => {
        resetState();

        state.dialog = true;
        state.orderId = orderId;
        state.employee = employee;

        return new Promise<OrderOperator>((resolve) => {
          state.result = resolve;
        });
      };

      const save = async () => {
        v$.value.$clearExternalResults();
        let valid = await v$.value.$validate();

        if (valid) {
          OrderOperatorService.createOrderOperator(state.orderId, {
            employeeId: state.employee.id,
            operatorNumber: state.operatorNumber
          } as OrderOperatorCreateRequest)
            .then(response => {
              state.dialog = false;

              // @ts-ignore
              const id = response.headers.location.split("/").pop();

              state.result({
                id: id,
                employee: state.employee,
                operatorNumber: Number(state.operatorNumber),
              } as OrderOperator);
            })
            .catch(error => {
              $externalResults.value = {
                operatorNumber: error.response.data.title
              };
            });
        }
      };

      const name = computed(() => {
        return state.employee ? state.employee.firstName + " " + state.employee.lastName : null;
      });

      const errorMessage = computed(() => {
        return v$.value.$error ? v$.value.$errors[0].$message : null;
      });

      return {
        ...toRefs(state),
        close,
        open,
        save,
        errorMessage,
        name
      };
    }
  });
