
  import {computed, defineComponent, PropType, Ref} from "vue";
  import {MarketAreaCategory} from "@/model/MarketArea/MarketAreaCategory";

  export default defineComponent({
    name: "InventoriesMarketAreaCategoriesGeneralForm",
    props: {
      marketAreaName: {
        required: true,
        type: String
      },
      marketAreaCategory: {
        required: true,
        type: Object as PropType<MarketAreaCategory>
      },
      errors: {
        required: false,
        type: Map as PropType<Map<string, string | Ref<string>>>,
        default: () => new Map<string, string>()
      }
    },
    setup(props, context) {

      const marketAreaCategoryInputModel = computed({
        get(): MarketAreaCategory {
          return props.marketAreaCategory;
        },
        set(newValue: MarketAreaCategory) {
          context.emit("marketAreaCategoryChanged", newValue);
        },
      });

      return {
        marketAreaCategoryInputModel
      };
    }
  });
