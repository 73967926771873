
  import RegistrationPersonalForm from "@/views/Registration/Forms/RegistrationPersonalForm.vue";
  import RegistrationAddressAndContactForm
    from "@/views/Registration/Forms/RegistrationAddressAndContactForm.vue";

  import RegistrationBankingForm from "@/views/Registration/Forms/RegistrationBankingForm.vue";
  import RegistrationWelcomeCard from "@/views/Registration/RegistrationWelcomeCard.vue";
  import RegistrationFinishedCard from "@/views/Registration/RegistrationFinishedCard.vue";
  import RegistrationService from "@/service/RegistrationService";
  import RegistrationSummaryCard from "@/views/Registration/RegistrationSummaryCard.vue";
  import {MessageTypes} from "@/components/message-types";
  import RegistrationCitizenshipForm
    from "@/views/Registration/Forms/RegistrationCitizenshipForm.vue";
  import RegistrationTaxAndSocialSecurityForm
    from "@/views/Registration/Forms/RegistrationTaxAndSocialSecurityForm.vue";
  import RegistrationEmploymentForm from "@/views/Registration/Forms/RegistrationEmploymentForm.vue";
  import {mapGetters} from "vuex";
  import {RegistrationGetters} from "@/store/modules/registration";
  import {REGISTRATION} from "@/plugins/i18n";
  import RegistrationMiscForm from "@/views/Registration/Forms/RegistrationMiscForm.vue";
  import RegistrationShortTermEmploymentPreliminaryCheck
    from "@/views/Registration/Forms/RegistrationShortTermEmploymentQuestionnaire.vue";
  import RegistrationMixin from "@/views/Registration/RegistrationMixins";


  export default RegistrationMixin.extend({
    name: "RegistrationStepper",
    components: {
      RegistrationAddressAndContactForm,
      RegistrationBankingForm,
      RegistrationFinishedCard,
      RegistrationCitizenshipForm,
      RegistrationEmploymentForm,
      RegistrationMiscForm,
      RegistrationShortTermEmploymentPreliminaryCheck,
      RegistrationTaxAndSocialSecurityForm,
      RegistrationPersonalForm,
      RegistrationWelcomeCard,
      RegistrationSummaryCard
    },
    data: () => ({
      currentStep: 0,
      files: []
    }),
    computed: {
      ...mapGetters({
        registration: RegistrationGetters.GET_REGISTRATION,
        registrationType: RegistrationGetters.GET_REGISTRATION_TYPE
      }),
      registrationUuid(): string {
        return this.$route.params.registrationUuid;
      },
      minorEmploymentSteps(): Array<string> {
        return [
          "RegistrationWelcomeCard",
          "RegistrationPersonalForm",
          "RegistrationAddressAndContactForm",
          "RegistrationBankingForm",
          "RegistrationTaxAndSocialSecurityForm",
          "RegistrationEmploymentForm",
          "RegistrationCitizenshipForm",
          "RegistrationMiscForm",
          "RegistrationSummaryCard",
          "RegistrationFinishedCard"
        ];
      },
      shortTermSteps(): Array<string> {
        return [
          "RegistrationWelcomeCard",
          "RegistrationShortTermEmploymentPreliminaryCheck",
          "RegistrationPersonalForm",
          "RegistrationAddressAndContactForm",
          "RegistrationBankingForm",
          "RegistrationTaxAndSocialSecurityForm",
          "RegistrationEmploymentForm",
          "RegistrationCitizenshipForm",
          "RegistrationMiscForm",
          "RegistrationSummaryCard",
          "RegistrationFinishedCard"
        ];
      },
      socialSecuritySteps(): Array<string> {
        return [
          "RegistrationWelcomeCard",
          "RegistrationPersonalForm",
          "RegistrationAddressAndContactForm",
          "RegistrationBankingForm",
          "RegistrationTaxAndSocialSecurityForm",
          "RegistrationEmploymentForm",
          "RegistrationCitizenshipForm",
          "RegistrationMiscForm",
          "RegistrationSummaryCard",
          "RegistrationFinishedCard"
        ];
      },
      steps(): Array<string> {
        if (this.socialSecurity) {
          return this.socialSecuritySteps;
        } else if (this.shortTerm) {
          return this.shortTermSteps;
        } else if (this.minorEmployment) {
          return this.minorEmploymentSteps;
        } else {
          return [];
        }
      }
    },
    methods: {
      finishRegistration() {
        const title = this.t(REGISTRATION, "finishConfirmationDialog.title");
        const text = this.t(REGISTRATION, "finishConfirmationDialog.text");

        this.$root.$confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              this.$eventBus.$emit(MessageTypes.LOADING, this.t("registration.finishRegistration"));
              RegistrationService.finishRegistration(this.registrationUuid, this.registration)
                .then(response => {
                  this.$eventBus.$emit(MessageTypes.SUCCESS, this.t("registration.finishedRegistration"));

                  this.files = response.data.files;
                  this.currentStep++;
                })
                .catch(() => {
                  this.$eventBus.$emit(MessageTypes.ERROR, "Fehler beim Abschließen der Registrierung");
                });
            }
          });
      },
      previous() {
        this.currentStep--;
      },
      next() {
        this.currentStep++;
      },
      isVisible(name: string): boolean {
        return this.steps[this.currentStep] === name;
      }
    }
  });
