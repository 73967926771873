import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pl-4"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"lg":"6"}},[_c(VCard,[_c(VCardTitle,{staticClass:"py-0"}),_c(VCardActions,{staticClass:"py-0"},[(_vm.isAuthorized('Order_OrderAssignment_CreateEmployeeAssignment'))?_c('EmployeeLookup',{attrs:{"order-id":_vm.orderId,"to-hide":_vm.itemsAdmins,"assignment-type":"EMP"},on:{"selected":selected => _vm.employeeSelected(_vm.itemsEmployees, selected, 'EMP')}}):_vm._e()],1),_c('OrderAssignmentsList',{attrs:{"title":"Marktmitarbeiter","order":_vm.order,"items":_vm.itemsEmployees,"show-delete":_vm.isAuthorized('Order_OrderAssignment_DeleteEmployeeAssignment')},on:{"assignTeamLeader":item => _vm.assignTeamLeader(item),"deleteAssignment":item => _vm.deleteAssignment(item)}})],1)],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"lg":"6"}},[_c(VCard,[_c(VCardTitle,{staticClass:"py-0"}),_c(VCardActions,[(_vm.isAuthorized('Order_OrderAssignment_CreateAdminAssignment'))?_c('EmployeeLookup',{attrs:{"order-id":_vm.orderId,"to-hide":_vm.itemsEmployees,"assignment-type":"SUP"},on:{"selected":selected => _vm.employeeSelected(_vm.itemsAdmins, selected, 'SUP')}}):_vm._e()],1),_c('OrderAssignmentsList',{attrs:{"title":"Marktadmins","order":_vm.order,"items":_vm.itemsAdmins,"show-delete":_vm.isAuthorized('Order_OrderAssignment_DeleteAdminAssignment')},on:{"assignTeamLeader":item => _vm.assignTeamLeader(item),"deleteAssignment":item => _vm.deleteAssignment(item)}})],1)],1)],1),_c('ConfirmDialog',{ref:"confirmDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }