
  import {RegistrationDataMisc} from "@/model/Registration";
  import {helpers, minLength, required, requiredIf} from "@vuelidate/validators";
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import RegistrationFileUpload from "@/views/Registration/Forms/RegistrationFileUpload.vue";
  import RegistrationService from "@/service/RegistrationService";
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    SetupContext,
    toRefs,
    watch
  } from "vue";
  import {useDialog} from "@/views/Registration/RegistrationComposable";
  import useVuelidate, {ErrorObject} from "@vuelidate/core";
  import {ValidationResult} from "@/model/ValidationResult";
  import Utils from "@/Utils";
  import {VALUE} from "@/plugins/i18n";
  import {t, tWithParms} from "@/mixin/mixins";

  export default defineComponent({
    name: "RegistrationMiscForm",
    components: {
      RegistrationFileUpload,
      RegistrationCard
    },
    props: {
      step: {
        type: Number,
        default: null
      }
    },
    setup (props, context: SetupContext) {

      const {
        state: generalState,
        falseValue,
        trueValue,
        shortTerm,
        registration,
        registrationUuid,
        mobile,
        next
      } = useDialog(context);

      const initialState = {
        model: {
          misc: new RegistrationDataMisc()
        },
        student: null,
        uploadedFiles: new Array<string>()
      };

      const state = reactive({ ...initialState });

      const rules = computed(() => ({
        model: {
          misc: {
            selfEmployed: {
              required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required)
            },
            disabled: {
              required: helpers.withMessage(tWithParms("validation.required", t("registration.misc.disabled")), required)
            }
          }
        },
        uploadedFiles: {
          required: helpers.withMessage(tWithParms("validation.required", t("registration.misc.studentUploadRequired")), requiredIf(function () {
            return !!state.student;
          })),
          minLength: minLength(1)
        }
      }));

      // @ts-ignore
      let v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (generalState.serverErrors.length > 0) {
          generalState.serverErrors.forEach((error: ValidationResult) => {
            errors.set(error.path, error.message);
          });

          return errors;
        } else {
          const model = v$.value;
          if (model.$dirty) {
            model.$errors.forEach((error: ErrorObject) => {
              if (!errors.has(error.$propertyPath)) {
                // @ts-ignore
                errors.set(error.$propertyPath, error.$message);
              }
            });
          }
        }

        return errors;
      });

      watch(() => registration.misc, (newValue, oldValue) => {
        if (newValue) {
          state.model.misc = Object.assign(state.model.misc, newValue);
        }
      });

      watch(() => state.student, (newValue, oldValue) => {
        generalState.dirty = true;
      });

      onMounted(() => {
        if (registration.value.misc) {
          state.model.misc = registration.value.misc;

          RegistrationService.getFiles(registrationUuid.value, "misc")
            .then(response => {
              state.uploadedFiles = response.data.files;
            });
        } else {
          generalState.dirty = true;
        }
      });

      const deleteFile = (file: string) => {
        Utils.remove(state.uploadedFiles, file);
      };

      const onFileUploaded = (file: File) => {
        state.uploadedFiles.push(file.name);
      };

      const onNext = () => {
        next(v$, state.model);
      };

      return {
        ...toRefs(state),
        v$,
        errors,
        falseValue,
        trueValue,
        mobile,
        shortTerm,
        deleteFile,
        onFileUploaded,
        onNext
      };
    }
  });
