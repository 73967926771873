<template>
  <VDataTable
    :headers="headers"
    :items="items"
    :loading="loading"
    no-data-text="Keine Daten verfügbar"
    hide-default-footer
  >
    <template #body="{ items }">
      <tbody>
        <tr
          v-for="item in items"
          :key="item.data"
        >
          <td class="cell no-padding text-right">
            {{ item.date }}
          </td>
          <td class="cell no-padding text-left">
            {{ item.orderName }}
          </td>
          <td class="cell no-padding text-left">
            {{ item.address }}
          </td>
          <td class="cell no-padding text-right">
            {{ item.start }}
          </td>
          <td class="cell no-padding text-right">
            {{ item.end }}
          </td>
        </tr>
      </tbody>
    </template>
  </VDataTable>
</template>

<script>
  import TimecontrollingService from "@/service/TimecontrollingService";

  export default {
    data: () => ({
      items: [],
      loading: false,
      headers: [
        {
          text: "Datum",
          align: "right",
          value: "date"
        },
        {
          text: "Auftrag",
          align: "left",
          value: "orderName"
        },
        {
          text: "Adresse",
          align: "left",
          value: "address"
        },
        {
          text: "Start",
          align: "right",
          value: "start"
        },
        {
          text: "Ende",
          align: "right",
          value: "end"
        }
      ]
    }),
    created () {
      this.init();
    },
    methods: {
      init: function () {
        this.loading = true;
        TimecontrollingService.getTimesWithoutTeamlead()
          .then(response => {
            this.items = response.data;
          })
          .finally(() => this.loading = false);
      }
    }
  };
</script>
