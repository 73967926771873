import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('keep-alive',[(_vm.isAuthorized('Superadmin'))?_c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v(" mdi-arrow-left ")])],1),_c(VRow,{attrs:{"align":"center","justify":"space-between"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{ref:"searchInputField",attrs:{"color":"primary","clearable":"","flat":"","solo":"","label":"Suche...","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c(VContainer,[_c(VRow,{attrs:{"justify":"space-around"}},[_c(VCol,{attrs:{"cols":"12","lg":"4","xs":"12"}},[_c('TheHeaderSearchLookupResultList',{attrs:{"items":_vm.employees,"title":"Mitarbeiter"},on:{"itemClicked":_vm.goTo}})],1),_c(VCol,{attrs:{"cols":"12","lg":"4","xs":"12"}},[_c('TheHeaderSearchLookupResultList',{attrs:{"items":_vm.orders,"title":"Märkte/Projekte"},on:{"itemClicked":_vm.goTo}})],1),_c(VCol,{attrs:{"cols":"12","lg":"4","xs":"12"}},[_c('TheHeaderSearchLookupResultList',{attrs:{"items":_vm.recent,"title":"Besucht"},on:{"itemClicked":_vm.goTo}})],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }