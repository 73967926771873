
  import Vue from "vue";
  import Component from "vue-class-component";
  import {ClockingParameters} from "@/store/modules/timeclock";
  import {Getter} from "vuex-class";
  import TimeclockEmployeeEntry from "@/model/TimeclockEmployeeEntry";
  import {HeaderGetters, HeaderModule} from "@/store/modules/header";
  import TimeclockClockingRequest from "@/model/TimeclockClockingRequest";
  import FabBlockButton from "@/components/FabBlockButton.vue";
  import TimeclockOrderTabEmployeesAddDialog from "@/views/Timeclock/TimeclockOrderTabEmployeesAddDialog.vue";
  import TimeclockOrderEmployeeList from "@/views/Timeclock/TimeclockOrderEmployeeList.vue";
  import TimeclockOrderTabSelectableList from "@/views/Timeclock/TimeclockOrderSelectableEmployeeList.vue";
  import FabButton from "@/components/FabButton.vue";
  import {Prop, Watch} from "vue-property-decorator";
  import WorklogUtils, {minutes} from "@/WorklogUtils";
  import Utils from "@/Utils";
  import {MessageTypes} from "@/components/message-types";
  import OrderChecklistDetailsDialog from "@/views/Orders/OrderChecklistDetailsDialog.vue";
  import Order from "@/model/Order";
  import moment from "moment";
  import TimeclockOrderNumberAssignmentDialog from "@/views/Timeclock/TimeclockOperatorNumberAssignmentDialog.vue";
  import {OrderOperator} from "@/views/Orders/Operators/OrderOperators.vue";
  import Employee from "@/model/Employee";
  import OrderOperatorService, {OrderOperatorCreateRequest} from "@/service/OrderOperatorService";
  import {OrderType} from "@/enums";
  import OrderServiceV2 from "@/service/OrderServiceV2";

  @Component({
    components: {
      FabButton,
      FabBlockButton,
      OrderChecklistDetailsDialog,
      TimeclockOrderTabEmployeesAddDialog,
      TimeclockOrderNumberAssignmentDialog,
      TimeclockOrderEmployeeList,
      TimeclockOrderTabSelectableList
    }
  })
  export default class TimeclockOrderTabClockIn extends Vue {
    @Getter(HeaderGetters.GET_DATE, {namespace: HeaderModule}) date: string;

    @Getter(HeaderGetters.GET_TIME, {namespace: HeaderModule}) time: string;

    @Prop({default: () => []})
    public items: Array<any>;

    @Prop({default: () => []})
    public errors: Array<any>;

    @Prop({default: () => []})
    public operators: Array<OrderOperator>;

    private addedEmployees: Array<any> = [];

    private selectedItems: Array<any> = [];

    private fab = false;

    private order = new Order();

    get orderId(): number {
      return Number(this.$route.params.orderId);
    }

    get customerId(): number | null {
      return this.order && this.order.customer && this.order.customer.id;
    }

    get hasCustomer(): boolean {
      return this.customerId != null;
    }

    get lists(): Array<any> {
      return [
        {items: this.clockedIn, title: "Eingestempelt"},
        {items: this.available, title: "Verfügbar"},
        {items: this.assigned, title: "Zugewiesene Mitarbeiter"},
        {items: this.addedEmployees, title: "Hinzugefügte Mitarbeiter"}
      ];
    }

    get totalTime(): number {
      return this.items
        .filter(item => item.worklogs)
        .flatMap(item => item.worklogs)
        .filter(worklog => worklog.order.id === this.orderId)
        .filter(worklog => {
          return worklog.worklogType === "WORK";
        })
        .map(worklog => {
          if (worklog.open) {
            return moment().diff(moment(worklog.start, "HH:mm"), "minutes");
          } else {
            return minutes(worklog);
          }
        })
        .reduce((prev, cur) => prev + cur, 0);
    }

    get clockedIn() {
      return this.items
        .filter(item => {
          const openWorklog = WorklogUtils.getOpenWorklog(this.filterOrderRelevantWorklogs(item.worklogs));
          return openWorklog != null;
        })
        .sort((item1, item2) => {
          const openWorklog1 = WorklogUtils.getOpenWorklog(this.filterOrderRelevantWorklogs(item1.worklogs));
          const openWorklog2 = WorklogUtils.getOpenWorklog(this.filterOrderRelevantWorklogs(item2.worklogs));
          // @ts-ignore
          return this.$moment(openWorklog1.start, "HH:mm").diff(this.$moment(openWorklog2.start, "HH:mm"), "minutes");
        });
    }

    get available() {
      return this.items
        .filter(item => item.worklogs && this.filterOrderRelevantWorklogs(item.worklogs).length > 0)
        .filter(item => !WorklogUtils.getOpenWorklog(this.filterOrderRelevantWorklogs(item.worklogs)))
        .filter(item => !this.clockedIn.includes(item));
    }

    get assigned() {
      return this.items
        .filter(item => this.filterOrderRelevantWorklogs(item.worklogs).length == 0);
    }

    get clockedOut() {
      return this.items
        .filter(item => item.worklogs && item.worklogs.length > 0)
        .filter(item => !WorklogUtils.getOpenWorklog(this.filterOrderRelevantWorklogs(item.worklogs)));
    }

    @Watch("items")
    onItemsChanged() {
      this.selectedItems = [];
      const clockedEmployees = this.addedEmployees.filter(added => {
        return this.items.find(item => {
          // @ts-ignore
          return added.id === item.id;
        });
      });

      clockedEmployees.forEach(clockedEmployee => Utils.remove(this.addedEmployees, clockedEmployee));
    }

    mounted() {
      window.scrollTo(0, 0);
      OrderServiceV2.getOrder(this.orderId)
        .then(response => {
          this.order = Object.assign(new Order(), response.data);
        });
    }

    filterOrderRelevantWorklogs(worklogs: any) {
      return worklogs ? worklogs.filter((worklog: any) => worklog.order.id === this.orderId) : [];
    }

    isOptionAvailable(item: TimeclockEmployeeEntry, worklogType: string) {
      // @ts-ignore
      const openWorklog = WorklogUtils.getOpenWorklog(this.filterOrderRelevantWorklogs(item.worklogs));
      if (openWorklog) {
        return openWorklog.worklogType !== worklogType;
      } else {
        return true;
      }
    }

    onClick(item: TimeclockEmployeeEntry, worklogType: string) {
      const found = this.findItemInSelection(item);
      if (found) {
        this.deselectItem(item);
        // @ts-ignore
        if (found.worklogType !== worklogType) {
          this.selectItem(item, worklogType);
        }
      } else {
        this.selectItem(item, worklogType);
      }
    }

    selectAll(items: Array<TimeclockEmployeeEntry>, worklogType: string) {
      // This is the case when all items have already been selected for that type and we hit the button again
      // to deselect them
      const deselectAllItems = this.allItemsEqualWorklogType(items, worklogType);

      this.selectedItems = [];
      if (!deselectAllItems) {
        items.filter(item => this.isOptionAvailable(item, worklogType))
          .forEach(item => {
            this.selectItem(item, worklogType);
          });
      }
    }

    findItemInSelection(item: TimeclockEmployeeEntry) {
      return this.selectedItems.find(selectedItem => {
        // @ts-ignore
        return selectedItem.employeeId === item.id;
      });
    }

    selectItem(item: TimeclockEmployeeEntry, worklogType: string) {
      const found = this.findItemInSelection(item);
      if (!found) {
        // @ts-ignore
        this.selectedItems.push({employeeId: item.id, worklogType: worklogType});
      } else {
        found.worklogType = worklogType;
      }
    }

    deselectItem(item: TimeclockEmployeeEntry) {
      const found = this.findItemInSelection(item);
      if (found) {
        Utils.remove(this.selectedItems, found);
      }
    }

    itemColor(item: TimeclockEmployeeEntry, worklogType: string) {
      const found = this.findItemInSelection(item);
      return found && found.worklogType === worklogType ? "primary" : "";
    }

    allItemsEqualWorklogType(items: Array<TimeclockEmployeeEntry>, worklogType: string) {
      return items.every(item => {
        const found = this.findItemInSelection(item);
        return found && found.worklogType === worklogType;
      });
    }

    openCustomer() {
      if (this.hasCustomer) {
        this.$router.push({
          name: "CustomerInfo",
          params: {customerId: this.customerId as unknown as string}
        });
      }
    }

    openOrder() {
      this.$router.push({path: `/orders/${this.orderId}/info`});
    }

    openAddEmployeeDialog() {
      // @ts-ignore
      this.$refs.addDialog.open(this.addedEmployees)
        .then((toAdd: any) => {
          this.addedEmployees = toAdd;
        });
    }

    openChecklist() {
      this.$eventBus.$emit(MessageTypes.OPEN_ORDER_CHECKLIST_DETAILS, this.orderId);
    }

    async clockIn() {
      const employees: Array<TimeclockClockingRequest> = [];

      // To avoid loading the entire list of assigned operator numbers after each create operation from the backend we
      // will cache them here
      let alreadyAssignedOperatorNumbers = this.operators.map(operator => operator.operatorNumber);

      for (const selectedItem of this.selectedItems) {
        if (selectedItem.worklogType) {
          if (this.order && this.order.orderType == OrderType.INV) {
            if (selectedItem.worklogType == "WORK") {
              let index = this.operators.findIndex(operator => operator.employee.employeeId == selectedItem.employeeId);
              if (index == -1) {
                let itemIndex = this.items.findIndex(item => item.employeeId == selectedItem.employeeId);
                // @ts-ignore
                let item;
                if (itemIndex != -1) {
                  item = this.items[itemIndex];
                } else {
                  itemIndex = this.addedEmployees.findIndex(item => item.employeeId == selectedItem.employeeId);
                  item = this.addedEmployees[itemIndex];
                }

                // If the number is already assigned to another employee we ignore the default number
                // @ts-ignore
                let operatorNumberAlreadyAssigned = alreadyAssignedOperatorNumbers.findIndex(operatorNumber => operatorNumber == item.operatorNumber) != -1;
                if (item.operatorNumber && !operatorNumberAlreadyAssigned) {
                  await OrderOperatorService.createOrderOperator(this.orderId, {
                    employeeId: item.employeeId,
                    operatorNumber: item.operatorNumber
                  } as OrderOperatorCreateRequest)
                    .then(() => {
                      // @ts-ignore
                      alreadyAssignedOperatorNumbers.push(item.operatorNumber);
                    });
                } else {
                  let employee = {
                    id: item.id,
                    employeeId: item.employeeId,
                    firstName: item.firstName,
                    lastName: item.lastName
                  } as Employee;

                  try {
                    // @ts-ignore
                    let orderOperator = await this.$refs.orderNumberDialog.open(this.orderId, employee);
                    // @ts-ignore
                    alreadyAssignedOperatorNumbers.push(orderOperator.operatorNumber);
                  } catch (err) {
                  /* do nothing */
                  }
                }
              }
            }
          }

          const clockingRequest = new TimeclockClockingRequest();
          clockingRequest.employeeId = selectedItem.employeeId;
          clockingRequest.worklogType = selectedItem.worklogType;
          clockingRequest.clockingAction = "IN";
          employees.push(clockingRequest);
        }
      }

      const params = new ClockingParameters();
      params.orderId = Number(this.$route.params.orderId);
      params.date = this.date;
      params.time = this.time;
      params.employees = employees;
      params.messageProgress = "Stemple ein...";
      params.messageSuccess = "Einstempeln erfolgreich!";
      params.messageValidation = "Einstempeln teilweise erfolgreich.";

      this.$emit("clockIn", params);
    }
  }
