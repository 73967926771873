export default class PerDiemUpdateRequest {
  public orderId: number;

  public date: string;

  public misc: number;

  public catering: number;

  public breakfast: boolean;
}
