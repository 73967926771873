import {PagedResult} from "@/service/PagedResult";

export default {
  extractCollection<T> (result: PagedResult, key: string): Array<T> {
    const collections = result._embedded;

    if(collections && collections.hasOwnProperty(key)) {
      // @ts-ignore
      return collections[key] as Array<T>;
    }
    return [];
  }
};
