
  import RegistrationCard from "@/views/Registration/RegistrationCard.vue";
  import {alpha, helpers, maxLength, minLength, required} from "@vuelidate/validators";
  import {computed, defineComponent, reactive, ref, Ref, toRefs, watch} from "vue";
  import useVuelidate, {ErrorObject} from "@vuelidate/core";
  import {MessageTypes} from "@/components/message-types";
  import RegistrationService from "@/service/RegistrationService";
  import eventBus from "@/eventbus";
  import {t} from "@/mixin/mixins";
  import {AxiosError} from "axios";
  import {useDialog} from "@/views/Registration/RegistrationComposable";
  import {ValidationResult} from "@/model/ValidationResult";
  import {useRouter} from "vue-router/composables";

  export default defineComponent({
    name: "RegistrationStartCard",
    components: {
      RegistrationCard
    },
    setup(props, context) {
      let router = useRouter();

      const initialState = reactive({
        registrationCode: "",
        codePlaceholder: ""
      });

      const state = reactive({...initialState});

      const validationMessage = "Der Code muss 4 Buchstaben haben, z.B. ABCD.";

      const rules = {
        registrationCode: {
          alpha: helpers.withMessage(validationMessage, alpha),
          required: helpers.withMessage(validationMessage, required),
          minLength: helpers.withMessage(validationMessage, minLength(4)),
          maxLength: helpers.withMessage(validationMessage, maxLength(4))
        }
      };

      const v$ = useVuelidate(rules, state);

      const {
        state: generalState,
      } = useDialog(context);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (generalState.serverErrors.length > 0) {
          generalState.serverErrors.forEach((error: ValidationResult) => {
            errors.set(error.path, error.message);
          });

          return errors;
        } else {
          const model = v$.value;
          if (model.$dirty) {
            model.$errors.forEach((error: ErrorObject) => {
              if(!errors.has(error.$propertyPath)) {
                // @ts-ignore
                errors.set(error.$propertyPath, error.$message);
              }
            });
          }
        }

        return errors;
      });

      watch(() => state.registrationCode, (newValue, oldValue) => {
        generalState.serverErrors.length = 0;
        v$.value.registrationCode.$reset();
      });

      const startRegistration = () => {
        v$.value.registrationCode.$reset();
        v$.value.registrationCode.$touch();
        if (v$.value.registrationCode.$error) {
          return;
        }

        eventBus.$emit(MessageTypes.LOAD);
        RegistrationService.startRegistration(state.registrationCode)
          .then(response => {
            const resourceLocation = response.headers.location;
            // @ts-ignore
            router.push(resourceLocation.replace("/registrations", "/register"));
          })
          .catch((error: AxiosError) => {
            if (error.response) {
              if (error.response.status === 404) {
                generalState.serverErrors.push(new ValidationResult("registrationCode", t("errorCodes.REGISTRATION_CODE_INVALID")));
              } else {
                eventBus.$emit(MessageTypes.ERROR, t("errore.generalError"));
              }
            }
          })
          .finally(() => eventBus.$emit(MessageTypes.LOADING_FINISHED));
      };

      const registrationCodeRef = ref<HTMLInputElement | null>(null);
      const onInput = () => {
        // Unfocus textfield once all letters have been entered
        if (!v$.value.$invalid) {
          registrationCodeRef.value?.blur();
        }

        state.registrationCode = state.registrationCode.toUpperCase();
      };

      return {
        ...toRefs(state),
        errors,
        onInput,
        startRegistration,
        registrationCodeRef
      };
    }
  });
