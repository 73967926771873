
  import ModalDialog from "@/components/ModalDialog.vue";
  import useVuelidate from "@vuelidate/core";
  import {helpers, minLength, required, sameAs} from "@vuelidate/validators";
  import AuthService from "@/service/AuthService";
  import {MessageTypes} from "@/components/message-types";
  import ValidationError from "@/model/ValidationError";
  import {computed, defineComponent, reactive, Ref, toRefs, watch} from "vue";
  import eventBus from "@/eventbus";

  export default defineComponent({
    name: "UserProfilePasswordDialog",
    components: {
      ModalDialog
    },
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    setup (props, context) {
      const initialState = {
        currentPassword: "",
        newPassword: "",
        newPasswordRepeat: "",
        serverErrors: new Map<string, string>()
      };

      const state = reactive({...initialState});

      const newPasswordRef = computed(() => state.newPassword);
      const rules = {
        currentPassword: {
          required: helpers.withMessage("Aktuelles Passwort ist erforderlich.", required),
        },
        newPassword: {
          required: helpers.withMessage("Neues Passwort ist erforderlich.", required),
          minLength: helpers.withMessage("Neues Passwort muss mindestens 8 Zeichen lang sein.", minLength(8))
        },
        newPasswordRepeat: {
          required: helpers.withMessage("Neues Passwort (Wiederholung) ist erforderlich.", required),
          sameAsPassword: helpers.withMessage("Passwörter stimmen nicht überein.", sameAs(newPasswordRef)),
        }
      };

      const v$ = useVuelidate(rules, state);


      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (state.serverErrors.size > 0) {
          return state.serverErrors;
        } else {
          if (v$.value.$dirty) {
            if(v$.value.currentPassword.$error) {
              // @ts-ignore
              errors.set("currentPassword", v$.value.currentPassword.$errors[0].$message);
            }
            if(v$.value.newPassword.$error) {
              // @ts-ignore
              errors.set("newPassword", v$.value.newPassword.$errors[0].$message);
            }
            if(v$.value.newPasswordRepeat.$error) {
              // @ts-ignore
              errors.set("newPasswordRepeat", v$.value.newPasswordRepeat.$errors[0].$message);
            }
          }
        }

        return errors;
      });

      const close = () => {
        context.emit("input", false);
        context.emit("close");
      };

      const save = () => {
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          AuthService.updatePassword(state.currentPassword, state.newPassword)
            .then(() => {
              eventBus.$emit(MessageTypes.SUCCESS, "Passwort geändert.");
              close();
            })
            .catch(error => {
              if (error.response.status === 422) {
                const errorMap = new Map<string, string>();
                error.response.data.forEach((error: ValidationError) => {
                  if (error.path === "currentPassword") {
                    errorMap.set(error.path, "Aktuelles Passwort ist nicht korrekt");
                  } else {
                    errorMap.set(error.path, error.message);
                  }
                });

                // Needed to trigger reactivity
                state.serverErrors = errorMap;
              } else {
                eventBus.$emit(MessageTypes.ERROR, "Fehler! Bitte Admin kontaktieren");
              }
            });
        }
      };

      watch(() => props.value, (newValue, oldValue) => {
        if (newValue) {
          v$.value.$reset();
          state.currentPassword = "";
          state.newPassword = "";
          state.newPasswordRepeat = "";
        }
      });

      return {...toRefs(state), v$, errors, close, save};
    }
  });
