import {RegistrationType} from "@/enums";

export class RegistrationDataPersonal {
  sex!: string;
  firstName!: string;
  birthName?: string | null;
  lastName!: string;
  maritalStatus!: string;
  birthdate!: string;

  get fullName (): string {
    return this.firstName + " " + this.lastName;
  }
}

export class RegistrationDataAddressAndContact {
  street!: string;
  zip!: string;
  city!: string;
  email!: string;
  phone!: string;
}

export class RegistrationDataCitizenship {
  citizenship!: string;
  birthplace!: string;
  euCitizen?: boolean | null;
  residencePermitValidTo?: string | null;
  passportValidTo?: string | null;
  workPermitValidTo?: string | null;
}

export class RegistrationDataMisc {
  disabled!: boolean;
  selfEmployed!: boolean;
  miniJobCompensation?: string;
  maxMonthlyCompensation?: string;
  maxWeeklyHours?: string;
}

export class RegistrationData {
  personal?: RegistrationDataPersonal | null = null;

  addressAndContact?: RegistrationDataAddressAndContact | null = null;

  banking?: RegistrationDataBanking | null = null;

  citizenship?: RegistrationDataCitizenship | null = null;

  education?: RegistrationDataEducation | null = null;
  employment?: RegistrationDataEmployment | null = null;

  misc?: RegistrationDataMisc | null = null;

  tax?: RegistrationDataTax | null = null;
  socialSecurity?: RegistrationDataSocialSecurity | null = null;
}

export class RegistrationDataBanking {
  bic!: string;
  iban!: string;
}

export class RegistrationDataEducation {
  schoolEducation!: string;
  professionalDegree!: string;
}

export class RegistrationDataEmployment {
  mainEmployer!: boolean;
  otherEmployments!: boolean;
  otherEmployerInCurrentYear!: boolean;
  daysShortTermEmployment?: number | null;
}

export class RegistrationDataTax {
  taxClass!: string;
  taxIdentNumber!: string;
  childTaxCreditCount!: number;
}

export class RegistrationDataSocialSecurity {
  pensionInsuranceNumber: string;
  healthInsurance!: string;
  pensionInsuranceVoluntaryPayment?: boolean;
  children: Array<RegistrationDataSocialSecurityChild> = new Array<RegistrationDataSocialSecurityChild>();
}

export class RegistrationDataSocialSecurityChild {
  firstName!: string;
  lastName!: string;
  birthdate!: string;
}

export class Registration {
  uuid!: string;
  registrationType!: RegistrationType;
  registrationData: RegistrationData = new RegistrationData();
}
