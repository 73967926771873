
  import Vue from "vue";
  import EmployeeDetailsCardListEntry from "./EmployeeDetailsCardListEntry.vue";
  import EmployeeDetailsCardWagesDialog from "./EmployeeDetailsCardWagesDialog.vue";
  import WageUtils from "@/views/Employee/details/WageUtils";
  import EmployeeDetailsCardWagesHistory from "./EmployeeDetailsCardWagesHistory.vue";
  import Wage from "@/model/Wage";
  import {WageType} from "@/enums";
  import Employee from "@/model/Employee";
  import FormDatePicker from "@/components/FormDatePicker.vue";

  class ListEntry {
    public label: string;
    public text: string;
    public icon?: string;
  }

  export default Vue.extend({
    name: "EmployeeDetailsCardWages",
    components: {
      FormDatePicker,
      EmployeeDetailsCardListEntry,
      EmployeeDetailsCardWagesDialog,
      EmployeeDetailsCardWagesHistory
    },
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      employee: {
        type: Employee,
        default: () => new Employee()
      }
    },
    data: () => ({
      dialog: false
    }),
    computed: {
      getInventoryBonusEligible(): boolean {
        const eligible = this.employee.inventoryBonusEligible;
        return eligible ? eligible : false;
      },
      getInventoryBonusEligibleSince(): string {
        const eligibleSince = this.employee.isInventoryBonusEligibleSince;
        return eligibleSince ? eligibleSince : "";
      },
      employeeId(): number {
        return Number(this.$route.params.employeeId);
      },
      currentWage(): Wage {
        return WageUtils.getCurrentWage(this.employee.wages);
      },
      nextWage(): Wage | null {
        return WageUtils.getNextWage(this.employee.wages);
      },
      currentWageListEntries(): Array<ListEntry> {
        const listEntries = new Array<ListEntry>();
        const wageTypeI18N = this.currentWage.wageType ? this.$vuetify.lang.t("$vuetify.enum.wageType." + this.currentWage.wageType) : "";
        const validFrom = this.$moment(this.currentWage.validFrom).format("MMMM YYYY");
        listEntries.push({label: "Entlohnungsart", text: wageTypeI18N});
        listEntries.push({label: "Gehalt", text: this.currentWage.wage + "€"});
        if (this.currentWage.wageType === WageType.SAL && this.currentWage.targetHours) {
          listEntries.push({label: "Sollstunden", text: "" + this.currentWage.targetHours});
        }
        listEntries.push({label: "Gültig seit", text: validFrom});

        return listEntries;
      },
      nextWageListEntries(): Array<ListEntry> {
        const listEntries = new Array<ListEntry>();
        if (this.nextWage) {
          const wageTypeI18N = this.$vuetify.lang.t("$vuetify.enum.wageType." + this.nextWage.wageType);
          const validFrom = this.$moment(this.nextWage.validFrom).format("MMMM YYYY");
          listEntries.push({label: "Entlohnungsart", text: wageTypeI18N});
          listEntries.push({label: "Gehalt", text: "" + this.nextWage.wage});
          if (this.nextWage.wageType === WageType.SAL) {
            listEntries.push({label: "Sollstunden", text: "" + this.nextWage.targetHours});
          }
          listEntries.push({label: "Gültig ab", text: validFrom});
        }

        return listEntries;
      }
    }
  });
