import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalDialog',{attrs:{"crud":"","editable":"","disable-fullscreen":""},on:{"save":_vm.save,"close":_vm.close},scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.t("employee.operatorNumber"))+" ")]},proxy:true},{key:"content",fn:function(){return [_c(VForm,[_c(VContainer,[_c(VCol,[_c(VRow,[_c('span',[_c('b',[_vm._v("Name:")]),_vm._v(" "+_vm._s(_vm.name))])]),_c(VRow,[_c(VTextField,{staticClass:"mt-4",attrs:{"type":"number","label":_vm.t('employee.operatorNumber'),"outlined":"","error-messages":_vm.errorMessage},model:{value:(_vm.operatorNumber),callback:function ($$v) {_vm.operatorNumber=$$v},expression:"operatorNumber"}})],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }