import Employment from "@/model/Employment";
import moment from "moment";

export default {
  getCurrentEmployment (employments: Array<Employment>): Employment {
    const currentEmployment = employments.find(employment => {
      const validFromIsSameOrBeforeCurrentMonth = moment(employment.validFrom).isSameOrBefore(moment().startOf("month"));
      const validToIsSameOrAfterCurrentDay = !employment.validTo || moment(employment.validTo).isSameOrAfter(moment(), "day");
      return validFromIsSameOrBeforeCurrentMonth && validToIsSameOrAfterCurrentDay;
    });

    return Object.assign(new Employment(), currentEmployment) || new Employment();
  },
  getNextEmployment (employments: Array<Employment>): Employment | null {
    const currentEmployment = this.getCurrentEmployment(employments);
    const indexCurrentEmployment = employments.findIndex(emp => emp.id === currentEmployment.id);
    if (employments.length > indexCurrentEmployment + 1) {
      return Object.assign(new Employment(), employments[indexCurrentEmployment + 1]);
    } else {
      return null;
    }
  },
  getFutureEmployments (employments: Array<Employment>): Array<Employment> {
    let futureEmployments = new Array<Employment>();
    const nextEmployment = this.getNextEmployment(employments);
    if (nextEmployment != null) {
      const nextEmploymentId = nextEmployment.id;
      const indexNextEmployment = employments.findIndex(emp => emp.id === nextEmploymentId);
      if (employments.length > indexNextEmployment + 1) {
        futureEmployments = employments.slice(indexNextEmployment + 1);
      }
    }

    return futureEmployments;
  }
};
