
  import Vue from "vue";
  import Wage from "@/model/Wage";
  import ModalDialog from "@/components/ModalDialog.vue";
  import moment from "moment";

  export default Vue.extend({
    name: "EmployeeDetailsCardWagesHistory",
    components: {
      ModalDialog
    },
    props: {
      wages: {
        type: Array as () => Array<Wage>,
        default: () => new Array<Wage>()
      }
    },
    data: () => ({
      dialog: false
    }),
    computed: {
      sortedWages (): Array<Wage> {
        if (this.wages.length > 0) {
          const sorted = this.wages.slice(0);
          sorted.sort((a, b) => moment(b.validFrom).diff(moment(a.validFrom)));
          return sorted;
        } else {
          return this.wages;
        }
      }
    }
  });
